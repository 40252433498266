import { EuiFlexGroup, EuiLink, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui';
import { LegacyRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, CellMeasurer, CellMeasurerCache, AutoSizer, ScrollSync } from 'react-virtualized';
import { RenderedRows } from 'react-virtualized/dist/es/List';
import debounce from 'lodash.debounce';

import { useAppSelector } from '@/store';
import { profilerSelectors } from '@/store/profiler';
import { EmptyData } from '../Core';
import { ProfilerCard } from '../ProfilerCard';
import { AppSearchProfiler, AppSearchProfilerRef } from '../AppSearchProfiler';
import { useWindowSize } from '@/hooks';
import { customerSelectors } from '@/store/customer';
import { ProfilerContainer } from '../layouts';
import { useCountry } from '../LeakCountryBadge';
import { Loader } from '../Loader';
import { CollapsedContext } from '../CollapsedItem';
import { Highlight } from '../CardData';

import { ProfilerSearchDataHeader } from './ProfilerSearchDataHeader';

interface ProfilerSearchData {
  isSearched: boolean;
  highlightValues: Highlight[];
  onLeakOpen: (data: Definitions.Leak) => void;
  onLoadMore: () => void;
  searchBarHeight: number;
}

interface ListHeaderItem {
  isHeader: true;
}

const cache = new CellMeasurerCache({
  defaultHeight: 500,
});

const scrollEvent = new Event('scroll-profiler-list');

export const ProfilerSearchData = memo<ProfilerSearchData>(function ProfilerSearchData({
  highlightValues,
  isSearched,
  onLeakOpen,
  onLoadMore,
  searchBarHeight,
}) {
  const lastIndexRawWithLoader = useRef<number | null>(null);
  const { height } = useWindowSize();
  const { getCountry } = useCountry();
  const [searchValue, setSearchValue] = useState<string>('');
  const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);
  const { t } = useTranslation();
  const items = useAppSelector(profilerSelectors.getData);
  const totalItems = useAppSelector(profilerSelectors.getTotalData);
  const isLoading = useAppSelector(profilerSelectors.getLoading);
  const isLoadMoreLoading = useAppSelector(profilerSelectors.getLoadMoreLoading);
  const savedSearchParams = useAppSelector(profilerSelectors.getSearchParams);
  const uiRawLimit = useAppSelector(customerSelectors.getUiLimitRow);
  const searchParams = useAppSelector(profilerSelectors.getSearchParams);

  const appSearchRef = useRef<AppSearchProfilerRef | null>(null);
  const listRef = useRef<List | null>(null);
  const itemsRefs = useRef<Record<number, HTMLDivElement | null>>({});
  const [showedIndex, setShowedIndex] = useState<number>(-1);
  const searchIndex = useRef<string[]>([]);
  const [jsonItems, setJsonItems] = useState<string[]>([]);
  const queryItems = useMemo(() => savedSearchParams?.query, [savedSearchParams]);

  const [collapsed, setCollapsed] = useState<string[]>([]);

  const handleCollapseShow = useCallback((key: string) => {
    setCollapsed((items) => {
      if (!items.includes(key)) return [...items, key];
      return items;
    });
  }, []);

  const highlights = useMemo<Highlight[]>(
    () =>
      searchValue
        ? [
            {
              key: '',
              operator: '',
              value: searchValue,
            },
          ]
        : highlightValues,
    [searchValue, highlightValues]
  );

  const listHeight = useMemo(() => height - (searchBarHeight + 160), [height, searchBarHeight]);
  const listItems = useMemo(() => [{ isHeader: true } as ListHeaderItem, ...items], [items]);

  useEffect(() => {
    cache.clearAll();
  }, [queryItems]);

  useEffect(() => {
    if (searchParams?.query) {
      listRef.current?.scrollToPosition(0);
    }
  }, [searchParams?.query]);

  const onChangeJson = useCallback((id: string) => {
    setJsonItems((state) => {
      if (state.findIndex((itemId) => itemId === id) === -1) {
        return [...state, id];
      } else {
        return state.filter((itemId) => id !== itemId);
      }
    });
  }, []);

  const forceUpdate = useCallback(
    (rowIndex: number, allowScroll = true) => {
      cache.clear(rowIndex, 0);
      listRef.current?.recomputeRowHeights(rowIndex);
      if (allowScroll) listRef.current?.scrollToRow(rowIndex);
    },
    [listRef]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadMoreRows = useCallback(
    debounce(({ startIndex }: RenderedRows) => {
      if (startIndex + 2 >= items.length - 1 && totalItems && totalItems.value && items.length < totalItems.value) {
        onLoadMore();
      }
    }, 500),
    [onLoadMore, items, totalItems]
  );

  useEffect(() => {
    if (isLoadMoreLoading) {
      lastIndexRawWithLoader.current = listItems.length - 1;
      forceUpdate(listItems.length - 1);
      const position = listRef.current?.getOffsetForRow({
        index: listItems.length - 1,
        alignment: 'end',
      });
      listRef.current?.scrollToPosition(position);
    } else if (lastIndexRawWithLoader.current) {
      forceUpdate(lastIndexRawWithLoader.current);
      lastIndexRawWithLoader.current = null;
    }
  }, [listItems, isLoadMoreLoading, forceUpdate]);

  useEffect(() => {
    appSearchRef.current?.research();
  }, [items]);

  const rowRenderer = useCallback(
    ({ index, key, isVisible, parent, style }) => {
      if (!isVisible) return null;
      if (listItems[index] && (listItems[index] as ListHeaderItem).isHeader)
        return (
          <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
            {({ registerChild }) => (
              <div ref={registerChild as LegacyRef<HTMLDivElement>} style={style} data-row={index}>
                <ProfilerContainer withScroll>
                  <ProfilerSearchDataHeader />
                </ProfilerContainer>
              </div>
            )}
          </CellMeasurer>
        );

      if (listItems[index]) {
        const listItem = listItems[index] as Definitions.Identity;
        return (
          <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
            {({ registerChild }) => (
              <div ref={registerChild as LegacyRef<HTMLDivElement>} style={style} data-row={index}>
                <ProfilerContainer withScroll>
                  <ProfilerCard
                    ref={(ref) => (itemsRefs.current[index] = ref)}
                    key={`profiler-${listItem.id}`}
                    data={listItem}
                    highlightValues={highlights}
                    onLeakOpen={onLeakOpen}
                    searchValue={searchValue}
                    rowIndex={index}
                    isJson={jsonItems.includes(listItem.id)}
                    forceUpdate={forceUpdate}
                    onJsonChange={onChangeJson}
                    getCountry={getCountry}
                  />
                  {listItems.length - 1 === index && isLoadMoreLoading && (
                    <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
                      <Loader />
                    </EuiFlexGroup>
                  )}
                  {listItems.length - 1 === index && uiRawLimit && listItems.length >= uiRawLimit + 1 && (
                    <EuiFlexGroup gutterSize="s" direction="column" alignItems="center" justifyContent="center">
                      <EuiTitle size="xs">
                        <h4>
                          {t('page.profiler.limit.message', {
                            limit: uiRawLimit,
                          })}
                        </h4>
                      </EuiTitle>
                      <EuiText
                        style={{
                          whiteSpace: 'pre-wrap',
                          textAlign: 'center',
                        }}
                        size="s"
                        color="subdued"
                      >
                        {t('page.profiler.limit.support')}
                        <EuiLink href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                          {t('page.profiler.limit.service')}
                        </EuiLink>
                      </EuiText>
                    </EuiFlexGroup>
                  )}
                </ProfilerContainer>
              </div>
            )}
          </CellMeasurer>
        );
      }
    },
    [
      listItems,
      highlights,
      uiRawLimit,
      t,
      searchValue,
      jsonItems,
      isLoadMoreLoading,
      onChangeJson,
      forceUpdate,
      onLeakOpen,
      getCountry,
    ]
  );

  if (isLoading)
    return (
      <ProfilerContainer>
        <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
          <EuiSpacer size="xl" />
          <Loader />
        </EuiFlexGroup>
      </ProfilerContainer>
    );

  if (!totalItems.value)
    return (
      <ProfilerContainer>
        <EmptyData title={isSearched ? t('emptyData.title') : ''} />
      </ProfilerContainer>
    );

  return (
    <CollapsedContext.Provider
      value={{
        items: collapsed,
        setItem: handleCollapseShow,
      }}
    >
      <ScrollSync>
        {({ onScroll }) => (
          <>
            <ProfilerContainer>
              {!!items && (
                <AppSearchProfiler
                  ref={appSearchRef}
                  value={searchValue}
                  items={items}
                  isOpen={isOpenSearch}
                  setIsOpen={setIsOpenSearch}
                  setValue={setSearchValue}
                  listRef={listRef}
                  itemRefs={itemsRefs}
                  showedIndex={showedIndex}
                  setShowedIndex={setShowedIndex}
                  searchIndex={searchIndex}
                  jsonItems={jsonItems}
                />
              )}
            </ProfilerContainer>
            <AutoSizer>
              {({ width }) => (
                <>
                  <List
                    className="eui-scrollBar profiler-list"
                    width={width - 1}
                    height={listHeight}
                    deferredMeasurementCache={cache}
                    rowCount={listItems.length}
                    rowHeight={cache.rowHeight}
                    rowRenderer={rowRenderer}
                    overscanRowCount={0}
                    ref={listRef}
                    onScroll={(params) => {
                      onScroll(params);
                      appSearchRef.current?.scrollToElement();
                      document.dispatchEvent(scrollEvent);
                    }}
                    onRowsRendered={handleLoadMoreRows}
                    measureAllRows={false}
                  />
                </>
              )}
            </AutoSizer>
          </>
        )}
      </ScrollSync>
    </CollapsedContext.Provider>
  );
});
