import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import axios from 'axios';

import { groupsApi } from '@/api';
import { RootState } from '../store';
import { customerSelectors } from '../customer';
import { credentialsActions } from '../credentials';

export interface State {
  isLoading: boolean;
  isLoadingMembers: boolean;
  info: Definitions.GroupResponse | null;
  members: Definitions.CustomerHTTPResponse[];
}

export const fetchGroupById = createAsyncThunk(
  'groups/fetchGroupById',
  async (payload: Paths.GetGroup.PathParameters) => {
    const {
      data: { data },
    } = await groupsApi.getGroupById(payload);
    return data;
  }
);

export const fetchMembersByGroupId = createAsyncThunk(
  'groups/fetchMembersByGroupId',
  async (payload: Paths.GetGroupMembers.PathParameters) => {
    const {
      data: { data },
    } = await groupsApi.getMembersByGroupId(payload);
    return data;
  }
);

export const createGroup = createAsyncThunk(
  'groups/createGroup',
  async (payload: Paths.CreateGroup.Parameters.Body, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const customer = customerSelectors.getInfo(state);
    try {
      const {
        data: { data },
      } = await groupsApi.createGroup(payload);
      if (payload.members?.find((member) => customer?.id === member) !== -1) {
        thunkApi.dispatch(credentialsActions.clearSearch());
      }
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.CreateGroup.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

export const updateGroupById = createAsyncThunk(
  'groups/updateGroupById',
  async (payload: Paths.GroupUpdate.Parameters.Body & Paths.GroupUpdate.PathParameters, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const customer = customerSelectors.getInfo(state);
      const {
        data: { data },
      } = await groupsApi.updateGroupById(payload);
      if (payload.members?.find((member) => customer?.id === member) !== -1) {
        thunkApi.dispatch(credentialsActions.clearSearch());
      }
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.GroupUpdate.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

const clearInfo = createAction('groups/clearInfo');

const initialState: State = {
  isLoading: true,
  isLoadingMembers: true,
  info: null,
  members: [],
};

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'groups',
  initialState,
  reducers: {
    clearInfo: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGroupById.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.info = action.payload;
        }
      })
      .addCase(fetchGroupById.rejected, (state) => {
        state.isLoading = false;
        state.info = null;
      });

    builder
      .addCase(fetchMembersByGroupId.pending, (state) => {
        state.isLoadingMembers = true;
      })
      .addCase(fetchMembersByGroupId.fulfilled, (state, action) => {
        state.isLoadingMembers = false;
        if (action.payload) {
          state.members = action.payload.items || [];
        }
      })
      .addCase(fetchMembersByGroupId.rejected, (state) => {
        state.isLoadingMembers = false;
        state.members = [];
      });
  },
});

export const actions = {
  fetchGroupById,
  fetchMembersByGroupId,
  createGroup,
  updateGroupById,
  clearInfo,
};

export default slice.reducer;
