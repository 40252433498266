import { FunctionComponent, ReactNode } from 'react';
import { EuiLink, EuiText } from '@elastic/eui';

type SocialLinkProps = {
  url: string;
  children: ReactNode;
  className?: string;
};

export const SocialLink: FunctionComponent<SocialLinkProps> = ({ url, children, className = '' }) => {
  const normalizedUrl = url.startsWith('http') ? url : `https://${url}`;
  return (
    <EuiLink href={normalizedUrl} target="_blank" external={false}>
      <EuiText size="s" className={className}>
        {children}
      </EuiText>
    </EuiLink>
  );
};
