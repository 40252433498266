import { useEffect, useRef } from 'react';

import { useAppDispatch } from '@/store';
import { appActions } from '@/store/app';

export const useDatabaseLoad = () => {
  const dispatch = useAppDispatch();
  const timerId = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    dispatch(
      appActions.getDatabaseLoad({
        showToast: false,
      })
    );
    const start = () => {
      timerId.current = setInterval(() => {
        dispatch(
          appActions.getDatabaseLoad({
            showToast: false,
          })
        );
      }, 10000);
    };

    const stop = () => {
      if (timerId.current) clearInterval(timerId.current);
    };
    start();
    window.addEventListener('blur', stop);
    window.addEventListener('focus', start);

    return () => {
      stop();
      window.removeEventListener('blur', stop);
      window.removeEventListener('focus', start);
    };
  }, [dispatch]);
};
