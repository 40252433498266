import { useTranslation } from 'react-i18next';
import { EuiButton, EuiButtonEmpty, EuiContextMenuPanel, EuiPopover, EuiSpacer } from '@elastic/eui';
import { FC, useState } from 'react';

interface SearchDataControlsProps {
  limit: string;
  isDisabledExport: boolean;
  onExport: () => void;
}

export const SearchDataControls: FC<SearchDataControlsProps> = ({ limit, isDisabledExport, onExport }) => {
  const { t } = useTranslation();
  const [isOpenExportPopover, setIsOpenExportPopover] = useState<boolean>(false);

  return (
    <EuiPopover
      button={
        <EuiButtonEmpty size="xs" iconType="exportAction" onClick={() => setIsOpenExportPopover((open) => !open)}>
          {t('button.export')}
        </EuiButtonEmpty>
      }
      isOpen={isOpenExportPopover}
      closePopover={() => setIsOpenExportPopover(false)}
      panelPaddingSize="none"
    >
      <EuiContextMenuPanel
        size="s"
        style={{ padding: '10px', maxWidth: '200px' }}
        items={[
          <span key="export-note" style={{ fontSize: '12px' }}>
            {t('searchDataControls.exportNote', {
              limit,
            })}
          </span>,
          <EuiSpacer key="export-spacer" size="s" />,
          <EuiButton
            key="export-button"
            type="button"
            fullWidth
            size="s"
            style={{ textDecoration: 'none' }}
            onClick={onExport}
            disabled={isDisabledExport}
          >
            {t('button.generateJson')}
          </EuiButton>,
        ]}
      />
    </EuiPopover>
  );
};
