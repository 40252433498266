import { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';

import { leakApi } from '@/api';

export const useDownloads = ({ id, title }: { id?: string; title: string }) => {
  const [leak, setLeak] = useState<Definitions.LeakStats | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const handleLoadLeak = useCallback(async () => {
    if (id) {
      try {
        const response = await leakApi.getProfilerLeakById({ id });
        if (response && response.data && response.data.data) setLeak(response.data.data);
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    } else {
      captureException(new Error(`Not found leak id: ${title}`));
      setLoading(false);
    }
  }, [id, title]);

  useEffect(() => {
    handleLoadLeak();
  }, [handleLoadLeak]);

  return {
    isLoading,
    leak,
  };
};
