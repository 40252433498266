import { ReactComponent as Cell_tower } from '@/assets/icons/markers/Cell_tower.svg';
import { ReactComponent as Civil_airport } from '@/assets/icons/markers/Civil_airport.svg';
import { ReactComponent as Compromised_machine } from '@/assets/icons/markers/Compromised_machine.svg';
import { ReactComponent as Military_airport } from '@/assets/icons/markers/Military_airport.svg';
import { ReactComponent as Military_town } from '@/assets/icons/markers/Military_town.svg';
import { ReactComponent as Military_unit } from '@/assets/icons/markers/Military_unit.svg';
import { ReactComponent as Organization } from '@/assets/icons/markers/Organization.svg';
import { ReactComponent as People } from '@/assets/icons/markers/People.svg';
import { ReactComponent as Plant } from '@/assets/icons/markers/Plant.svg';
import { ReactComponent as Launcher } from '@/assets/icons/markers/Launcher.svg';
import { LandmarksTypes } from '@/pages/Map/types';

export const getIcon = (type: LandmarksTypes) => {
  switch (type) {
    case 'military_airport':
      return Military_airport;
      break;
    case 'military_unit':
      return Military_unit;
      break;
    case 'military_town':
      return Military_town;
      break;
    case 'civil_airport':
      return Civil_airport;
      break;
    case 'cell_tower':
      return Cell_tower;
      break;
    case 'people':
      return People;
      break;
    case 'compromised_machine':
      return Compromised_machine;
      break;
    case 'organization':
      return Organization;
      break;
    case 'plant':
      return Plant;
      break;
    case 'launcher':
      return Launcher;
  }
};
