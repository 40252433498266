import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { Suspense } from 'react';

import { AuthRequired, BaseLayout, ErrorElement, PageLoader, RoleRequired } from '@/components';
import {
  SignIn,
  Page404,
  LogOut,
  MyAccount,
  Apps,
  PasswordFinder,
  Profiler,
  SignInOtp,
  LeakRegistry,
  Phonebook,
  Map,
  Datasets,
  SettingsOtp,
  Profiler2,
} from '@/pages';
import { Roles } from '@/hooks';
import { PermissionRequired } from '@/components/layouts/PermissionRequired';

import { Routes } from './types';
import { organizationRoutes } from './organizationRouter';
import { profiler } from './profiler';
import { passwordFinder } from './passwordFinder';
import { leakRegistry } from './leakRegistry';
import { logs } from './logs';
import { recursiveSearch } from './recursiveSearch';

const routes: RouteObject[] = [
  {
    path: '/',
    errorElement: <ErrorElement />,
    children: [
      {
        path: Routes.HOME,
        element: <AuthRequired />,
        children: [
          {
            element: <BaseLayout />,
            children: [
              {
                path: '',
                element: <Apps />,
              },
              {
                path: Routes.UPDATES,
                children: logs,
              },
              {
                path: Routes.ACCOUNT_GENERAL_SETTING,
                element: <MyAccount />,
              },
              {
                path: Routes.ORGANIZATION,
                element: (
                  <RoleRequired
                    roles={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.SYSTEM]}
                    fallback={<Navigate to={Routes.HOME} />}
                  />
                ),
                children: organizationRoutes,
              },
              {
                path: Routes.PROFILER,
                element: (
                  <PermissionRequired permission="profiler">
                    <Profiler />
                  </PermissionRequired>
                ),
                children: profiler,
              },
              {
                path: Routes.LEAK_REGISTRY,
                element: (
                  <PermissionRequired permission="profiler">
                    <LeakRegistry />
                  </PermissionRequired>
                ),
                children: leakRegistry,
              },
              {
                path: Routes.PASSWORD_FINDER,
                element: (
                  <PermissionRequired permission="password_finder">
                    <PasswordFinder />
                  </PermissionRequired>
                ),
                children: passwordFinder,
              },
              {
                path: Routes.PHONEBOOK,
                element: (
                  <PermissionRequired permission="phonebook">
                    <Phonebook />
                  </PermissionRequired>
                ),
              },
              {
                path: Routes.MAP_RADAR,
                element: (
                  <PermissionRequired permission="map_radar">
                    <Map />
                  </PermissionRequired>
                ),
              },
              {
                path: Routes.DATASETS,
                element: (
                  <PermissionRequired permission={['recursive_search']}>
                    <Datasets />
                  </PermissionRequired>
                ),
              },
              {
                path: Routes.RECURSIVE_SEARCH,
                children: recursiveSearch,
              },
              {
                path: Routes.PROFILER_2,
                element: (
                  <PermissionRequired permission="profiler">
                    <Profiler2 />
                  </PermissionRequired>
                ),
              },
            ],
          },
        ],
      },
      {
        path: Routes.SETTINGS_OTP,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SettingsOtp />
          </Suspense>
        ),
      },
      {
        path: Routes.LOGIN,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SignIn />
          </Suspense>
        ),
      },
      {
        path: Routes.LOGIN_OTP,
        element: (
          <Suspense fallback={<PageLoader />}>
            <SignInOtp />
          </Suspense>
        ),
      },
      {
        path: Routes.LOGOUT,
        element: (
          <Suspense fallback={<PageLoader />}>
            <LogOut />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<PageLoader />}>
            <Page404 />
          </Suspense>
        ),
      },
    ],
  },
];

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routes);
