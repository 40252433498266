import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import { PageLoader } from '@/components';
import { RecursiveSearch, RecursiveSearchTask } from '@/pages';

export const recursiveSearch: RouteObject[] = [
  {
    path: '',
    element: (
      <Suspense fallback={<PageLoader />}>
        <RecursiveSearch />
      </Suspense>
    ),
  },
  {
    path: ':id',
    element: (
      <Suspense fallback={<PageLoader />}>
        <RecursiveSearchTask />
      </Suspense>
    ),
  },
];
