import { useCallback, useMemo } from 'react';
import { IconType } from '@elastic/eui';

import { useAppDispatch, useAppSelector } from '@/store';
import { appActions, appSelectors } from '@/store/app';
import { Routes } from '@/routes';
import { ReactComponent as ProfilerIcon } from '@/assets/icons/Subtract.svg';
import { ReactComponent as PasswordFinderIcon } from '@/assets/icons/Union.svg';
import { ReactComponent as PhonebookIcon } from '@/assets/icons/Phone.svg';
import { ReactComponent as RecursiveSearchIcon } from '@/assets/icons/Research.svg';

const STORAGE_KEY = 'SELECTED_APP';

export enum Apps {
  PROFILER = 'PROFILER',
  PASSWORD_FINDER = 'PASSWORD_FINDER',
  LEAK_REGISTRY = 'LEAK_REGISTRY',
  PHONEBOOK_PARSER = 'PHONEBOOK_PARSER',
  MAP_RADAR = 'MAP_RADAR',
  DATASETS = 'DATASETS',
  RECURSIVE_SEARCH = 'RECURSIVE_SEARCH',
  PROFILER_2 = 'PROFILER_2',
}

export const apps = [
  Apps.PASSWORD_FINDER,
  Apps.PROFILER,
  Apps.PROFILER_2,
  Apps.RECURSIVE_SEARCH,
  Apps.LEAK_REGISTRY,
  Apps.DATASETS,
  Apps.PHONEBOOK_PARSER,
  Apps.MAP_RADAR,
];

export const permissionsAppConfig: {
  name: Apps;
  icon: IconType;
  iconColor: Apps;
  field: keyof Definitions.Products;
}[] = [
  {
    icon: PasswordFinderIcon,
    name: Apps.PASSWORD_FINDER,
    field: 'password_finder',
    iconColor: Apps.PASSWORD_FINDER,
  },
  {
    icon: ProfilerIcon,
    name: Apps.PROFILER,
    field: 'profiler',
    iconColor: Apps.PROFILER,
  },
  {
    icon: PhonebookIcon,
    name: Apps.PHONEBOOK_PARSER,
    field: 'phonebook',
    iconColor: Apps.PHONEBOOK_PARSER,
  },
  {
    icon: RecursiveSearchIcon,
    name: Apps.RECURSIVE_SEARCH,
    field: 'recursive_search',
    iconColor: Apps.RECURSIVE_SEARCH,
  },
  {
    icon: 'visMapRegion',
    name: Apps.MAP_RADAR,
    field: 'map_radar',
    iconColor: Apps.MAP_RADAR,
  },
];

export const getSelectedApp = () => {
  let app = null;
  const savedApp = localStorage.getItem(STORAGE_KEY);
  if (savedApp && apps.includes(savedApp as Apps)) {
    app = savedApp as Apps;
  }
  return app;
};

export const useSelectedApp = () => {
  const dispatch = useAppDispatch();
  const selectApp = useCallback(
    (appName: Apps) => {
      dispatch(appActions.changeApp(appName));
      localStorage.setItem(STORAGE_KEY, appName);
    },
    [dispatch]
  );

  const clearApp = useCallback(() => {
    dispatch(appActions.changeApp(null));
    localStorage.removeItem(STORAGE_KEY);
  }, [dispatch]);

  const selectedApp = useAppSelector(appSelectors.getCurrentApp);

  const selectedAppRoute = useMemo(() => {
    switch (selectedApp) {
      case Apps.PASSWORD_FINDER:
        return Routes.PASSWORD_FINDER;
      case Apps.PROFILER:
        return Routes.PROFILER;
      case Apps.LEAK_REGISTRY:
        return Routes.LEAK_REGISTRY;
      case Apps.DATASETS:
        return Routes.DATASETS;
      case Apps.PROFILER_2:
        return Routes.PROFILER_2;
      default:
        return Routes.HOME;
    }
  }, [selectedApp]);

  return {
    selectedApp,
    selectedAppRoute,
    clearApp,
    selectApp,
  };
};
