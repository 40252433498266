export enum Columns {
  id = 'id',
  title = 'title',
  description = 'description',
  total_members = 'total_members',
  status = 'status',
}

export type ColumnItem = {
  [key in Columns]: string | number | undefined | boolean | null;
};
