import { ColumnItem, Columns, SortColumn } from './types';

export const getRowData = (
  items: Definitions.CredentialResponse[]
): (ColumnItem & { rest: Definitions.CredentialResponse })[] =>
  items.map(({ http_info, leaks, login, url, password, created_at, updated_at, ...rest }) => ({
    [Columns.url]: url,
    [Columns.login]: login,
    [Columns.password]: password,
    [Columns.http_info]: http_info,
    [Columns.http_info_scanned_at]: http_info?.scanned_at,
    [Columns.http_info_ip]: http_info?.ip,
    [Columns.leak]: leaks,
    [Columns.created_at]: created_at,
    [Columns.updated_at]: updated_at,
    [Columns.note]: rest.note || '',
    [Columns.document]: null,
    rest: {
      ...rest,
      http_info,
      leak: leaks,
      login,
      url,
      password,
      created_at,
      updated_at,
    },
  }));

const LEAKS_SORT_FIELD = 'leaks.released_at';
const HTTP_INFO_SORT_FIELD = 'http_info.status_code';

export const sortingUiAdapter = ({ id, direction }: SortColumn): SortColumn => {
  if ([HTTP_INFO_SORT_FIELD, LEAKS_SORT_FIELD].includes(id)) {
    switch (id) {
      case LEAKS_SORT_FIELD:
        id = Columns.leak;
        break;
      case HTTP_INFO_SORT_FIELD:
        id = Columns.http_info;
        break;
      default:
        break;
    }
  }
  return {
    direction,
    id,
  };
};
export const sortingRequestAdapter = ({ id, direction }: SortColumn): SortColumn => {
  if ([Columns.leak, Columns.http_info].includes(id as Columns)) {
    switch (id) {
      case Columns.leak:
        id = LEAKS_SORT_FIELD;
        break;
      case Columns.http_info:
        id = HTTP_INFO_SORT_FIELD;
        break;
      default:
        break;
    }
  }
  return {
    direction,
    id,
  };
};
