import { EuiHeader, useEuiTheme } from '@elastic/eui';
import { FC } from 'react';

import { HeaderUser } from './HeaderUser';
import { HeaderDelimiter } from './HeaderDelimiter';
import { HeaderLogo } from './HeaderLogo';
import { HeaderMenu } from './HeaderMenu';
import { HeaderUIMenu } from './HeaderUIMenu';

export const Header: FC = () => {
  const { colorMode } = useEuiTheme();
  return (
    <EuiHeader
      css={{
        height: 'auto',
        paddingInline: 72,
      }}
      theme={colorMode === 'DARK' ? 'dark' : 'default'}
      position="static"
      sections={[
        {
          items: [<HeaderLogo key="logo" />, <HeaderMenu key="menu" />],
        },
        {
          items: [<HeaderUser key="user" />, <HeaderDelimiter key="delimiter" />, <HeaderUIMenu key="UI" />],
        },
      ]}
    />
  );
};
