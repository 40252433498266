import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { EuiButtonEmpty } from '@elastic/eui';

import { usePrevious } from '@/hooks';
import { CollapsedContext } from '../CollapsedItem';

import { CollapseLineProps } from './types';
import { CardDataLine } from './CardData';

export const CollapseLine: FunctionComponent<CollapseLineProps> = ({ data, id, forceUpdate, ...props }) => {
  const { data: lines, level, count } = data;
  const collapseLineId = id + lines[0].parentHash;
  const { items, setItem } = useContext(CollapsedContext);
  const [isShowed, setShowed] = useState(items && items.includes(collapseLineId));

  const prevShowed = usePrevious(isShowed);

  useEffect(() => {
    if (prevShowed !== isShowed && forceUpdate) forceUpdate();
  }, [isShowed, prevShowed, forceUpdate]);

  const handleClick = () => {
    setShowed(true);
    setItem(collapseLineId);
  };
  if (!isShowed) {
    return (
      <span className="line" data-level={level}>
        <span
          className="key"
          style={{
            paddingLeft: level * 10,
          }}
        ></span>
        <span className="value">
          <EuiButtonEmpty
            contentProps={{
              style: {
                paddingInline: 0,
              },
            }}
            onClick={handleClick}
          >
            Show more ({count})
          </EuiButtonEmpty>
        </span>
      </span>
    );
  }

  return (
    <>
      {lines.map((lineData, index) => (
        <CardDataLine key={`${id}-${index}`} id={id} data={lineData} {...props} />
      ))}
    </>
  );
};
