import { EuiButtonIcon, EuiFlexGroup, EuiFocusTrap, EuiOverlayMask, EuiSpacer, EuiTitle } from '@elastic/eui';
import { css } from '@emotion/css';
import { FC, useState } from 'react';

import { ReactComponent as RefreshFlipIcon } from '@/assets/icons/RefreshFlip.svg';

type ImagePreviewProps = {
  name: string;
  file: string;
  onClose: () => void;
};

const ROTATE_STEP = 90;

export const ImagePreview: FC<ImagePreviewProps> = ({ file, name, onClose }) => {
  const [rotate, setRotate] = useState<number>(0);

  return (
    <EuiOverlayMask
      className={css(`
        padding-block-end: 0;
      `)}
    >
      <EuiFocusTrap onClickOutside={onClose}>
        <EuiTitle size="xxs">
          <h6>{name}</h6>
        </EuiTitle>
        <EuiSpacer size="s" />
        <img
          style={{
            maxHeight: '90vh',
            maxWidth: '90vw',
            transform: `rotate(${rotate}deg)`,
          }}
          alt={name}
          src={file}
        />
        <EuiFlexGroup
          gutterSize="s"
          direction="row"
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          <EuiButtonIcon
            display="base"
            color="ghost"
            iconType={RefreshFlipIcon}
            onClick={() => setRotate((r) => r - ROTATE_STEP)}
          />
          <EuiButtonIcon
            display="base"
            color="ghost"
            iconType="refresh"
            onClick={() => setRotate((r) => r + ROTATE_STEP)}
          />
          <EuiButtonIcon display="base" color="ghost" iconType="cross" onClick={onClose} />
        </EuiFlexGroup>
      </EuiFocusTrap>
    </EuiOverlayMask>
  );
};
