export enum Routes {
  HOME = '/',
  LOGIN = '/login',
  LOGIN_OTP = '/login-otp',
  SETTINGS_OTP = '/settings-otp',
  LOGOUT = '/logout',
  ACCOUNT_GENERAL_SETTING = '/account',
  DOCUMENTATION = '/documentation',
  ORGANIZATION = '/organization',
  PROFILER = '/profiler',
  PASSWORD_FINDER = '/password-finder',
  UPDATES = '/updates',
  MAP_RADAR = '/map-radar',
  LEAK_REGISTRY = '/leak-registry',
  PHONEBOOK = '/phonebook',
  DATASETS = '/datasets',
  RECURSIVE_SEARCH = '/recursive-search',
  PROFILER_2 = '/profilers-assessments',
}

export enum OrganizationRoutes {
  DISABLED_USERS = 'disabled-users',
  ACTIVE_USERS = 'active-users',
  GROUPS = 'groups',
  ACTIONS = 'actions',
  CERATE_ACCOUNT = 'create-account',
  EDIT_ACCOUNT = 'edit-account',
  CREATE_GROUP = 'create-group',
  EDIT_GROUP = 'edit-group',
  STATISTIC = 'statistic',
}

export enum PasswordFinderRoutes {
  SEARCH_RESULTS = '',
  DATA_STRUCTURE = 'data-structure',
  SEARCH_CONCEPT = 'search-concept',
  EXAMPLES = 'examples',
  API = 'api',
}

export enum ProfilerRoutes {
  SEARCH_RESULTS = '',
  DATA_STRUCTURE = 'data-structure',
  SEARCH_CONCEPT = 'search-concept',
  EXAMPLES = 'examples',
  API = 'api',
}

export enum LeakRegistryRoutes {
  SEARCH_RESULTS = '',
  DATA_STRUCTURE = 'data-structure',
  SEARCH_CONCEPT = 'search-concept',
  API = 'api',
}

export enum ReleaseNotesRoutes {
  CREATE = 'create',
  UPDATE = ':id',
}
