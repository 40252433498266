import {
  EuiContextMenuItem,
  EuiFieldSearch,
  EuiFieldSearchProps,
  EuiIcon,
  EuiInputPopover,
  EuiIconProps,
  keys,
  useEuiTheme,
  EuiCallOut,
  EuiSpacer,
  EuiButton,
  EuiFlexGroup,
} from '@elastic/eui';
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Color from 'color';
import { Trans } from 'react-i18next';

import { AutocompleteType, Group, Matcher } from './matcher';

interface AutoCompleteProps extends EuiFieldSearchProps {
  filters: Definitions.Filter[];
  valueQuery: string;
  setComponentHeight?: (value: number) => void;
}

interface AutoCompleteItem {
  type: AutocompleteType;
  value: string;
  onClick: () => void;
}

const getColor = (color: any) => Color(color).alpha(0.3).rgb().toString();

const WARNING_CONTAINS_KEY = 'dont_show_contains_warning';

export const AutoComplete: FC<AutoCompleteProps> = ({
  filters,
  valueQuery,
  value,
  onChange,
  setComponentHeight,
  ...props
}) => {
  const { euiTheme } = useEuiTheme();

  const [isPopoverOpen, setIsOpenPopover] = useState(false);
  const [items, setItems] = useState<AutoCompleteItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>(-1);
  const [isDontShowWarning, setIsDontShowWarning] = useState(!!localStorage.getItem(WARNING_CONTAINS_KEY));

  const handleDontShowWarning = useCallback(() => {
    localStorage.setItem(WARNING_CONTAINS_KEY, 'true');
    setIsDontShowWarning(true);
  }, []);

  const scrollRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>();
  const componentRef = useRef<HTMLDivElement>(null);

  const operators = useMemo(() => {
    const operators = new Map();
    filters.forEach((filter) => filter.operators?.forEach((operator) => operators.set(operator, operator)));
    return Array.from(operators.values()) as string[];
  }, [filters]);

  const isWarningContains = useMemo<boolean>(() => {
    const matcher = new Matcher({
      text: value || '',
      operators,
      caretPosition: 0,
      filters: filters.map(({ filter }) => filter as string),
    });

    return (
      matcher.validate((data) =>
        data.reduce<boolean>((acc, current) => {
          if (current.operator && current.operator === 'contains' && current.value && current.value.match(/[*?]/gi)) {
            return true;
          }

          return acc;
        }, false)
      ) && !isDontShowWarning
    );
  }, [value, filters, operators, isDontShowWarning]);

  const handleChangeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>, group: Group, replacedValue: string | RegExp, value: string) => {
      let text = event.target.value.slice(group.start, group.end);

      text = text.replace(replacedValue, value);

      const beforeText = event.target.value.slice(0, group.start);
      const afterText = event.target.value.slice(group.end);
      event.target.value = [beforeText, text, afterText].join('');

      if (onChange) {
        event.target.value = event.target.value.replace(/^\s/, '');
        event.target.value = event.target.value.replaceAll(/\s\s/gi, ' ');
        onChange(event);
        if (inputRef.current) {
          const position = group.start + text.length;
          inputRef.current.setSelectionRange(position, position);

          if (event.target.value.length * 0.8 < position) {
            inputRef.current.scrollTo({
              left: inputRef.current.scrollWidth,
            });
          }
        }
      }
    },
    [onChange]
  );

  const handleGetType = useCallback(
    (text: string, caretPosition: number, event: ChangeEvent<HTMLInputElement>) => {
      const matcher = new Matcher({
        text,
        operators,
        caretPosition,
        filters: filters.map(({ filter }) => filter as string),
      });

      const { types, isOnlyValue, matchedValue, input, group } = matcher.getAutocompleteType();

      const items: AutoCompleteItem[] = [];
      if (types.includes(AutocompleteType.FILTER)) {
        filters.forEach((item) => {
          if (!matchedValue || item.filter?.match(matchedValue)?.length)
            items.push({
              value: item.filter as string,
              type: AutocompleteType.FILTER,
              onClick: () => {
                if (inputRef.current) {
                  inputRef.current.focus();
                }

                handleChangeValue(event, group, input, ` ${item.filter}.`);
                handleGetType(event.target.value, inputRef.current?.selectionStart || 0, event);
              },
            });
        });
      }

      if (types.includes(AutocompleteType.FILTER_OPERATOR) && matchedValue) {
        const matchedFilter = filters.find(({ filter }) => filter === matchedValue);
        if (matchedFilter && matchedFilter.operators) {
          matchedFilter.operators.forEach((operator) => {
            items.push({
              value: operator,
              type: AutocompleteType.FILTER_OPERATOR,
              onClick: () => {
                if (inputRef.current) {
                  inputRef.current.focus();
                }

                handleChangeValue(
                  event,
                  group,
                  new RegExp(`${matchedValue}.([\\wа-яА-ЯЁёІіЇї*?"]+)?:?`),
                  `${matchedValue}.${operator}: `
                );
                handleGetType(event.target.value, inputRef.current?.selectionStart || 0, event);
              },
            });
          });
        }
      }

      if (types.includes(AutocompleteType.OPERATOR)) {
        ['and', 'or'].forEach((operator) => {
          items.push({
            value: operator,
            type: AutocompleteType.OPERATOR,
            onClick: () => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
              let newInput = `${input} ${operator} `;
              const matchFirstChar = input.endsWith(` ${operator.charAt(0)}`);
              const matchTwoChar = input.endsWith(` ${operator.substring(0, 2)}`);
              if (matchFirstChar || matchTwoChar) {
                newInput = `${input.slice(0, input.length - (matchTwoChar ? 2 : 1))} ${operator} `;
              }
              handleChangeValue(event, group, input, newInput);
              handleGetType(event.target.value, inputRef.current?.selectionStart || 0, event);
            },
          });
        });
      }

      if (types.includes(AutocompleteType.VALUE) && isOnlyValue && !!valueQuery) {
        items.push({
          value: `${valueQuery} "${input}"`,
          type: AutocompleteType.VALUE,
          onClick: () => {
            if (inputRef.current) {
              inputRef.current.focus();
            }

            handleChangeValue(event, group, input, ` ${valueQuery} "${input}"`);
            handleGetType(event.target.value, inputRef.current?.selectionStart || 0, event);
          },
        });
      }

      setItems(items);
      setSelectedItem(-1);
      setIsOpenPopover(!!items.length);
    },
    [operators, filters, valueQuery, handleChangeValue]
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const caretPosition = e.target.selectionStart || 0;
      const text = e.target.value;
      handleGetType(text, caretPosition, e);
      if (onChange) onChange(e);
    },
    [onChange, handleGetType]
  );

  const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      const text = e.target.value;
      if (text.length === 0) {
        handleGetType(text, 0, e);
      }
    },
    [handleGetType]
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      switch (e.key) {
        case keys.ARROW_DOWN:
          e.preventDefault();
          e.stopPropagation();
          setSelectedItem((state) => Math.min(state + 1, items.length - 1));
          break;
        case keys.ARROW_UP:
          e.preventDefault();
          e.stopPropagation();
          setSelectedItem((state) => Math.max(state - 1, -1));
          break;
        case keys.ENTER:
          if (selectedItem > -1) {
            e.preventDefault();
            e.stopPropagation();
            items[selectedItem].onClick();
          } else {
            setItems([]);
            setSelectedItem(-1);
            setIsOpenPopover(false);
          }
          break;
        case keys.ARROW_LEFT:
        case keys.ARROW_RIGHT:
        case keys.HOME:
        case keys.END:
          setItems([]);
          setSelectedItem(-1);
          setIsOpenPopover(false);
          break;
        default:
          setSelectedItem(-1);
          break;
      }
    },
    [selectedItem, items]
  );

  const getIconProps = useCallback(
    (type: AutocompleteType, isSelected: boolean): EuiIconProps => {
      switch (type) {
        case AutocompleteType.FILTER:
          return {
            type: 'kqlField',
            color: isSelected ? euiTheme.colors.text : euiTheme.colors.warningText,
            style: {
              backgroundColor: getColor(euiTheme.colors.warning),
            },
          };
        case AutocompleteType.FILTER_OPERATOR:
          return {
            type: 'kqlOperand',
            color: isSelected ? euiTheme.colors.text : euiTheme.colors.primaryText,
            style: {
              backgroundColor: getColor(euiTheme.colors.primary),
            },
          };
        case AutocompleteType.OPERATOR:
          return {
            type: 'kqlSelector',
            color: isSelected ? euiTheme.colors.text : euiTheme.colors.accentText,
            style: {
              backgroundColor: getColor(euiTheme.colors.accent),
            },
          };
        case AutocompleteType.VALUE:
          return {
            type: 'kqlValue',
            color: isSelected ? euiTheme.colors.text : euiTheme.colors.successText,
            style: {
              backgroundColor: getColor(euiTheme.colors.success),
            },
          };
      }
    },
    [euiTheme]
  );

  useEffect(() => {
    if (scrollRef.current) {
      const selectedChild = Array.from(scrollRef.current.children).find((_, index) => index === selectedItem) as
        | HTMLElement
        | undefined;
      if (selectedChild) {
        if (
          scrollRef.current.scrollTop + scrollRef.current.clientHeight - selectedChild.clientHeight <
          selectedChild.offsetTop
        ) {
          scrollRef.current.scrollTo({
            top: selectedChild.offsetTop - scrollRef.current.clientHeight + selectedChild.clientHeight,
            behavior: 'smooth',
          });
        } else if (scrollRef.current.scrollTop > selectedChild.offsetTop) {
          scrollRef.current.scrollTo({
            top: selectedChild.offsetTop,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    if (componentRef.current) {
      const height = componentRef.current.getBoundingClientRect().height;
      if (setComponentHeight) setComponentHeight(height);
    }
  }, [isWarningContains, setComponentHeight]);

  return (
    <div ref={componentRef}>
      <EuiInputPopover
        fullWidth
        input={
          <EuiFieldSearch
            {...props}
            value={value}
            inputRef={(ref) => {
              inputRef.current = ref;
            }}
            onFocus={handleFocus}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoComplete="off"
          />
        }
        isOpen={isPopoverOpen}
        closePopover={() => {
          setIsOpenPopover(false);
        }}
        disableFocusTrap
        panelPaddingSize="none"
      >
        <div
          style={{
            maxHeight: 400,
            overflow: 'auto',
          }}
          role="autocomplete"
          aria-label=""
          className="eui-scrollBar"
          ref={scrollRef}
        >
          {items.map(({ value, type, onClick }, index) => {
            const { style, ...iconProps } = getIconProps(type, selectedItem === index);
            return (
              <EuiContextMenuItem
                key={`autocomplete-${index}`}
                onClick={() => {
                  setSelectedItem(index);
                  onClick();
                }}
                icon={
                  <div
                    style={{
                      ...style,
                      display: 'inline-block',
                      paddingInline: euiTheme.size.m,
                      paddingBlock: euiTheme.size.xs,
                    }}
                  >
                    <EuiIcon size="m" {...iconProps} />
                  </div>
                }
                style={{
                  paddingBlock: 0,
                  paddingInlineStart: 0,
                  background: selectedItem === index ? euiTheme.colors.lightestShade : undefined,
                }}
              >
                {value}
              </EuiContextMenuItem>
            );
          })}
        </div>
      </EuiInputPopover>
      {isWarningContains && (
        <>
          <EuiSpacer size="s" />
          <EuiCallOut
            style={{
              borderRadius: euiTheme.border.radius.medium,
            }}
            size="s"
            title={<Trans i18nKey="form.warning.searchRegExeptAndContainsOperator" components={[<strong key={0} />]} />}
            iconType="alert"
            color="warning"
          >
            <EuiFlexGroup gutterSize="none" justifyContent="flexEnd">
              <EuiButton size="s" color="warning" onClick={handleDontShowWarning}>
                <Trans i18nKey="button.dontShow" />
              </EuiButton>
            </EuiFlexGroup>
          </EuiCallOut>
        </>
      )}
    </div>
  );
};
