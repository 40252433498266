import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, useEuiTheme } from '@elastic/eui';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { EditCurrentAccountForm } from '../EditCurrentAccountForm';
import { ProductsPermissionInfo } from '../ProductsPermissionInfo';

interface AccountInfoProps {
  user: Definitions.Profile;
}

export const AccountInfo: FC<AccountInfoProps> = ({ user }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  const items: {
    value: ReactNode;
    label: string;
  }[] = [
    {
      value: <EuiText size="s">{user.organization_title}</EuiText>,
      label: 'organization',
    },

    {
      value: <EuiText size="s">{t(`roles.${user.role_id}`)}</EuiText>,
      label: 'role',
    },

    {
      value: <EuiText size="s">{user.group_title}</EuiText>,
      label: 'group',
    },
    {
      value: (
        <>
          <EditCurrentAccountForm nickname={user.nickname || ''} />
          <EuiSpacer size="m" />
        </>
      ),
      label: 'nickname',
    },
    {
      value: <ProductsPermissionInfo label permissions={user?.products_permission} />,
      label: 'accesses',
    },
  ];

  return (
    <div>
      {items.map((item) => (
        <EuiFlexGroup
          style={{
            marginBlock: euiTheme.size.s,
          }}
          key={item.label}
        >
          <EuiFlexItem grow={0}>
            <EuiText size="s">
              <b style={{ fontWeight: euiTheme.font.weight.semiBold, width: 128, display: 'inline-block' }}>
                {t(`page.account.settings.current.items.${item.label}`)}
              </b>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={1}>{item.value}</EuiFlexItem>
        </EuiFlexGroup>
      ))}
    </div>
  );
};
