import { useCallback, useMemo, useState } from 'react';
import { EuiDataGridOnColumnResizeHandler } from '@elastic/eui';

import { organizationSelectors } from '@/store/organization';
import { useAppSelector } from '@/store';

import { columnsConfig } from './constants';
import { getRowData } from './helper';
import { Columns } from './types';

const getColumnsConfig = () =>
  columnsConfig.map((column) => {
    let initialWidth = Number(localStorage.getItem(`${column.id}_column_width_members`));
    if (initialWidth === 0 && column.initialWidth) {
      initialWidth = column.initialWidth;
    }

    return {
      ...column,
      initialWidth: initialWidth !== 0 ? initialWidth : undefined,
    };
  });

export type STATUS_USER = 'active' | 'deactivated';

export const useData = ({ status }: { status: STATUS_USER }) => {
  const [columns, setColumns] = useState(() => getColumnsConfig());

  const [visibleColumns, setVisibleColumns] = useState<string[]>([
    Columns.nickname,
    Columns.role,
    Columns.group,
    Columns.products_permissions,
    Columns.enabled_two_fa,
    Columns.login,
    Columns.created_at,
    Columns.exp_date,
    Columns.last_activities,
    Columns.status,
  ]);

  const items = useAppSelector(organizationSelectors.getMembers);
  const totalItems = useAppSelector(
    status === 'active' ? organizationSelectors.getTotalMembers : organizationSelectors.getTotalMembersDisabled
  );
  const isLoading = useAppSelector(organizationSelectors.getLoadingMembers);

  const rawData = useMemo(() => getRowData(items || []), [items]);

  const handleColumnResize: EuiDataGridOnColumnResizeHandler = useCallback((event) => {
    localStorage.setItem(`${event.columnId}_column_width_members`, event.width.toString());
    setColumns(getColumnsConfig());
  }, []);

  return {
    columns,
    rawData,
    totalItems,
    isLoading,
    visibleColumns,
    setVisibleColumns,
    handleColumnResize,
  };
};
