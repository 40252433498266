const SIZE = 128;

export const Loader = () => {
  return (
    <img
      style={{
        width: SIZE,
        height: SIZE,
      }}
      src="/loader.gif"
      alt="loader"
    />
  );
};
