/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Control, ControlOptions, ControlPosition, DomUtil, Map } from 'leaflet';

import { CUSTOM_CONTAINERS } from '../CustomControlContainers';

interface GeocoderControlOptions extends ControlOptions {
  title?: string;
  setContainer?: (element: HTMLElement | null) => void;
}

const INITIAL_OPTIONS: GeocoderControlOptions = {
  position: 'topleft',
};

const ELEMENT_CLASS_NAME = 'leaflet-control-geocoder';

export class GeocoderControl extends Control {
  constructor(options?: GeocoderControlOptions) {
    super({ ...INITIAL_OPTIONS, ...options });
    this.options = { ...INITIAL_OPTIONS, ...options };
  }

  options: GeocoderControlOptions = INITIAL_OPTIONS;
  button: HTMLElement | undefined;
  private _map!: Map;

  onAdd(map: Map): HTMLElement {
    const button = map.getCustomControlContainer(
      this.options.position as ControlPosition,
      CUSTOM_CONTAINERS.SEARCH
    ) as HTMLElement;

    const container = DomUtil.create('div', `${ELEMENT_CLASS_NAME}__content`);

    const mapControlContainer = (this._map as any)._controlContainer as HTMLElement;
    if (mapControlContainer) {
      mapControlContainer.appendChild(container);
    }
    if (this.options.setContainer) {
      this.options.setContainer(container);
    }
    this.button = button;

    return button;
  }

  getMap() {
    return this._map as Map;
  }
}

export const geocoderControl = (options?: GeocoderControlOptions) => {
  return new GeocoderControl(options);
};
