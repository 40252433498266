import { SchemaOf, mixed, object, string } from 'yup';

export type FormFields = Paths.CreateTasks.FormDataParameters;

export const XLSX_REG = /.*\.xlsx?$/;

export const schema: SchemaOf<FormFields> = object().shape({
  title: string().required('form.error.required'),
  description: string(),
  leaked_at: string().required('form.error.required'),
  file: mixed()
    .required('form.error.required')
    .test({
      message: ({ value }) =>
        value && value.length === 0 ? 'form.error.required' : 'phonebookForm.error.invalidFormatFile',
      test: (fileList, context) => {
        return (
          fileList &&
          fileList.length === 1 &&
          context?.parent?.filename &&
          typeof context?.parent?.filename === 'string' &&
          context?.parent?.filename.match(XLSX_REG)
        );
      },
    }),
});
