import {
  EuiAvatar,
  EuiBetaBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiText,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';
import cn from 'classnames';

import { Routes } from '@/routes';
import { Apps as AvailableApps } from '@/hooks';
import { ReactComponent as ProfilerIcon } from '@/assets/icons/menu/Profiler.svg';
import { ReactComponent as PasswordFinderIcon } from '@/assets/icons/menu/PasswordFinder.svg';
import { ReactComponent as LeakIcon } from '@/assets/icons/menu/LeaksRegistry.svg';
import { ReactComponent as PhonebookIcon } from '@/assets/icons/menu/Phonebook.svg';
import { ReactComponent as MapRadarIcon } from '@/assets/icons/menu/LocationExplorer.svg';
// import { ReactComponent as MapImgDark } from '@/assets/icons/menu/MapImg_dark.svg';
// import { ReactComponent as MapImgLight } from '@/assets/icons/menu/MapImg_light.svg';
import { ReactComponent as DatasetsIcon } from '@/assets/icons/menu/Dataset.svg';
import { ReactComponent as RecursiveSearchIcon } from '@/assets/icons/menu/RecursiveSearch.svg';
import { ReactComponent as RecursiveSearchImgDark } from '@/assets/icons/menu/RecursiveSearchImg_dark.svg';
import { ReactComponent as RecursiveSearchImgLight } from '@/assets/icons/menu/RecursiveSearchImg_light.svg';
import { ReactComponent as LockedIcon } from '@/assets/icons/locked.svg';
import { useAppSelector } from '@/store';
import { customerSelectors } from '@/store/customer';
import { ThemeExtensions } from '../ThemeProvider';

export const AppCard = ({ app, showBorderAnimation }: { app: AvailableApps; showBorderAnimation: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { colorMode, euiTheme } = useEuiTheme<ThemeExtensions>();
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const isDarkTheme = colorMode === 'DARK';
  const user = useAppSelector(customerSelectors.getInfo);

  const hasPermission = useMemo((): boolean => {
    switch (app) {
      case AvailableApps.PASSWORD_FINDER:
        return Boolean(user && user.products_permission?.password_finder);
      case AvailableApps.PROFILER:
      case AvailableApps.LEAK_REGISTRY:
      case AvailableApps.PROFILER_2:
        return Boolean(user && user.products_permission?.profiler);
      case AvailableApps.PHONEBOOK_PARSER:
        return Boolean(user && user.products_permission?.phonebook);
      case AvailableApps.MAP_RADAR:
        return Boolean(user && user.products_permission?.map_radar);
      case AvailableApps.DATASETS:
        return Boolean(user && (user.products_permission?.password_finder || user.products_permission?.profiler));
      case AvailableApps.RECURSIVE_SEARCH:
        return Boolean(user && user.products_permission?.recursive_search);
      default:
        return false;
    }
  }, [user, app]);
  const handleClick = useCallback(() => {
    if (!hasPermission) return;
    switch (app) {
      case AvailableApps.PROFILER:
        navigate(Routes.PROFILER);
        break;
      case AvailableApps.PASSWORD_FINDER:
        navigate(Routes.PASSWORD_FINDER);
        break;
      case AvailableApps.LEAK_REGISTRY:
        navigate(Routes.LEAK_REGISTRY);
        break;
      case AvailableApps.PHONEBOOK_PARSER:
        navigate(Routes.PHONEBOOK);
        break;
      case AvailableApps.MAP_RADAR:
        navigate(Routes.MAP_RADAR);
        break;
      case AvailableApps.DATASETS:
        navigate(Routes.DATASETS);
        break;
      case AvailableApps.RECURSIVE_SEARCH:
        navigate(Routes.RECURSIVE_SEARCH);
        break;
      case AvailableApps.PROFILER_2:
        navigate(Routes.PROFILER_2);
        break;
      default:
        break;
    }
  }, [hasPermission, app, navigate]);

  const isBeta = useMemo(
    () => [AvailableApps.RECURSIVE_SEARCH, AvailableApps.MAP_RADAR, AvailableApps.PROFILER_2].includes(app),
    [app]
  );

  const bigSize = app === AvailableApps.RECURSIVE_SEARCH;

  const hasBorderAnimation = useMemo(
    () => [AvailableApps.PROFILER_2].includes(app) && showBorderAnimation,
    [app, showBorderAnimation]
  );

  const getIcon = useCallback(() => {
    switch (app) {
      case AvailableApps.PASSWORD_FINDER:
        return PasswordFinderIcon;
      case AvailableApps.PROFILER:
        return ProfilerIcon;
      case AvailableApps.LEAK_REGISTRY:
        return LeakIcon;
      case AvailableApps.MAP_RADAR:
        return MapRadarIcon;
      case AvailableApps.PHONEBOOK_PARSER:
        return PhonebookIcon;
      case AvailableApps.DATASETS:
        return DatasetsIcon;
      case AvailableApps.RECURSIVE_SEARCH:
        return RecursiveSearchIcon;
      case AvailableApps.PROFILER_2:
        return ProfilerIcon;
    }
  }, [app]);

  const getImage = useCallback(() => {
    switch (app) {
      case AvailableApps.RECURSIVE_SEARCH:
        return (
          <EuiIcon
            type={isDarkTheme ? RecursiveSearchImgDark : RecursiveSearchImgLight}
            style={{ width: 'auto', height: '100%', position: 'absolute', top: 0, right: '24px' }}
          />
        );
      // case AvailableApps.MAP_RADAR:
      //   return (
      //     <EuiIcon
      //       type={isDarkTheme ? MapImgDark : MapImgLight}
      //       style={{ width: 'auto', height: '100%', position: 'absolute', top: 0, right: 0 }}
      //     />
      //   );
      default:
        return null;
    }
  }, [isDarkTheme, app]);

  const showText = (!hasPermission && !hovered) || hasPermission;

  return (
    <EuiPanel
      style={{
        textAlign: 'start',
        minHeight: '100px',
        gridColumn: bigSize ? 'span 2' : 'span 1',
        display: 'flex',
        alignItems: 'flex-start',
        position: 'relative',
        padding: euiTheme.size.m,
      }}
      className={cn(
        css`
          &:hover {
            box-shadow: 2px 2px 5px 0px var(--color-accent-shadow);
          }
        `,
        { 'border-animation': hasBorderAnimation }
      )}
      hasBorder
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <EuiFlexItem>
        <EuiFlexGroup alignItems="center" gutterSize="s">
          <EuiAvatar
            size="s"
            name={app}
            iconType={getIcon()}
            type="space"
            color={isDarkTheme ? '#072719' : '#5C6CFB'}
            iconColor={isDarkTheme ? '#6EE7B7' : '#EBEBEB'}
            style={{
              border: `1px solid ${isDarkTheme ? '#0C3925' : '#5C6CFB'}`,
              boxShadow: isDarkTheme ? '0px 0px 10px 1px #072719' : 'none',
            }}
          />
          <EuiFlexItem>
            <EuiFlexGroup alignItems="center" gutterSize="xs">
              <EuiTitle
                size="xs"
                className={css`
                  font-weight: 500;
                `}
              >
                <h4>
                  {t(`apps.title.${app}`)}
                  {isBeta && (
                    <EuiBetaBadge
                      label={'BETA'}
                      color="hollow"
                      tooltipContent={t('beta.description', {
                        appName: t(`apps.title.${app}`),
                      })}
                      style={{
                        color: '#6EE7B7',
                        boxShadow: 'none',
                        padding: '0 0 6px 6px',
                        fontSize: '10px',
                        lineHeight: 1,
                        background: 'none',
                      }}
                    />
                  )}
                  {!hasPermission && (
                    <EuiBetaBadge
                      label=""
                      iconType={LockedIcon}
                      color="hollow"
                      className={css`
                        color: #ff0303;
                        box-shadow: none;
                        padding: '0 0 6px 6px';
                        line-height: 1;
                        background: none;
                      `}
                    />
                  )}
                </h4>
              </EuiTitle>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup style={{ marginTop: '12px', maxWidth: '300px' }} gutterSize="s">
          {!showText && <EuiIcon type={LockedIcon} size="l" color="#ff0303" />}
          <EuiText size="s" color={isDarkTheme ? '#BBBBBB' : '#757474'}>
            {showText ? t(`apps.description.${app}`) : t('apps.disabled.text')}
          </EuiText>
        </EuiFlexGroup>
      </EuiFlexItem>
      {getImage()}
      <EuiIcon type="arrowRight" color={isDarkTheme ? '#6EE7B7' : '#5C6CFB'} />
    </EuiPanel>
  );
};
