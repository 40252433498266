import { useEuiTheme } from '@elastic/eui';
import { Link } from 'react-router-dom';

import { Routes } from '@/routes';
import { Logo } from '../Logo';

export const HeaderLogo = () => {
  const { euiTheme } = useEuiTheme();

  return (
    <Link
      style={{
        padding: `${euiTheme.size.m} ${euiTheme.size.xl} ${euiTheme.size.m} 0px`,
      }}
      key="logo"
      to={Routes.HOME}
    >
      <Logo />
    </Link>
  );
};
