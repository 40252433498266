import { EuiDataGridColumn } from '@elastic/eui';

import { Columns } from './types';

export const columnsConfig: EuiDataGridColumn[] = [
  {
    id: Columns.title,
    displayAsText: 'Title',
    isExpandable: false,
    initialWidth: 300,
    actions: false,
  },
  {
    id: Columns.status,
    displayAsText: 'Status',
    isExpandable: false,
    initialWidth: 150,
    actions: false,
  },
  {
    id: Columns.created_at,
    displayAsText: 'Created at',
    isExpandable: false,
    actions: false,
    initialWidth: 150,
  },
  {
    id: Columns.login,
    displayAsText: 'User',
    isExpandable: false,
    initialWidth: 100,
    actions: false,
  },
  {
    id: Columns.recursion_levels_number,
    displayAsText: 'Level',
    isExpandable: false,
    actions: false,
    initialWidth: 100,
  },
  {
    id: Columns.total_credentials,
    displayAsText: 'Credentials',
    isExpandable: false,
    actions: false,
    initialWidth: 100,
  },
  {
    id: Columns.total_identities,
    displayAsText: 'Identities',
    isExpandable: false,
    actions: false,
    initialWidth: 100,
  },
  {
    id: Columns.system_message,
    displayAsText: 'System message',
    isExpandable: false,
    actions: false,
    initialWidth: 200,
  },
  {
    id: Columns.note,
    displayAsText: 'Note',
    isExpandable: false,
    actions: false,
  },
];

export const CONFIG_ITEMS_PER_PAGE = [25, 50, 100];
