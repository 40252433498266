import {
  AreaSeries,
  Axis,
  Chart,
  DARK_THEME,
  LIGHT_THEME,
  LineSeries,
  Position,
  ScaleType,
  Settings,
} from '@elastic/charts';
import { useEuiTheme } from '@elastic/eui';
import { EUI_CHARTS_THEME_DARK, EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme';
import { useCallback, useMemo } from 'react';
import { Moment } from 'moment';
import 'moment/locale/uk';
import '@elastic/charts/dist/theme_light.css';
import { useTranslation } from 'react-i18next';

import { Period } from './types';
import { getDateFromPeriod, getPointsConfig, getPointsData, getPointsLimit } from './helper';

interface StatisticChartProps {
  data: Definitions.RequestsMetric;
  type?: 'area' | 'line';
  period: Period;
  from: string;
  to: string;
}

export const StatisticChart = ({ data, type = 'area', period, from, to }: StatisticChartProps) => {
  const { colorMode } = useEuiTheme();
  const { i18n } = useTranslation();

  const pointsLimit = useMemo(() => getPointsLimit(data, period, from, to), [data, period, from, to]);
  const { step, format } = useMemo(() => getPointsConfig(period, pointsLimit), [pointsLimit, period]);

  const chartData = useMemo<
    [
      string,
      {
        count: number;
        day: number;
      }[]
    ][]
  >(
    () => Object.entries(data).map(([key, data]) => [key, getPointsData(data || [], step, period, pointsLimit)]),
    [data, step, period, pointsLimit]
  );

  const handleTickFormat = useCallback(
    (d) => (getDateFromPeriod(d, period, step, pointsLimit.start) as Moment).locale(i18n.language).format(format),
    [i18n.language, format, pointsLimit, step, period]
  );

  const theme = colorMode === 'DARK' ? DARK_THEME : LIGHT_THEME;
  const chartTheme = colorMode === 'DARK' ? EUI_CHARTS_THEME_DARK.theme : EUI_CHARTS_THEME_LIGHT.theme;

  let ChartComponent = AreaSeries;
  switch (type) {
    case 'line':
      ChartComponent = LineSeries;
      break;
  }

  return (
    <Chart size={{ height: 360 }}>
      <Settings theme={chartTheme} baseTheme={theme} showLegend legendPosition={Position.Bottom} />
      <Axis id="bottom" position={Position.Bottom} tickFormat={handleTickFormat} />
      <Axis id="left" position={Position.Left} integersOnly />
      {chartData.map(([key, value]) => (
        <ChartComponent
          key={`area-${key}`}
          id={`area-${key}`}
          name={key}
          xScaleType={ScaleType.Linear}
          yScaleType={ScaleType.Linear}
          xAccessor={'day'}
          yAccessors={['count']}
          data={value}
        />
      ))}
    </Chart>
  );
};
