import { FC, memo, useCallback, useState } from 'react';
import {
  EuiButtonIcon,
  EuiConfirmModal,
  EuiDataGrid,
  EuiDataGridCellValueElementProps,
  EuiFlexGroup,
  EuiHealth,
  EuiPanel,
  EuiSpacer,
  EuiTablePagination,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useWindowSize } from '@/hooks';
import { EmptyData } from '../Core';
import { Loader } from '../Loader';
import { LeakLink } from '../LeakProfilerData/LeakLink';
import { useAppDispatch } from '@/store';
import { phonebookActions } from '@/store/phonebook';

import { useSearchData } from './useSearchData';
import { CONFIG_ITEMS_PER_PAGE } from './constants';
import { Columns } from './types';

interface PhonebookSearchDataProps {
  activePage: number;
  limitPagination: number;
  onChangeItemsPerPage: (pageSize: number) => void;
  onChangePage: (pageIndex: number) => void;
  onEmptyDataButtonClick: () => void;
  onSuccessRemove: () => void;
}

const getStatusColor = (status: string): string => {
  switch (status) {
    case 'success':
      return '#00BB20';
    case 'pending':
    case 'processing':
      return '#FEC514';
    case 'failed':
      return '#E20000';
    default:
      return 'default';
  }
};

export const PhonebookSearchData = memo<PhonebookSearchDataProps>(function SearchData({
  activePage,
  limitPagination,
  onChangeItemsPerPage,
  onChangePage,
  onEmptyDataButtonClick,
  onSuccessRemove,
}) {
  const { t } = useTranslation();

  const {
    columns,
    visibleColumns,
    rawData,
    totalItems,
    isLoading,
    uiLimitRow,
    handleChangeVisibleColumns,
    handleColumnResize,
  } = useSearchData();
  const { height } = useWindowSize();

  const RenderCellValue = useCallback(
    ({ rowIndex, columnId }: EuiDataGridCellValueElementProps) => {
      switch (columnId) {
        case Columns.status:
          return (
            <EuiHealth
              style={{
                textTransform: 'capitalize',
              }}
              color={getStatusColor(rawData[rowIndex][columnId as Columns] as string)}
            >
              {rawData[rowIndex][columnId as Columns] as string}
            </EuiHealth>
          );
        case Columns.title:
          return rawData[rowIndex]?.status === 'success' ? (
            <LeakLink title={rawData[rowIndex][columnId as Columns] as string} />
          ) : (
            <span>{rawData[rowIndex][columnId as Columns]}</span>
          );
        case Columns.created_at:
          return (
            <span>
              {rawData[rowIndex][columnId as Columns]
                ? moment
                    .parseZone(rawData[rowIndex][columnId as Columns] as string)
                    .format(process.env.REACT_APP_DATE_FORMAT)
                : ''}
            </span>
          );
        default:
          return <span>{rawData[rowIndex][columnId as Columns]}</span>;
      }
    },
    [rawData]
  );

  if (isLoading)
    return (
      <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
        <EuiSpacer size="xl" />
        <Loader />
      </EuiFlexGroup>
    );

  if (!totalItems)
    return (
      <EmptyData
        title={t('emptyDataPhonebook.title')}
        note={t('emptyDataPhonebook.note')}
        button={{
          children: t('button.uploadDump'),
          iconType: 'plusInCircle',
          size: 's',
          onClick: onEmptyDataButtonClick,
        }}
      />
    );

  return (
    <EuiPanel style={{ overflow: 'hidden', width: '100%', marginTop: 24 }} hasBorder paddingSize="none">
      <EuiDataGrid
        className="EuiDataEmptyTopBorder"
        aria-labelledby=""
        style={{
          maxHeight: Math.max(height - 320, 400),
          height: Math.max(height - 320, 400),
        }}
        columns={columns}
        columnVisibility={{ visibleColumns, setVisibleColumns: handleChangeVisibleColumns }}
        rowCount={uiLimitRow && rawData.length > uiLimitRow ? uiLimitRow : rawData.length}
        renderCellValue={RenderCellValue}
        toolbarVisibility={false}
        onColumnResize={handleColumnResize}
        gridStyle={{
          border: 'horizontal',
          header: 'shade',
          fontSize: 'm',
          cellPadding: 'l',
        }}
        trailingControlColumns={[
          {
            id: 'actions',
            width: 50,
            headerCellRender: () => null,
            rowCellRender: ({ rowIndex }) => (
              <ControlCellRender
                id={rawData[rowIndex].rest.id as number}
                title={rawData[rowIndex].title as string}
                onRemove={onSuccessRemove}
              />
            ),
          },
        ]}
      />
      {totalItems && uiLimitRow && (
        <EuiTablePagination
          aria-label="Search pagination"
          pageCount={Math.ceil(Math.min(totalItems, uiLimitRow) / limitPagination)}
          activePage={activePage}
          showPerPageOptions={totalItems > limitPagination}
          onChangePage={onChangePage}
          itemsPerPage={limitPagination}
          onChangeItemsPerPage={onChangeItemsPerPage}
          itemsPerPageOptions={CONFIG_ITEMS_PER_PAGE}
        />
      )}
    </EuiPanel>
  );
});

interface ControlCellRenderProps {
  id: number;
  title: string;
  onRemove: () => void;
}

const ControlCellRender: FC<ControlCellRenderProps> = ({ id, title, onRemove }) => {
  const dispatch = useAppDispatch();
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const handleDelete = useCallback(async () => {
    try {
      setIsLoadingDelete(true);
      await dispatch(phonebookActions.remove({ id: `${id}` }));
      onRemove();
    } catch (e) {
      setIsLoadingDelete(false);
    }
  }, [dispatch, onRemove, id]);

  const { t } = useTranslation();

  return (
    <>
      <EuiButtonIcon color={'text'} iconType="trash" onClick={() => setOpenConfirmModal(true)} aria-label="Edit" />
      {isOpenConfirmModal && (
        <EuiConfirmModal
          title={t('modals.confirmRemovePhonebook.title', {
            title,
          })}
          onCancel={() => setOpenConfirmModal(false)}
          onConfirm={handleDelete}
          cancelButtonText={t('button.cancel')}
          confirmButtonText={t('button.remove')}
          buttonColor="danger"
          defaultFocusedButton="cancel"
          isLoading={isLoadingDelete}
        >
          <p>{t('modals.confirmRemovePhonebook.note')}</p>
        </EuiConfirmModal>
      )}
    </>
  );
};
