import { useCallback, useEffect, useMemo, useState } from 'react';
import { EuiDataGridOnColumnResizeHandler } from '@elastic/eui';

import { useAppDispatch, useAppSelector } from '@/store';
import { customerSelectors } from '@/store/customer';
import { phonebookActions, phonebookSelectors } from '@/store/phonebook';

import { columnsConfig } from './constants';
import { getRowData } from './helper';
import { Columns } from './types';

export const VISIBLE_COLUMNS_STORAGE_KEY = 'visible_columns_phonebook';
const getSavedVisibleColumns = (mustContained?: string): string[] => {
  const columnsJson = localStorage.getItem(VISIBLE_COLUMNS_STORAGE_KEY);
  let columns: string[] = [];
  if (columnsJson) {
    columns = JSON.parse(columnsJson);
  } else {
    columns = [Columns.title, Columns.status, Columns.created_at, Columns.login, Columns.records, Columns.note];
  }
  if (mustContained && !columnsJson?.includes(mustContained)) {
    columns.push(mustContained);
  }
  return columns;
};

const getColumnsConfig = () =>
  columnsConfig.map((column) => {
    let initialWidth = Number(localStorage.getItem(`${column.id}_column_width_phonebook`));
    if (initialWidth === 0 && column.initialWidth) {
      initialWidth = column.initialWidth;
    }

    return {
      ...column,
      initialWidth: initialWidth !== 0 ? initialWidth : undefined,
    };
  });

export const useSearchData = () => {
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState(() => getColumnsConfig());

  const [visibleColumns, setVisibleColumns] = useState(() => getSavedVisibleColumns());

  const items = useAppSelector(phonebookSelectors.getData);
  const totalItems = useAppSelector(phonebookSelectors.getTotalData);
  const isLoading = useAppSelector(phonebookSelectors.getLoading);
  const customer = useAppSelector(customerSelectors.getInfo);

  useEffect(() => {
    const dispatchWatcher = dispatch(phonebookActions.watch());

    return () => {
      dispatchWatcher.abort();
    };
  }, [dispatch]);

  const handleChangeVisibleColumns = useCallback(
    (columns: string[]) => {
      localStorage.setItem(VISIBLE_COLUMNS_STORAGE_KEY, JSON.stringify(columns));
      setVisibleColumns(columns);
    },
    [setVisibleColumns]
  );

  const rawData = useMemo(() => getRowData(items || []), [items]);

  const handleColumnResize: EuiDataGridOnColumnResizeHandler = useCallback((event) => {
    localStorage.setItem(`${event.columnId}_column_width`, event.width.toString());
    setColumns(getColumnsConfig());
  }, []);

  return {
    columns,
    visibleColumns,
    rawData,
    totalItems,
    isLoading,
    uiLimitRow: customer?.permissions?.ui_rows_limit,
    handleChangeVisibleColumns,
    handleColumnResize,
  };
};
