import {
  EuiButtonEmpty,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiLink,
  EuiPopover,
  useEuiTheme,
} from '@elastic/eui';
import { css } from '@emotion/css';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

interface AddressRawProps {
  value: string;
  title?: string;
  children?: JSX.Element | string;
}
const GOOGLE_MAPS = 'https://www.google.com/maps/search/';
const YANDEX_MAPS = 'https://yandex.com/maps/?mode=search&text=';

export const AddressRaw: FC<AddressRawProps> = ({ value, children }) => {
  const { euiTheme } = useEuiTheme();
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleToggleMenu = useCallback(() => setIsMenuOpen((state) => !state), []);
  const handleCloseMenu = useCallback(() => setIsMenuOpen(false), []);
  const actions = useMemo(
    () => [
      <EuiLink
        key="openOnGoogleMap"
        href={GOOGLE_MAPS + value}
        target="_blank"
        onClick={handleCloseMenu}
        color="text"
        external={false}
        className={css`
          display: block;
          padding: 6px 8px;
          line-height: 1.7;
          font-weight: 400;
          :hover,
          :focus {
            background-color: rgba(0, 119, 204, 0.1);
            text-decoration: none;
          }
        `}
      >
        {t('button.openOnGoogleMap')}
      </EuiLink>,
      <EuiLink
        key="openOnYandexMap"
        href={YANDEX_MAPS + value}
        target="_blank"
        onClick={handleCloseMenu}
        color="text"
        external={false}
        className={css`
          display: block;
          padding: 6px 8px;
          line-height: 1.7;
          font-weight: 400;
          :hover,
          :focus {
            background-color: rgba(0, 119, 204, 0.1);
            text-decoration: none;
          }
        `}
      >
        {t('button.openOnYandexMap')}
      </EuiLink>,
      <CopyToClipboard
        key="copyAddress"
        text={value}
        options={{
          format: 'text/plain',
        }}
      >
        <EuiContextMenuItem key="copyAddress" onClick={handleCloseMenu} size="s">
          {t('button.copy')}
        </EuiContextMenuItem>
      </CopyToClipboard>,
    ],
    [t, value, handleCloseMenu]
  );

  useEffect(() => {
    const list = document.querySelector('.profiler-list');
    document.addEventListener('scroll-profiler-list', handleCloseMenu);
    if (list) list.addEventListener('scroll', handleCloseMenu);

    return () => {
      document.removeEventListener('scroll-profiler-list', handleCloseMenu);
      if (list) list.removeEventListener('scroll', handleCloseMenu);
    };
  }, [handleCloseMenu]);

  return (
    <EuiFlexGroup alignItems="flexStart" gutterSize="xs">
      <EuiPopover
        button={
          <EuiButtonEmpty
            aria-label="Actions profiler card"
            iconType="visMapRegion"
            color="text"
            onClick={handleToggleMenu}
            size="xs"
            contentProps={{ style: { padding: 0, color: euiTheme.colors.successText } }}
            style={{ height: '14px' }}
            className={css(`
                        min-inline-size: 0;
                        width: 14px;
                        svg {
                          height: 12px;
                          width: 12px;
                          fill: var(--color-accent-text);
                        }
                        .euiButtonEmpty__text {
                          display: none;
                        }
                      `)}
            disabled={false}
          />
        }
        isOpen={isMenuOpen}
        closePopover={handleCloseMenu}
        panelPaddingSize="none"
        anchorPosition="rightUp"
      >
        <EuiContextMenuPanel size="s" items={actions} />
      </EuiPopover>
      <span>{children || value}</span>
    </EuiFlexGroup>
  );
};
