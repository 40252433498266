import {
  EuiButton,
  EuiButtonEmpty,
  EuiDatePicker,
  EuiFieldText,
  EuiFilePicker,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import JSZip from 'jszip';
import moment from 'moment';
import { v4 as getUid } from 'uuid';

import { phonebookActions } from '@/store/phonebook';
import { useAppDispatch } from '@/store';
import { toastActions } from '@/store/toast';
import { ToastVariantBasic, ToastVariantWithText } from '@/components/Toasts';

import { FormFields, XLSX_REG, schema } from './schema';
interface UploadPhonebookProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const UploadPhonebook: FC<UploadPhonebookProps> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isReadingFile, setReadingFile] = useState<boolean>(false);

  const { control, handleSubmit, setError, watch, setValue, trigger } = useForm<
    FormFields & {
      filename: string;
    }
  >({
    defaultValues: {
      title: '',
      description: '',
      leaked_at: '',
      filename: '',
    },
    resolver: yupResolver(schema),
  });

  const files = watch('file') as FileList | null;

  const readFile = useCallback(
    async (file: File) => {
      try {
        setReadingFile(true);
        let currentFileName = '';

        if (file.name.match(XLSX_REG)) {
          currentFileName = file.name;
        }

        if (file.name.match(/.*\.zip$/)) {
          try {
            const zip = new JSZip();
            const zipFiles = await zip.loadAsync(file);
            let foundedFile: string | null = null;
            zipFiles.forEach((relativePath) => {
              if (!foundedFile && relativePath.match(XLSX_REG)) {
                foundedFile = relativePath;
              }
            });

            if (foundedFile) {
              currentFileName = foundedFile;
            }
          } catch (errorZip) {
            captureException(errorZip);
          }
        }

        if (currentFileName) {
          setValue('title', currentFileName + '_' + getUid().slice(0, 8));
        }

        const leakedAt = moment(file.lastModified).toISOString();

        setValue('leaked_at', leakedAt);
        setValue('filename', currentFileName);
      } catch (error) {
        captureException(error);
      } finally {
        setReadingFile(false);
        trigger('file');
      }
    },
    [setValue, trigger]
  );

  const handleUpload = useCallback(
    async (data: FormFields) => {
      try {
        setLoading(true);
        await dispatch(
          phonebookActions.create({
            ...data,
            leaked_at: moment(data.leaked_at).format(process.env.REACT_APP_DATE_FORMAT),
          })
        ).unwrap();
        setLoading(false);
        onClose();
        onSuccess();
      } catch (e: any) {
        const errorResponse = e as Definitions.Error;
        if (errorResponse?.errors) {
          errorResponse.errors.forEach((error) => {
            return setError(error.location as keyof FormFields, error);
          });
        } else if (errorResponse?.message) {
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.ERROR_ACTION,
              text: errorResponse.message,
            })
          );
        } else {
          dispatch(
            toastActions.create({
              type: ToastVariantBasic.UNKNOWN_ERROR,
            })
          );
        }
        setLoading(false);
      }
    },
    [dispatch, onClose, setError, onSuccess]
  );

  useEffect(() => {
    if (files && files[0]) {
      readFile(files[0]);
    }
  }, [files, readFile]);

  return (
    <EuiModal onClose={onClose} maxWidth="l">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t('modals.uploadPhonebook.title')}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <div
          style={{
            maxWidth: 525,
          }}
        >
          <EuiText
            size="s"
            color="subdued"
            style={{
              wordBreak: 'break-word',
            }}
          >
            {t('phonebookForm.label.file')}
          </EuiText>
          <EuiSpacer size="m" />
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow fullWidth isInvalid={!!error} error={t(error?.message as string)}>
                <EuiFilePicker
                  initialPromptText={t('phonebookForm.placeholder.file')}
                  onChange={onChange}
                  onBlur={onBlur}
                  display={'large'}
                  isInvalid={!!error}
                  isLoading={isReadingFile}
                  aria-label={t('phonebookForm.placeholder.file')}
                  accept=".xls, .xlsx, .zip"
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
        </div>
        <EuiSpacer size="m" />
        <Controller
          control={control}
          name="title"
          render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
            <EuiFormRow
              fullWidth
              label={t(`phonebookForm.label.${name}`)}
              isInvalid={!!error}
              error={t(error?.message as string)}
            >
              <EuiFieldText
                placeholder={t(`phonebookForm.placeholder.${name}`)}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isInvalid={!!error}
                fullWidth
              />
            </EuiFormRow>
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
            <EuiFormRow
              fullWidth
              label={t(`phonebookForm.label.${name}`)}
              isInvalid={!!error}
              error={t(error?.message as string)}
            >
              <EuiFieldText
                placeholder={t(`phonebookForm.placeholder.${name}`)}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isInvalid={!!error}
                fullWidth
              />
            </EuiFormRow>
          )}
        />
        <Controller
          control={control}
          name="leaked_at"
          render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
            <EuiFormRow
              fullWidth
              label={t(`phonebookForm.label.${name}`)}
              isInvalid={!!error}
              error={t(error?.message as string)}
            >
              <EuiDatePicker
                fullWidth
                selected={value ? moment(value) : null}
                isInvalid={!!error}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t(`phonebookForm.placeholder.${name}`)}
                dateFormat={process.env.REACT_APP_DATE_FORMAT}
              />
            </EuiFormRow>
          )}
        />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty color="text" onClick={onClose}>
          {t('button.cancel')}
        </EuiButtonEmpty>
        <EuiButton isLoading={isLoading} onClick={handleSubmit(handleUpload)} fill>
          {t('modals.uploadPhonebook.upload')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
