import { FC, ReactElement } from 'react';

import { useRole, Roles } from '@/hooks';

interface RoleRequiredProps {
  roles: Roles[];
  fallback: ReactElement | null;
}

export const RoleRequiredChildren: FC<RoleRequiredProps> = ({ roles, fallback, children }) => {
  const { isValid } = useRole(roles);
  return isValid ? <>{children}</> : fallback;
};
