import { useHref, useLinkClickHandler } from 'react-router-dom';
import { EuiLink } from '@elastic/eui';
import { ReactNode, useMemo } from 'react';

import { Routes } from '@/routes';

interface LeakLinkProps {
  title: string;
  className?: string;
  children?: ReactNode;
}

export const LeakLink = ({ title, children, className }: LeakLinkProps) => {
  const params = useMemo(() => {
    const urlSearchParams = new URLSearchParams({
      query: `leak.title.eq: "${title}"`,
    });

    return urlSearchParams.toString();
  }, [title]);

  const url = useHref({
    search: params,
    pathname: Routes.PROFILER,
  });

  const handleClick = useLinkClickHandler(url);

  return (
    <EuiLink className={className} href={url.toString()} onClick={handleClick}>
      {children || title}
    </EuiLink>
  );
};
