import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EuiDataGridOnColumnResizeHandler } from '@elastic/eui';

import { useAppSelector } from '@/store';
import { customerSelectors } from '@/store/customer';
import { leaksSelectors } from '@/store/leaks';

import { columnsConfig } from './constants';
import { getRowData, sortingRequestAdapter } from './helper';
import { Columns, SortColumn } from './types';

export const VISIBLE_COLUMNS_STORAGE_KEY = 'visible_columns_leak_registry';
const getSavedVisibleColumns = (mustContained?: string): string[] => {
  const columnsJson = localStorage.getItem(VISIBLE_COLUMNS_STORAGE_KEY);
  let columns: string[] = [];
  if (columnsJson) {
    columns = JSON.parse(columnsJson);
  } else {
    columns = [Columns.trust, Columns.title, Columns.released_at, Columns.records, Columns.fields, Columns.description];
  }
  if (mustContained && !columnsJson?.includes(mustContained)) {
    columns.push(mustContained);
  }
  return columns;
};

const getColumnsConfig = () =>
  columnsConfig.map((column) => {
    let initialWidth = Number(localStorage.getItem(`${column.id}_column_width_leak_registry`));
    if (initialWidth === 0 && column.initialWidth) {
      initialWidth = column.initialWidth;
    }

    return {
      ...column,
      initialWidth: initialWidth !== 0 ? initialWidth : undefined,
    };
  });

export const useSearchData = ({
  initialSort,
  onChangeSort,
}: {
  initialSort: SortColumn | null;
  onChangeSort: (sort: Definitions.Sort) => void;
}) => {
  const [columns, setColumns] = useState(() => getColumnsConfig());

  const [visibleColumns, setVisibleColumns] = useState(() => getSavedVisibleColumns(initialSort?.id));

  const [sortingColumns, setSortingColumns] = useState<SortColumn[]>(initialSort ? [initialSort] : []);
  const prevSortingColumns = useRef<SortColumn | null>(initialSort);

  const items = useAppSelector(leaksSelectors.getData);
  const totalItems = useAppSelector(leaksSelectors.getTotalData);
  const isLoading = useAppSelector(leaksSelectors.getLoading);
  const isExportPennding = useAppSelector(leaksSelectors.getExportPennding);
  const customer = useAppSelector(customerSelectors.getInfo);

  const handleChangeVisibleColumns = useCallback(
    (columns: string[]) => {
      localStorage.setItem(VISIBLE_COLUMNS_STORAGE_KEY, JSON.stringify(columns));
      setVisibleColumns(columns);
    },
    [setVisibleColumns]
  );

  const handleChangeSort = useCallback(
    (columns: SortColumn[]) => {
      setSortingColumns((prevColumns) =>
        columns.filter(
          (column) =>
            prevColumns.findIndex(
              (prevColumn) => prevColumn.id === column.id && prevColumn.direction === column.direction
            ) === -1
        )
      );
    },
    [setSortingColumns]
  );

  useEffect(() => {
    const currentColumn = sortingColumns[0];

    if (
      currentColumn &&
      ((prevSortingColumns.current &&
        (prevSortingColumns.current.direction !== currentColumn.direction ||
          prevSortingColumns.current.id !== currentColumn.id)) ||
        !prevSortingColumns.current)
    ) {
      const sortDataRequest = sortingRequestAdapter(currentColumn);
      onChangeSort({
        order_by: sortDataRequest.id,
        sort_by: sortDataRequest.direction,
      });
      prevSortingColumns.current = currentColumn;
    } else if (!currentColumn && prevSortingColumns.current) {
      onChangeSort({
        order_by: undefined,
        sort_by: undefined,
      });
      prevSortingColumns.current = null;
    }
  }, [prevSortingColumns, sortingColumns, onChangeSort]);

  const rawData = useMemo(() => getRowData(items || []), [items]);

  const handleColumnResize: EuiDataGridOnColumnResizeHandler = useCallback((event) => {
    localStorage.setItem(`${event.columnId}_column_width_leak_registry`, event.width.toString());
    setColumns(getColumnsConfig());
  }, []);

  return {
    columns,
    visibleColumns,
    rawData,
    sortingColumns,
    totalItems,
    isLoading,
    uiLimitRow: customer?.permissions?.ui_rows_limit,
    isExportPennding,
    handleChangeVisibleColumns,
    handleColumnResize,
    handleChangeSort,
  };
};
