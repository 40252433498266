import { EuiFlexGroup, EuiIcon, EuiSwitch, EuiText } from '@elastic/eui';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LandmarksTypes } from '@/pages/Map/types';
import { getIcon } from '../LandmarkIcon';

interface LandmarkControlProps {
  selected?: boolean;
  type: LandmarksTypes;
  onChange: (type: LandmarksTypes) => void;
}

export const LandmarkControl: FunctionComponent<LandmarkControlProps> = ({ selected = false, type, onChange }) => {
  const { t } = useTranslation();
  const handleChange = useCallback(() => {
    onChange(type);
  }, [type, onChange]);

  return (
    <EuiFlexGroup gutterSize="xs" alignItems="center">
      <EuiSwitch
        label={t(`landmarks.${type}`)}
        showLabel={false}
        checked={selected}
        onChange={handleChange}
        compressed
      />
      <EuiIcon type={getIcon(type)} color="text" size="l" />
      <EuiText textAlign="left" color="subdued" size="s">
        {t(`landmarks.${type}`)}
      </EuiText>
    </EuiFlexGroup>
  );
};
