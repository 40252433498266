import { FC } from 'react';

import { ImagePreview } from './ImagePreview';
import { PdfPreview } from './PdfPreview';
import { FileTypes } from './types';

type PreviewFileProps = {
  name?: string;
  ext: string;
  type: FileTypes;
  file: string;
  onClose: () => void;
};

export const PreviewFile: FC<PreviewFileProps> = ({ type, name, file, ext, onClose }) => {
  if (type === 'image') {
    return <ImagePreview file={file} name={name || file} onClose={onClose} />;
  }
  if (type === 'document' && ext === 'pdf') {
    return <PdfPreview file={file} name={name || file} onClose={onClose} />;
  }
  return null;
};
