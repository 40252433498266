import moment from 'moment';

export const getDeepSearchFields = (
  data: Definitions.Identity | Definitions.IdentityProfile
): Definitions.RecursiveSearchFieldsRequest => {
  const { names, emails, inn, snils, phones, addresses, passports } = data;
  const fd: Definitions.RecursiveSearchFieldsRequest = {};
  let name = '';
  if (names) {
    const item = names.find((v) => v.raw || (v.last && v.first));
    if (item) name = item.last && item.first ? `${item.last} ${item.first} ${item.middle || ''}` : `${item.raw}`;
  }
  if (name && 'birthday' in data && data.birthday && (data.birthday.date || data.birthday.raw)) {
    const birthdayFormattedDate = moment(data.birthday.date || data.birthday.raw).format(
      process.env.REACT_APP_DATE_FORMAT
    );
    fd.birthday = birthdayFormattedDate;
    fd.raw_name = name;
  }
  if (name && 'birthdays' in data && data.birthdays) {
    const birthday = data.birthdays.find((v) => v.date || v.raw);
    if (birthday) {
      const birthdayFormattedDate = moment(birthday.date || birthday.raw).format(process.env.REACT_APP_DATE_FORMAT);
      fd.birthday = birthdayFormattedDate;
      fd.raw_name = name;
    }
  }
  if (emails && emails.length > 0) {
    const email = emails.find((item) => item.email || item.raw);
    if (email) fd.email = email.email || email.raw;
  }
  if (inn) fd.inn = Array.isArray(inn) ? inn[0].value : inn;
  if (snils) fd.snils = Array.isArray(snils) ? snils[0] : snils;
  if (phones && phones.length > 0) {
    const phone = phones.find((item) => item.phone || item.raw);
    if (phone) fd.phone = phone.phone || phone.raw;
  }
  if (addresses && addresses.length > 0) {
    const address = addresses.find((item) => item.raw);
    if (address) fd.address = address.raw;
  }
  if (passports) {
    const passport = passports.find(
      (passport) => (passport.passport_series && passport.passport_number) || passport.raw
    );
    if (passport)
      fd.passport =
        passport.passport_series && passport.passport_number
          ? `${passport.passport_series}${passport.passport_number}`
          : passport.raw;
  }

  return fd;
};

export const validateActions = (
  data: Definitions.Identity | Definitions.IdentityProfile,
  type: 'deep_search' | 'validate_snils' | string
) => {
  switch (type) {
    case 'deep_search':
      return Object.values(getDeepSearchFields(data)).length > 0;
    case 'validate_snils':
      if ('birthday' in data) {
        return !!data.snils && !!data.birthday && !!data.names;
      } else if ('birthdays' in data) {
        return !!data.snils && !!data.birthdays && !!data.names;
      }
      return false;
    default:
      return true;
  }
};
