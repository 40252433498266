import { captureException } from '@sentry/react';
import { HTMLAttributes, memo, useEffect, useRef } from 'react';

import { Highlight, getChunks, getMatch } from '../CardData';

interface SpanHighLightsCodeProps extends HTMLAttributes<HTMLSpanElement> {
  highlights: Highlight[];
}

export const SpanHighLightsCode = memo(function SpanHighLightsCode({ highlights, children }: SpanHighLightsCodeProps) {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (container.current) {
      const elements = container.current.querySelectorAll('span.token');

      elements.forEach((containerElement) => {
        const children = containerElement.querySelectorAll('.highlight-child');
        children.forEach((child) => {
          containerElement.removeChild(child);
        });

        let text = '';
        if (!containerElement.classList.contains('highlight-parent')) {
          text = containerElement.textContent?.toLocaleLowerCase() || '';
          containerElement.textContent = null;
          containerElement.classList.add('highlight-parent');
          const element = document.createElement('span');
          element.className = 'highlight-content';
          element.textContent = text;
          containerElement.appendChild(element);
        } else {
          text = containerElement.querySelector('.highlight-content')?.textContent?.toLowerCase() || '';
        }

        try {
          highlights.forEach(({ value, operator, key }) => {
            const isAnyEq = key.length === 0 && operator === 'eq' && value === '*';

            if (value && ((isAnyEq && containerElement.classList.contains('string')) || !isAnyEq)) {
              const match = getMatch(text.toLowerCase().replaceAll(/^"|"$/gi, ''), value, operator as string);

              if (match) {
                const chunks = getChunks(text, match, operator);
                const hightLightElement = document.createElement('span');
                hightLightElement.style.width = Math.ceil(containerElement.getBoundingClientRect().width) + 'px';
                hightLightElement.className = 'highlight-child';
                chunks.forEach((chunk, index) => {
                  hightLightElement.appendChild(document.createTextNode(chunk));
                  if (index < chunks.length - 1) {
                    const hightLightElementPart = document.createElement('span');
                    hightLightElementPart.className = 'hightlight';

                    hightLightElementPart.textContent = match;
                    hightLightElement.appendChild(hightLightElementPart);
                  }
                });
                containerElement.appendChild(hightLightElement);
              }
            }
          });
        } catch (error) {
          captureException(error);
        }
      });
    }
  }, [highlights]);
  return <div ref={container}>{children}</div>;
});
