import {
  EuiFlexGroup,
  EuiGlobalToastListItem,
  EuiIcon,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  EuiToast,
  useEuiTheme,
} from '@elastic/eui';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { jsx as ___EmotionJSX } from '@emotion/react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { euiGlobalToastListStyles } from '@elastic/eui/es/components/toast/global_toast_list.styles.js';

import { useAppDispatch, useAppSelector } from '@/store';
import { toastActions, toastSelectors } from '@/store/toast';

import { Toast, ToastVariant, ToastVariantBasic, ToastVariantWithText } from './types';
import { ContactUsToastButton } from './ContactUsToastButton';

const DEFAUT_TOAST_DURATION = 10000;

export const ToastProvider = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const euiThemeContext = useEuiTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const timersRef = useRef(new Map<string, NodeJS.Timer>());

  const styles = useMemo(() => euiGlobalToastListStyles(euiThemeContext), [euiThemeContext]);

  const toasts = useAppSelector(toastSelectors.getItems);

  useEffect(() => {
    toasts
      .filter(({ id }) => !timersRef.current.get(id))
      .forEach(({ id, type }) => {
        const config = ToastConfigs.get(type);
        if (config && config.duration) {
          const timerId = setTimeout(() => {
            dispatch(toastActions.remove(id));
            timersRef.current.delete(id);
          }, config.duration);

          timersRef.current.set(id, timerId);
        }
      });
  }, [toasts, timersRef, dispatch]);

  useEffect(() => {
    if (containerRef.current && containerRef.current.lastChild) {
      const lastItem = containerRef.current.lastChild as HTMLDivElement;
      lastItem.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [toasts]);

  const handleClose = useCallback(
    (id: string) => {
      dispatch(toastActions.remove(id));
    },
    [dispatch]
  );
  /*
  useEffect(() => {
    dispatch(
      toastActions.create({
        type: ToastVariantBasic.DOWNLOADED_ERROR_FILE,
      })
    );
  }, [dispatch]);
  */

  const renderContentToast = useCallback(
    (data: Toast, config: ToastConfig) => {
      const { type } = data;
      switch (type) {
        case ToastVariantBasic.DAILY_LIMIT:
        case ToastVariantBasic.SUBSCRIPTION_EXPIRED:
        case ToastVariantBasic.UNKNOWN_ERROR:
          return (
            <>
              <EuiText size="s">{t(`toast.content.${type}`)}</EuiText>
              <EuiSpacer size="s" />
              <EuiFlexGroup justifyContent="flexEnd">
                <ContactUsToastButton color={config.color}>{t('button.contact_us')}</ContactUsToastButton>
              </EuiFlexGroup>
            </>
          );
        case ToastVariantBasic.DOWNLOADING_FILE:
          return (
            <EuiFlexGroup direction="row">
              <EuiLoadingSpinner />
              <EuiText size="s">{t(`toast.content.${type}`)}</EuiText>
            </EuiFlexGroup>
          );
        case ToastVariantBasic.DOWNLOADED_FILE:
          return (
            <EuiFlexGroup direction="row">
              <EuiIcon type="check" color="success" />
              <EuiText size="s">{t(`toast.content.${type}`)}</EuiText>
            </EuiFlexGroup>
          );
        case ToastVariantBasic.DOWNLOADED_ERROR_FILE:
          return (
            <EuiFlexGroup direction="row">
              <EuiIcon type="alert" color="danger" />
              <EuiText size="s">{t(`toast.content.${type}`)}</EuiText>
            </EuiFlexGroup>
          );
        case ToastVariantBasic.TO_MANY_REQUESTS:
        case ToastVariantBasic.NETWORK_ERROR:
        case ToastVariantBasic.PASSWORD_CHANGED:
          return (
            <>
              <EuiText size="s">{t(`toast.content.${type}`)}</EuiText>
            </>
          );
        case ToastVariantWithText.SUCCESS_ACTION:
          return (
            <EuiFlexGroup direction="row">
              <EuiIcon type="check" color="success" />
              <EuiText size="s">
                <p
                  style={{
                    paddingRight: 20,
                  }}
                >
                  {data.text}
                </p>
              </EuiText>
            </EuiFlexGroup>
          );
        case ToastVariantWithText.WARNING_ACTION:
          return (
            <EuiFlexGroup direction="row">
              <EuiIcon type="alert" color="warning" />
              <EuiText size="s">
                <p
                  style={{
                    paddingRight: 20,
                  }}
                >
                  {data.text}
                </p>
              </EuiText>
            </EuiFlexGroup>
          );
        case ToastVariantWithText.ERROR_ACTION:
          return (
            <EuiFlexGroup direction="row">
              <EuiIcon type="alert" color="danger" />
              <EuiText size="s">
                <p
                  style={{
                    paddingRight: 20,
                  }}
                >
                  {data.text}
                </p>
              </EuiText>
            </EuiFlexGroup>
          );
      }
    },
    [t]
  );

  return ___EmotionJSX(
    'div',
    {
      ref: containerRef,
      css: [styles.euiGlobalToastList, styles.right],
    },
    toasts.map((toast) => {
      const { type, id } = toast;
      const config = ToastConfigs.get(type);

      return config ? (
        <EuiGlobalToastListItem key={id}>
          <EuiToast
            title={i18n.exists(`toast.title.${type}`) ? t(`toast.title.${type}`) : null}
            onClose={config.disabledClose ? undefined : () => handleClose(id)}
            color={config.color}
          >
            {renderContentToast(toast, config)}
          </EuiToast>
        </EuiGlobalToastListItem>
      ) : null;
    })
  );
};

interface ToastConfig {
  color: 'primary' | 'success' | 'warning' | 'danger';
  disabledClose: boolean;
  duration?: number; //in milli seconds
}

const ToastConfigs = new Map<ToastVariant, ToastConfig>([
  [
    ToastVariantBasic.DAILY_LIMIT,
    {
      color: 'danger',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantBasic.TO_MANY_REQUESTS,
    {
      color: 'warning',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantBasic.DOWNLOADED_FILE,
    {
      color: 'success',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantBasic.DOWNLOADED_ERROR_FILE,
    {
      color: 'danger',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantBasic.DOWNLOADING_FILE,
    {
      color: 'primary',
      disabledClose: true,
    },
  ],
  [
    ToastVariantBasic.NETWORK_ERROR,
    {
      color: 'danger',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantBasic.PASSWORD_CHANGED,
    {
      color: 'success',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantBasic.SUBSCRIPTION_EXPIRED,
    {
      color: 'danger',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantBasic.UNKNOWN_ERROR,
    {
      color: 'danger',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantWithText.SUCCESS_ACTION,
    {
      color: 'success',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantWithText.WARNING_ACTION,
    {
      color: 'warning',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantWithText.ERROR_ACTION,
    {
      color: 'danger',
      disabledClose: false,
      duration: DEFAUT_TOAST_DURATION,
    },
  ],
]);
