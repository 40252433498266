import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { captureException } from '@sentry/react';
import {
  EuiBadge,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiLoadingSpinner,
  EuiSplitPanel,
  EuiText,
} from '@elastic/eui';
import cn from 'classnames';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '@/store';
import { mapActions, mapSelectors } from '@/store/map';
import { getTrustColor } from '@/components/LeakProfilerData';
import { LeakCountryBadge, useCountry } from '@/components/LeakCountryBadge';
import { SelectedPoint, SelectedPointMachine } from '../types';
import { getIcon } from '../LandmarkIcon';
import { LandmarksTypes } from '@/pages/Map/types';
import { filterData, getLines, CardData } from '@/components/CardData';

import { LeakPagination } from './LeakPaggination';

interface MapProfilerCardProps {
  points: SelectedPoint[];
  onClose: () => void;
}

const MAX_WIDTH = 460;
const FILTERED_KEYS_PEOPLE_CARD = ['leak', 'created_at', 'updated_at', 'profile_photos'];
const FILTERED_KEYS_INFO_CARD = ['title', 'type'];

export const MapProfilerCard: FC<MapProfilerCardProps> = ({ points, onClose }) => {
  const [selectedPoint, setSelectedPoint] = useState<number>(0);

  const point = useMemo<SelectedPoint | undefined>(() => points[selectedPoint], [points, selectedPoint]);
  const isPeople = useMemo(() => point?.type === 'people', [point]);
  const isCompromisedMachine = useMemo(() => point?.type === 'compromised_machine', [point]);

  const dispatch = useAppDispatch();
  const data = useAppSelector((state) =>
    point?.type === 'people'
      ? mapSelectors.getCardById(state, point?.id || '')
      : mapSelectors.getLandmarksById(state, point?.id || '')
  );
  const [isLoading, setLoading] = useState(!data);
  const componentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { getCountry } = useCountry();

  const handleLoad = useCallback(async () => {
    if (point && isPeople) {
      try {
        setLoading(true);
        await dispatch(mapActions.fetchProfilerCardById({ id: point.id })).unwrap();
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    }
  }, [point, dispatch, isPeople]);

  useEffect(() => {
    setSelectedPoint(0);
  }, [points]);

  const handleNext = useCallback(
    () =>
      setSelectedPoint((selected) => {
        if (selected + 1 <= points.length - 1) {
          return selected + 1;
        }

        return selected;
      }),
    [points]
  );
  const handlePrev = useCallback(
    () =>
      setSelectedPoint((selected) => {
        if (selected - 1 >= 0) {
          return selected - 1;
        }

        return selected;
      }),
    []
  );

  useEffect(() => {
    if (!data) {
      handleLoad();
    }
  }, [data, handleLoad]);

  const renderHeaderCard = useCallback(() => {
    let infoHeader = null;

    if (isPeople) {
      const { leak } = data as Definitions.Identity;

      infoHeader = (
        <EuiFlexGroup gutterSize="xs" alignItems="center">
          {leak?.trust && (
            <EuiFlexItem grow={0}>
              <EuiBadge
                className={cn(
                  (() => {
                    const trustColors = getTrustColor(leak.trust);
                    if (!trustColors) return '';

                    return css(`
      border-color: ${trustColors.border}!important;
      background-color: ${trustColors.background}!important;
      color: ${trustColors.text}!important;
    `);
                  })(),
                  'trustBadge'
                )}
                aria-label="trust"
                title={t(`tooltips.trust`, {
                  trust: t(`trust.${leak.trust}`),
                })}
              >
                {leak.trust.toUpperCase()}
              </EuiBadge>
            </EuiFlexItem>
          )}
          {leak?.country && (
            <EuiFlexItem grow={0}>
              <LeakCountryBadge code={leak.country} getCountry={getCountry} />
            </EuiFlexItem>
          )}
          {leak?.released_at && (
            <EuiFlexItem grow={0}>
              <EuiBadge
                className={cn(
                  'leakText',
                  css(`
      border-color: #D3DAE6!important;
      margin: 0!important;
    `)
                )}
                color="transparent"
                title={t(`tooltips.date`)}
              >
                {moment.parseZone(leak.released_at).format(process.env.REACT_APP_DATE_FORMAT)}
              </EuiBadge>
            </EuiFlexItem>
          )}
          <EuiFlexItem
            grow={1}
            style={{
              minWidth: 0,
            }}
          >
            <EuiText size="s">
              <EuiLink
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingRight: 16,
                  maxWidth: '100%',
                }}
                color={'primary'}
                onClick={() => {
                  //if (leak) onLeakOpen(leak);
                }}
              >
                <b className="leakText">{leak?.title}</b>
              </EuiLink>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    } else if (isCompromisedMachine) {
      const compromisedMachinePoint = point as SelectedPointMachine;
      infoHeader = (
        <EuiFlexGroup gutterSize="xs" alignItems="center">
          <EuiFlexItem grow={0}>
            <EuiIcon
              title={t(`landmarks.compromised_machine`)}
              type={getIcon('compromised_machine')}
              color="text"
              size="l"
            />
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              width: 1,
            }}
          >
            <EuiText size="s">
              <span
                style={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingRight: 16,
                  maxWidth: '100%',
                }}
              >
                <b className="leakText">
                  {t(`landmarks.compromised_machine`)} ({compromisedMachinePoint.machines.length})
                </b>
              </span>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    } else {
      const { type, info } = data as Definitions.Landmarks;

      infoHeader = (
        <EuiFlexGroup gutterSize="xs" alignItems="center">
          {type && (
            <EuiFlexItem grow={0}>
              <EuiIcon title={t(`landmarks.${type}`)} type={getIcon(type as LandmarksTypes)} color="text" size="l" />
            </EuiFlexItem>
          )}
          {info?.title && (
            <EuiFlexItem
              style={{
                width: 1,
              }}
            >
              <EuiText size="s">
                <span
                  style={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    paddingRight: 16,
                    maxWidth: '100%',
                  }}
                >
                  <b className="leakText" title={info?.title}>
                    {info?.title}
                  </b>
                </span>
              </EuiText>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      );
    }

    return (
      <EuiSplitPanel.Inner color="subdued" grow={false}>
        <EuiFlexGroup gutterSize="none" alignItems="center" justifyContent="spaceBetween">
          <EuiFlexItem
            style={{
              minWidth: 0,
            }}
          >
            {infoHeader}
          </EuiFlexItem>
          <EuiFlexItem grow={0}>
            <EuiButtonIcon iconType="cross" onClick={onClose} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiSplitPanel.Inner>
    );
  }, [isPeople, point, isCompromisedMachine, data, t, getCountry, onClose]);

  const renderDataCard = useCallback(() => {
    let content = null;
    if (isPeople) {
      const { id, ...rest } = data;
      const lines = getLines.call(
        { profilerValidatedKeys: [] },
        filterData(cloneDeep(rest), FILTERED_KEYS_PEOPLE_CARD),
        0,
        ''
      );
      content = <CardData data={lines} id={id} />;
    } else if (isCompromisedMachine) {
      const compromisedMachinePoint = point as SelectedPointMachine;
      if (compromisedMachinePoint.machines.length) {
        content = <LeakPagination containerRef={componentRef} data={compromisedMachinePoint.machines} />;
      }
    } else {
      const { id, ...rest } = data;
      const lines = getLines.call(
        { profilerValidatedKeys: [] },
        filterData(cloneDeep(rest), FILTERED_KEYS_INFO_CARD),
        0,
        ''
      );
      content = <CardData data={lines} id={id} />;
    }

    const style: CSSProperties = {
      position: 'relative',
      overflowY: 'auto',
    };
    if (isCompromisedMachine) {
      style['padding'] = 0;
    }

    return (
      <EuiSplitPanel.Inner panelRef={componentRef} style={style} grow={true} className="eui-scrollBar">
        {content}
      </EuiSplitPanel.Inner>
    );
  }, [isPeople, isCompromisedMachine, point, data]);

  return (
    <EuiSplitPanel.Outer
      hasBorder={true}
      hasShadow={false}
      borderRadius={'none'}
      className={cn(
        'MapProfilerCard',
        css(`
        width: ${MAX_WIDTH}px;
        display: flex;
        flex-direction: column;
        height: 100%;
      `)
      )}
    >
      {isLoading && (
        <EuiSplitPanel.Inner>
          <EuiFlexGroup
            style={{
              height: '100%',
            }}
            alignItems="center"
            justifyContent="center"
          >
            <EuiLoadingSpinner size="l" />
          </EuiFlexGroup>
        </EuiSplitPanel.Inner>
      )}
      {data && !isLoading && (
        <>
          {renderHeaderCard()}
          {renderDataCard()}
        </>
      )}
      {points.length > 1 && (
        <EuiSplitPanel.Inner color="subdued" grow={false}>
          <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
            <EuiButtonIcon disabled={!(selectedPoint - 1 >= 0)} iconType="arrowLeft" onClick={handlePrev} />
            <EuiText size="s" color="subdued">
              {selectedPoint + 1} {t('pagination.from')} {points.length}
            </EuiText>
            <EuiButtonIcon
              disabled={!(selectedPoint + 1 <= points.length - 1)}
              iconType="arrowRight"
              onClick={handleNext}
            />
          </EuiFlexGroup>
        </EuiSplitPanel.Inner>
      )}
    </EuiSplitPanel.Outer>
  );
};
