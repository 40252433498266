import { EuiBadge, EuiIcon } from '@elastic/eui';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { captureException } from '@sentry/core';

interface LeakCountryBadgeProps {
  code: string;
  getCountry: (code: string) => string;
}

interface LeakCountryFlagProps {
  code: string;
}

interface Country {
  Code: string;
  Name: string;
}

export const useCountry = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      const data = await import(`@/assets/countries/${i18n.language}.json`);
      setCountries(Array.from(data));
    })();
  }, [i18n.language]);

  const getCountry = useCallback(
    (code: string) => {
      const country = countries.find((item) => item?.Code === code);
      if (country && country?.Name) {
        return country?.Name;
      }
      return code;
    },
    [countries]
  );

  return {
    getCountry,
  };
};

export const LeakCountryBadge: FC<LeakCountryBadgeProps> = ({ code, getCountry }) => {
  const { t } = useTranslation();

  return (
    <EuiBadge
      style={{
        margin: 0,
        padding: 0,
        width: 24,
        height: 20,
        position: 'relative',
      }}
      title={code === 'WW' ? t(`country.WW`) : getCountry(code)}
    >
      <LeakCountryFlag code={code} />
    </EuiBadge>
  );
};

const LeakCountryFlag: FC<LeakCountryFlagProps> = ({ code }) => {
  return code?.toLocaleUpperCase() === 'WW' ? (
    <EuiIcon
      style={{
        borderRadius: 3,
        position: 'absolute',
        top: 2,
        left: 4,
        width: 16,
        height: 16,
      }}
      size="s"
      type="globe"
    />
  ) : (
    <ReactCountryFlag
      style={{
        borderRadius: 3,
        position: 'absolute',
        top: 0,
        left: 0,
        width: 22,
        height: 18,
      }}
      svg
      onError={(error) => {
        captureException(error);
      }}
      countryCode={code}
    />
  );
};
