import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';

import { apps, useSelectedApp, useTitle, Roles } from '@/hooks';
import { useAppSelector } from '@/store';
import { customerSelectors } from '@/store/customer';
import {
  AccountTwoAuthCallOut,
  Administration,
  AppCard,
  RoleRequiredChildren,
  TgBotNotification,
  ThemeExtensions,
  UpdateLogger,
} from '@/components';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { PROFILER2_ANIMATION_KEY } from '../Profiler2/useData';

const TG_BOT_NOTIFICATION_KEY = 'dont_show_bot_notification';

export const Apps = () => {
  const [isDontShowBotNotification, setIsDontShowBotNotification] = useLocalStorage(TG_BOT_NOTIFICATION_KEY, false);
  const [isDontShowProfile2Animation] = useLocalStorage(PROFILER2_ANIMATION_KEY, false);

  const handleDontShowNotification = useCallback(() => {
    setIsDontShowBotNotification(true);
  }, [setIsDontShowBotNotification]);

  const { t } = useTitle('page.home.title');

  const { clearApp } = useSelectedApp();

  const user = useAppSelector(customerSelectors.getInfo);

  const { colorMode } = useEuiTheme<ThemeExtensions>();

  const isExpireNotification = useMemo(() => {
    const expireDate = process.env.REACT_APP_TG_BOT_NOTIFICATION_EXPIRE_DATE;
    return expireDate ? moment().isSameOrAfter(moment(expireDate)) : false;
  }, []);

  useEffect(() => {
    clearApp();
  }, [clearApp]);

  if (!user) {
    return null;
  }

  return (
    <EuiFlexGroup direction="column" alignItems="center">
      {!user?.enabled_two_fa && (
        <div style={{ width: 864, maxWidth: '100%' }}>
          <EuiSpacer size="xxl" />
          <AccountTwoAuthCallOut />
        </div>
      )}
      <EuiFlexGroup
        style={{ width: 960, maxWidth: '100%' }}
        direction="column"
        alignItems="flexStart"
        justifyContent="center"
        gutterSize="none"
      >
        <EuiSpacer size={!user?.enabled_two_fa ? 'xs' : 'xxl'} />
        {!isDontShowBotNotification && !isExpireNotification && (
          <>
            <TgBotNotification close={handleDontShowNotification} />
            <EuiSpacer size="l" />
          </>
        )}
        <EuiTitle size="s">
          <h1
            style={{
              margin: 0,
              color: colorMode === 'DARK' ? '#EDEDED' : '#3143E4',
              fontSize: '18px',
            }}
          >
            {t('page.home.title')}
          </h1>
        </EuiTitle>
        <EuiSpacer size="xl" />
        <EuiFlexGrid style={{ width: '100%', gridTemplateRows: 'repeat(3, 1fr)' }} gutterSize="m" columns={3}>
          {apps.map((app) => (
            <AppCard key={app} app={app} showBorderAnimation={!isDontShowProfile2Animation && !isExpireNotification} />
          ))}
        </EuiFlexGrid>
        <RoleRequiredChildren roles={[Roles.SYSTEM, Roles.SUPER_ADMIN, Roles.ADMIN]} fallback={null}>
          <EuiSpacer size="xxl" />
          <EuiFlexGrid style={{ width: '100%', gridTemplateColumns: '1fr 2fr' }} gutterSize="m" columns={2}>
            <EuiFlexItem grow={1}>
              <EuiTitle size="s">
                <h2
                  style={{
                    margin: 0,
                    fontSize: '18px',
                    color: colorMode === 'DARK' ? '#EDEDED' : '#3143E4',
                  }}
                >
                  {t('page.home.administration.title')}
                </h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={0}>
              <Administration />
            </EuiFlexItem>
          </EuiFlexGrid>
        </RoleRequiredChildren>
        <EuiSpacer size="xxl" />
        <EuiSpacer size="s" />
        <div style={{ width: 960, maxWidth: '100%' }}>
          <UpdateLogger limit={5} />
        </div>
        <EuiSpacer size="xxl" />
        <EuiFlexItem
          style={{
            alignSelf: 'center',
          }}
        >
          <EuiText size="xs" color="subdued">
            {t('page.home.footer')}
            &nbsp; &nbsp;
            <EuiLink href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{t('page.signIn.help.answer')}</EuiLink>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
    </EuiFlexGroup>
  );
};
