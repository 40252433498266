import { AxiosPromise, ResponseType } from 'axios';

import axios from '@/services/axios';

class LeakApi {
  private BASE_PATH = '/leaks';

  search(data: Paths.SearchCredentials.Parameters.Body): AxiosPromise<Paths.SearchCredentials.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/credentials/search`,
      method: 'post',
      data,
    });
  }

  profilersSearch(data: Paths.SearchProfilers.Parameters.Body): AxiosPromise<Paths.SearchProfilers.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/search`,
      method: 'post',
      data,
    });
  }

  getProfilersSearchFilter(
    params: Paths.ProfilerSearchFilters.QueryParameters
  ): AxiosPromise<Paths.ProfilerSearchFilters.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/search/filters`,
      method: 'get',
      params,
    });
  }

  getCredentialSearchFilter(
    params: Paths.CredentialSearchFilters.QueryParameters
  ): AxiosPromise<Paths.CredentialSearchFilters.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/credentials/search/filters`,
      method: 'get',
      params,
    });
  }

  updateNote({
    id,
    ...data
  }: Paths.UpdateCredentialNotes.Parameters.Body &
    Paths.UpdateCredentialNotes.PathParameters): AxiosPromise<Paths.UpdateCredentialNotes.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/credentials/${id}/notes`,
      method: 'put',
      data,
    });
  }

  download(
    data: Paths.LeaksDownload.Parameters.Body,
    responseType?: ResponseType,
    abortSignal?: AbortSignal
  ): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/leaks/download`,
      method: 'post',
      data,
      responseType,
      signal: abortSignal,
    });
  }

  getLeakById({ id }: Paths.GetLeaks.PathParameters): AxiosPromise<Paths.GetLeaks.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/leaks/${id}`,
      method: 'get',
    });
  }

  updateProfilerLeakById({
    id,
    ...data
  }: Paths.EditProfilerLeaks.PathParameters &
    Paths.EditProfilerLeaks.Parameters.Body): AxiosPromise<Paths.EditProfilerLeaks.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/leaks/${id}`,
      method: 'put',
      data,
    });
  }

  leakProfilerSearch(
    data: Paths.SearchProfilerLeaks.Parameters.Body
  ): AxiosPromise<Paths.SearchProfilerLeaks.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/leaks/search`,
      method: 'post',
      data,
    });
  }

  leakLocationSearch(
    data: Paths.SearchLeaksByLocation.Parameters.Body
  ): AxiosPromise<Paths.SearchLeaksByLocation.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/leaks/location/search`,
      method: 'post',
      data,
    });
  }

  getLeakProfilerFilter(params: Paths.ProfilerLeaksSearchFilters.QueryParameters) {
    return axios({
      url: `${this.BASE_PATH}/profilers/leaks/search/filters`,
      method: 'get',
      params,
    });
  }

  getProfilerLeakById({
    id,
  }: Paths.GetProfilerLeaks.PathParameters): AxiosPromise<Paths.GetProfilerLeaks.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/leaks/${id}`,
      method: 'get',
    });
  }

  getProfilerCounters(): AxiosPromise<Paths.GetProfilersCounters.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/counters`,
      method: 'get',
    });
  }

  getDatabaseStatus(): AxiosPromise<Paths.DatabaseLoadCheck.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/database/load`,
      method: 'get',
    });
  }

  profilersSearchByLocation(
    data: Paths.SearchProfilersByLocation.Parameters.Body
  ): AxiosPromise<Paths.SearchProfilersByLocation.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/location/search`,
      method: 'post',
      data,
    });
  }

  getSearchLocationFilter(
    params: Paths.ProfilerLocationSearchFilters.QueryParameters
  ): AxiosPromise<Paths.ProfilerLocationSearchFilters.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/location/search/filters`,
      method: 'get',
      params,
    });
  }

  landmarksSearchByLocation(
    data: Paths.SearchLandmarksByLocation.Parameters.Body
  ): AxiosPromise<Paths.SearchLandmarksByLocation.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/landmarks`,
      method: 'post',
      data,
    });
  }

  profilersGetById({ id }: Paths.GetProfilers.PathParameters): AxiosPromise<Paths.GetProfilers.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/search/${id}`,
      method: 'get',
    });
  }

  getDatasets(params: Paths.GetDataset.QueryParameters): AxiosPromise<Paths.GetDataset.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/datasets`,
      method: 'get',
      params,
    });
  }

  getDatasetsCounters(): AxiosPromise<Paths.GetDatasetsCounters.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/datasets/counters`,
      method: 'get',
    });
  }

  getRecursiveTasks(
    params: Paths.GetRecursiveSearchTasks.QueryParameters
  ): AxiosPromise<{ data: Paths.GetRecursiveSearchTasks.Responses.$200 }> {
    return axios({
      url: `${this.BASE_PATH}/recursive-search-tasks`,
      method: 'get',
      params,
    });
  }

  createRecursiveTask(
    data: Paths.CreateRecursiveSearchTasks.Parameters.Body
  ): AxiosPromise<Paths.CreateRecursiveSearchTasks.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/recursive-search-tasks`,
      method: 'post',
      data,
    });
  }

  editRecursiveTask(
    data: Paths.EditRecursiveSearchTask.Parameters.Body
  ): AxiosPromise<Paths.EditRecursiveSearchTask.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/recursive-search-tasks/${data.id}`,
      method: 'put',
      data,
    });
  }

  deleteRecursiveTask({ id }: Paths.DeleteRecursiveSearchTask.PathParameters): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/recursive-search-tasks/${id}`,
      method: 'delete',
    });
  }

  getRecursiveTask(
    { id }: Paths.GetRecursiveSearchTaskResults.PathParameters,
    signal?: AbortSignal
  ): AxiosPromise<Paths.GetRecursiveSearchTaskResults.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/recursive-search-results/${id}`,
      method: 'get',
      signal,
    });
  }

  getCredentialsCounter(
    data: Paths.CredentialsSearchCounter.Parameters.Body
  ): AxiosPromise<Paths.CredentialsSearchCounter.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/credentials/search/counter`,
      method: 'post',
      data,
    });
  }
  profilersAssessments(
    params: Paths.ProfilerAssessments.QueryParameters
  ): AxiosPromise<Paths.ProfilerAssessments.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/profilers/assessments`,
      method: 'get',
      params,
    });
  }
}

export const leakApi = new LeakApi();
