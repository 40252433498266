import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiLoadingContent,
  EuiMarkdownFormat,
  EuiSpacer,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { FC, MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LeakMetaCard } from '../LeakMetaCard';
import { EmptyData } from '../Core';

import { useDownloads } from './useDownloads';

interface LeakFlyoutProps {
  leak: Definitions.Leak;
  hrefAllLeak: string | undefined;
  hrefNotLeak: string | undefined;
  onShowAllLeak: MouseEventHandler<HTMLAnchorElement>;
  onShowNoLeak: MouseEventHandler<HTMLAnchorElement>;
  onClose: () => void;
}

export const LeakProfilerFlyout: FC<LeakFlyoutProps> = ({
  leak,
  hrefAllLeak,
  hrefNotLeak,
  onShowAllLeak,
  onShowNoLeak,
  onClose,
}) => {
  const { t } = useTranslation();

  const { euiTheme } = useEuiTheme();

  const { leak: detailLeak, isLoading } = useDownloads({
    id: leak?.id,
    title: leak.title || '',
  });

  const fields = useMemo(
    () =>
      detailLeak
        ? Object.entries(detailLeak)
            .filter(([key, val]) => !!val && !['id', 'country', 'type', 'trust', 'input_lines_count'].includes(key))
            .map(([key, val]) => (val ? key.replaceAll('_', ' ').replace('count', '') : ''))
        : [],
    [detailLeak]
  );

  return (
    <EuiFlyout
      aria-label={`flyout-${leak.title}`}
      onClose={onClose}
      ownFocus
      maxWidth={700}
      maskProps={{
        headerZindexLocation: 'above',
      }}
    >
      <EuiFlyoutBody>
        <EuiTitle size="xxs">
          <h2
            style={{
              fontWeight: euiTheme.font.weight.regular,
              wordBreak: 'break-all',
              paddingRight: euiTheme.size.m,
            }}
          >
            <b>{t('modals.leak.title')}:</b> {leak.title}
          </h2>
        </EuiTitle>
        <EuiSpacer size="m" />

        {detailLeak && detailLeak.id && (
          <>
            <EuiFlexGroup alignItems="flexStart">
              <EuiFlexItem grow={1}>
                <EuiSpacer size="m" />
                {!!detailLeak?.description && (
                  <>
                    <EuiMarkdownFormat
                      style={{
                        wordBreak: 'break-word',
                      }}
                      color="subdued"
                      textSize="s"
                    >
                      {detailLeak.description}
                    </EuiMarkdownFormat>
                    <EuiSpacer size="m" />
                  </>
                )}

                <EuiFlexGroup gutterSize="s">
                  <EuiButtonEmpty
                    style={{ paddingInline: 0 }}
                    iconType="search"
                    contentProps={{
                      style: {
                        paddingInline: 0,
                      },
                    }}
                    onClick={onShowAllLeak}
                    href={hrefAllLeak}
                  >
                    {t('modals.leak.button.showAll')}
                  </EuiButtonEmpty>
                  <EuiButtonEmpty
                    style={{ paddingInline: 0 }}
                    iconType="eraser"
                    onClick={onShowNoLeak}
                    href={hrefNotLeak}
                  >
                    {t('modals.leak.button.showNot')}
                  </EuiButtonEmpty>
                </EuiFlexGroup>
                <EuiSpacer size="s" />
              </EuiFlexItem>
            </EuiFlexGroup>
            <LeakMetaCard
              data={{
                trust: detailLeak.trust,
                country: detailLeak.country,
                released_at: detailLeak.released_at,
                fields,
              }}
              id={detailLeak.id}
            />
          </>
        )}
        {isLoading && !detailLeak && <EuiLoadingContent lines={10} />}
        {!isLoading && !detailLeak && <EmptyData title={t('emptyData.title')} />}
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};
