import { FunctionComponent, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { EuiCallOut } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { Apps } from '@/hooks';
import { useMap } from '../MapContainer';

import { betaWarningControl } from './BetaWarningControl';

import './index.scss';

export const BetaWarning: FunctionComponent = () => {
  const { t } = useTranslation();
  const { map } = useMap();
  const ctr = useRef(betaWarningControl());

  useEffect(() => {
    const control = ctr.current;
    if (!control.isInit) control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map]);

  const renderChildren = () => {
    if (ctr.current.container) {
      return createPortal(
        <EuiCallOut
          title={t('beta.description', {
            appName: t(`apps.title.${Apps.MAP_RADAR}`),
          })}
          color="warning"
          iconType="alert"
          size="s"
          onDoubleClickCapture={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
        />,
        ctr.current.container
      );
    }

    return null;
  };

  if (ctr.current.button) {
    return <>{renderChildren()}</>;
  }

  return null;
};
