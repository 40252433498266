import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const getData = (state: RootState) => state.credentials.items;
export const getTotalData = (state: RootState) => state.credentials.total_items;
export const getLoading = (state: RootState) => state.credentials.loading;
export const getError = (state: RootState) => state.credentials.error;
export const getExportPennding = (state: RootState) => state.credentials.isExportPennding;

const getLoadingCredentials = (state: RootState) => state.credentials.isLoadingCredentials;
export const getLoadingCredentialsById = (id: string) =>
  createSelector([getLoadingCredentials], (ids) => ids.includes(id));

export const getEditNote = (state: RootState) => state.credentials.editNote;
export const getSearchParams = (state: RootState) => state.credentials.searchParams;
export const getFilters = (state: RootState) => state.credentials.filters;
export const getLeak = (state: RootState) => state.credentials.leak;
