import { FC } from 'react';
import { EuiFlexGroup, EuiSpacer, EuiTablePagination } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { EmptyData } from '../Core';
import { Loader } from '../Loader';
import { DatasetCard } from '../DatasetCard';

import { useData } from './useData';
import { CONFIG_ITEMS_PER_PAGE } from './constants';

interface DatasetDataProps {
  activePage: number;
  limitPagination: number;
  onChangeItemsPerPage: (pageSize: number) => void;
  onChangePage: (pageIndex: number) => void;
}

export const DatasetData: FC<DatasetDataProps> = ({
  activePage,
  limitPagination,
  onChangeItemsPerPage,
  onChangePage,
}) => {
  const { t } = useTranslation();
  const { isLoading, items, totalItems } = useData();

  if (isLoading)
    return (
      <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
        <EuiSpacer size="xl" />
        <Loader />
      </EuiFlexGroup>
    );

  if (!totalItems.value) return <EmptyData title={t('emptyData.title')} />;

  return (
    <>
      <EuiFlexGroup wrap>
        {items.map((item) => (
          <DatasetCard key={item.id} data={item} />
        ))}
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      {!!totalItems.value && !!Math.floor(totalItems.value / limitPagination) && (
        <EuiTablePagination
          aria-label="Search pagination"
          pageCount={Math.ceil(totalItems.value / limitPagination)}
          activePage={activePage}
          showPerPageOptions={totalItems.value > limitPagination}
          onChangePage={onChangePage}
          itemsPerPage={limitPagination}
          onChangeItemsPerPage={onChangeItemsPerPage}
          itemsPerPageOptions={CONFIG_ITEMS_PER_PAGE}
        />
      )}
    </>
  );
};
