import { ColumnItem, Columns } from './types';

export const getRowData = (items: Definitions.TaskData[]): (ColumnItem & { rest: Definitions.TaskData })[] =>
  items.map(({ title, status, created_at, login, total_records, note, ...rest }) => ({
    [Columns.title]: title,
    [Columns.status]: status,
    [Columns.created_at]: created_at,
    [Columns.login]: login,
    [Columns.records]: total_records,
    [Columns.note]: note || '',
    rest,
  }));
