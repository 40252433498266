import { EuiDataGridColumn } from '@elastic/eui';

import { Columns } from './types';

export const columnsConfig: EuiDataGridColumn[] = [
  {
    id: Columns.url,
    displayAsText: 'URL',
    isExpandable: false,
    initialWidth: 350,
  },
  {
    id: Columns.login,
    displayAsText: 'login',
    isExpandable: false,
    initialWidth: 200,
  },
  {
    id: Columns.password,
    isExpandable: false,
    displayAsText: 'password',
    initialWidth: 200,
  },
  {
    id: Columns.http_info,
    displayAsText: 'HTTP info',
    isExpandable: false,
  },
  {
    id: Columns.http_info_scanned_at,
    displayAsText: 'scanned at',
    isExpandable: false,
  },
  {
    id: Columns.http_info_ip,
    displayAsText: 'http host IP',
    isExpandable: false,
  },
  {
    id: Columns.leak,
    displayAsText: 'leak info',
    isExpandable: false,
  },
  {
    id: Columns.note,
    displayAsText: 'note',
    isExpandable: false,
    isSortable: false,
  },
  {
    id: Columns.created_at,
    displayAsText: 'created at',
    isExpandable: false,
  },
  {
    id: Columns.updated_at,
    displayAsText: 'updated at',
    isExpandable: false,
  },
  /*{
    id: Columns.document,
    displayAsText: '_document',
    isSortable: false,
    isExpandable: false,
    actions: {
      showSortAsc: false,
      showSortDesc: false,
    },
  },*/
];

export const CONFIG_ITEMS_PER_PAGE = [25, 50, 100];

export const REGEXP_VALIDATE_URL = new RegExp(
  // eslint-disable-next-line max-len
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
);
