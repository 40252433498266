import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface ConfirmDownloadFileProps {
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
export const ConfirmDownloadFile: FC<ConfirmDownloadFileProps> = ({ isLoading, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t('modals.confirmDownloadFile.title')}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <p
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          <Trans i18nKey="modals.confirmDownloadFile.note" components={[<strong key={0} />]} />
        </p>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty color="text" onClick={onClose}>
          {t('button.cancel')}
        </EuiButtonEmpty>
        <EuiButton onClick={onConfirm} isLoading={isLoading} fill color="danger">
          {t('modals.confirmDownloadFile.confirmButton')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
