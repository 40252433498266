import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { memo, useCallback, useState } from 'react';
import { EuiButton, EuiFieldText, EuiForm, EuiFormRow } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '@/store';
import { customerActions } from '@/store/customer';
import { toastActions } from '@/store/toast';
import { ToastVariantWithText } from '../Toasts';

import { schema } from './schema';

interface EditCurrentAccountFormProps {
  nickname: string;
}

export const EditCurrentAccountForm = memo<EditCurrentAccountFormProps>(function EditCurrentAccountForm({
  nickname = '',
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, setError } = useForm<Paths.CustomerUpdate.Parameters.Body>({
    defaultValues: {
      nickname,
    },
    resolver: yupResolver(schema),
  });

  const handleRequest = useCallback(
    async (data: Paths.CustomerUpdate.Parameters.Body) => {
      try {
        setIsLoading(true);
        await dispatch(customerActions.updateCurrentAccount(data)).unwrap();
        dispatch(
          toastActions.create({
            type: ToastVariantWithText.SUCCESS_ACTION,
            text: 'Success! Yuor Nickname has been updated',
          })
        );
      } catch (e: any) {
        const errorResponse = e as Definitions.Error;
        if (errorResponse?.errors) {
          errorResponse?.errors.forEach((error) => {
            setError(error.location as keyof Paths.CustomerUpdate.Parameters.Body, error);
          });
        } else if (errorResponse?.message) {
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.ERROR_ACTION,
              text: errorResponse?.message,
            })
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, setError]
  );
  return (
    <EuiForm component="form" onSubmit={handleSubmit(handleRequest)}>
      <Controller
        control={control}
        name="nickname"
        render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
          <EuiFormRow isInvalid={!!error} error={t(error?.message as string)}>
            <EuiFieldText
              compressed
              placeholder={t(`form.placeholder.${name}`)}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={!!error}
              fullWidth
            />
          </EuiFormRow>
        )}
      />
      <EuiButton size="s" type="submit" isLoading={isLoading}>
        {t('button.saveNickname')}
      </EuiButton>
    </EuiForm>
  );
});
