import { FC, FormEventHandler } from 'react';
import { Control, Controller } from 'react-hook-form';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiToolTip } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '../AutoComplete';

export type SearchBarControl = Control<{
  query: string;
}>;

interface SearchBarProps {
  filters: Definitions.Filter[];
  placeholder: string;
  control: SearchBarControl;
  isLoading: boolean;
  valueQuery: string;
  disabled?: boolean;
  disabledTooltip?: string;
  compressed?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  setComponentHeight?: (value: number) => void;
}

export const SearchBar: FC<SearchBarProps> = ({
  filters,
  valueQuery,
  placeholder,
  control,
  isLoading,
  disabled,
  disabledTooltip,
  compressed,
  onSubmit,
  setComponentHeight,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <EuiToolTip display="block" position="bottom" content={disabled && !!disabledTooltip ? disabledTooltip : null}>
      <form
        onSubmit={onSubmit}
        noValidate
        onDoubleClickCapture={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <EuiFlexGroup>
          <EuiFlexItem>
            <Controller
              control={control}
              name="query"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <EuiFormRow
                  fullWidth={true}
                  isInvalid={!!error}
                  error={
                    error && error.message ? (i18n.exists(error.message) ? t(error.message) : error.message) : null
                  }
                >
                  <AutoComplete
                    filters={filters}
                    value={value}
                    disabled={isLoading || disabled}
                    isInvalid={!!error}
                    placeholder={t(placeholder)}
                    fullWidth
                    onChange={onChange}
                    valueQuery={valueQuery}
                    compressed={compressed}
                    setComponentHeight={setComponentHeight}
                  />
                </EuiFormRow>
              )}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton size={compressed ? 's' : 'm'} fill disabled={isLoading || disabled} type="submit">
              {t('button.search')}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </form>
    </EuiToolTip>
  );
};
