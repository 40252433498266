import { ColumnItem, Columns } from './types';

export const getRowData = (
  items: Definitions.Profile[]
): (ColumnItem & {
  rest: Omit<
    Definitions.Profile,
    'role_id' | 'created_at' | 'group_title' | 'nickname' | 'login' | 'status' | 'subscription_expires_at' | 'last_seen'
  >;
})[] =>
  items.map(
    ({
      nickname,
      created_at,
      role_id,
      group_title,
      login,
      status,
      subscription_expires_at,
      last_seen,
      products_permission,
      enabled_two_fa,
      ...rest
    }) => ({
      [Columns.nickname]: nickname,
      [Columns.role]: role_id,
      [Columns.group]: group_title,
      [Columns.products_permissions]: products_permission,
      [Columns.enabled_two_fa]: enabled_two_fa,
      [Columns.login]: login,
      [Columns.exp_date]: subscription_expires_at,
      [Columns.last_activities]: last_seen,
      [Columns.status]: status,
      [Columns.created_at]: created_at,
      rest,
    })
  );
