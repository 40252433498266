export enum Columns {
  role = 'role',
  nickname = 'nickname',
  group = 'group',
  login = 'login',
  created_at = 'created_at',
  exp_date = 'exp_date',
  last_activities = 'last_activities',
  status = 'status',
  products_permissions = 'products_permissions',
  enabled_two_fa = 'enabled_two_fa',
}

export type ColumnItem = {
  [key in Columns]: string | number | undefined | Definitions.ProductsPermission | null | boolean;
};
