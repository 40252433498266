import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiPanel, EuiSpacer, EuiText, useEuiTheme } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';
import { useHref, useLinkClickHandler } from 'react-router-dom';

import { useAppSelector } from '@/store';
import { profilerSelectors } from '@/store/profiler';
import { Routes } from '@/routes';

const getCountedText = (value: number, variants: [string, string, string]) => {
  if ((value >= 10 && value < 20) || value % 10 >= 5) return variants[2];
  if (value % 10 === 1) return variants[0];
  return variants[1];
};

export const ProfilerSearchDataHeader = () => {
  const { t } = useTranslation();
  const { euiTheme, colorMode } = useEuiTheme();
  const totalItems = useAppSelector(profilerSelectors.getTotalData);
  const counters = useAppSelector(profilerSelectors.getCounters);

  const url = useHref({
    pathname: Routes.LEAK_REGISTRY,
  });

  const handleClick = useLinkClickHandler(url);

  const bgColor = colorMode === 'DARK' ? '#141519' : '#E5EEE7';
  return (
    <>
      <EuiPanel
        style={{
          paddingBlock: 0,
        }}
        hasBorder
      >
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          {!!totalItems.value && (
            <EuiText
              color="subdued"
              className={css(`
                 padding-block:  ${euiTheme.size.xs};
            `)}
            >
              <b>
                {totalItems.relation === 'gte'
                  ? '> 10 000'
                  : totalItems.value
                  ? new Intl.NumberFormat('uk-UA').format(totalItems.value)
                  : null}
              </b>
              &nbsp;
              {totalItems.relation === 'gte'
                ? t('page.profiler.results.5')
                : getCountedText(totalItems.value, [
                    t('page.profiler.results.1'),
                    t('page.profiler.results.2'),
                    t('page.profiler.results.5'),
                  ])}
              &nbsp;
              {t('page.profiler.found')}
            </EuiText>
          )}
          {!!counters && (
            <div
              className={css(`
          display: flex;`)}
            >
              <div
                className={css(
                  `
                    width:  ${euiTheme.size.xl};
                    background: linear-gradient(255deg, 
                        ${bgColor} 0%,
                        ${bgColor} 35%,
                        rgba(0,0,0,0) 65%,
                        rgba(0,0,0,0) 100%);
                `
                )}
              />
              <div
                className={css(`
                display: flex;
                white-space: nowrap;
                background-color: ${bgColor};
                margin-right: -${euiTheme.size.base};
                padding-inline: ${euiTheme.size.base};
                border-radius: 0 ${euiTheme.border.radius.medium}  ${euiTheme.border.radius.medium} 0;
          `)}
              >
                <EuiFlexItem
                  className={css(`
                padding-block:  ${euiTheme.size.xs};
                `)}
                >
                  <EuiText size="s" color="subdued">
                    {t('page.profiler.counter.total_documents')}
                  </EuiText>
                  <EuiText color="#1EAC2E">
                    {!!counters.total_documents && new Intl.NumberFormat('uk-UA').format(counters.total_documents)}
                  </EuiText>
                </EuiFlexItem>
                <div
                  className={css(`
                    margin-inline: ${euiTheme.size.s};
                    width: 1px;
                    background-color: ${euiTheme.border.color};
                `)}
                />
                <EuiFlexItem
                  className={css(`
                padding-block:  ${euiTheme.size.xs};
                `)}
                >
                  <EuiText size="s" color="subdued">
                    {t('page.profiler.counter.total_leaks')}
                  </EuiText>
                  <EuiText color="#1EAC2E">
                    <EuiLink
                      style={{
                        color: 'inherit',
                      }}
                      href={url.toString()}
                      onClick={handleClick}
                    >
                      {!!counters.total_leaks && new Intl.NumberFormat('uk-UA').format(counters.total_leaks)}
                    </EuiLink>
                  </EuiText>
                </EuiFlexItem>
              </div>
            </div>
          )}
        </EuiFlexGroup>
      </EuiPanel>
      <EuiSpacer size="m" />
    </>
  );
};
