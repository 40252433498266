import { EuiButtonIcon, EuiFlexGroup, EuiFocusTrap, EuiOverlayMask, EuiSpacer, EuiTitle } from '@elastic/eui';
import { css } from '@emotion/css';
import { FC, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { useWindowSize } from '@/hooks';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

type PdfPreviewProps = {
  name: string;
  file: string;
  onClose: () => void;
};

export const PdfPreview: FC<PdfPreviewProps> = ({ file, name, onClose }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { height } = useWindowSize();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <EuiOverlayMask
      className={css(`
          padding-block-end: 0;
        `)}
    >
      <EuiFocusTrap onClickOutside={onClose}>
        <div>
          <EuiTitle size="xxs">
            <h6>{name}</h6>
          </EuiTitle>
          <EuiSpacer size="s" />
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onItemClick={({ pageNumber }) => {
              setPageNumber(pageNumber);
            }}
          >
            <Page height={height - 100} pageNumber={pageNumber} />
          </Document>
          <EuiSpacer size="m" />
          <EuiFlexGroup alignItems="center" justifyContent="center" gutterSize="m">
            <EuiButtonIcon
              disabled={!numPages || pageNumber <= 1}
              onClick={() => setPageNumber(pageNumber - 1)}
              iconType="arrowLeft"
              color="text"
            />
            {pageNumber} / {numPages}
            <EuiButtonIcon
              disabled={!numPages || pageNumber >= numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
              iconType="arrowRight"
              color="text"
            />
          </EuiFlexGroup>
        </div>
        <EuiFlexGroup
          gutterSize="s"
          direction="row"
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          <EuiButtonIcon display="base" color="ghost" iconType="cross" onClick={onClose} />
        </EuiFlexGroup>
      </EuiFocusTrap>
    </EuiOverlayMask>
  );
};
