import { createAction, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { leakApi } from '@/api';
import { RootState } from '../store';
import { appActions } from '../app';
import { GeoItem } from '@/api/geo/types';

export interface State {
  searchParams: Definitions.LocationSearchRequest | null;
  profiler: Map<string, Definitions.AddressLocation[]>;
  landmarks: Map<string, Definitions.Landmarks>;
  leaks: Map<string, Definitions.LeakLocation>;
  error: Definitions.Error | null;
  filters: Definitions.Filter[];
  cards: Record<string, Definitions.Identity>;
  cardsOfLeak: Record<string, Definitions.LeakData>;
  leakCounters: Record<string, number>;
  isLoadedProfiler: boolean;
  isLoadedLandmarks: boolean;
  isLoadedLeaks: boolean;
  savedGeoQuery: string;
  geoItems: GeoItem[];
}

const clearSearch = createAction('map/clearSearch');
const clearSearchParams = createAction('map/clearSearchParams');
const clearProfiler = createAction('map/clearProfiler');
const clearLandmarks = createAction('map/clearLandmarks');
const clearLeaks = createAction('map/clearLeaks');
const setGeoItems = createAction<GeoItem[]>('map/setGeoItems');
const setGeoQuery = createAction<string>('map/setGeoQuery');

export const fetchProfiler = createAsyncThunk(
  'map/fetchProfiler',
  async (payload: Definitions.LocationSearchRequest, thunkApi) => {
    thunkApi.dispatch(appActions.getDatabaseLoad());
    const state = thunkApi.getState() as RootState;

    if (
      !!state.map.searchParams &&
      !Object.entries(payload).some(
        ([key, value]) =>
          state.map.searchParams && value !== state.map.searchParams[key as keyof Definitions.LocationSearchRequest]
      )
    ) {
      return;
    }
    try {
      const {
        data: { data },
      } = await leakApi.profilersSearchByLocation(payload);
      if (data) {
        const { items } = data;
        if (items) {
          const points = new Map<string, Definitions.AddressLocation[]>();
          items.forEach(({ id, addresses }) => {
            points.set(id as string, addresses as Definitions.AddressLocation[]);
          });

          return { ...data, items: points };
        }
      }
      return;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchLandmarks = createAsyncThunk(
  'map/fetchLandmarks',
  async (payload: Paths.SearchLandmarksByLocation.Parameters.Body, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.landmarksSearchByLocation(payload);
      if (data) {
        const { items } = data;
        if (items) {
          const points = new Map<string, Definitions.Landmarks>();
          items.forEach(({ id, ...landmark }) => {
            points.set(id as string, landmark);
          });

          return { ...data, items: points };
        }
      }
      return;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchLeaks = createAsyncThunk(
  'map/fetchLeaks',
  async (payload: Paths.SearchLeaksByLocation.Parameters.Body, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.leakLocationSearch(payload);
      if (data) {
        const { items } = data;
        if (items) {
          const points = new Map<string, Definitions.Landmarks>();
          items.forEach(({ id, ...landmark }) => {
            points.set(id as string, landmark);
          });

          return { ...data, items: points };
        }
      }
      return;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchProfilerCardById = createAsyncThunk(
  'map/fetchProfilerCardById',
  async (payload: Paths.GetProfilers.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.profilersGetById(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchLeakCardById = createAsyncThunk(
  'map/fetchLeakCardById',
  async (payload: Paths.GetLeaks.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.getLeakById(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchLeakCredentialsCounter = createAsyncThunk(
  'map/fetchLeakCredentialsCounter',
  async (payload: Paths.CredentialsSearchCounter.Parameters.Body & { id: string }, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.getCredentialsCounter({
        query: payload.query,
      });
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchFilters = createAsyncThunk(
  'map/fetchFilters',
  async (payload: Paths.ProfilerLocationSearchFilters.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.getSearchLocationFilter(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'map',
  initialState: {
    error: null,
    profiler: new Map(),
    landmarks: new Map(),
    leaks: new Map(),
    searchParams: null,
    cards: {},
    cardsOfLeak: {},
    leakCounters: {},
    filters: [],
    isLoadedProfiler: false,
    isLoadedLandmarks: false,
    isLoadedLeaks: false,
    geoItems: [],
    savedGeoQuery: '',
  },
  reducers: {
    clearSearch: (state) => {
      state.profiler = new Map();
      state.landmarks = new Map();
      state.leaks = new Map();
      state.error = null;
      state.searchParams = null;
      state.cards = {};
      state.cardsOfLeak = {};
      state.leakCounters = {};
      state.isLoadedProfiler = false;
      state.isLoadedLandmarks = false;
      state.isLoadedLeaks = false;
    },
    clearProfiler: (state) => {
      state.profiler = new Map();
      state.searchParams = null;
      state.isLoadedProfiler = true;
    },
    clearLandmarks: (state) => {
      state.landmarks = new Map();
      state.isLoadedLandmarks = true;
    },
    clearLeaks: (state) => {
      state.leaks = new Map();
      state.isLoadedLeaks = true;
    },
    clearSearchParams: (state) => {
      state.searchParams = null;
    },
    setGeoItems: (state, action: PayloadAction<GeoItem[]>) => {
      state.geoItems = action.payload;
    },
    setGeoQuery: (state, action: PayloadAction<string>) => {
      state.savedGeoQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfiler.pending, (state) => {
        state.isLoadedProfiler = false;
      })
      .addCase(fetchProfiler.fulfilled, (state, action) => {
        state.searchParams = action.meta.arg;

        if (action.payload) {
          const { items } = action.payload;
          state.profiler = items;
          state.isLoadedProfiler = true;
        }
        state.error = null;
      })
      .addCase(fetchProfiler.rejected, (state, action) => {
        state.searchParams = action.meta.arg;
        const { error } = action.payload as Paths.SearchCredentials.Responses.$400;
        state.profiler = new Map();
        state.error = error || {
          message: 'unknownError',
        };
        state.isLoadedProfiler = true;
      });

    builder
      .addCase(fetchLandmarks.pending, (state) => {
        state.isLoadedLandmarks = false;
      })
      .addCase(fetchLandmarks.fulfilled, (state, action) => {
        if (action.payload) {
          const { items } = action.payload;
          state.landmarks = items;
          state.isLoadedLandmarks = true;
        }
      })
      .addCase(fetchLandmarks.rejected, (state) => {
        state.landmarks = new Map();
        state.isLoadedLandmarks = true;
      });

    builder
      .addCase(fetchLeaks.pending, (state) => {
        state.isLoadedLeaks = false;
      })
      .addCase(fetchLeaks.fulfilled, (state, action) => {
        if (action.payload) {
          const { items } = action.payload;
          state.leaks = items;
          state.isLoadedLeaks = true;
        }
      })
      .addCase(fetchLeaks.rejected, (state) => {
        state.landmarks = new Map();
        state.isLoadedLeaks = true;
      });

    builder.addCase(fetchProfilerCardById.fulfilled, (state, action) => {
      if (action.payload) state.cards = { ...state.cards, [action.payload.id]: action.payload };
    });

    builder.addCase(fetchLeakCardById.fulfilled, (state, action) => {
      if (action.payload && action.payload.id)
        state.cardsOfLeak = { ...state.cardsOfLeak, [action.payload.id]: action.payload };
    });

    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      if (action.payload) {
        state.filters = action.payload.items || [];
      }
    });

    builder.addCase(fetchLeakCredentialsCounter.fulfilled, (state, action) => {
      if (action.payload) {
        state.leakCounters = { ...state.leakCounters, [action.meta.arg.id]: action.payload.total_items || 0 };
      }
    });
  },
});

export const actions = {
  clearSearch,
  fetchProfiler,
  fetchLeaks,
  fetchProfilerCardById,
  fetchLeakCardById,
  clearSearchParams,
  fetchFilters,
  fetchLandmarks,
  clearProfiler,
  clearLandmarks,
  clearLeaks,
  fetchLeakCredentialsCounter,
  setGeoItems,
  setGeoQuery,
};

export default slice.reducer;
