import L, { Map, MapOptions } from 'leaflet';
import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import 'leaflet/dist/leaflet.css';

import { LayerTheme } from './types';

type MapContext = {
  map: Map;
  layerTheme: LayerTheme;
  setLayerTheme: (theme: LayerTheme) => void;
};

interface MapContainerProps extends MapOptions {
  children: ReactNode;
  height: number | string;
  width: number | string;
  onCreate?: (map: Map) => void;
  defaultLayerTheme: LayerTheme;
}

const MapContext = createContext<MapContext>({} as MapContext);

export const useMap = () => {
  const context = useContext(MapContext);
  return context;
};

export const MapContainer: FunctionComponent<MapContainerProps> = ({
  height,
  width,
  children,
  onCreate,
  defaultLayerTheme,
  ...options
}) => {
  const container = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<Map | null>(null);
  const [layerTheme, setLayerTheme] = useState(defaultLayerTheme);
  const handleSetLayerTheme = useCallback((theme: LayerTheme) => setLayerTheme(theme), []);

  useEffect(() => {
    let map: Map;
    if (container.current) {
      map = L.map(container.current, options);
      if (onCreate) {
        map.whenReady(((e: { target: Map }) => onCreate(e.target)) as () => void);
      }
      setMap(map);
    }

    return () => {
      if (map) map.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={container}
      style={{
        height,
        width,
      }}
    >
      {map && (
        <MapContext.Provider value={{ map, layerTheme, setLayerTheme: handleSetLayerTheme }}>
          {children}
        </MapContext.Provider>
      )}
    </div>
  );
};
