import { EuiDataGridColumn } from '@elastic/eui';

import { Columns } from './types';

const columnsRest = [
  'addresses_count',
  'birthday_count',
  'citizenships_count',
  'inn_count',
  'insurance_count',
  'military_count',
  'names_count',
  'notes_count',
  'organization_count',
  'other_documents_count',
  'passports_count',
  'passwords_count',
  'payments_count',
  'phones_count',
  'positions_count',
  'sessions_count',
  'snils_count',
  'socials_count',
  'usernames_count',
  'vehicles_count',
  'websites_count',
];

export const columnsConfig: EuiDataGridColumn[] = [
  {
    id: Columns.trust,
    displayAsText: 'trust',
    isExpandable: false,
    isSortable: false,
    initialWidth: 80,
  },
  {
    id: Columns.title,
    displayAsText: 'title',
    isExpandable: false,
    initialWidth: 300,
  },
  {
    id: Columns.released_at,
    isExpandable: false,
    displayAsText: 'released at',
    initialWidth: 150,
  },
  {
    id: Columns.records,
    displayAsText: 'records',
    isExpandable: false,
    initialWidth: 100,
  },
  {
    id: Columns.fields,
    displayAsText: 'fields',
    isExpandable: false,
    isSortable: false,
    initialWidth: 250,
  },
  {
    id: Columns.description,
    displayAsText: 'description',
    isExpandable: false,
    isSortable: false,
  },
  {
    id: Columns.input_lines_count,
    displayAsText: 'raw records',
    isExpandable: false,
    initialWidth: 100,
  },
  ...columnsRest.map((col) => ({
    id: Columns[col as Columns],
    displayAsText: col.replaceAll('_', ' '),
    isExpandable: false,
    isSortable: false,
  })),
];

export const CONFIG_ITEMS_PER_PAGE = [25, 50, 100];
