import { useRef, useEffect, memo } from 'react';
import L from 'leaflet';

import Location from '@/assets/icons/markers/Location.svg';
import Location_light from '@/assets/icons/markers/Location_light.svg';

import { useMap } from './MapContainer';
import { LayerTheme, SelectedPoint } from './types';
import { getIcon } from './MarkerCluster';

const getSelectedIcon = (layerTheme: LayerTheme) =>
  L.icon({
    iconUrl: layerTheme === 'dark' ? Location : Location_light,
    iconSize: [32, 32],
  });

const getClusterIcon = (count: number) => {
  let size = 'large';
  if (count < 10) {
    size = 'small';
  } else if (count < 100) {
    size = 'medium';
  }
  return L.divIcon({
    html: `<div><span>${count}</span></div>`,
    className: `leaflet-marker-icon marker-cluster marker-cluster-${size} leaflet-zoom-animated leaflet-interactive`,
    iconSize: [40, 40],
  });
};

const getPrevIcon = (type: string, layerTheme: LayerTheme, count?: number | null) =>
  count ? getClusterIcon(count) : getIcon(type, layerTheme);

interface SelectPointProps {
  selectedPoints: SelectedPoint[] | null;
}

export const SelectPoint = memo<SelectPointProps>(
  function SelectPoint({ selectedPoints }) {
    const { map, layerTheme } = useMap();
    const point = useRef<{ id: number; type: string; count?: number } | null>(null);

    useEffect(() => {
      if (point.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const marker = map._layers[point.current.id];
        if (marker) marker.setIcon(getPrevIcon(point.current.type, layerTheme, point.current.count));
        point.current = null;
      }
      if (selectedPoints) {
        const markerId = selectedPoints[0].leafletId as number;
        point.current = { id: markerId, type: selectedPoints[0].type, count: selectedPoints[0].clusterCount };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const marker = map._layers[markerId];
        if (marker) {
          marker.setIcon(getSelectedIcon(layerTheme));
        }
      }
    }, [layerTheme, map, selectedPoints]);

    return null;
  },
  (prevProps, nextProps) => prevProps.selectedPoints === nextProps.selectedPoints
);
