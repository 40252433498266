import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultCountries, parseCountry, buildCountryData } from 'react-international-phone';

import { Apps, useSelectedApp, useDatabaseLoad } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { profiler2Actions, profiler2Selectors } from '@/store/profiler2';
import { useCountry } from '@/components/LeakCountryBadge';

import { SearchFields } from './types';

const SEARCH_FILTERS: (keyof SearchFields)[] = [
  'phone',
  'email',
  'name',
  'birth_date',
  'address',
  'inn',
  'snils',
  'car_number',
  'ip',
];

export const PROFILER2_ANIMATION_KEY = 'dont_show_profiler2_animation';
export const useData = () => {
  const { selectApp } = useSelectedApp();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { getCountry } = useCountry();

  const [searchParams, setSearchParams] = useSearchParams();

  const countries = useMemo(
    () =>
      defaultCountries.map((country) => {
        const parsedCountry = parseCountry(country);
        //when guessing the country, prefer choosing russia over Kazakhstan
        if (parsedCountry.iso2 === 'kz') parsedCountry.priority = 1;
        if (parsedCountry.iso2 === 'ru') parsedCountry.priority = 0;
        parsedCountry.name = getCountry(parsedCountry.iso2.toUpperCase());
        return buildCountryData(parsedCountry);
      }),
    [getCountry]
  );

  const defaultValues = useMemo(() => {
    return {
      email: searchParams.get('email') || '',
      phone: searchParams.get('phone') || '',
      name: searchParams.get('name') || '',
      birth_date: searchParams.get('birth_date') || '',
      inn: searchParams.get('inn') || '',
      snils: searchParams.get('snils') || '',
      car_number: searchParams.get('car_number') || '',
      address: searchParams.get('address') || '',
      ip: searchParams.get('ip') || '',
    };
  }, [searchParams]);

  const [filters, setFilters] = useState(defaultValues);
  const filterOptions = useMemo(
    () =>
      SEARCH_FILTERS.filter((name) => !filters[name]).map((value) => ({
        value,
        inputDisplay: t(`addFilterForm.filters.${value}`),
      })),
    [t, filters]
  );

  const showSearchResults = useMemo(
    () => Object.keys(defaultValues).some((key) => searchParams.has(key)),
    [defaultValues, searchParams]
  );

  const data = useAppSelector(profiler2Selectors.getData);
  const enriched = useAppSelector(profiler2Selectors.getEnriched);
  const isLoading = useAppSelector(profiler2Selectors.getLoading);
  const serverError = useAppSelector(profiler2Selectors.getError);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleRequest = useCallback(
    (data: SearchFields) => {
      dispatch(profiler2Actions.fetchData(data));
    },
    [dispatch]
  );

  const handleSetSearchParams = useCallback(
    (data: SearchFields) => {
      const params = new URLSearchParams(Object.entries(data).filter(([, value]) => !!value));

      setSearchParams(params);
    },
    [setSearchParams]
  );

  const handleSearch = useCallback(() => {
    if (errorMessage) setErrorMessage(null);
    handleSetSearchParams(filters);
  }, [errorMessage, filters, handleSetSearchParams]);

  const handleSetFilter = useCallback(
    (data) => {
      if (errorMessage) setErrorMessage(null);
      setFilters((prev) => ({ ...prev, ...data }));
    },
    [errorMessage]
  );

  const handleDeleteFilter = useCallback((field) => {
    setFilters((prev) => ({ ...prev, [field]: '' }));
  }, []);

  useDatabaseLoad();

  useEffect(() => {
    let message = '';
    if (serverError) {
      if (serverError.errors) {
        message = serverError.errors
          .map(({ code, location, message }) => {
            if (i18n.exists(`addFilterForm.error.${location}.${code}`)) {
              return t(`addFilterForm.error.${location}.${code}`);
            }
            return message;
          })
          .join('; ');
      } else if (serverError.message) {
        message = serverError.message;
      }
    }
    if (message) {
      setErrorMessage(message);
    }
  }, [dispatch, i18n, serverError, t]);

  useEffect(() => {
    selectApp(Apps.PROFILER_2);
  }, [selectApp]);

  useEffect(() => {
    localStorage.setItem(PROFILER2_ANIMATION_KEY, 'true');
  }, []);

  useEffect(() => {
    dispatch(profiler2Actions.fetchCounters());
    return () => {
      dispatch(profiler2Actions.clearSearch);
    };
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(defaultValues).some((key) => searchParams.has(key))) {
      handleRequest(defaultValues);
      setFilters(defaultValues);
    } else {
      dispatch(profiler2Actions.clearSearch());
      setFilters(defaultValues);
    }
  }, [defaultValues, dispatch, handleRequest, searchParams]);

  return {
    handleSearch,
    showSearchResults,
    data,
    isLoading,
    filterOptions,
    handleSetFilter,
    filters,
    handleDeleteFilter,
    countries,
    enriched,
    errorMessage,
    nameQuery: defaultValues.name,
  };
};
