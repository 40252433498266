import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useLinkClickHandler } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store';
import { credentialsActions, credentialsSelectors } from '@/store/credentials';
import { Routes } from '@/routes';

import { SearchFields } from './types';

interface useLeakParams {
  handleSubmit: UseFormHandleSubmit<SearchFields>;
}

export const useLeak = ({ handleSubmit }: useLeakParams) => {
  const dispatch = useAppDispatch();
  const leak = useAppSelector(credentialsSelectors.getLeak);
  const [params, setParams] = useState<SearchFields | null>(null);

  const hrefLeak = useMemo(() => {
    if (params) {
      const urlParams = new URLSearchParams(Object.entries(params).filter((item) => item[1]));
      return `${Routes.PASSWORD_FINDER}?${urlParams.toString()}`;
    }
  }, [params]);

  const handleClose = useCallback(() => {
    dispatch(credentialsActions.showLeak(null));
  }, [dispatch]);

  const handleOpen = useCallback(
    (leak: Definitions.Leak) => {
      dispatch(credentialsActions.showLeak(leak));
    },
    [dispatch]
  );

  const handleShowAllData = useLinkClickHandler(hrefLeak as string);

  useEffect(() => {
    if (leak && leak.title) {
      handleSubmit((data: SearchFields) => {
        data.offset = 0;
        data.query = `leaks.title.eq: "${leak?.title?.replace(/"/gi, '"')}"`;
        setParams(data);
      })();
    }
  }, [handleSubmit, leak]);

  return { leak, hrefLeak, handleOpen, handleClose, handleShowAllData };
};
