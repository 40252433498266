import { object, string, boolean, SchemaOf } from 'yup';

import { FormFields } from './types';

export const schema: SchemaOf<FormFields> = object().shape({
  note_text: string().max(500, 'notesForm.error.max_note_text'),
  is_valid: boolean().notRequired(),
  work_in_progress: boolean().notRequired(),
  id: string().required(),
});
