import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText, useEuiTheme } from '@elastic/eui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ProfilerIcon } from '@/assets/icons/Subtract.svg';
import { ReactComponent as PasswordFinderIcon } from '@/assets/icons/Union.svg';
import { ReactComponent as PhonebookIcon } from '@/assets/icons/Phone.svg';
import { ReactComponent as DatasetsIcon } from '@/assets/icons/Pie.svg';
import { ReactComponent as RecursiveSearchIcon } from '@/assets/icons/Research.svg';
import { Apps } from '@/hooks';
import { ThemeExtensions } from '../ThemeProvider';

interface ProductsPermissionInfoProps {
  permissions?: Definitions.ProductsPermission;
  label?: boolean;
}

export const ProductsPermissionInfo: FC<ProductsPermissionInfoProps> = ({ permissions, label }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme<ThemeExtensions>();

  const isProfiler = permissions?.profiler;
  const isPasswordFinder = permissions?.password_finder;
  const isPhonebook = permissions?.phonebook;
  const isDatasets = !!permissions?.password_finder || !!permissions?.profiler;
  const isRecursiveSearch = permissions?.recursive_search;
  const isMapRadar = permissions?.map_radar;

  return (
    <EuiFlexGroup direction="column" gutterSize="s">
      <EuiFlexGroup gutterSize="xl" alignItems="center" justifyContent="flexStart">
        <EuiFlexItem grow={0}>
          <EuiFlexGroup
            style={{
              minWidth: 145,
            }}
            gutterSize="s"
            alignItems="center"
          >
            <EuiIcon
              color={isPasswordFinder ? euiTheme.colors.tools.PASSWORD_FINDER : euiTheme.colors.disabledText}
              type={PasswordFinderIcon}
            />
            <EuiText size="s" color={isPasswordFinder ? 'default' : euiTheme.colors.disabledText}>
              {t(`apps.title.${Apps.PASSWORD_FINDER}`)}
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
        {label && (
          <EuiBadge isDisabled={!isPasswordFinder} color={isPasswordFinder ? 'success' : 'default'}>
            {t(`badges.${isPasswordFinder ? 'available' : 'not_available'}`)}
          </EuiBadge>
        )}
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="l" alignItems="center" justifyContent="flexStart">
        <EuiFlexItem grow={0}>
          <EuiFlexGroup
            style={{
              minWidth: 145,
            }}
            gutterSize="s"
            alignItems="center"
          >
            <EuiIcon
              color={isProfiler ? euiTheme.colors.tools.PROFILER : euiTheme.colors.disabledText}
              type={ProfilerIcon}
            />
            <EuiText size="s" color={isProfiler ? 'default' : euiTheme.colors.disabledText}>
              {t(`apps.title.${Apps.PROFILER}`)}
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
        {label && (
          <EuiBadge isDisabled={!isProfiler} color={isProfiler ? 'success' : 'default'}>
            {t(`badges.${isProfiler ? 'available' : 'not_available'}`)}
          </EuiBadge>
        )}
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="l" alignItems="center" justifyContent="flexStart">
        <EuiFlexItem grow={0}>
          <EuiFlexGroup
            style={{
              minWidth: 145,
            }}
            gutterSize="s"
            alignItems="center"
          >
            <EuiIcon
              color={isPhonebook ? euiTheme.colors.tools.PHONEBOOK_PARSER : euiTheme.colors.disabledText}
              type={PhonebookIcon}
            />
            <EuiText size="s" color={isPhonebook ? 'default' : euiTheme.colors.disabledText}>
              {t(`apps.title.${Apps.PHONEBOOK_PARSER}`)}
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
        {label && (
          <EuiBadge isDisabled={!isPhonebook} color={isPhonebook ? 'success' : 'default'}>
            {t(`badges.${isPhonebook ? 'available' : 'not_available'}`)}
          </EuiBadge>
        )}
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="l" alignItems="center" justifyContent="flexStart">
        <EuiFlexItem grow={0}>
          <EuiFlexGroup
            style={{
              minWidth: 145,
            }}
            gutterSize="s"
            alignItems="center"
          >
            <EuiIcon
              color={isDatasets ? euiTheme.colors.tools.DATASETS : euiTheme.colors.disabledText}
              type={DatasetsIcon}
            />
            <EuiText size="s" color={isDatasets ? 'default' : euiTheme.colors.disabledText}>
              {t(`apps.title.${Apps.DATASETS}`)}
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
        {label && (
          <EuiBadge isDisabled={!isDatasets} color={isDatasets ? 'success' : 'default'}>
            {t(`badges.${isDatasets ? 'available' : 'not_available'}`)}
          </EuiBadge>
        )}
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="l" alignItems="center" justifyContent="flexStart">
        <EuiFlexItem grow={0}>
          <EuiFlexGroup
            style={{
              minWidth: 145,
            }}
            gutterSize="s"
            alignItems="center"
          >
            <EuiIcon
              color={isRecursiveSearch ? euiTheme.colors.tools.RECURSIVE_SEARCH : euiTheme.colors.disabledText}
              type={RecursiveSearchIcon}
            />
            <EuiText size="s" color={isRecursiveSearch ? 'default' : euiTheme.colors.disabledText}>
              {t(`apps.title.${Apps.RECURSIVE_SEARCH}`)}
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
        {label && (
          <EuiBadge isDisabled={!isRecursiveSearch} color={isRecursiveSearch ? 'success' : 'default'}>
            {t(`badges.${isRecursiveSearch ? 'available' : 'not_available'}`)}
          </EuiBadge>
        )}
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="l" alignItems="center" justifyContent="flexStart">
        <EuiFlexItem grow={0}>
          <EuiFlexGroup
            style={{
              minWidth: 145,
            }}
            gutterSize="s"
            alignItems="center"
          >
            <EuiIcon
              color={isMapRadar ? euiTheme.colors.tools.MAP_RADAR : euiTheme.colors.disabledText}
              type={'visMapRegion'}
            />
            <EuiText size="s" color={isMapRadar ? 'default' : euiTheme.colors.disabledText}>
              {t(`apps.title.${Apps.MAP_RADAR}`)}
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
        {label && (
          <EuiBadge isDisabled={!isMapRadar} color={isMapRadar ? 'success' : 'default'}>
            {t(`badges.${isMapRadar ? 'available' : 'not_available'}`)}
          </EuiBadge>
        )}
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};
