import { captureException } from '@sentry/react';
import { FC, useEffect, useState } from 'react';

import { DENSITY_STAGE_KEY, RAW_HEIGHT_STAGE_KEY } from '../SearchData';
import { VISIBLE_COLUMNS_STORAGE_KEY } from '../SearchData/useSearchData';

const STORAGE_VERSION_CHECKER_KEY = 'CLEAR_STORAGE_VERSION';
const version = localStorage.getItem(STORAGE_VERSION_CHECKER_KEY);

export const StorageCleaner: FC = ({ children }) => {
  const [isCleared, setCleared] = useState(!!version && process.env.REACT_APP_CLEAR_STORAGE_VERSION === version);

  useEffect(() => {
    if (!isCleared) {
      try {
        localStorage.setItem(STORAGE_VERSION_CHECKER_KEY, process.env.REACT_APP_CLEAR_STORAGE_VERSION as string);
        Object.keys(localStorage)
          .filter(
            (key) =>
              [DENSITY_STAGE_KEY, RAW_HEIGHT_STAGE_KEY, VISIBLE_COLUMNS_STORAGE_KEY].includes(key) ||
              key.match(/.*_column_width/gi)
          )
          .forEach((key) => {
            localStorage.removeItem(key);
          });
      } catch (e) {
        captureException(e);
      }
      setCleared(true);
    }
  }, [isCleared]);

  if (isCleared) return <>{children}</>;
  return null;
};
