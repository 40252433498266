import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, Location } from 'react-router-dom';

import { HistoryContext, HistoryContextProps } from '@/hooks';

export const HistoryCatcher: FC = ({ children }) => {
  const [history, setHistory] = useState<Record<string, Location>>({});
  const context = useMemo<HistoryContextProps>(
    () => ({
      get: (path: string | Location) => {
        if (typeof path === 'string') {
          path = path.replace(/\/$/gi, '');
          if (history && history[path]) {
            return history[path];
          }
        } else {
          if (history.current && history[path.pathname]) {
            return history[path.pathname];
          }
        }
        return path;
      },
    }),
    [history]
  );

  const location = useLocation();

  const handleChange = useCallback(() => {
    if (location) {
      setHistory((history) => ({ ...history, [location.pathname]: location }));
    }
  }, [location]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  return <HistoryContext.Provider value={context}>{children}</HistoryContext.Provider>;
};
