import { ReactElement, memo, useCallback, useState } from 'react';
import { LatLng, Map as LeafletMap } from 'leaflet';
import { css } from '@emotion/css';
import { EuiProgress } from '@elastic/eui';
import { v4 as getUid } from 'uuid';

import { ReactComponent as OpenStreetIcon } from '@/assets/icons/map/Openstreetmap_logo.svg';
import { ReactComponent as GoogleIcon } from '@/assets/icons/map/Google_Maps_Logo.svg';
import { LandmarksTypes } from '@/pages/Map/types';

import { MoveControl, MoveHandler } from './MoveControl';
import { MapProfilerCard } from './MapProfilerCard';
import { AreaBounds, PointMachine, SelectedPoint } from './types';
import { MapContainer } from './MapContainer';
import { FullScreen } from './Fullscreen';
import { ClusterPoints } from './ClusterPoints';
import { Filter } from './Filter';
import { AreaSelector } from './AreaSelector';
import { SearchField } from './SearchField';
import { CustomControlContainers } from './CustomControlContainers';
import { Zoom } from './Zoom';
import { BetaWarning } from './BetaWarning';
import { Layers } from './Layers';
import { Geocoder } from './Geocoder';
import { SelectPoint } from './SelectPoint';

interface MapProps {
  location: string;
  isLoading: boolean;
  center: Definitions.GeoPoint;
  zoom: number;
  height: string | number;
  width: string | number;
  types: LandmarksTypes[];
  searchBar: ReactElement;
  area: AreaBounds;
  onMove: MoveHandler;
  onCreate?: (map: LeafletMap) => void;
  onChangeLandmarkTypes: (types: LandmarksTypes[]) => void;
  onAreaChange: (params: { nw: LatLng; se: LatLng }) => void;
  onAreaRemove: () => void;
  onChangeLocation: (query: string) => void;
}
const DEFAULT_LAYER_THEME = 'dark';
export const Map = memo<MapProps>(function Map({
  center,
  zoom,
  height,
  width,
  isLoading,
  types,
  searchBar,
  area,
  location,
  onChangeLandmarkTypes,
  onMove,
  onCreate,
  onAreaChange,
  onAreaRemove,
  onChangeLocation,
}) {
  const [selectedPoints, setSelectedPoints] = useState<SelectedPoint[] | null>(null);
  const [isFullScreen, setFullScreen] = useState(false);
  const [isSearchFieldPeople, setSearchFieldPeople] = useState(false);
  const handleCloseSelectPoint = useCallback(() => setSelectedPoints(null), []);

  const handleClusterSelectPoint = (data: SelectedPoint[]) => {
    const compromisedMachine: PointMachine[] = data.filter((point) => point.type === 'compromised_machine');
    const sd = data.filter((point) => point.type !== 'compromised_machine');
    if (compromisedMachine.length) {
      sd.push({
        id: getUid(),
        position: data[0].position,
        type: 'compromised_machine',
        machines: compromisedMachine,
      });
    }

    setSelectedPoints(sd);
  };

  const handleToggleSearchFieldPeople = () => setSearchFieldPeople((state) => !state);

  return (
    <div
      className={css(`
          display: flex;
          height: ${height}px;

          &>.leaflet-container {
            flex: 1 1 100%;
          }
          &>.MapProfilerCard {
            flex: 1 0 auto;
          }

          ${
            isFullScreen &&
            `
            &>.MapProfilerCard {
              position: fixed !important;
              right: 0;
              top: 0;
              transform: none !important;
              z-index: 100000;
            }

            ${
              isFullScreen &&
              selectedPoints &&
              `
              &>.leaflet-pseudo-fullscreen {
                width: calc(100% - 380px )!important;
              }
              `
            }
          `
          }

        `)}
    >
      {isLoading && (
        <EuiProgress
          style={{
            zIndex: 100000,
          }}
          size="s"
          color="primary"
          position={isFullScreen ? 'fixed' : 'absolute'}
        />
      )}
      <MapContainer
        zoomControl={false}
        height={height}
        width={width}
        center={{
          lat: center.lat,
          lng: center.lon,
        }}
        zoom={zoom}
        minZoom={4}
        onCreate={onCreate}
        defaultLayerTheme={DEFAULT_LAYER_THEME}
      >
        <CustomControlContainers />
        <Layers
          layers={[
            {
              url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
              name: 'Open Street',
              options: {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
              },
              icon: OpenStreetIcon,
              theme: 'dark',
            },
            {
              url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
              name: 'Sputnik',
              options: {
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
              },
              icon: GoogleIcon,
              theme: 'light',
            },
            {
              url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
              name: 'Map',
              options: {
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
              },
              icon: GoogleIcon,
              theme: 'dark',
            },
          ]}
        />
        <BetaWarning />
        <MoveControl onMove={onMove} />
        <Geocoder
          location={location}
          isActive={!isSearchFieldPeople}
          toggleActive={handleToggleSearchFieldPeople}
          onChangeLocation={onChangeLocation}
        />
        <SearchField isActive={isSearchFieldPeople} toggleActive={handleToggleSearchFieldPeople}>
          {searchBar}
        </SearchField>
        <Zoom />
        <FullScreen isActive={isFullScreen} setActive={setFullScreen} />
        <AreaSelector area={area} onSelect={onAreaChange} onClear={onAreaRemove} />
        <Filter data={types} onChange={onChangeLandmarkTypes} />
        <ClusterPoints onSelect={handleClusterSelectPoint} />
        <SelectPoint selectedPoints={selectedPoints} />
      </MapContainer>
      {selectedPoints ? <MapProfilerCard points={selectedPoints} onClose={handleCloseSelectPoint} /> : null}
    </div>
  );
});
