import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { EuiButtonIcon, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';

import { useMap } from '../MapContainer';

import { zoomControl } from './ZoomControl';

export const Zoom = () => {
  const { euiTheme } = useEuiTheme();
  const { map } = useMap();
  const ctx = useRef(zoomControl());

  useEffect(() => {
    const container = ctx.current;
    container.addTo(map);

    return () => {
      container.remove();
    };
  }, [map]);

  const handlePlus = () => {
    const maxZoom = map.getMaxZoom();
    const zoom = map.getZoom() + 1;
    if (zoom <= maxZoom) map.setZoom(zoom);
  };

  const handleMinus = () => {
    const minZoom = map.getMinZoom();
    const zoom = map.getZoom() - 1;
    if (zoom >= minZoom) map.setZoom(zoom);
  };

  if (ctx.current.button) {
    return createPortal(
      <>
        <EuiButtonIcon
          className={css(`
              background-color: ${euiTheme.colors.emptyShade};
            `)}
          size="s"
          display="base"
          color="text"
          iconType="plus"
          onClick={handlePlus}
        />
        <EuiButtonIcon
          className={css(`
              background-color: ${euiTheme.colors.emptyShade};
            `)}
          size="s"
          display="base"
          color="text"
          iconType="minus"
          onClick={handleMinus}
        />
      </>,
      ctx.current.button
    );
  }
  return null;
};
