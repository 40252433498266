import { Navigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import { Routes } from '@/routes';
import { useAppDispatch, useAppSelector } from '@/store';
import { authenticationSelectors } from '@/store/authentication';
import { customerActions, customerSelectors } from '@/store/customer';
import { PageLoader } from '../Core';

import { HistoryCatcher } from './HistoryCatcher';

export const AuthRequired = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(authenticationSelectors.getAuthenticated);
  const isEnabledTwoAuth = useAppSelector(customerSelectors.getCustomerTwoAuth);
  const isLoading = useAppSelector(customerSelectors.getLoading);
  const user = useAppSelector(customerSelectors.getInfo);

  useEffect(() => {
    if (isAuthenticated) {
      if (!user) {
        dispatch(customerActions.fetchCurrentAccount());
      }
    } else {
      dispatch(customerActions.clearInfo());
    }
  }, [dispatch, user, isAuthenticated]);

  if (isAuthenticated) {
    if (isLoading) {
      return <PageLoader />;
    }

    if (!isEnabledTwoAuth) {
      return <Navigate to={Routes.SETTINGS_OTP} />;
    }

    return (
      <HistoryCatcher>
        <Outlet />
      </HistoryCatcher>
    );
  }

  const redirectUrl = `${window.location.pathname}${window.location.search}`;
  const navigateTo = `${Routes.LOGIN}/?redirect=${redirectUrl}`;
  return <Navigate to={navigateTo} replace />;
};
