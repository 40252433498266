/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Control, ControlOptions, ControlPosition, DomUtil, Map } from 'leaflet';

import { CUSTOM_CONTAINERS } from '../CustomControlContainers';

interface BetaWarningControlOptions extends ControlOptions {
  title?: string;
}

const INITIAL_OPTIONS: BetaWarningControlOptions = {
  position: 'topleft',
  title: 'Beta',
};

const ELEMENT_CLASS_NAME = 'leaflet-control-beta-warning';

export class BetaWarningControl extends Control {
  constructor(options?: BetaWarningControlOptions) {
    super({ ...INITIAL_OPTIONS, ...options });
    this.options = { ...INITIAL_OPTIONS, ...options };
  }

  isInit = false;
  options: BetaWarningControlOptions = INITIAL_OPTIONS;
  container: HTMLElement | null = null;
  button: HTMLElement | undefined;
  private _map!: Map;
  private isActive = false;

  onAdd(map: Map): HTMLElement {
    let button = map.getCustomControlContainer(this.options.position as ControlPosition, CUSTOM_CONTAINERS.SEARCH);

    if (!button) {
      button = DomUtil.create('div');
    }

    this.isInit = true;
    this.container = DomUtil.create('div', `${ELEMENT_CLASS_NAME}__content`);

    const mapControlContainer = (this._map as any)._controlContainer as HTMLElement;
    if (mapControlContainer) {
      mapControlContainer.appendChild(this.container);
    }
    this.button = button;
    return button;
  }

  getMap() {
    return this._map as Map;
  }
}

export const betaWarningControl = (options?: BetaWarningControlOptions) => {
  return new BetaWarningControl(options);
};
