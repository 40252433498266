import { useAppSelector } from '@/store';
import { datasetSelectors } from '@/store/dataset';

export const useData = () => {
  const items = useAppSelector(datasetSelectors.getData);
  const totalItems = useAppSelector(datasetSelectors.getTotalData);
  const isLoading = useAppSelector(datasetSelectors.getLoading);

  return {
    items,
    isLoading,
    totalItems,
  };
};
