import { EuiPageSection } from '@elastic/eui';
import { useContext } from 'react';

import { LeakFlyout, NotesForm, SearchData } from '@/components';
import { useAppSelector } from '@/store';
import { credentialsSelectors } from '@/store/credentials';
import { SortColumnDirection } from '@/components/SearchData/types';
import { sortingUiAdapter } from '@/components/SearchData/helper';

import { SearchResultContext } from './context';
import { useLeak } from './useLeakData';

export const SearchResult = () => {
  const editNote = useAppSelector(credentialsSelectors.getEditNote);

  const {
    activePage,
    limit,
    isSearched,
    defaultValues,
    handleChangeItemsPerPage,
    handleChangePage,
    handleChangeSort,
    handleExportToJson,
    handleCloseEditNote,
    handleSubmit,
    handleAddQueryOperator,
  } = useContext(SearchResultContext);

  const { leak, hrefLeak, handleOpen, handleClose, handleShowAllData } = useLeak({
    handleSubmit,
  });

  return (
    <EuiPageSection
      paddingSize="none"
      grow
      alignment="center"
      contentProps={{
        css: {
          width: '100%',
        },
      }}
    >
      <SearchData
        activePage={activePage}
        limitPagination={limit}
        onChangeItemsPerPage={handleChangeItemsPerPage}
        onChangePage={handleChangePage}
        onChangeSort={handleChangeSort}
        onExport={handleExportToJson}
        onLeakOpen={handleOpen}
        onAddQueryOperator={handleAddQueryOperator}
        isSearched={isSearched}
        initialSort={
          defaultValues.order_by && defaultValues.sort_by
            ? sortingUiAdapter({
                id: defaultValues.order_by,
                direction: defaultValues.sort_by as SortColumnDirection,
              })
            : null
        }
      />
      {!!editNote && <NotesForm onClose={handleCloseEditNote} data={editNote} />}
      {!!leak && (
        <LeakFlyout hrefAllLeak={hrefLeak} onClose={handleClose} onShowAllLeak={handleShowAllData} leak={leak} />
      )}
    </EuiPageSection>
  );
};
