import { EuiContextMenuItem } from '@elastic/eui';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useLinkClickHandler } from 'react-router-dom';

interface HeaderMenuLinkProps {
  to: string;
  name: string;
  onClick: () => void;
}

export const HeaderMenuLink: FC<HeaderMenuLinkProps> = ({ to, name, onClick }) => {
  const handleClickHref = useLinkClickHandler(to);

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      if (!e.defaultPrevented) {
        onClick();
        handleClickHref(e);
      }
    },
    [onClick, handleClickHref]
  );

  return (
    <EuiContextMenuItem href={to} onClick={handleClick as MouseEventHandler<Element>}>
      {name}
    </EuiContextMenuItem>
  );
};
