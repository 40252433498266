import { EuiDataGridColumn } from '@elastic/eui';

import { Columns } from './types';

export const columnsConfig: EuiDataGridColumn[] = [
  {
    id: Columns.title,
    isExpandable: false,
    displayAsText: 'Group',
    initialWidth: 170,
    actions: false,
  },
  {
    id: Columns.description,
    displayAsText: 'Description',
    isExpandable: false,
    actions: false,
  },

  {
    id: Columns.total_members,
    displayAsText: 'Members',
    isExpandable: false,
    actions: false,
    initialWidth: 145,
  },
  {
    id: Columns.status,
    displayAsText: 'Status',
    isExpandable: false,
    actions: false,
    initialWidth: 145,
  },
];

export const CONFIG_ITEMS_PER_PAGE = [10, 25, 50];

export const DEFAULT_ITEMS_PER_PAGE = 50;
