import { SchemaOf, object, string, number, boolean } from 'yup';

export type FormFields = Paths.CreateRecursiveSearchTasks.Parameters.Body;

export const MAX_LIMIT_RECURSION = 5;
export const MIN_LIMIT_RECURSION = 1;

export const schema: SchemaOf<FormFields> = object().shape({
  title: string().required('form.error.required'),
  recursion_levels_number: number()
    .required('form.error.required')
    .max(MAX_LIMIT_RECURSION, 'createRecursiveTaskForm.error.recursion_levels_number_max')
    .min(MIN_LIMIT_RECURSION, 'createRecursiveTaskForm.error.recursion_levels_number_min'),
  search_fields: object().shape({
    address: string(),
    birthday: string().test(
      'with-raw-name',
      'createRecursiveTaskForm.error.required_with_raw_name',
      (value, context) => !(!!context.parent.raw_name && !value)
    ),
    email: string().email('form.error.email'),
    inn: string(),
    passport: string(),
    phone: string(),
    raw_name: string().test(
      'with-birthday',
      'createRecursiveTaskForm.error.required_with_birthday',
      (value, context) => !(!!context.parent.birthday && !value)
    ),
    snils: string(),
  }),
  different_name_forms: boolean(),
});
