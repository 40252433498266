import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { customerApi, organizationApi } from '@/api';
import { toastActions, toastSelectors } from '../toast';
import { ToastVariantBasic } from '@/components';
import { RootState } from '../store';

import { getOrganizationId } from './selectors';

export type Action = 'search' | 'reset' | null;

export interface State {
  isLoading: boolean;
  isLoadingMembers: boolean;
  isLoadingGroups: boolean;
  isLoadingActions: boolean;
  action: Action;
  info: Definitions.OrganizationProfile | null;
  members: Definitions.Profile[];
  actions: Definitions.OrganizationActionHistory[];
  totalActions: number;
  totalMembers: number;
  totalMembersDisabled: number;
  groups: Definitions.Group[];
  totalGroups: number;
  isLoadingAutocomplete: boolean;
  autocompleteMembers: Definitions.Profile[];
  defaultGroupId: number | null;
  isLoadingCounters: boolean;
  counters: Definitions.HistoryCountersData | null;
  list: Definitions.OrganizationProfile[];
}

export type OrganizationTabs = 'active-users-tab' | 'disabled-users-tab' | 'groups-tab' | 'actions-tab';

export const ACCOUNT_TABS: OrganizationTabs[] = ['active-users-tab', 'disabled-users-tab'];

export const fetchCurrent = createAsyncThunk('organization/fetchCurrent', async () => {
  const {
    data: { data },
  } = await organizationApi.getCurrent();
  return data;
});

export const fetchMembers = createAsyncThunk(
  'organization/fetchMembers',
  async (payload: Paths.GetOrganizationMembers.QueryParameters) => {
    const {
      data: { data },
    } = await organizationApi.getMembers(payload);
    return data;
  }
);

export const fetchActions = createAsyncThunk(
  'organization/fetchActions',
  async (payload: Paths.GetOrganizationActionHistory.QueryParameters, { getState }) => {
    const organizationId = getOrganizationId(getState() as RootState);
    if (organizationId) {
      const {
        data: { data },
      } = await organizationApi.getActionHistory({ ...payload, organization_id: `${organizationId}` });
      return data;
    }
  }
);

export const fetchTotals = createAsyncThunk(
  'organization/fetchTotals',
  async (payload: OrganizationTabs, { getState }) => {
    if (ACCOUNT_TABS.includes(payload)) {
      const {
        data: { data },
      } = await organizationApi.getMembers({
        limit: 1,
        status: payload === 'active-users-tab' ? 'active' : 'deactivated',
      });
      return data;
    }
    if (payload === 'groups-tab') {
      const {
        data: { data },
      } = await organizationApi.getGroups({
        limit: 1,
      });
      return data;
    }

    if (payload === 'actions-tab') {
      const organizationId = getOrganizationId(getState() as RootState);
      if (organizationId) {
        const {
          data: { data },
        } = await organizationApi.getActionHistory({
          limit: 1,
          organization_id: `${organizationId}`,
        });
        return data;
      }
    }
  }
);

export const fetchMembersAutocomplete = createAsyncThunk(
  'organization/fetchMembersAutocomplete',
  async (payload: Paths.GetOrganizationMembers.QueryParameters) => {
    const {
      data: { data },
    } = await organizationApi.getMembers(payload);
    return data;
  }
);

export const fetchGroups = createAsyncThunk(
  'organization/fetchGroups',
  async (payload: Paths.GetOrganizationMembers.QueryParameters) => {
    const {
      data: { data },
    } = await organizationApi.getGroups(payload);
    return data;
  }
);

export const fetchDefaultGroupId = createAsyncThunk('organization/fetchDefaultGroupId', async () => {
  const {
    data: { data },
  } = await organizationApi.getGroups({
    limit: 1,
    offset: 0,
  });
  const group = data?.items?.[0];
  const defaultGroupId = group && group.is_default ? group.id : null;
  return defaultGroupId;
});

export const fetchList = createAsyncThunk('organization/fetchList', async () => {
  const {
    data: { data },
  } = await organizationApi.getList();

  return data;
});

export const createMember = createAsyncThunk(
  'organization/createMember',
  async (payload: Paths.RegisterCustomer.Parameters.Body, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerApi.createAccount(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.RegisterCustomer.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

export const fetchCounters = createAsyncThunk(
  'organization/fetchCounters',
  async (
    payload: Paths.OrganizationsCounters.PathParameters & Paths.OrganizationsCounters.QueryParameters,
    thunkApi
  ) => {
    try {
      const {
        data: { data },
      } = await organizationApi.getStatistic(payload);
      return data;
    } catch {
      return thunkApi.rejectWithValue(null);
    }
  }
);

export const downloadCounters = createAsyncThunk(
  'organization/downloadCounters',
  async (
    payload: Paths.DownloadOrganizationRequestHistory.PathParameters &
      Paths.DownloadOrganizationRequestHistory.Parameters.Body,
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(
        toastActions.create({
          type: ToastVariantBasic.DOWNLOADING_FILE,
        })
      );
      const { data } = await organizationApi.downloadStatistic(payload);
      fileDownload(data, `${payload.organization_id}.csv`);
      thunkApi.dispatch(
        toastActions.create({
          type: ToastVariantBasic.DOWNLOADED_FILE,
        })
      );
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.EnableTwoFactorAuth.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    } finally {
      const toasts = toastSelectors.getItems(thunkApi.getState() as RootState);
      const toast = toasts.find((item) => item.type === ToastVariantBasic.DOWNLOADING_FILE);
      if (toast) thunkApi.dispatch(toastActions.remove(toast.id));
    }
  }
);

const initialState: State = {
  isLoading: true,
  isLoadingGroups: true,
  isLoadingMembers: true,
  isLoadingActions: true,
  action: null,
  info: null,
  members: [],
  actions: [],
  totalActions: 0,
  totalMembers: 0,
  totalMembersDisabled: 0,
  groups: [],
  defaultGroupId: null,
  totalGroups: 0,
  isLoadingAutocomplete: true,
  autocompleteMembers: [],
  counters: null,
  isLoadingCounters: true,
  list: [],
};

const clearInfo = createAction('organization/clearInfo');
const clearMembersAutoComplete = createAction('organization/clearMembersAutoComplete');
const setAction = createAction<Action>('organization/setAction');

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'organization',
  initialState,
  reducers: {
    clearInfo: () => ({ ...initialState }),
    clearMembersAutoComplete: (state) => {
      state.isLoadingAutocomplete = true;
      state.autocompleteMembers = [];
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotals.fulfilled, (state, action) => {
        if (action.meta.arg === 'active-users-tab' && action.payload) {
          state.totalMembers = action.payload.total_items || 0;
        }
        if (action.meta.arg === 'disabled-users-tab' && action.payload) {
          state.totalMembersDisabled = action.payload.total_items || 0;
        }
        if (action.meta.arg === 'groups-tab' && action.payload) {
          state.totalGroups = action.payload.total_items || 0;
        }
        if (action.meta.arg === 'actions-tab' && action.payload) {
          state.totalActions = action.payload.total_items || 0;
        }
      })
      .addCase(fetchTotals.rejected, (state, action) => {
        if (action.meta.arg === 'active-users-tab' && action.payload) {
          state.totalMembers = 0;
        }
        if (action.meta.arg === 'disabled-users-tab' && action.payload) {
          state.totalMembersDisabled = 0;
        }
        if (action.meta.arg === 'groups-tab' && action.payload) {
          state.totalGroups = 0;
        }
        if (action.meta.arg === 'actions-tab' && action.payload) {
          state.totalActions = 0;
        }
      });
    builder
      .addCase(fetchCurrent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCurrent.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.info = action.payload;
        }
      })
      .addCase(fetchCurrent.rejected, (state) => {
        state.isLoading = false;
        state.info = null;
      });

    builder
      .addCase(fetchMembersAutocomplete.pending, (state) => {
        state.isLoadingAutocomplete = true;
      })
      .addCase(fetchMembersAutocomplete.fulfilled, (state, action) => {
        state.isLoadingAutocomplete = false;
        if (action.payload) {
          state.autocompleteMembers = action.payload.items || [];
        }
      })
      .addCase(fetchMembersAutocomplete.rejected, (state) => {
        state.isLoadingAutocomplete = false;
        state.autocompleteMembers = [];
      });

    builder
      .addCase(fetchDefaultGroupId.fulfilled, (state, action) => {
        if (action.payload) {
          state.defaultGroupId = action.payload;
        }
      })
      .addCase(fetchDefaultGroupId.rejected, (state) => {
        state.defaultGroupId = null;
      });

    builder
      .addCase(fetchMembers.pending, (state) => {
        state.isLoadingMembers = true;
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.isLoadingMembers = false;
        if (action.payload) {
          state.members = action.payload.items || [];
          if (action.meta.arg.status && action.meta.arg.status === 'active') {
            state.totalMembers = action.payload.total_items || 0;
          }
          if (action.meta.arg.status && action.meta.arg.status === 'deactivated') {
            state.totalMembersDisabled = action.payload.total_items || 0;
          }
        }
      })
      .addCase(fetchMembers.rejected, (state, action) => {
        state.isLoadingMembers = false;
        state.members = [];
        if (action.meta.arg.status && action.meta.arg.status === 'active') {
          state.totalMembers = 0;
        }
        if (action.meta.arg.status && action.meta.arg.status === 'deactivated') {
          state.totalMembersDisabled = 0;
        }
      });

    builder
      .addCase(fetchGroups.pending, (state) => {
        state.isLoadingGroups = true;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.isLoadingGroups = false;
        if (action.payload) {
          state.groups = action.payload.items || [];
          state.totalGroups = action.payload.total_items || 0;
        }
      })
      .addCase(fetchGroups.rejected, (state) => {
        state.isLoadingGroups = false;
        state.groups = [];
        state.totalGroups = 0;
      });

    builder
      .addCase(fetchActions.pending, (state) => {
        state.isLoadingActions = true;
      })
      .addCase(fetchActions.fulfilled, (state, action) => {
        state.isLoadingActions = false;
        if (action.payload) {
          state.actions = action.payload.items || [];
          state.totalActions = action.payload.total_items || 0;
        }
      })
      .addCase(fetchActions.rejected, (state) => {
        state.isLoadingActions = false;
        state.actions = [];
        state.totalActions = 0;
      });

    builder
      .addCase(fetchCounters.pending, (state) => {
        state.isLoadingCounters = true;
      })
      .addCase(fetchCounters.fulfilled, (state, action) => {
        state.isLoadingCounters = false;
        if (action.payload) {
          state.counters = action.payload;
        }
      })
      .addCase(fetchCounters.rejected, (state) => {
        state.isLoadingCounters = false;
        state.groups = [];
        state.totalGroups = 0;
      });

    builder.addCase(fetchList.fulfilled, (state, action) => {
      if (action.payload) {
        state.list = action.payload;
      }
    });
  },
});

export const actions = {
  fetchCurrent,
  fetchMembers,
  fetchGroups,
  fetchActions,
  createMember,
  fetchMembersAutocomplete,
  fetchDefaultGroupId,
  clearInfo,
  clearMembersAutoComplete,
  fetchTotals,
  fetchCounters,
  fetchList,
  downloadCounters,
  setAction,
};

export default slice.reducer;
