import { object, string, SchemaOf } from 'yup';

export type FormFields = Paths.DownloadOrganizationRequestHistory.PathParameters &
  Omit<Paths.DownloadOrganizationRequestHistory.Parameters.Body, 'period'> &
  Definitions.Period;

export const schema: SchemaOf<FormFields> = object().shape({
  password: string().required('form.error.required'),
  from: string(),
  to: string(),
  organization_id: string().required('form.error.required'),
});
