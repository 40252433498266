import { FC, Fragment, memo, useCallback, useEffect, useMemo, useState, MouseEvent, ReactElement } from 'react';
import {
  EuiBadge,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiCopy,
  EuiDataGrid,
  EuiDataGridCellValueElementProps,
  EuiDataGridColumn,
  EuiDataGridRowHeightOption,
  EuiDataGridRowHeightsOptions,
  EuiDataGridStyle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiHorizontalRule,
  EuiLink,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiTablePagination,
  useEuiTheme,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { captureException } from '@sentry/react';
import { css } from '@emotion/css';

import { useWindowSize } from '@/hooks';
import { EmptyData } from '../Core';
import { useAppDispatch } from '@/store';
import { credentialsActions } from '@/store/credentials';
import { toastActions } from '@/store/toast';
import { ToastVariantBasic, ToastVariantWithText } from '../Toasts';
import { CollapsedItem } from '../CollapsedItem';
import { LeakCell } from '../LeakCell';
import { Loader } from '../Loader';

import { DocumentItem } from './DocumentItem';
import { useSearchData } from './useSearchData';
import { ColumnItem, Columns, SortColumn } from './types';
import { SearchDataControls } from './SearchDataControls';
import { CONFIG_ITEMS_PER_PAGE, REGEXP_VALIDATE_URL } from './constants';

enum RowClasses {
  WORK_IN_PROGRESS = '--workInProgress',
  IS_VALID = '--isValid',
  IS_INVALID = '--isInValid',
}
interface SearchDataProps {
  activePage: number;
  limitPagination: number;
  initialSort: SortColumn | null;
  isSearched: boolean;
  onChangeItemsPerPage: (pageSize: number) => void;
  onChangePage: (pageIndex: number) => void;
  onChangeSort: (sort: Definitions.Sort) => void;
  onExport: () => void;
  onLeakOpen: (data: Definitions.Leak) => void;
  onAddQueryOperator: (groupValue: string) => void;
}

interface RowCellActionsProps {
  data: ColumnItem & {
    rest: Definitions.CredentialResponse;
  };
  columns: EuiDataGridColumn[];
  visibleColumns: string[];
}

type FormFields = Paths.UpdateCredentialNotes.Parameters.Body & Paths.UpdateCredentialNotes.PathParameters;

export const DENSITY_STAGE_KEY = 'density-grid-style';
export const RAW_HEIGHT_STAGE_KEY = 'raw-height-grid-style';
const COLLAPSIBLE_SIZE_ARRAY = 1;

const getDensity = (): EuiDataGridStyle => {
  const styles: EuiDataGridStyle = { fontSize: 'm', cellPadding: 'm' };
  try {
    const data = localStorage.getItem(DENSITY_STAGE_KEY);
    if (data) {
      const savedStyles = JSON.parse(data);
      if (savedStyles.cellPadding && savedStyles.fontSize) {
        styles.cellPadding = savedStyles.cellPadding;
        styles.fontSize = savedStyles.fontSize;
      }
    }
  } catch (e) {
    captureException(e);
  }
  return styles;
};

const getRawHeightOptions = (): undefined | EuiDataGridRowHeightOption => {
  let options: undefined | EuiDataGridRowHeightOption = 'auto';
  const data = localStorage.getItem(RAW_HEIGHT_STAGE_KEY);
  try {
    if (data) {
      options = JSON.parse(data);
    }
  } catch {
    if (data && typeof data === 'string') {
      if (data === 'auto') {
        options = data;
      } else if (data === 'undefined') {
        options = undefined;
      }
    }
  }
  return options;
};

const getStatusColor = (status: number): string => {
  if (status >= 400) return 'danger';
  if (status >= 300) return 'warning';
  if (status >= 200) return 'success';
  return 'default';
};

const getDomainFromUrl = (url: string) => {
  try {
    return new URL(url).hostname;
  } catch {
    return '';
  }
};

const RowCellActions: FC<RowCellActionsProps> = ({ data: { rest, ...rawData }, columns }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const dispatch = useAppDispatch();

  const { register, unregister, setValue, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      id: rest.id,
      note_text: rest.note || '',
      is_valid: rest.is_valid,
      work_in_progress: rest.work_in_progress,
    },
  });

  useEffect(() => {
    register('id');
    register('note_text');
    register('is_valid');
    register('work_in_progress');
    return () => {
      unregister('id');
      unregister('note_text');
      unregister('is_valid');
      unregister('work_in_progress');
    };
  }, [register, unregister]);

  const handleRequest = useCallback(
    async (data) => {
      try {
        await dispatch(credentialsActions.updateNoteCredentialById(data)).unwrap();
        dispatch(
          toastActions.create({
            type: ToastVariantWithText.SUCCESS_ACTION,
            text: `Success! Credential has been updated!`,
          })
        );
      } catch (e: any) {
        const errorResponse = e as Definitions.Error;
        if (errorResponse?.errors) {
          errorResponse.errors.forEach((error) => {
            if (error.message) {
              dispatch(
                toastActions.create({
                  type: ToastVariantWithText.ERROR_ACTION,
                  text: error.message,
                })
              );
            } else {
              dispatch(
                toastActions.create({
                  type: ToastVariantBasic.UNKNOWN_ERROR,
                })
              );
              captureException(errorResponse);
            }
          });
        } else if (errorResponse?.message) {
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.ERROR_ACTION,
              text: errorResponse.message,
            })
          );
        }
      }
    },
    [dispatch]
  );

  const handleValid = useCallback(
    (isValid: boolean | undefined) => {
      setValue('is_valid', isValid);
      handleSubmit(handleRequest)();
    },
    [setValue, handleSubmit, handleRequest]
  );

  const handleWorkInProgress = useCallback(
    (isValid: boolean) => {
      setValue('work_in_progress', isValid);
      handleSubmit(handleRequest)();
    },
    [setValue, handleSubmit, handleRequest]
  );

  const handleUpdateNote = useCallback(() => {
    if (rest.id) {
      dispatch(
        credentialsActions.updateNote({
          id: rest.id,
          note_text: rest.note || '',
          is_valid: rest.is_valid,
          work_in_progress: rest.work_in_progress,
        })
      );
    }
    setIsPopoverVisible(false);
  }, [dispatch, rest]);

  const copyText = useMemo(() => {
    let templateText = ``;
    columns.forEach((column) => {
      switch (column.id) {
        case Columns.document:
          /*templateText += `\n\n${column.displayAsText}\n`;
          Object.entries(rawData).map(([key, value]) => {
            if (value && !visibleColumns.includes(key))
              templateText += `${t('document.badge.' + key)}:${renderValueDocument(value, key as Columns)}\n`;
          });*/
          break;
        case Columns.leak:
          templateText += `\n${column.displayAsText}:`;
          (rawData[column.id as Columns] as Definitions.CredentialsLeak[]).forEach((leak) => {
            templateText += `\n\t${leak.country} ${leak.title} ${moment
              .parseZone(leak.released_at)
              .format(process.env.REACT_APP_DATE_FORMAT)} ${leak.meta?.ip}`;
          });
          break;
        case Columns.http_info:
          if (rawData[column.id as Columns] as Definitions.HTTPInfo) {
            templateText += `\n${column.displayAsText}: ${
              (rawData[column.id as Columns] as Definitions.HTTPInfo).status_code || ''
            } ${
              rest.technologies && rest.technologies.length
                ? '[' +
                  rest.technologies
                    .map(
                      (technology) => `${technology.name || ''}${technology.version ? ' ' + technology.version : ''}`
                    )
                    .join(', ') +
                  ']'
                : ''
            } ${(rawData[column.id as Columns] as Definitions.HTTPInfo).title || ''}`;
          }
          break;
        case Columns.created_at:
        case Columns.updated_at:
        case Columns.http_info_scanned_at:
          /*
        case Columns.leak_released_at:
          */
          templateText += `\n${column.displayAsText}:`;
          if (rawData[column.id]) {
            templateText += `${moment
              .parseZone(rawData[column.id] as string)
              .format(process.env.REACT_APP_DATE_FORMAT)}`;
          }
          break;
        default:
          templateText += `\n${column.displayAsText}:${rawData[column.id as Columns]}`;
          break;
      }
    });

    return templateText.replace(/^\n/, '');
  }, [rawData, rest, columns]);

  const actions = [
    <Fragment key="mark_valid">
      <EuiContextMenuItem className="data" onClick={() => handleValid(rest.is_valid === true ? undefined : true)}>
        <EuiHealth className="euiHealth--reverse" color="success">
          {rest.is_valid === true ? 'Unmark as valid' : 'Mark as valid'}
        </EuiHealth>
      </EuiContextMenuItem>
      <EuiHorizontalRule margin="none" />
    </Fragment>,
    <Fragment key="mark_invalid">
      <EuiContextMenuItem layoutAlign="center" onClick={() => handleValid(rest.is_valid === false ? undefined : false)}>
        <EuiHealth className="euiHealth--reverse" color="danger">
          {rest.is_valid === false ? 'Unmark as invalid' : 'Mark as invalid'}
        </EuiHealth>
      </EuiContextMenuItem>
      <EuiHorizontalRule margin="none" />
    </Fragment>,
    <Fragment key="in_progress">
      <EuiContextMenuItem onClick={() => handleWorkInProgress(!rest.work_in_progress)}>
        {!rest.work_in_progress ? 'In progress' : 'Not in process'}
      </EuiContextMenuItem>
      <EuiHorizontalRule margin="none" />
    </Fragment>,
    <Fragment key="copy_row">
      <EuiCopy textToCopy={copyText} display="block">
        {(copy) => <EuiContextMenuItem onClick={copy}>Copy row</EuiContextMenuItem>}
      </EuiCopy>
      <EuiHorizontalRule margin="none" />
    </Fragment>,
    <EuiContextMenuItem key="note" onClick={handleUpdateNote}>
      Note
    </EuiContextMenuItem>,
  ];

  return (
    <EuiFlexGroup justifyContent="center">
      <EuiPopover
        isOpen={isPopoverVisible}
        panelPaddingSize="none"
        anchorPosition="upCenter"
        button={
          <EuiButtonIcon
            aria-label="Show actions"
            iconType="boxesHorizontal"
            color="text"
            onClick={() => setIsPopoverVisible(!isPopoverVisible)}
          />
        }
        closePopover={() => setIsPopoverVisible(false)}
      >
        <EuiContextMenuPanel
          css={{
            minWidth: 160,
          }}
          items={actions}
          size="s"
        />
      </EuiPopover>
    </EuiFlexGroup>
  );
};

interface ExcludeOption {
  filter: string;
  operator: string;
  value: string;
}

type ContextMenuColumnBaseProps = {
  copyText: string;
  actions?: ReactElement[];
};
type ContextMenuColumnWithExclude = ContextMenuColumnBaseProps & {
  exclude: ExcludeOption[];
  onAddQueryOperator: (value: string) => void;
};

type ContextMenuColumnDefault = ContextMenuColumnBaseProps & {
  exclude?: undefined;
  onAddQueryOperator?: undefined;
};

type ContextMenuColumnProps = ContextMenuColumnDefault | ContextMenuColumnWithExclude;

const ContextMenuColumn: FC<ContextMenuColumnProps> = ({
  actions,
  children,
  copyText,
  exclude,
  onAddQueryOperator,
}) => {
  const [isPopoverOpen, setPopover] = useState(false);

  const closePopover = useCallback(() => {
    setPopover(false);
  }, []);

  const items = useMemo<ReactElement[]>(() => {
    let defaultItems: ReactElement[] = [
      <EuiCopy key="copy" textToCopy={copyText} display="block">
        {(copy) => (
          <EuiContextMenuItem
            onClick={() => {
              copy();
              setTimeout(() => {
                closePopover();
              }, 1000);
            }}
          >
            Copy row
          </EuiContextMenuItem>
        )}
      </EuiCopy>,
    ];
    if (actions) {
      defaultItems = [...actions, ...defaultItems];
    }

    if (exclude && onAddQueryOperator) {
      const excludeItems = exclude.map(({ filter, operator, value }) => (
        <EuiContextMenuItem
          key={`${filter}-${operator}`}
          onClick={() => {
            onAddQueryOperator(`${filter}.${operator}: "${value}"`);
            closePopover();
          }}
        >
          Exclude {filter}.{operator}: &ldquo;{value}&rdquo;
        </EuiContextMenuItem>
      ));
      defaultItems = [...excludeItems, ...defaultItems];
    }

    return defaultItems;
  }, [actions, copyText, exclude, closePopover, onAddQueryOperator]);

  const onButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setPopover((state) => !state);
  };

  return (
    <EuiPopover
      style={{
        width: '100%',
      }}
      isOpen={isPopoverOpen}
      anchorPosition="downRight"
      closePopover={closePopover}
      panelPaddingSize="none"
      button={<div onContextMenu={onButtonClick}>{children}</div>}
      anchorClassName={css(`
        width: 100%;
      `)}
    >
      <EuiContextMenuPanel size="s" items={items} />
    </EuiPopover>
  );
};

export const SearchData = memo<SearchDataProps>(function SearchData({
  activePage,
  limitPagination,
  initialSort,
  isSearched,
  onChangeItemsPerPage,
  onChangePage,
  onChangeSort,
  onExport,
  onLeakOpen,
  onAddQueryOperator,
}) {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  const {
    columns,
    visibleColumns,
    sortingColumns,
    rawData,
    totalItems,
    isLoading,
    uiLimitRow,
    isExportPennding,
    handleChangeVisibleColumns,
    handleColumnResize,
    handleChangeSort,
  } = useSearchData({ initialSort, onChangeSort });
  const { height } = useWindowSize();
  const [isFullScreen, setFullscreen] = useState(false);
  const [density] = useState<EuiDataGridStyle>(getDensity());
  const [directionLeak, setDirectionLeak] = useState<'row' | 'column'>(getRawHeightOptions() ? 'column' : 'row');

  const handleChangeGridStyle = useCallback(({ cellPadding, fontSize }: EuiDataGridStyle) => {
    localStorage.setItem(DENSITY_STAGE_KEY, JSON.stringify({ cellPadding, fontSize }));
  }, []);

  const handleChangeRawHeight = useCallback(({ defaultHeight }: EuiDataGridRowHeightsOptions) => {
    localStorage.setItem(RAW_HEIGHT_STAGE_KEY, JSON.stringify(defaultHeight));
    if (defaultHeight) {
      setDirectionLeak('column');
    } else {
      setDirectionLeak('row');
    }
  }, []);

  const RenderCellValue = useCallback(
    ({ rowIndex, columnId }: EuiDataGridCellValueElementProps) => {
      switch (columnId) {
        case Columns.document:
          return Object.entries(rawData[rowIndex]).map(
            ([key, value]) =>
              !!value &&
              !['rest', 'leak', 'http_info'].includes(key) &&
              !visibleColumns.includes(key) && (
                <DocumentItem
                  key={`${rowIndex}-${columnId}-${key}`}
                  id={key as Columns}
                  value={value as string | number}
                />
              )
          );
        case Columns.url: {
          const domain = getDomainFromUrl(rawData[rowIndex][columnId as Columns] as string);
          const exclude: ExcludeOption[] = [];
          if (domain) {
            exclude.push({
              filter: 'domain',
              operator: 'not_eq',
              value: domain,
            });
          }
          return rawData[rowIndex][columnId as Columns] ? (
            <ContextMenuColumn
              copyText={rawData[rowIndex][columnId as Columns] as string}
              exclude={exclude}
              onAddQueryOperator={onAddQueryOperator}
            >
              {rawData[rowIndex][columnId as Columns]?.toString().match(REGEXP_VALIDATE_URL) ? (
                <EuiLink
                  external={false}
                  href={rawData[rowIndex][columnId as Columns] as string}
                  css={{ fontWeight: 600 }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {rawData[rowIndex][columnId as Columns]}
                </EuiLink>
              ) : (
                <span>{rawData[rowIndex][columnId as Columns]}</span>
              )}
            </ContextMenuColumn>
          ) : (
            <span />
          );
        }
        case Columns.leak: {
          if (rawData[rowIndex][columnId as Columns] && Array.isArray(rawData[rowIndex][columnId as Columns])) {
            const leaks = rawData[rowIndex][columnId as Columns] as Definitions.Leak[];
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: directionLeak,
                  flexWrap: 'nowrap',
                }}
              >
                <CollapsedItem
                  size={COLLAPSIBLE_SIZE_ARRAY}
                  data={leaks}
                  id={rawData[rowIndex].rest.id as string}
                  render={(button, showedData) => (
                    <>
                      {showedData.map((leak) => (
                        <LeakCell
                          key={`${rowIndex}-${leak.id}`}
                          leak={leak}
                          direction={directionLeak}
                          onLeakOpen={onLeakOpen}
                        />
                      ))}
                      {button ? (
                        <div
                          style={{
                            display: directionLeak === 'row' ? 'inline-block' : 'block',
                          }}
                        >
                          {button}
                        </div>
                      ) : null}
                    </>
                  )}
                />
              </div>
            );
          } else {
            return <span />;
          }
        }
        case Columns.http_info:
          return rawData[rowIndex][columnId as Columns] ? (
            <>
              {(rawData[rowIndex][columnId as Columns] as Definitions.HTTPInfo)?.status_code ? (
                <>
                  <EuiBadge
                    color={getStatusColor(
                      (rawData[rowIndex][columnId as Columns] as Definitions.HTTPInfo).status_code as number
                    )}
                  >
                    {(rawData[rowIndex][columnId as Columns] as Definitions.HTTPInfo).status_code}
                  </EuiBadge>
                  &nbsp;
                </>
              ) : null}
              {rawData[rowIndex].rest.technologies ? (
                <>
                  {rawData[rowIndex].rest.technologies?.map((technology, index) => (
                    <EuiBadge key={`technology-${index}`}>
                      {technology.name}&nbsp;{technology.version}
                    </EuiBadge>
                  ))}
                  &nbsp;
                </>
              ) : null}
              {(rawData[rowIndex][columnId as Columns] as Definitions.HTTPInfo)?.title ? (
                <>
                  {(rawData[rowIndex][columnId as Columns] as Definitions.HTTPInfo).title}
                  &nbsp;
                </>
              ) : null}
            </>
          ) : (
            <span />
          );
        case Columns.created_at:
        case Columns.updated_at:
        case Columns.http_info_scanned_at:
          return (
            <ContextMenuColumn
              copyText={
                rawData[rowIndex][columnId as Columns]
                  ? moment
                      .parseZone(rawData[rowIndex][columnId as Columns] as string)
                      .format(process.env.REACT_APP_DATE_FORMAT)
                  : ''
              }
            >
              <span>
                {rawData[rowIndex][columnId as Columns]
                  ? moment
                      .parseZone(rawData[rowIndex][columnId as Columns] as string)
                      .format(process.env.REACT_APP_DATE_FORMAT)
                  : ''}
              </span>
            </ContextMenuColumn>
          );
        default:
          return (
            <ContextMenuColumn copyText={rawData[rowIndex][columnId as Columns] as string}>
              <span>{rawData[rowIndex][columnId as Columns]}</span>
            </ContextMenuColumn>
          );
      }
    },
    [rawData, directionLeak, visibleColumns, onLeakOpen, onAddQueryOperator]
  );

  const rowClasses = useMemo(() => {
    const classes: Record<number, string> = {};
    rawData.forEach((data, index) => {
      const styles = [
        data.rest.work_in_progress ? RowClasses.WORK_IN_PROGRESS : '',
        data.rest.is_valid === true ? RowClasses.IS_VALID : '',
        data.rest.is_valid === false ? RowClasses.IS_INVALID : '',
      ].filter((style) => !!style);
      if (styles.length > 0) classes[index] = ['euiDataGridRow', ...styles].join('');
    });
    return classes;
  }, [rawData]);

  if (isLoading && isFullScreen) {
    return (
      <EuiPanel
        style={{
          overflow: 'hidden',
          position: 'fixed',
          zIndex: 1000000,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        hasBorder={false}
        hasShadow={false}
        paddingSize="none"
        borderRadius={'none'}
      >
        <EuiSpacer size="xl" />
        <Loader />
      </EuiPanel>
    );
  }

  if (isLoading)
    return (
      <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
        <EuiSpacer size="xl" />
        <Loader />
      </EuiFlexGroup>
    );

  if (!totalItems.value) return <EmptyData title={isSearched ? t('emptyData.title') : ''} />;

  return (
    <EuiPanel
      style={{
        overflow: 'hidden',
        width: '100%',
        ...(isFullScreen
          ? {
              position: 'fixed',
              zIndex: euiTheme.levels.header as number,
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
            }
          : {}),
      }}
      hasBorder={!isFullScreen}
      hasShadow={false}
      paddingSize="none"
      borderRadius={isFullScreen ? 'none' : 'm'}
    >
      {!isFullScreen && (
        <EuiFlexItem>
          <span style={{ margin: '5px 10px 5px 10px' }}>
            <strong>
              {totalItems.relation === 'gte'
                ? '> 10 000'
                : totalItems.value
                ? new Intl.NumberFormat('uk-UA').format(totalItems.value)
                : null}
            </strong>{' '}
            {t('page.passwordFinder.hits')}
          </span>
        </EuiFlexItem>
      )}
      <div>
        <EuiDataGrid
          aria-labelledby=""
          style={
            isFullScreen
              ? {
                  maxHeight: height - 34,
                  height: height - 34,
                }
              : {
                  maxHeight: Math.max(height - 320, 400),
                  height: Math.max(height - 320, 400),
                }
          }
          columns={columns}
          columnVisibility={{ visibleColumns, setVisibleColumns: handleChangeVisibleColumns }}
          sorting={{ columns: sortingColumns, onSort: handleChangeSort }}
          rowCount={uiLimitRow && rawData.length > uiLimitRow ? uiLimitRow : rawData.length}
          renderCellValue={RenderCellValue}
          rowHeightsOptions={{ defaultHeight: getRawHeightOptions(), onChange: handleChangeRawHeight }}
          leadingControlColumns={[
            {
              id: 'actions',
              width: 40,
              headerCellRender: () => null,
              rowCellRender: ({ rowIndex }) => (
                <RowCellActions visibleColumns={visibleColumns} columns={columns} data={rawData[rowIndex]} />
              ),
            },
          ]}
          toolbarVisibility={{
            showSortSelector: false,
            showFullScreenSelector: false,
            additionalControls: {
              left: {
                append: (
                  <SearchDataControls
                    limit={
                      uiLimitRow
                        ? new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 3 }).format(uiLimitRow)
                        : ''
                    }
                    isDisabledExport={isExportPennding}
                    onExport={onExport}
                  />
                ),
              },
              right: (
                <EuiButtonIcon
                  iconType={isFullScreen ? 'fullScreenExit' : 'fullScreen'}
                  color="text"
                  display="empty"
                  size="xs"
                  onClick={() => {
                    setFullscreen((state) => !state);
                  }}
                />
              ),
            },
          }}
          onColumnResize={handleColumnResize}
          gridStyle={{
            ...density,
            rowClasses,
            border: 'all',
            header: 'shade',
            onChange: handleChangeGridStyle,
          }}
        />
      </div>
      {totalItems.value && uiLimitRow && (
        <EuiTablePagination
          aria-label="Search pagination"
          pageCount={Math.ceil(Math.min(totalItems.value, uiLimitRow) / limitPagination)}
          activePage={activePage}
          showPerPageOptions={totalItems.value > limitPagination}
          onChangePage={onChangePage}
          itemsPerPage={limitPagination}
          onChangeItemsPerPage={onChangeItemsPerPage}
          itemsPerPageOptions={CONFIG_ITEMS_PER_PAGE}
        />
      )}
    </EuiPanel>
  );
});
