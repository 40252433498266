import {
  EuiBadge,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCheckbox,
  EuiFieldSearch,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiLoadingContent,
  EuiPopover,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTextArea,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import debounce from 'lodash.debounce';
import {
  ChangeEvent,
  CSSProperties,
  FC,
  FormEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/react';

import { organizationActions, organizationSelectors } from '@/store/organization';
import { useAppDispatch, useAppSelector } from '@/store';
import { ConfirmAddMember } from '../Modals';
import { toastActions } from '@/store/toast';
import { ToastVariantBasic, ToastVariantWithText } from '../Toasts';

import { FormFields, GroupFormProps } from './types';
import { schema } from './schema';
import { MemberField } from './MemberField';

const MEMBER_SEARCH_LIMIT = 10;

export const GroupForm: FC<GroupFormProps> = memo(function GroupForm({ members, data, onSubmit, onCancel }) {
  const isEdit = useMemo(() => !!data, [data]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { euiTheme } = useEuiTheme();

  const autocompleteMembers = useAppSelector(organizationSelectors.getMembersAutocomplete);
  const isLoadingAutocompleteMembers = useAppSelector(organizationSelectors.getLoadingAutocompleteMembers);
  const defaultGroupId = useAppSelector(organizationSelectors.getDefaultGroupId);

  const isDefault = useMemo(() => !!data && defaultGroupId === data.id, [data, defaultGroupId]);

  const titleStyles = useMemo<CSSProperties>(
    () => ({
      color: euiTheme.colors.text,
      marginBottom: euiTheme.size.base,
    }),
    [euiTheme]
  );

  const [isMembersInfoOpen, setIsMembersInfoOpen] = useState(false);
  const [isMembersAutocompleteOpen, setIsMembersAutocompleteOpen] = useState(false);
  const [isMemberConfirm, setIsMemberConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { control, watch, setError, setValue, handleSubmit, register, unregister } = useForm<FormFields>({
    defaultValues: {
      title: data?.title || '',
      description: data?.description || '',
      is_active: data ? !!data.is_active : true,
      members: members
        ? members.map(({ id, ...member }) => ({
            value: id,
            data: member,
          }))
        : [],
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'members',
  });

  const groupTitle = watch('title');
  const isActiveGroup = watch('is_active');

  const isDisabledMembers = useMemo(() => isDefault || !isActiveGroup, [isActiveGroup, isDefault]);

  useEffect(() => {
    if (isEdit) {
      register('id');
    } else {
      register('is_active');
    }

    return () => {
      if (isEdit) {
        unregister('id');
      } else {
        unregister('is_active');
      }
    };
  }, [isEdit, register, unregister]);

  useEffect(() => {
    if (data && data.id) {
      setValue('id', String(data.id));
    }
  }, [data, setValue]);

  useEffect(() => {
    dispatch(organizationActions.fetchDefaultGroupId());
  }, [dispatch]);

  const conflictMembers = useMemo(
    () =>
      fields
        .filter((field) => field.data.group_id !== defaultGroupId && field.data.group_id !== data?.id)
        .map((field) => field.data),
    [fields, data, defaultGroupId]
  );

  const handleRequest = useCallback(
    async ({ members, ...data }: FormFields) => {
      setIsLoading(true);
      try {
        const fd = {
          ...data,
          members: members.map(({ value }) => value),
        };

        const group = await onSubmit(fd);
        dispatch(
          toastActions.create({
            type: ToastVariantWithText.SUCCESS_ACTION,
            text: `Success! Group has been ${isEdit ? 'updated' : 'created'}!`,
          })
        );
        if (group) {
          onCancel();
        }
      } catch (e: any) {
        const errorResponse = e as Definitions.Error;
        if (errorResponse?.errors) {
          errorResponse.errors.forEach((error) => {
            if (control._names.mount.has(error.location as keyof FormFields)) {
              setError(error.location as keyof FormFields, error);
            } else if (error.message) {
              dispatch(
                toastActions.create({
                  type: ToastVariantWithText.ERROR_ACTION,
                  text: error.message,
                })
              );
            } else {
              dispatch(
                toastActions.create({
                  type: ToastVariantBasic.UNKNOWN_ERROR,
                })
              );
              captureException(errorResponse);
            }
          });
        } else if (errorResponse?.message) {
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.ERROR_ACTION,
              text: errorResponse.message,
            })
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [onSubmit, onCancel, dispatch, setError, isEdit, control]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchMembersAutocomplete = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      dispatch(organizationActions.fetchMembersAutocomplete({ target: e.target.value, limit: MEMBER_SEARCH_LIMIT }));
    }, 500),
    [dispatch]
  );

  const handleOpenMembersAutocomplete = useCallback(() => {
    if (!isMembersAutocompleteOpen) {
      setIsMembersAutocompleteOpen(true);
      if (autocompleteMembers.length === 0) {
        dispatch(organizationActions.fetchMembersAutocomplete({ limit: MEMBER_SEARCH_LIMIT }));
      }
    } else {
      setIsMembersAutocompleteOpen(false);
      dispatch(organizationActions.clearMembersAutoComplete());
    }
  }, [dispatch, isMembersAutocompleteOpen, autocompleteMembers]);

  const handleCloseMembersAutocomplete = useCallback(() => {
    setIsMembersAutocompleteOpen(false);
    dispatch(organizationActions.clearMembersAutoComplete());
  }, [dispatch]);

  const handleUndoAddMember = useCallback(() => {
    setIsMemberConfirm(false);
  }, []);

  const handleConfirmAddMember = useCallback(() => {
    handleSubmit(handleRequest)();
    setIsMemberConfirm(false);
  }, [handleRequest, handleSubmit]);

  const handleOpenConfirmAddMember: FormEventHandler<HTMLFormElement> = useCallback((e) => {
    e.preventDefault();
    setIsMemberConfirm(true);
  }, []);

  return (
    <>
      <EuiForm
        component="form"
        onSubmit={
          conflictMembers.length && !isDisabledMembers ? handleOpenConfirmAddMember : handleSubmit(handleRequest)
        }
      >
        <EuiFlexGrid
          columns={2}
          style={{
            rowGap: 24,
            columnGap: 60,
          }}
        >
          <div>
            <EuiTitle size="xs">
              <h2 style={titleStyles}>{t('groupForm.label.title')}*</h2>
            </EuiTitle>
            <Controller
              control={control}
              name="title"
              render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
                <EuiFormRow
                  isInvalid={!!error}
                  error={t(error?.message as string)}
                  style={{
                    marginTop: 0,
                  }}
                >
                  <EuiFieldText
                    placeholder={t(`groupForm.placeholder.${name}`)}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    isInvalid={!!error}
                    fullWidth
                    readOnly={isDefault}
                  />
                </EuiFormRow>
              )}
            />
            {isEdit && (
              <Controller
                control={control}
                name="is_active"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <EuiFormRow fullWidth isInvalid={!!error} error={t(error?.message as string)}>
                    <EuiFlexGroup alignItems="center" gutterSize="s">
                      <EuiSwitch
                        showLabel={false}
                        label={'isActive'}
                        disabled={isDefault}
                        checked={!!value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                      <EuiSpacer size="m" />
                      {value ? (
                        <EuiIcon type="lockOpen" color={euiTheme.colors.primaryText} />
                      ) : (
                        <EuiIcon type="lock" color={euiTheme.colors.subduedText} />
                      )}
                      {value ? (
                        <EuiText color={euiTheme.colors.primaryText}>
                          <b>Active</b>
                        </EuiText>
                      ) : (
                        <EuiText color="subdued">
                          <b>Disabled</b>
                        </EuiText>
                      )}
                    </EuiFlexGroup>
                  </EuiFormRow>
                )}
              />
            )}
          </div>
          <div>
            <EuiTitle size="xs">
              <h2 style={titleStyles}>{t('groupForm.label.description')}</h2>
            </EuiTitle>
            <Controller
              control={control}
              name="description"
              render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
                <EuiFormRow
                  fullWidth
                  isInvalid={!!error}
                  error={t(error?.message as string)}
                  style={{
                    marginTop: 0,
                  }}
                >
                  <EuiTextArea
                    placeholder={t(`groupForm.placeholder.${name}`)}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    isInvalid={!!error}
                    fullWidth
                    rows={10}
                    readOnly={isDefault}
                  />
                </EuiFormRow>
              )}
            />
          </div>
        </EuiFlexGrid>
        <EuiSpacer size="xxl" />
        <EuiHorizontalRule margin="xl" />
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexGroup>
            <EuiTitle size="xs">
              <h2 style={{ ...titleStyles, marginBottom: 0 }}>{t('groupForm.label.members')}</h2>
            </EuiTitle>
            <EuiPopover
              button={
                <EuiButtonIcon
                  size="s"
                  onClick={() => setIsMembersInfoOpen(!isMembersInfoOpen)}
                  iconType="iInCircle"
                  aria-label="About members"
                />
              }
              isOpen={isMembersInfoOpen}
              closePopover={() => setIsMembersInfoOpen(false)}
              anchorPosition="rightCenter"
            >
              <EuiText style={{ maxWidth: 270 }}>
                <p>{t('groupForm.placeholder.members')}</p>
              </EuiText>
              <EuiHorizontalRule margin="m" />
              <EuiButton fullWidth onClick={() => setIsMembersInfoOpen(false)}>
                OK
              </EuiButton>
            </EuiPopover>
          </EuiFlexGroup>

          <EuiFlexItem grow={0}>
            <EuiPopover
              button={
                <EuiButton
                  size="s"
                  iconSide="right"
                  iconType="arrowDown"
                  disabled={isDisabledMembers}
                  onClick={handleOpenMembersAutocomplete}
                >
                  {t('button.addMember')}
                </EuiButton>
              }
              panelPaddingSize="none"
              isOpen={isMembersAutocompleteOpen}
              closePopover={handleCloseMembersAutocomplete}
              anchorPosition="downLeft"
              offset={20}
            >
              <div
                style={{
                  width: 300,
                }}
              >
                <EuiFieldSearch
                  placeholder="Search"
                  isLoading={isLoadingAutocompleteMembers}
                  onChange={handleSearchMembersAutocomplete}
                  isClearable={false}
                  autoFocus
                />
                {isLoadingAutocompleteMembers && autocompleteMembers.length === 0 && (
                  <>
                    <EuiLoadingContent lines={5} />
                    <EuiSpacer size="xs" />
                    <EuiLoadingContent lines={4} />
                    <EuiSpacer size="xs" />
                    <EuiLoadingContent lines={5} />
                  </>
                )}
                {autocompleteMembers.length ? (
                  autocompleteMembers.map(({ id: memberId, ...member }) => {
                    const isChecked = !!memberId && fields.map((field) => field.value).includes(memberId);
                    const indexChecked = fields.findIndex((field) => field.value === memberId);
                    return (
                      <EuiFlexGroup
                        style={{
                          paddingInline: 16,
                          paddingBlock: 6,
                          borderBottom: `1px solid ${euiTheme.border.color}`,
                        }}
                        alignItems="flexStart"
                        gutterSize="xs"
                        key={`member-autocomplete-${memberId}`}
                      >
                        <EuiCheckbox
                          id={`checkbox-${memberId}`}
                          onChange={() => {
                            if (!isChecked) {
                              if (memberId) {
                                append({
                                  value: memberId,
                                  data: member,
                                });
                              }
                            } else if (indexChecked !== -1) {
                              remove(indexChecked);
                            }
                          }}
                          checked={isChecked}
                        />
                        <EuiText
                          style={{
                            wordBreak: 'break-all',
                          }}
                          size="s"
                        >
                          {member.nickname || member.login}
                        </EuiText>
                        <EuiBadge color="default">{member.group_title}</EuiBadge>
                      </EuiFlexGroup>
                    );
                  })
                ) : isLoadingAutocompleteMembers ? null : (
                  <EuiFlexGroup
                    style={{
                      paddingInline: 16,
                      paddingBlock: 6,
                      borderBottom: `1px solid ${euiTheme.border.color}`,
                    }}
                    gutterSize="xs"
                    direction="column"
                  >
                    <EuiSpacer size="l" />
                    <EuiText size="s" color="subdued">
                      {t('emptyData.title')}
                    </EuiText>
                    <EuiSpacer size="l" />
                  </EuiFlexGroup>
                )}
              </div>
            </EuiPopover>
          </EuiFlexItem>
        </EuiFlexGroup>
        {isActiveGroup && fields.length > 0 && (
          <>
            <EuiSpacer size="xl" />
            {fields.map((field, index) => (
              <MemberField
                disabled={isDisabledMembers}
                key={field.id}
                data={field.data}
                index={index}
                onRemove={remove}
              />
            ))}
          </>
        )}
        {!isActiveGroup || fields.length === 0 ? (
          <>
            <EuiSpacer size="xxl" />
            <EuiSpacer size="xl" />
            <EuiHorizontalRule margin="none" />
            <EuiSpacer size="xl" />
          </>
        ) : (
          <>
            <EuiSpacer size="xxl" />
            <EuiSpacer size="xl" />
            <EuiSpacer size="xl" />
          </>
        )}

        <EuiFlexGroup justifyContent="flexEnd">
          <EuiButtonEmpty onClick={onCancel} color="text">
            {t('button.cancel')}
          </EuiButtonEmpty>
          <EuiButton type="submit" disabled={isDefault} fill isLoading={isLoading}>
            {t(`button.${isEdit ? 'saveChanges' : 'createGroup'}`)}
          </EuiButton>
        </EuiFlexGroup>
      </EuiForm>
      {isMemberConfirm && (
        <ConfirmAddMember
          groupTitle={groupTitle || 'N/A'}
          members={conflictMembers}
          onClose={handleUndoAddMember}
          onConfirm={handleConfirmAddMember}
        />
      )}
    </>
  );
});
