import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useLinkClickHandler } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store';
import { Routes } from '@/routes';
import { profilerActions, profilerSelectors } from '@/store/profiler';

import { SearchFields } from './types';

interface useLeakParams {
  handleSubmit: UseFormHandleSubmit<SearchFields>;
}

export const useLeak = ({ handleSubmit }: useLeakParams) => {
  const dispatch = useAppDispatch();
  const leak = useAppSelector(profilerSelectors.getLeak);
  const [paramsEq, setParamsEq] = useState<SearchFields | null>(null);
  const [paramsNotEq, setParamsNotEq] = useState<SearchFields | null>(null);

  const hrefLeak = useMemo(() => {
    if (paramsEq) {
      const urlParams = new URLSearchParams(Object.entries(paramsEq).filter((item) => item[1]));
      return `${Routes.PROFILER}?${urlParams.toString()}`;
    }
  }, [paramsEq]);

  const hrefNotLeak = useMemo(() => {
    if (paramsNotEq) {
      const urlParams = new URLSearchParams(Object.entries(paramsNotEq).filter((item) => item[1]));
      return `${Routes.PROFILER}?${urlParams.toString()}`;
    }
  }, [paramsNotEq]);

  const handleClose = useCallback(() => {
    dispatch(profilerActions.showLeak(null));
  }, [dispatch]);

  const handleOpen = useCallback(
    (leak: Definitions.Leak) => {
      dispatch(profilerActions.showLeak(leak));
    },
    [dispatch]
  );

  const handleShowAllData = useLinkClickHandler(hrefLeak as string);
  const handleShowNotData = useLinkClickHandler(hrefNotLeak as string);

  useEffect(() => {
    if (leak && leak.title) {
      handleSubmit((data: SearchFields) => {
        data.offset = 0;
        data.query = `leak.title.eq: "${leak?.title?.replace(/"/gi, '"')}"`;
        setParamsEq(data);
      })();

      handleSubmit((data: SearchFields) => {
        data.offset = 0;
        if (data.query) {
          data.query = `${data.query} and leak.title.not_eq: "${leak?.title?.replace(/"/gi, '"')}"`;
        } else {
          data.query = `leak.title.not_eq: "${leak?.title?.replace(/"/gi, '"')}"`;
        }

        setParamsNotEq(data);
      })();
    }
  }, [handleSubmit, leak]);

  return { leak, hrefLeak, hrefNotLeak, handleOpen, handleClose, handleShowAllData, handleShowNotData };
};
