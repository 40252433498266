import { Languages } from './types';

export const languages: Record<
  Languages,
  {
    name: string;
  }
> = {
  [Languages.EN]: {
    name: 'English',
  },
  [Languages.UK]: {
    name: 'Українська',
  },
};
