import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmGenerateTokenProps {
  onClose: () => void;
  onConfirm: () => void;
  tokenType: 'api' | 'telegram';
}
export const ConfirmGenerateToken: FC<ConfirmGenerateTokenProps> = ({ onClose, onConfirm, tokenType }) => {
  const { t } = useTranslation();
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t('modals.confirmGenerateToken.title', { tokenType: t(`modals.tokenType.${tokenType}`) })}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <p>{t('modals.confirmGenerateToken.note', { tokenType: t(`modals.tokenType.${tokenType}`) })}</p>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty color="text" onClick={onClose}>
          {t('button.cancel')}
        </EuiButtonEmpty>
        <EuiButton onClick={onConfirm} fill color="warning">
          {t('button.confirm')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
