import { useCallback, useMemo, useState } from 'react';
import { EuiDataGridOnColumnResizeHandler } from '@elastic/eui';

import { organizationSelectors } from '@/store/organization';
import { useAppSelector } from '@/store';

import { columnsConfig } from './constants';
import { getRowData } from './helper';
import { Columns } from './types';

const getColumnsConfig = () =>
  columnsConfig.map((column) => {
    let initialWidth = Number(localStorage.getItem(`${column.id}_column_width_groups`));
    if (initialWidth === 0 && column.initialWidth) {
      initialWidth = column.initialWidth;
    }

    return {
      ...column,
      initialWidth: initialWidth !== 0 ? initialWidth : undefined,
    };
  });

export const useData = () => {
  const [columns, setColumns] = useState(() => getColumnsConfig());

  const [visibleColumns, setVisibleColumns] = useState<string[]>([
    Columns.title,
    Columns.description,
    Columns.total_members,
    Columns.status,
  ]);

  const items = useAppSelector(organizationSelectors.getGroups);
  const totalItems = useAppSelector(organizationSelectors.getTotalGroups);
  const isLoading = useAppSelector(organizationSelectors.getLoadingGroups);

  const rawData = useMemo(() => getRowData(items || []), [items]);

  const handleColumnResize: EuiDataGridOnColumnResizeHandler = useCallback((event) => {
    localStorage.setItem(`${event.columnId}_column_width_groups`, event.width.toString());
    setColumns(getColumnsConfig());
  }, []);

  return {
    columns,
    rawData,
    totalItems,
    isLoading,
    visibleColumns,
    setVisibleColumns,
    handleColumnResize,
  };
};
