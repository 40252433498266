/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Navigate, RouteObject } from 'react-router-dom';

import {
  CreateAccount,
  CreateGroup,
  EditAccount,
  OrganizationStatistic,
  EditGroup,
  Organization,
} from '@/pages/Account';
import { RoleRequired } from '@/components';
import { Roles } from '@/hooks';

import { OrganizationRoutes, Routes } from './types';

export const organizationRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to={OrganizationRoutes.ACTIVE_USERS} replace />,
  },
  {
    path: OrganizationRoutes.ACTIVE_USERS,
    element: <Organization tab="active-users-tab" />,
  },
  {
    path: OrganizationRoutes.DISABLED_USERS,
    element: <Organization tab="disabled-users-tab" />,
  },
  {
    path: OrganizationRoutes.GROUPS,
    element: <Organization tab="groups-tab" />,
  },
  {
    path: OrganizationRoutes.ACTIONS,
    element: <Organization tab="actions-tab" />,
  },
  {
    path: OrganizationRoutes.CERATE_ACCOUNT,
    element: <CreateAccount />,
  },
  {
    path: OrganizationRoutes.EDIT_ACCOUNT + '/:id',
    element: <EditAccount />,
  },
  {
    path: OrganizationRoutes.CREATE_GROUP,
    element: <CreateGroup />,
  },
  {
    path: OrganizationRoutes.EDIT_GROUP + '/:id',
    element: <EditGroup />,
  },
  {
    path: OrganizationRoutes.STATISTIC,
    element: <RoleRequired roles={[Roles.SUPER_ADMIN, Roles.SYSTEM]} fallback={<Navigate to={Routes.HOME} />} />,
    children: [
      {
        path: '',
        element: <OrganizationStatistic />,
      },
    ],
  },
];
