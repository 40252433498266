import moment, { Moment } from 'moment';

import { LimitPoints, Period, Step } from './types';

export const getPointsLimit = (
  data: Definitions.RequestsMetric,
  type: Period,
  from: string,
  to: string
): LimitPoints => {
  let startPoint;
  let endPoint;
  switch (type) {
    case 'week': {
      startPoint = moment().startOf('isoWeek');
      endPoint = moment().endOf('isoWeek');
      break;
    }
    case 'month': {
      startPoint = moment().startOf('month');
      endPoint = moment().endOf('month');
      break;
    }
    case 'year': {
      startPoint = moment().startOf('year');
      endPoint = moment().endOf('year');
      break;
    }
    default: {
      const startMinDate = from ? Date.parse(from) : new Date().getTime();
      const minDate = Object.values(data).reduce((prev, curr) => {
        const mappedDay = curr
          ? curr.reduce((p, c) => {
              const day = Date.parse(c.day as string);
              return p < day ? p : day;
            }, startMinDate)
          : startMinDate;

        return prev < mappedDay ? prev : mappedDay;
      }, startMinDate);

      const startMaxDate = to ? Date.parse(to) : new Date().getTime();
      const maxDate = Object.values(data).reduce((prev, curr) => {
        const mappedDay = curr
          ? curr.reduce((p, c) => {
              const day = Date.parse(c.day as string);
              return p > day ? p : day;
            }, startMaxDate)
          : startMaxDate;

        return prev > mappedDay ? prev : mappedDay;
      }, startMaxDate);

      startPoint = moment(minDate);
      endPoint = moment(maxDate);

      const diff = endPoint.diff(startPoint, 'days');
      let step;
      if (diff <= 62) {
        step = 'day';
      } else if (diff < 365 * 3) {
        step = 'month';
      } else {
        step = 'year';
      }
      switch (step) {
        case 'month':
          startPoint = startPoint.startOf('month');
          endPoint = endPoint.endOf('month');
          break;
        case 'year':
          startPoint = startPoint.startOf('year');
          endPoint = endPoint.endOf('year');
          break;
      }
    }
  }

  return {
    start: startPoint,
    end: endPoint,
  };
};

export const getPointsConfig = (
  type: Period,
  limitPoints: LimitPoints
): {
  format: string;
  step: Step;
} => {
  let format;
  let step: Step;

  switch (type) {
    case 'week':
      format = 'ddd.';
      step = 'day';
      break;
    case 'month':
      format = 'DD MMM.';
      step = 'day';
      break;
    case 'year':
      format = 'MMM.';
      step = 'month';
      break;
    default: {
      const diff = limitPoints.end.diff(limitPoints.start, 'days');

      if (diff <= 62) {
        format = 'DD MMM. YYYY';
        step = 'day';
      } else if (diff < 365 * 3) {
        format = 'MMM. YYYY';
        step = 'month';
      } else {
        format = 'YYYY';
        step = 'year';
      }
      break;
    }
  }

  return { format, step };
};

const getDateNumber = (step: Step, type: Period, date: Moment, startPoint: Moment) => {
  switch (type) {
    case 'week':
      return date.isoWeekday();
    case 'month':
      return date.date();
    case 'year':
      return date.month();
    default:
      switch (step) {
        case 'day':
          return date.diff(startPoint, 'days');
        case 'month':
          return date.diff(startPoint, 'months');
        case 'year':
          return date.diff(startPoint, 'years');
      }
  }
};

export const getPointsData = (
  data: Definitions.RequestCounter[],
  step: Step,
  type: Period,
  points: LimitPoints
): {
  count: number;
  day: number;
}[] => {
  const existsPeriod = new Map<number, number>();
  data.forEach(({ day, count }) => {
    const existPeriod = getDateNumber(step, type, moment(day as string), points.start);

    const grouped = existsPeriod.get(existPeriod);
    if (grouped) {
      existsPeriod.set(existPeriod, grouped + (count as number));
    } else {
      existsPeriod.set(existPeriod, count as number);
    }
  });

  const zeroStart = getDateNumber(step, type, points.start, points.start);
  const zeroEnd = getDateNumber(step, type, points.end, points.start);

  for (let i = zeroStart; i <= zeroEnd; i++) {
    if (!existsPeriod.get(i)) {
      existsPeriod.set(i, 0);
    }
  }

  const newData = Array.from(existsPeriod.entries())
    .map(([day, count]) => ({
      count,
      day,
    }))
    .sort((a, b) => (a.day < b.day ? -1 : a.day > b.day ? 1 : 0));

  return newData;
};

export const getDateFromPeriod = (index: number, period: Period, step: Step, startPoint: Moment) => {
  switch (period) {
    case 'week':
      return moment().isoWeekday(index);
    case 'month':
      return moment().date(index);
    case 'year':
      return moment().month(index);
    default: {
      const startDate = startPoint.clone();
      switch (step) {
        case 'day':
          return startDate.add(index, 'days');
        case 'month':
          return startDate.clone().add(index, 'months');
        case 'year':
          return startDate.clone().add(index, 'years');
      }
    }
  }
};
