import { useHref, useLinkClickHandler } from 'react-router-dom';
import { EuiLink } from '@elastic/eui';

import { Routes } from '@/routes';

interface RecursiveTaskLinkProps {
  id: string;
  title: string;
}

export const RecursiveTaskLink = ({ id, title }: RecursiveTaskLinkProps) => {
  const url = useHref({
    pathname: `${Routes.RECURSIVE_SEARCH}/${id}`,
  });

  const handleClick = useLinkClickHandler(url);

  return (
    <EuiLink href={url.toString()} onClick={handleClick}>
      {title}
    </EuiLink>
  );
};
