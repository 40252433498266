/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Control, ControlOptions, ControlPosition, DomUtil, Map } from 'leaflet';

import { CUSTOM_CONTAINERS } from '../CustomControlContainers';

interface SearchFieldControlOptions extends ControlOptions {
  title?: string;
  initialActive?: boolean;
}

const INITIAL_OPTIONS: SearchFieldControlOptions = {
  position: 'topleft',
  title: 'Search',
};

const ELEMENT_CLASS_NAME = 'leaflet-control-search-field';

export class SearchFieldControl extends Control {
  constructor(options?: SearchFieldControlOptions) {
    super({ ...INITIAL_OPTIONS, ...options });
    this.options = { ...INITIAL_OPTIONS, ...options };
  }

  options: SearchFieldControlOptions = INITIAL_OPTIONS;
  container: HTMLElement | null = null;
  button: HTMLElement | undefined;
  private _map!: Map;

  onAdd(map: Map): HTMLElement {
    const button = map.getCustomControlContainer(
      this.options.position as ControlPosition,
      CUSTOM_CONTAINERS.SEARCH
    ) as HTMLElement;

    this.container = DomUtil.create('div', `${ELEMENT_CLASS_NAME}__content`);

    const mapControlContainer = (this._map as any)._controlContainer as HTMLElement;
    if (mapControlContainer) {
      mapControlContainer.appendChild(this.container);
    }

    this.button = button;

    return button;
  }

  getMap() {
    return this._map as Map;
  }
}

export const searchFieldControl = (options?: SearchFieldControlOptions) => new SearchFieldControl(options);
