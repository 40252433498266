import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const getLoadedProfiler = (state: RootState) => state.map.isLoadedProfiler;
export const getLoadedLandmarks = (state: RootState) => state.map.isLoadedLandmarks;
export const getLoadedLeaks = (state: RootState) => state.map.isLoadedLeaks;

export const getProfilers = (state: RootState) => state.map.profiler;
export const getLandmarks = (state: RootState) => state.map.landmarks;
export const getLeaks = (state: RootState) => state.map.leaks;

export const getError = (state: RootState) => state.map.error;
export const getSearchParams = (state: RootState) => state.map.searchParams;

const getCards = (state: RootState) => state.map.cards;
const getCardId = (_: RootState, itemId: string) => itemId;

const getLeaksCards = (state: RootState) => state.map.cardsOfLeak;
const getLeaksCredentialCounters = (state: RootState) => state.map.leakCounters;

export const getCardById = createSelector([getCards, getCardId], (items, itemId) => items[itemId]);
export const getLandmarksById = createSelector([getLandmarks, getCardId], (items, itemId) => ({
  ...items.get(itemId),
  id: itemId,
}));
export const getLeaksCardById = createSelector([getLeaksCards, getCardId], (items, itemId) => items[itemId]);
export const getLeaksCredentialCounterById = createSelector(
  [getLeaksCredentialCounters, getCardId],
  (items, itemId) => items[itemId]
);

export const getFilters = (state: RootState) => state.map.filters;

//GEO

export const getGeoItems = (state: RootState) => state.map.geoItems;
export const getGeoQuery = (state: RootState) => state.map.savedGeoQuery;
