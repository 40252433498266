import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { EuiPage, EuiPageTemplate } from '@elastic/eui';

import { Header } from '../Header';
import { PageLoader } from '../Core';

export const BaseLayout: FC = () => {
  return (
    <EuiPageTemplate grow minHeight="100%">
      <EuiPage direction="column" grow>
        <Header />
        <EuiPage direction="column" grow paddingSize="none">
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </EuiPage>
      </EuiPage>
    </EuiPageTemplate>
  );
};
