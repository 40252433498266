import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import L, { Icon, LatLngExpression, MarkerClusterGroupOptions, PointExpression } from 'leaflet';
import { FunctionComponent, useEffect, useRef } from 'react';

import Cell_tower from '@/assets/icons/markers/Cell_tower.svg';
import Cell_tower_light from '@/assets/icons/markers/Cell_tower_light.svg';
import Civil_airport from '@/assets/icons/markers/Civil_airport.svg';
import Civil_airport_light from '@/assets/icons/markers/Civil_airport_light.svg';
import Compromised_machine from '@/assets/icons/markers/Compromised_machine.svg';
import Compromised_machine_light from '@/assets/icons/markers/Compromised_machine_light.svg';
import Military_airport from '@/assets/icons/markers/Military_airport.svg';
import Military_airport_light from '@/assets/icons/markers/Military_airport_light.svg';
import Military_town from '@/assets/icons/markers/Military_town.svg';
import Military_town_light from '@/assets/icons/markers/Military_town_light.svg';
import Military_unit from '@/assets/icons/markers/Military_unit.svg';
import Military_unit_light from '@/assets/icons/markers/Military_unit_light.svg';
import Organization from '@/assets/icons/markers/Organization.svg';
import Organization_light from '@/assets/icons/markers/Organization_light.svg';
import People from '@/assets/icons/markers/People.svg';
import People_light from '@/assets/icons/markers/People_light.svg';
import Plant from '@/assets/icons/markers/Plant.svg';
import Plant_light from '@/assets/icons/markers/Plant_light.svg';
import Launcher from '@/assets/icons/markers/Launcher.svg';
import Launcher_light from '@/assets/icons/markers/Launcher_light.svg';

import { useMap } from './MapContainer';
import { LayerTheme } from './types';

export type MarketClusterPoint = {
  id: string;
  position: LatLngExpression;
  type: string;
  rest?: Omit<Definitions.LeakLocation, 'location'>;
  leafletId?: number;
  clusterCount?: number;
};

interface MarkerClusterProps extends MarkerClusterGroupOptions {
  points: MarketClusterPoint[];
  onPointsSelect: (points: MarketClusterPoint[]) => void;
}

const ICON_SIZES: PointExpression = [32, 32];

const MARKER_ICONS: { [key: string]: { [key in LayerTheme]: string } } = {
  military_airport: {
    light: Military_airport_light,
    dark: Military_airport,
  },
  military_unit: {
    light: Military_unit_light,
    dark: Military_unit,
  },
  military_town: {
    light: Military_town_light,
    dark: Military_town,
  },
  civil_airport: {
    light: Civil_airport_light,
    dark: Civil_airport,
  },
  cell_tower: {
    light: Cell_tower_light,
    dark: Cell_tower,
  },
  people: {
    light: People_light,
    dark: People,
  },
  compromised_machine: {
    light: Compromised_machine_light,
    dark: Compromised_machine,
  },
  organization: {
    light: Organization_light,
    dark: Organization,
  },
  plant: {
    light: Plant_light,
    dark: Plant,
  },
  launcher: {
    light: Launcher_light,
    dark: Launcher,
  },
};

export const getIcon = (type: string, layerTheme: LayerTheme): Icon | undefined => {
  const iconUrl = MARKER_ICONS[type]?.[layerTheme];
  if (iconUrl) {
    return L.icon({
      iconUrl,
      iconSize: ICON_SIZES,
    });
  }

  return;
};

export const MarkerCluster: FunctionComponent<MarkerClusterProps> = ({ points, onPointsSelect, ...options }) => {
  const { map, layerTheme } = useMap();

  const cluster = useRef(L.markerClusterGroup({ ...options, chunkedLoading: true }));

  useEffect(() => {
    map.removeLayer(cluster.current);
    if (points.length) {
      cluster.current = L.markerClusterGroup({ ...options, chunkedLoading: true });

      cluster.current
        .addLayers(
          points.map((point) => {
            const marker = L.marker(point.position, {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              point,
              icon: getIcon(point.type, layerTheme),
            });
            const leafletId = cluster.current.getLayerId(marker);
            marker.on('click', () => {
              onPointsSelect([{ ...point, leafletId }]);
            });

            return marker;
          })
        )
        .on('clusterclick', (event) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          if (event.target._zoom === event.target._maxZoom) {
            const markers = event.layer.getAllChildMarkers();

            const clusterCount = markers.length;
            if (clusterCount) {
              const id = cluster.current.getLayerId(event.layer);
              onPointsSelect(
                markers.map(
                  (marker: any) => ({ ...marker.options.point, leafletId: id, clusterCount } as MarketClusterPoint)
                )
              );
            }
          }
        });
      map.addLayer(cluster.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, points, onPointsSelect, layerTheme]);

  return null;
};
