import { FunctionComponent, ReactElement, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { EuiButtonIcon, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';

import { useMap } from '../MapContainer';

import { searchFieldControl } from './SearchFieldControl';
import { ReactComponent as Icon } from './icon.svg';
import './index.scss';

interface SearchFieldProps {
  children: ReactElement;
  isActive: boolean;
  toggleActive: () => void;
}

export const SearchField: FunctionComponent<SearchFieldProps> = ({ children, isActive, toggleActive }) => {
  const { map } = useMap();
  const { euiTheme } = useEuiTheme();
  const ctr = useRef(searchFieldControl());

  useEffect(() => {
    const control = ctr.current;
    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map]);

  const renderChildren = () => {
    if (isActive && ctr.current.container) {
      return createPortal(children, ctr.current.container);
    }

    return null;
  };

  if (ctr.current.button) {
    return (
      <>
        {createPortal(
          !isActive ? (
            <EuiButtonIcon
              className={css(`
              background-color: ${euiTheme.colors.emptyShade};
            `)}
              display="base"
              color="text"
              size="s"
              iconType={Icon}
              onClick={toggleActive}
              onDoubleClickCapture={(e: any) => e.stopPropagation()}
              onDoubleClick={(e: any) => e.stopPropagation()}
            />
          ) : null,
          ctr.current.button
        )}
        {renderChildren()}
      </>
    );
  }

  return null;
};
