// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import rdmd from 'moyarenko-markdown';
import { useCallback, useEffect, useState } from 'react';

export interface MenuLink {
  anchor: string;
  children?: MenuLink[];
}

export const useMarkdonwData = (document: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState<string>('');

  const bootstrap = useCallback(async () => {
    const response = await fetch(document);
    const text = await response.text();

    setContent(text);
    setIsLoading(false);
  }, [document]);

  useEffect(() => {
    bootstrap();
  }, [bootstrap]);

  return {
    content: rdmd(content, {
      copyButtons: false,
    }),
    isLoading,
  };
};
