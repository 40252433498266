import {
  EuiBadge,
  EuiButtonIcon,
  EuiDataGrid,
  EuiDataGridCellValueElementProps,
  EuiFlexGroup,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiTablePagination,
  EuiText,
  EuiToolTip,
  useEuiTheme,
} from '@elastic/eui';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { organizationActions } from '@/store/organization';
import { useAppDispatch, useAppSelector } from '@/store';
import { OrganizationRoutes, Routes } from '@/routes';
import { customerSelectors } from '@/store/customer';
import { Apps, Roles, useRole, useWindowSize } from '@/hooks';
import { ReactComponent as ProfilerIcon } from '@/assets/icons/Subtract.svg';
import { ReactComponent as PasswordFinderIcon } from '@/assets/icons/Union.svg';
import { ReactComponent as PhonebookIcon } from '@/assets/icons/Phone.svg';
import { ReactComponent as DatasetsIcon } from '@/assets/icons/Pie.svg';
import { ReactComponent as RecursiveSearchIcon } from '@/assets/icons/Research.svg';
import { ThemeExtensions } from '../ThemeProvider';
import { Loader } from '../Loader';
import { EmptyData } from '../Core';

import { STATUS_USER, useData } from './useData';
import { CONFIG_ITEMS_PER_PAGE, DEFAULT_ITEMS_PER_PAGE } from './constants';
import { Columns } from './types';

type FormsFields = Paths.GetOrganizationMembers.QueryParameters;

interface CustomersTableProps {
  status: STATUS_USER;
}

const getRolesWhatIsCanEditByRole = (role: Roles): Roles[] => {
  switch (role) {
    case Roles.SYSTEM:
      return [Roles.USER, Roles.ADMIN];
    case Roles.SUPER_ADMIN:
      return [Roles.USER, Roles.ADMIN];
    case Roles.ADMIN:
      return [Roles.USER];
    case Roles.USER:
    default:
      return [];
  }
};

export const CustomersTable = memo<CustomersTableProps>(function CustomersTable({ status }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentUserId = useAppSelector(customerSelectors.getInfo)?.id;
  const { role: currentUserRole } = useRole([Roles.SYSTEM]);

  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues = useMemo<FormsFields>(() => {
    let limit = Number(searchParams.get('limit') ?? DEFAULT_ITEMS_PER_PAGE);
    let offset = Number(searchParams.get('offset') ?? 0);
    if (!CONFIG_ITEMS_PER_PAGE.includes(limit)) {
      limit = DEFAULT_ITEMS_PER_PAGE;
    }
    offset = offset - (offset % limit);
    return {
      limit,
      offset,
      status,
    };
  }, [searchParams, status]);

  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme<ThemeExtensions>();
  const { height } = useWindowSize();

  const { columns, rawData, totalItems, isLoading, visibleColumns, setVisibleColumns, handleColumnResize } = useData({
    status,
  });

  const currentUser = useAppSelector(customerSelectors.getInfo);

  const gridRef = useRef(null);

  const { handleSubmit, setValue, watch, reset } = useForm<FormsFields & { limit: number }>({
    defaultValues,
  });

  const { limit, offset } = watch();

  const activePage = useMemo(() => (offset && limit ? offset / limit : 0), [limit, offset]);

  const handleSetSearchParams = useCallback(
    (data: FormsFields) => {
      const params = new URLSearchParams(Object.entries(data).filter((item) => item[1]));

      setSearchParams(params);
    },
    [setSearchParams]
  );

  const handleRequest = useCallback(
    (data: FormsFields) => {
      dispatch(organizationActions.fetchMembers(data));
    },
    [dispatch]
  );

  const handleChangeItemsPerPage = useCallback(
    (limit: number) => {
      setValue('limit', limit);
      setValue('offset', 0);
      handleSubmit(handleSetSearchParams)();
    },
    [setValue, handleSubmit, handleSetSearchParams]
  );

  const handleChangePage = useCallback(
    (indexPage: number) => {
      setValue('offset', indexPage * (limit ?? 0));
      handleSubmit(handleSetSearchParams)();
    },
    [limit, setValue, handleSubmit, handleSetSearchParams]
  );

  useEffect(() => {
    handleRequest(defaultValues);
    reset(defaultValues);
  }, [defaultValues, handleRequest, reset]);

  const getColorRole = useCallback(
    (role: Roles) => {
      switch (role) {
        case Roles.SUPER_ADMIN:
          return euiTheme.colors.dangerText;
        case Roles.ADMIN:
          return euiTheme.colors.warningText;
        case Roles.USER:
          return euiTheme.colors.primaryText;
        default:
          return euiTheme.colors.text;
      }
    },
    [euiTheme]
  );

  const checkEditUser = useCallback(
    (userRole: Roles, userId: number) =>
      getRolesWhatIsCanEditByRole(currentUserRole).includes(userRole) || (!!currentUserId && currentUserId === userId),
    [currentUserRole, currentUserId]
  );

  const RenderCellValue = useCallback(
    ({ rowIndex, columnId }: EuiDataGridCellValueElementProps) => {
      const disabledColor = rawData[rowIndex].status ? undefined : euiTheme.colors.disabledText;
      switch (columnId) {
        case Columns.last_activities:
          return (
            <EuiFlexGroup alignItems="center" gutterSize="xs" wrap>
              <EuiText size="s" color={disabledColor}>
                {rawData[rowIndex][columnId as Columns]
                  ? moment
                      .parseZone(rawData[rowIndex][columnId as Columns] as string)
                      .format(process.env.REACT_APP_DATE_FORMAT)
                  : ''}
              </EuiText>
              <EuiText size="xs" color={disabledColor || 'subdued'}>
                {rawData[rowIndex][columnId as Columns]
                  ? moment.parseZone(rawData[rowIndex][columnId as Columns] as string).format('LT')
                  : ''}
              </EuiText>
            </EuiFlexGroup>
          );
        case Columns.exp_date:
        case Columns.created_at:
          return (
            <EuiText size="s" color={disabledColor}>
              {rawData[rowIndex][columnId as Columns]
                ? moment
                    .parseZone(rawData[rowIndex][columnId as Columns] as string)
                    .format(process.env.REACT_APP_DATE_FORMAT)
                : ''}
            </EuiText>
          );
        case Columns.role:
          return (
            <EuiText size="s" color={disabledColor || getColorRole(rawData[rowIndex].role as Roles)}>
              <span
                style={{
                  fontWeight: 500,
                }}
              >
                {t(`roles.${rawData[rowIndex].role}`)}
              </span>
            </EuiText>
          );
        case Columns.group:
          return (
            <EuiBadge isDisabled={!!disabledColor} color="default">
              {rawData[rowIndex].group}
            </EuiBadge>
          );
        case Columns.nickname:
          return (
            <EuiText size="s" color={disabledColor}>
              <b>{rawData[rowIndex][columnId as Columns] || 'N/A'}</b>
              {!!currentUser && currentUser.id === rawData[rowIndex].rest.id && (
                <div
                  style={{
                    borderRadius: 6,
                    fontSize: 10,
                    lineHeight: 1.2,
                    padding: '2px 6px',
                    backgroundColor: 'rgba(0, 191, 179, 0.2)',
                    color: euiTheme.colors.text,
                    display: 'inline-block',
                    marginLeft: 6,
                  }}
                  color="success"
                >
                  {t('badges.you')}
                </div>
              )}
            </EuiText>
          );
        case Columns.status:
          return (
            <EuiFlexGroup gutterSize="s">
              {rawData[rowIndex].status ? <EuiIcon type="lockOpen" /> : <EuiIcon type="lock" color={disabledColor} />}
              <EuiText color={disabledColor} size="s">
                {rawData[rowIndex].status ? 'Active' : 'Disabled'}
              </EuiText>
            </EuiFlexGroup>
          );
        case Columns.products_permissions:
          return (
            <EuiFlexGroup gutterSize="s">
              <EuiToolTip content={t(`apps.title.${Apps.PASSWORD_FINDER}`)}>
                <EuiIcon
                  color={
                    (rawData[rowIndex].products_permissions as Definitions.ProductsPermission)?.password_finder
                      ? euiTheme.colors.tools.PASSWORD_FINDER
                      : euiTheme.colors.disabledText
                  }
                  type={PasswordFinderIcon}
                />
              </EuiToolTip>
              <EuiToolTip content={t(`apps.title.${Apps.PROFILER}`)}>
                <EuiIcon
                  color={
                    (rawData[rowIndex].products_permissions as Definitions.ProductsPermission)?.profiler
                      ? euiTheme.colors.tools.PROFILER
                      : euiTheme.colors.disabledText
                  }
                  type={ProfilerIcon}
                />
              </EuiToolTip>
              <EuiToolTip content={t(`apps.title.${Apps.DATASETS}`)}>
                <EuiIcon
                  color={
                    !!(rawData[rowIndex].products_permissions as Definitions.ProductsPermission)?.password_finder ||
                    !!(rawData[rowIndex].products_permissions as Definitions.ProductsPermission)?.profiler
                      ? euiTheme.colors.tools.DATASETS
                      : euiTheme.colors.disabledText
                  }
                  type={DatasetsIcon}
                />
              </EuiToolTip>
              <EuiToolTip content={t(`apps.title.${Apps.PHONEBOOK_PARSER}`)}>
                <EuiIcon
                  color={
                    (rawData[rowIndex].products_permissions as Definitions.ProductsPermission)?.phonebook
                      ? euiTheme.colors.tools.PHONEBOOK_PARSER
                      : euiTheme.colors.disabledText
                  }
                  type={PhonebookIcon}
                />
              </EuiToolTip>
              <EuiToolTip content={t(`apps.title.${Apps.RECURSIVE_SEARCH}`)}>
                <EuiIcon
                  color={
                    (rawData[rowIndex].products_permissions as Definitions.ProductsPermission)?.recursive_search
                      ? euiTheme.colors.tools.RECURSIVE_SEARCH
                      : euiTheme.colors.disabledText
                  }
                  type={RecursiveSearchIcon}
                />
              </EuiToolTip>
              <EuiToolTip content={t(`apps.title.${Apps.MAP_RADAR}`)}>
                <EuiIcon
                  color={
                    (rawData[rowIndex].products_permissions as Definitions.ProductsPermission)?.map_radar
                      ? euiTheme.colors.tools.MAP_RADAR
                      : euiTheme.colors.disabledText
                  }
                  type="visMapRegion"
                />
              </EuiToolTip>
            </EuiFlexGroup>
          );
        case Columns.enabled_two_fa:
          return (
            <EuiFlexGroup gutterSize="s">
              <EuiIcon
                color={
                  disabledColor
                    ? disabledColor
                    : rawData[rowIndex].enabled_two_fa
                    ? euiTheme.colors.successText
                    : euiTheme.colors.warningText
                }
                type={rawData[rowIndex].enabled_two_fa ? 'check' : 'alert'}
              />
              <EuiText color={disabledColor} size="s">
                {t(`customersTable.enabled_two_fa.${rawData[rowIndex].enabled_two_fa ? 'enabled' : 'disabled'}`)}
              </EuiText>
            </EuiFlexGroup>
          );
        default:
          return (
            <EuiText color={disabledColor} size="s">
              {rawData[rowIndex][columnId as Columns]}
            </EuiText>
          );
      }
    },
    [rawData, getColorRole, t, euiTheme, currentUser]
  );

  if (isLoading)
    return (
      <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
        <EuiSpacer size="xxl" />
        <Loader />
      </EuiFlexGroup>
    );

  if (!totalItems) return <EmptyData title={t('emptyDataDefault.title')} />;

  return (
    <EuiPanel style={{ overflow: 'hidden', width: '100%', marginTop: 24 }} hasBorder paddingSize="none">
      <EuiDataGrid
        className="EuiDataEmptyTopBorder"
        aria-labelledby=""
        style={{
          maxHeight: Math.max(height - 320, 400),
          height: Math.max(height - 320, 400),
        }}
        columns={columns}
        columnVisibility={{ visibleColumns, setVisibleColumns }}
        rowCount={rawData.length}
        renderCellValue={RenderCellValue}
        rowHeightsOptions={{ defaultHeight: 'auto' }}
        toolbarVisibility={false}
        onColumnResize={handleColumnResize}
        ref={gridRef}
        trailingControlColumns={[
          {
            id: 'actions',
            width: 50,
            headerCellRender: () => null,
            rowCellRender: ({ rowIndex }) =>
              rawData[rowIndex].rest.group_id &&
              checkEditUser(rawData[rowIndex].role as Roles, rawData[rowIndex].rest.id as number) ? (
                <>
                  <EuiButtonIcon
                    color={'text'}
                    iconType="pencil"
                    onClick={() => {
                      navigate(
                        `${Routes.ORGANIZATION}/${OrganizationRoutes.EDIT_ACCOUNT}/${rawData[rowIndex].rest.id}`
                      );
                    }}
                    aria-label="Edit"
                  />
                </>
              ) : null,
          },
        ]}
        gridStyle={{
          border: 'horizontal',
          header: 'shade',
          fontSize: 'm',
          cellPadding: 'l',
        }}
      />
      {!!totalItems && (
        <EuiTablePagination
          aria-label="Members pagination"
          pageCount={Math.ceil(totalItems / limit)}
          activePage={activePage}
          showPerPageOptions={true}
          onChangePage={handleChangePage}
          itemsPerPage={limit}
          onChangeItemsPerPage={handleChangeItemsPerPage}
          itemsPerPageOptions={CONFIG_ITEMS_PER_PAGE}
        />
      )}
    </EuiPanel>
  );
});
