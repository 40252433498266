import { RootState } from '../store';

export const getData = (state: RootState) => state.profiler.items;
export const getTotalData = (state: RootState) => state.profiler.total_items;
export const getLoading = (state: RootState) => state.profiler.loading;
export const getLoadMoreLoading = (state: RootState) => state.profiler.loadMoreLoading;
export const getError = (state: RootState) => state.profiler.error;
export const getSearchParams = (state: RootState) => state.profiler.searchParams;
export const getFilters = (state: RootState) => state.profiler.filters;
export const getLeak = (state: RootState) => state.profiler.leak;
export const getCounters = (state: RootState) => state.profiler.counters;
