import { ColumnItem, Columns } from './types';

export const getRowData = (
  items: Definitions.RecursiveSearchTask[]
): (ColumnItem & { rest: Definitions.RecursiveSearchTask })[] =>
  items.map(
    ({
      title,
      status,
      created_at,
      login,
      recursion_levels_number,
      total_credentials,
      total_identities,
      system_message,
      note,
      ...rest
    }) => ({
      [Columns.title]: title,
      [Columns.status]: status,
      [Columns.created_at]: created_at,
      [Columns.login]: login,
      [Columns.recursion_levels_number]: recursion_levels_number,
      [Columns.total_credentials]: total_credentials,
      [Columns.total_identities]: total_identities,
      [Columns.system_message]: system_message || '',
      [Columns.note]: note || '',
      rest,
    })
  );
