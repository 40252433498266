import { createAction, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { v4 as getUid } from 'uuid';

import { Toast, ToastBasic, ToastWithText } from '@/components';

export interface State {
  items: Toast[];
}

type ToastCreatePayload = Omit<ToastWithText, 'id'> | Omit<ToastBasic, 'id'>;

const create = createAction<ToastCreatePayload>('toast/create');

const remove = createAction<string>('toast/remove');

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'toast',
  initialState: {
    items: [],
  },
  reducers: {
    create: (state, { payload }: PayloadAction<ToastCreatePayload>) => {
      state.items = [...state.items, { ...payload, id: getUid() }];
    },
    remove: (state, { payload }: PayloadAction<string>) => {
      state.items = state.items.filter(({ id }) => id !== payload);
    },
  },
});

export const actions = {
  create,
  remove,
};

export default slice.reducer;
