import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const getInfo = (state: RootState) => state.customer.info;
export const getGroup = createSelector([getInfo], (user) =>
  user
    ? {
        id: user.group_id,
        title: user.group_title,
      }
    : null
);
export const getLoading = (state: RootState) => state.customer.loading;
export const getLoadingUpdatePassword = (state: RootState) => state.customer.isLoadingUpdatePassword;
export const getSubscription = (state: RootState) => state.customer.subscription;
export const getUiLimitRow = (state: RootState) => state.customer.info?.permissions?.ui_rows_limit;
//two-auth
export const getCustomerTwoAuth = (state: RootState) => !!state.customer.info?.enabled_two_fa;
export const getQrCodeTwoAuth = (state: RootState) => state.customer.twoAuthQrImage;
export const getQrCodeTwoAuthLoading = (state: RootState) => state.customer.isLoadingQr;
export const getTwoAuthLoading = (state: RootState) => state.customer.isLoadingTwoAuth;
export const getRecoveryCodes = (state: RootState) => state.customer.recoveryCodes;
export const getResetRecoveryCodesLoading = (state: RootState) => state.customer.isLoadingResetRecoveryCodes;
