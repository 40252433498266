import { EuiPageSection, EuiSpacer } from '@elastic/eui';
import { useContext } from 'react';

import { LeakProfilerFlyout, ProfilerSearchData } from '@/components';

import { SearchResultContext } from './context';
import { useLeak } from './useLeakData';

export const SearchResult = () => {
  const { isSearched, highlightValues, handleSubmit, handleLoadMore, searchBarHeight } =
    useContext(SearchResultContext);

  const { leak, hrefLeak, hrefNotLeak, handleOpen, handleClose, handleShowAllData, handleShowNotData } = useLeak({
    handleSubmit,
  });

  return (
    <EuiPageSection
      paddingSize="none"
      grow
      alignment="center"
      contentProps={{
        css: {
          width: '100%',
        },
      }}
    >
      <EuiSpacer size="s" />
      <ProfilerSearchData
        highlightValues={highlightValues}
        isSearched={isSearched}
        onLeakOpen={handleOpen}
        onLoadMore={handleLoadMore}
        searchBarHeight={searchBarHeight}
      />
      {!!leak && (
        <LeakProfilerFlyout
          hrefAllLeak={hrefLeak}
          hrefNotLeak={hrefNotLeak}
          onClose={handleClose}
          onShowAllLeak={handleShowAllData}
          onShowNoLeak={handleShowNotData}
          leak={leak}
        />
      )}
    </EuiPageSection>
  );
};
