import { LatLng, Map } from 'leaflet';
import { Control } from 'react-hook-form';
import { FormEventHandler } from 'react';

import { MoveHandler } from '@/components/MapRadar/MoveControl';

export type SearchFields = {
  query: string;
  center: string[];
  nw: string[];
  se: string[];
  zoom: string;
  types?: LandmarksTypes[];
  anw?: string[];
  ase?: string[];
  hide: string;
  location: string;
};

export const landmarksTypes = [
  'military_airport',
  'military_unit',
  'military_town',
  'civil_airport',
  'cell_tower',
  'people',
  'compromised_machine',
  'organization',
  'plant',
  'launcher',
] as const;

export type LandmarksTypes = typeof landmarksTypes[number];

export interface SearchResultContextProps {
  control: Control<SearchFields, any>;
  filters: Definitions.Filter[];
  isLoading: boolean;
  defaultValues: SearchFields;
  hightlightValues: {
    value: string;
    keys: string[];
    operator: string;
  }[];
  isDisabledSearchField: boolean;
  handleMove: MoveHandler;
  handleInitMap: (map: Map) => void;
  handleChangeLandmarkTypes: (types: LandmarksTypes[]) => void;
  handleSearch: FormEventHandler;
  handleAreaChange: (params: { nw: LatLng; se: LatLng }) => void;
  handleAreaRemove: () => void;
  handleChangeLocation: (location: string) => void;
}

export enum DataTabs {
  SEARCH_RESULTS = 'SEARCH_RESULTS',
}
