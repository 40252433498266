import { FC } from 'react';
import {
  EuiHorizontalRule,
  EuiLoadingContent,
  EuiPage,
  EuiPageBody,
  EuiPageSection,
  EuiSpacer,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useParams } from 'react-router-dom';
import { Global, css } from '@emotion/react';

import { useMarkdonwData } from '@/hooks';
import { Page404 } from '@/pages';

export const GuidesDocument: FC = () => {
  const document = useLoaderData();

  const params = useParams<{
    slug: string;
  }>();
  const { t } = useTranslation();
  const { colorMode, euiTheme } = useEuiTheme();

  const { isLoading, content } = useMarkdonwData(document);

  if (!document) {
    return (
      <>
        <EuiSpacer size="s" />
        <EuiPage paddingSize="none">
          <EuiPageBody paddingSize="none" panelled={true}>
            <Page404 />
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
  return (
    <>
      <EuiSpacer size="s" />
      <EuiPage paddingSize="none">
        <EuiPageBody paddingSize="none" panelled={true}>
          <EuiPageSection>
            {isLoading ? (
              <>
                <EuiLoadingContent lines={10} />
                <EuiSpacer />
                <EuiLoadingContent lines={10} />
                <EuiSpacer />
                <EuiLoadingContent lines={10} />
              </>
            ) : (
              <>
                <EuiTitle>
                  <h1>{t(`documentation.sideNav.${params.slug}`)}</h1>
                </EuiTitle>
                <EuiHorizontalRule size="full" margin="m" />
                <div className="markdown-body">
                  <Global
                    styles={css(
                      colorMode === 'DARK'
                        ? `
                          .markdown-body {
                            --md-code-background: ${euiTheme.colors.lightestShade};
                            --md-code-tabs: ${euiTheme.colors.lightShade};
                            --md-code-text: ${euiTheme.colors.text};
                          }
                          .markdown-body .rdmd-table {
                            --table-head: #212229;
                            --table-stripe: #1d1e24;
                            --table-edges: #343741;
                            --table-row: #25262e;
                          }
                          .markdown-body .callout.callout_okay{
                            --background: rgba(18,202,147, 0.05);
                          }
                          .markdown-body .callout.callout_info{
                            --background: rgba(17,140,253, 0.05);
                          }
                          .markdown-body .callout.callout_warn{
                            --background: rgba(247,197,67, 0.05);
                          }
                          .markdown-body .callout.callout_error{
                            --background: rgba(233,95,106, 0.05);
                          }
                          .markdown-body .callout.callout_default{
                            --background: rgba(248,248,248, 0.05);
                          }
                        `
                        : ''
                    )}
                  />
                  {content}
                </div>
              </>
            )}
          </EuiPageSection>
        </EuiPageBody>
      </EuiPage>
    </>
  );
};
