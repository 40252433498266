import * as yup from 'yup';

export type QueryType = 'email' | 'phone';

export const isValidEmail = (value: string) => {
  try {
    yup.string().required().email().validateSync(value);
    return true;
  } catch (error) {
    return false;
  }
};

export const clearPhoneNumber = (value: string) => value.replace(/\D/g, '');

export const checkPhoneNumber = (value: string): string | null => {
  const clearValue = clearPhoneNumber(value);
  if (clearValue.length < 10) {
    return null;
  }
  return clearValue;
};

export const checkPhoneOrEmail = (value: string): { query: string; type?: QueryType } => {
  let type: QueryType | undefined;
  let query = value;
  const isEmail = isValidEmail(value);
  if (isEmail) {
    type = 'email';
  } else {
    const normalizeQuery = checkPhoneNumber(value);
    if (normalizeQuery) {
      type = 'phone';
      query = normalizeQuery;
    }
  }
  return { query, type };
};
