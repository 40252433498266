export enum Columns {
  url = 'url',
  login = 'login',
  password = 'password',
  document = '_document',
  http_info = 'http_info',
  http_info_scanned_at = 'http_info.scanned_at',
  http_info_ip = 'http_info.ip',
  leak = 'leak',
  note = 'note',
  created_at = 'created_at',
  updated_at = 'updated_at',
}

export type ColumnItem = {
  [key in Columns]: string | number | undefined | null | Definitions.Leak[] | Definitions.HTTPInfo;
};

export type SortColumn = {
  id: string;
  direction: SortColumnDirection;
};

export type SortColumnDirection = 'asc' | 'desc';
