/* eslint-disable @typescript-eslint/ban-ts-comment */
import { LoaderFunctionArgs, RouteObject } from 'react-router-dom';

//@ts-ignore
import document1 from '@/i18next/documentation/v1.0/PasswordFinder/data-structure.md';
//@ts-ignore
import document2 from '@/i18next/documentation/v1.0/PasswordFinder/how-it-work.md';
//@ts-ignore
import document3 from '@/i18next/documentation/v1.0/PasswordFinder/example.md';
//@ts-ignore
import apiDoc from '@/i18next/documentation/api/password_finder_19_12_2022.yaml';
import { Api } from '@/pages';
import { GuidesDocument } from '@/components';
import { SearchResult } from '@/pages/PasswordFinder/SearchResult';

import { PasswordFinderRoutes } from './types';

export async function loader({ params }: LoaderFunctionArgs) {
  switch (params.slug as PasswordFinderRoutes) {
    case PasswordFinderRoutes.DATA_STRUCTURE:
      return document1;
    case PasswordFinderRoutes.SEARCH_CONCEPT:
      return document2;
    case PasswordFinderRoutes.EXAMPLES:
      return document3;
    default:
      return null;
  }
}

export const passwordFinder: RouteObject[] = [
  {
    path: PasswordFinderRoutes.SEARCH_RESULTS,
    element: <SearchResult />,
  },
  {
    path: PasswordFinderRoutes.API,
    loader: async () => apiDoc,
    element: <Api />,
  },
  {
    path: ':slug',
    loader,
    element: <GuidesDocument />,
  },
];
