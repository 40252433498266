import { useEuiTheme } from '@elastic/eui';

import { ReactComponent as LogoDark } from '@/assets/logo_dark.svg';
import { ReactComponent as LogoLight } from '@/assets/logo_light.svg';
export const Logo: React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
> = (props) => {
  const { colorMode } = useEuiTheme();
  return colorMode === 'DARK' ? <LogoDark {...props} /> : <LogoLight {...props} />;
};
