import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { leakApi } from '@/api';
import { RootState } from '../store';

export interface State {
  searchParams: Paths.GetDataset.QueryParameters | null;
  loading: boolean;
  items: Definitions.Dataset[];
  total_items: Definitions.TotalItems;
  counters: Definitions.DatasetCounters | null;
}

const clearSearch = createAction('dataset/clearSearch');
const clearSearchParams = createAction('dataset/clearSearchParams');

export const fetchData = createAsyncThunk(
  'dataset/fetchData',
  async (payload: Paths.GetDataset.QueryParameters, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    if (
      !!state.dataset.searchParams &&
      !Object.entries(payload).some(
        ([key, value]) =>
          state.dataset.searchParams &&
          value !== state.dataset.searchParams[key as keyof Paths.GetDataset.QueryParameters]
      )
    ) {
      return {
        items: state.dataset.items,
        total_items: state.dataset.total_items,
      };
    }
    try {
      const {
        data: { data },
      } = await leakApi.getDatasets(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchCounters = createAsyncThunk('dataset/fetchCounters', async (_, thunkApi) => {
  try {
    const {
      data: { data },
    } = await leakApi.getDatasetsCounters();
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  }
});

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'dataset',
  initialState: {
    loading: true,
    items: [],
    total_items: {},
    searchParams: null,
    counters: null,
  },
  reducers: {
    clearSearch: (state) => {
      state.items = [];
      state.total_items = {};
      state.searchParams = null;
      state.counters = null;
    },
    clearSearchParams: (state) => {
      state.searchParams = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.searchParams = action.meta.arg;

        if (action.payload) {
          const { items, total_items } = action.payload;

          state.items = items || [];
          state.total_items = total_items || {};
        }

        state.loading = false;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.searchParams = action.meta.arg;
        state.loading = false;
        state.items = [];
        state.total_items = {};
      });

    builder.addCase(fetchCounters.fulfilled, (state, action) => {
      if (action.payload) {
        state.counters = action.payload;
      }
    });
  },
});

export const actions = {
  clearSearch,
  fetchData,
  fetchCounters,
  clearSearchParams,
};

export default slice.reducer;
