import {
  EuiButton,
  EuiCommentList,
  EuiCommentProps,
  EuiConfirmModal,
  EuiDatePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiMarkdownFormat,
  EuiSelect,
  EuiSpacer,
  EuiTextArea,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import moment from 'moment';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import { logsActions } from '@/store/logs';
import { EmptyPanel } from '../Core';
import { ThemeExtensions } from '../ThemeProvider';
import { getActions, getAvatar, LOG_DATE_FORMAT } from '../UpdateLogger';
import { useAppDispatch } from '@/store';
import { toastActions } from '@/store/toast';
import { ToastVariantBasic, ToastVariantWithText } from '../Toasts';
import { Routes } from '@/routes';

import { schema, types } from './schema';

interface ReleaseNotesFormProps {
  id?: string;
  data?: Definitions.UpdateLog | null;
}

type FormFields = Paths.CreateUpdateLogs.Parameters.Body | Paths.EditUpdateLogs.Parameters.Body;

const dataAdapter = (data: FormFields): FormFields => {
  data.datetime = moment(data.datetime).format('YYYY-MM-DD');
  return data;
};

export const ReleaseNotesForm = ({ id, data }: ReleaseNotesFormProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { euiTheme } = useEuiTheme<ThemeExtensions>();
  const { t } = useTranslation();
  const { control, watch, setError, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      datetime: data?.datetime || new Date().toISOString(),
      log: data?.log || '',
      type: data?.type || 'password_finder',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    return () => {
      dispatch(logsActions.clearDetail());
    };
  }, [dispatch]);

  const { type, log, datetime } = watch();

  const logs = useMemo<EuiCommentProps[]>(() => {
    const data = [
      {
        username: type,
        event: 'оновлення',
        timelineAvatar: getAvatar(type, euiTheme),
        timestamp: `за ${moment(datetime).format(LOG_DATE_FORMAT)}`,
        children: <EuiMarkdownFormat textSize="xs">{log}</EuiMarkdownFormat>,
        actions: getActions(log, type),
      },
    ];
    return data.map(({ username, ...item }) => ({ ...item, username: t(`updateLogger.type.${username}`) }));
  }, [log, type, datetime, t, euiTheme]);

  const handleDelete = useCallback(async () => {
    if (id) {
      try {
        setIsLoadingDelete(true);
        await dispatch(logsActions.remove({ id })).unwrap();
        navigate(Routes.UPDATES);
        dispatch(
          toastActions.create({
            type: ToastVariantWithText.SUCCESS_ACTION,
            text: `Success! Log has been deleted!`,
          })
        );
      } catch (e: any) {
        setIsLoadingDelete(false);
        const errorResponse = e as Definitions.Error;
        if (errorResponse?.message) {
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.ERROR_ACTION,
              text: errorResponse.message,
            })
          );
        } else {
          dispatch(
            toastActions.create({
              type: ToastVariantBasic.UNKNOWN_ERROR,
            })
          );
        }
      }
    }
  }, [id, dispatch, navigate]);

  const onSubmit = useCallback(
    async (data: FormFields) => {
      try {
        setIsLoading(true);
        if (id) {
          await dispatch(logsActions.update({ ...dataAdapter(data), id })).unwrap();
          navigate(Routes.UPDATES);
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.SUCCESS_ACTION,
              text: `Success! Log has been updated!`,
            })
          );
        } else {
          await dispatch(logsActions.create(dataAdapter(data))).unwrap();
          navigate(Routes.UPDATES);
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.SUCCESS_ACTION,
              text: `Success! Log has been created!`,
            })
          );
        }
      } catch (e: any) {
        setIsLoading(false);
        const errorResponse = e as Definitions.Error;
        if (errorResponse?.errors) {
          errorResponse.errors.forEach((error) => {
            setError(error.location as keyof FormFields, error);
          });
        } else if (errorResponse?.message) {
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.ERROR_ACTION,
              text: errorResponse.message,
            })
          );
        } else {
          dispatch(
            toastActions.create({
              type: ToastVariantBasic.UNKNOWN_ERROR,
            })
          );
        }
      }
    },
    [dispatch, navigate, setError, id]
  );

  const showDestroyModal = useCallback(() => setIsDestroyModalVisible(true), []);
  const closeDestroyModal = useCallback(() => setIsDestroyModalVisible(false), []);

  return (
    <>
      <EuiSpacer size="xl" />
      <EmptyPanel>
        <EuiFlexGroup alignItems="flexStart">
          <EuiFlexItem>
            <EuiTitle size="s">
              <h2>Preview release note:</h2>
            </EuiTitle>
            <EuiSpacer />
            <EuiCommentList comments={logs} aria-label="Comment list example" />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiForm component="form" onSubmit={handleSubmit(onSubmit)}>
              <EuiTitle size="s">
                <h2>{id ? 'Update' : 'Create'} release note:</h2>
              </EuiTitle>
              <EuiSpacer />
              <Controller
                control={control}
                name="datetime"
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <EuiFormRow isInvalid={!!error} error={t(error?.message as string)} fullWidth>
                    <EuiDatePicker
                      fullWidth
                      selected={value ? moment(value) : null}
                      isInvalid={!!error}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder={'datetime'}
                      dateFormat={process.env.REACT_APP_DATE_FORMAT}
                      minDate={moment('01.01.2022')}
                    />
                  </EuiFormRow>
                )}
              />
              <Controller
                control={control}
                name="type"
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <EuiFormRow isInvalid={!!error} error={t(error?.message as string)} fullWidth>
                    <EuiSelect
                      fullWidth
                      options={types.map((value) => ({
                        value,
                        text: value,
                      }))}
                      value={value}
                      isInvalid={!!error}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </EuiFormRow>
                )}
              />
              <Controller
                control={control}
                name="log"
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <EuiFormRow isInvalid={!!error} error={t(error?.message as string)} fullWidth>
                    <EuiTextArea
                      placeholder="Enter text"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      isInvalid={!!error}
                      fullWidth
                      rows={20}
                    />
                  </EuiFormRow>
                )}
              />
              <EuiSpacer />
              <EuiFlexGroup justifyContent="flexEnd">
                {id && (
                  <>
                    <EuiButton onClick={() => navigate(Routes.UPDATES)} color="text">
                      {t('button.cancel')}
                    </EuiButton>
                    <EuiButton onClick={showDestroyModal} color="danger">
                      {t('button.remove')}
                    </EuiButton>
                  </>
                )}
                <EuiButton isLoading={isLoading} type="submit">
                  {t(`button.${id ? 'saveChanges' : 'create'}`)}
                </EuiButton>
              </EuiFlexGroup>
            </EuiForm>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EmptyPanel>
      {isDestroyModalVisible && (
        <EuiConfirmModal
          title="Delete update log?"
          onCancel={closeDestroyModal}
          onConfirm={handleDelete}
          cancelButtonText={t('button.cancel')}
          confirmButtonText={t('button.remove')}
          buttonColor="danger"
          defaultFocusedButton="cancel"
          isLoading={isLoadingDelete}
        />
      )}
    </>
  );
};
