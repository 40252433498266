import { FC } from 'react';

import { useDataMap } from '../DataMapWrapper';

import { ClusterPointsProps, ClusterPoints as ClusterPointsWrapped } from './ClusterPoints';

export const ClusterPoints: FC<Omit<ClusterPointsProps, 'profilers' | 'landmarks' | 'leaks'>> = (props) => {
  const { profilers, landmarks, leaks } = useDataMap();
  return <ClusterPointsWrapped {...props} profilers={profilers} landmarks={landmarks} leaks={leaks} />;
};
