import { RootState } from '../store';

export const getInfo = (state: RootState) => state.organization.info;
export const getOrganizationId = (state: RootState) => state.organization.info?.id;
export const getLoading = (state: RootState) => state.organization.isLoading;
export const getLoadingActions = (state: RootState) => state.organization.isLoadingActions;
export const getAction = (state: RootState) => state.organization.action;
export const getLoadingMembers = (state: RootState) => state.organization.isLoadingMembers;
export const getTotalMembersDisabled = (state: RootState) => state.organization.totalMembersDisabled;
export const getLoadingAutocompleteMembers = (state: RootState) => state.organization.isLoadingAutocomplete;
export const getLoadingGroups = (state: RootState) => state.organization.isLoadingGroups;
export const getTotalMembers = (state: RootState) => state.organization.totalMembers;
export const getTotalGroups = (state: RootState) => state.organization.totalGroups;
export const getTotalActions = (state: RootState) => state.organization.totalActions;
export const getMembers = (state: RootState) => state.organization.members;
export const getMembersAutocomplete = (state: RootState) => state.organization.autocompleteMembers;
export const getGroups = (state: RootState) => state.organization.groups;
export const getActions = (state: RootState) => state.organization.actions;
export const getDefaultGroupId = (state: RootState) => state.organization.defaultGroupId;
export const getLoadingCounters = (state: RootState) => state.organization.isLoadingCounters;
export const getCounters = (state: RootState) => state.organization.counters;
export const getList = (state: RootState) => state.organization.list;
