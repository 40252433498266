import { EuiAvatar, EuiButtonEmpty, EuiFlexGroup, EuiTitle, useEuiTheme } from '@elastic/eui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { ReactComponent as StatsIcon } from '@/assets/icons/Statistic.svg';
import { ReactComponent as SettingsPasteIcon } from '@/assets/icons/ManageOrganization.svg';
import { ReactComponent as TimeRefreshIcon } from '@/assets/icons/Export.svg';
import { ReactComponent as UserEditIcon } from '@/assets/icons/UserEdit.svg';
import { ThemeExtensions } from '../ThemeProvider';

type CardType = 'users_groups' | 'statistic' | 'history' | 'account';

interface AdministrationCardProps {
  type: CardType;
  onClick: () => void;
}

const getIcon = (type: CardType) => {
  switch (type) {
    case 'account':
      return UserEditIcon;
    case 'users_groups':
      return SettingsPasteIcon;
    case 'history':
      return TimeRefreshIcon;
    case 'statistic':
      return StatsIcon;
  }
};

export const AdministrationCard: FC<AdministrationCardProps> = ({ type, onClick }) => {
  const { t } = useTranslation();
  const { colorMode } = useEuiTheme<ThemeExtensions>();
  const isDarkTheme = colorMode === 'DARK';
  return (
    <EuiButtonEmpty
      color="text"
      onClick={onClick}
      style={{ height: 'auto' }}
      contentProps={{ style: { padding: 0 } }}
      className={css`
        height: 'auto';
        :hover {
          h4 {
            color: ${isDarkTheme ? '#6EE7B7' : '#3143E4'};
          }
          :not([class*='isDisabled']):hover {
            transform: none;
            text-decoration: none;
          }
        }
      `}
    >
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiAvatar
          size="s"
          name={type}
          iconType={getIcon(type)}
          type="space"
          color={isDarkTheme ? '#072719' : '#5C6CFB'}
          iconColor={isDarkTheme ? '#6EE7B7' : '#EBEBEB'}
          style={{
            border: `1px solid ${isDarkTheme ? '#0C3925' : '#5C6CFB'}`,
            boxShadow: isDarkTheme ? '0px 0px 10px 1px #072719' : 'none',
          }}
        />
        <EuiTitle size="xs">
          <h4 style={{ fontWeight: '500' }}>{t(`administration.items.${type}.title`)}</h4>
        </EuiTitle>
      </EuiFlexGroup>
    </EuiButtonEmpty>
  );
};
