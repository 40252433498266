import { ReactNode } from 'react';

import { useScrollbarWidth } from '@/hooks/useScrollbarWidth';

const CONTAINER_MANX_SIZE = 858;

export const ProfilerContainer = ({
  children,
  withScroll,
  containerMaxWidth = CONTAINER_MANX_SIZE,
  className,
}: {
  children: ReactNode;
  withScroll?: boolean;
  containerMaxWidth?: number;
  className?: string;
}) => {
  const SCROLL_SIZE = useScrollbarWidth();

  return (
    <div
      className={className}
      style={{
        maxWidth: '100%',
        width: withScroll ? containerMaxWidth + SCROLL_SIZE : containerMaxWidth,
        margin: '0 auto',
        paddingLeft: withScroll ? SCROLL_SIZE : 0,
      }}
    >
      {children}
    </div>
  );
};
