import { AxiosPromise } from 'axios';

import axios from '@/services/axios';

class GroupsApi {
  private BASE_PATH = '/customer/groups';

  createGroup(data: Paths.CreateGroup.Parameters.Body): AxiosPromise<{ data: Paths.CreateGroup.Responses.$200 }> {
    return axios({
      url: `${this.BASE_PATH}`,
      method: 'post',
      data,
    });
  }

  getGroupById({ id }: Paths.GetGroup.PathParameters): AxiosPromise<{
    data: Paths.GetGroup.Responses.$200;
  }> {
    return axios({
      url: `${this.BASE_PATH}/${id}`,
      method: 'get',
    });
  }

  updateGroupById({ id, ...data }: Paths.GroupUpdate.Parameters.Body & Paths.GroupUpdate.PathParameters): AxiosPromise<{
    data: Paths.GroupUpdate.Responses.$200;
  }> {
    return axios({
      url: `${this.BASE_PATH}/${id}`,
      method: 'put',
      data,
    });
  }

  getMembersByGroupId({
    id,
  }: Paths.GetGroupMembers.PathParameters): AxiosPromise<{ data: Paths.GetGroupMembers.Responses.$200 }> {
    return axios({
      url: `${this.BASE_PATH}/${id}/members`,
      method: 'get',
    });
  }
}

export const groupsApi = new GroupsApi();
