import { Control, ControlOptions, ControlPosition, DomUtil, Map } from 'leaflet';

import { CUSTOM_CONTAINERS } from '../CustomControlContainers';

interface FullscreenControlOptions extends ControlOptions {
  container: string;
  position: ControlPosition;
}

const INITIAL_OPTIONS: FullscreenControlOptions = {
  position: 'topleft',
  container: CUSTOM_CONTAINERS.ZOOM,
};

class FullscreenControl extends Control {
  constructor(options?: FullscreenControlOptions) {
    super({ ...INITIAL_OPTIONS, ...options });
    this.options = { ...INITIAL_OPTIONS, ...options };
  }
  options: FullscreenControlOptions;
  button: HTMLElement | undefined;
  _map!: Map;

  onAdd(map: Map): HTMLElement {
    let container = map.getCustomControlContainer(this.options.position, this.options.container);

    if (!container) container = DomUtil.create('div');
    this.button = container;
    return container;
  }

  toggleFullscreen(isActive: boolean) {
    const map = this.getMap();
    const mapContainer = map.getContainer();
    if (isActive) {
      DomUtil.addClass(mapContainer, 'leaflet-pseudo-fullscreen');
    } else {
      DomUtil.removeClass(mapContainer, 'leaflet-pseudo-fullscreen');
    }

    map.invalidateSize();
  }

  getMap() {
    return this._map;
  }
}

export const fullscreenControl = (options?: FullscreenControlOptions) => new FullscreenControl(options);
