import { EuiDataGridColumn } from '@elastic/eui';

import { Columns } from './types';

export const columnsConfig: EuiDataGridColumn[] = [
  {
    id: Columns.nickname,
    isExpandable: false,
    displayAsText: 'Nickname',
    initialWidth: 170,
    actions: false,
  },
  {
    id: Columns.role,
    displayAsText: 'User role',
    isExpandable: false,
    actions: false,
  },
  {
    id: Columns.group,
    displayAsText: 'Group',
    isExpandable: false,
    actions: false,
  },
  {
    id: Columns.products_permissions,
    displayAsText: 'Tools',
    isExpandable: false,
    actions: false,
  },
  {
    id: Columns.enabled_two_fa,
    displayAsText: '2-factor authentication',
    isExpandable: false,
    actions: false,
  },
  {
    id: Columns.login,
    displayAsText: 'Login',
    isExpandable: false,
    actions: false,
  },
  {
    id: Columns.created_at,
    displayAsText: 'Created date',
    isExpandable: false,
    actions: false,
  },
  {
    id: Columns.exp_date,
    displayAsText: 'Expiration date',
    isExpandable: false,
    actions: false,
  },
  {
    id: Columns.last_activities,
    displayAsText: 'Last activities',
    isExpandable: false,
    actions: false,
  },
  {
    id: Columns.status,
    displayAsText: 'Status',
    isExpandable: false,
    actions: false,
    initialWidth: 145,
  },
];

export const CONFIG_ITEMS_PER_PAGE = [10, 25, 50];

export const DEFAULT_ITEMS_PER_PAGE = 50;
