// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { hueInterpolator } from '@elastic/charts/dist/common/color_calcs';

import { palettes } from './palettes';

type ColorMaker = (x: number) => string;

export const indexInterpolatedFillColor = (colorMaker: ColorMaker) => (d: any, i: number, a: any[]) =>
  colorMaker(i / (a.length + 1));

export const interpolatorCET2s = (opacity = 0.7) =>
  hueInterpolator(palettes.CET2s.map(([r, g, b]) => [r, g, b, opacity]));
