import { FunctionComponent, useCallback, useState } from 'react';
import { captureException } from '@sentry/react';
import fileDownload from 'js-file-download';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiModal, EuiSpacer, EuiSplitPanel, EuiText } from '@elastic/eui';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import { leakApi } from '@/api';
interface DatasetModalProps {
  data: Definitions.Dataset;
  title?: string;
  description?: string;
  icon: JSX.Element | null;
  onClose: () => void;
}
export const DatasetModal: FunctionComponent<DatasetModalProps> = ({ title, description, icon, data, onClose }) => {
  const { filename, lines_count, fields, created_at, category } = data;

  const { t } = useTranslation();

  const [isDownload, setDownload] = useState(false);
  const handleLoad = useCallback(async () => {
    try {
      if (filename) {
        setDownload(true);
        const response = await leakApi.download(
          {
            target: 'dataset',
            title: filename,
          },
          'blob'
        );
        fileDownload(response.data, filename);
      }
    } catch (e) {
      captureException(e);
    } finally {
      setDownload(false);
    }
  }, [filename]);

  const handleClose = () => {
    if (!isDownload) {
      onClose();
    }
  };

  const countLines = lines_count
    ? new Intl.NumberFormat('uk-UA', { maximumSignificantDigits: 3 }).format(lines_count).toString()
    : '0';

  return (
    <EuiModal onClose={handleClose} maxWidth={400}>
      <EuiSplitPanel.Outer hasBorder={false} hasShadow={false}>
        <EuiSplitPanel.Inner color="subdued">
          <EuiText>
            <b>{title}</b>
          </EuiText>
        </EuiSplitPanel.Inner>
        <EuiSplitPanel.Inner>
          <EuiFlexGroup gutterSize="m">
            <EuiFlexItem grow={false}>{icon}</EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="s" color="subdued">
                {description}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          {created_at && (
            <>
              <EuiSpacer size="l" />
              <EuiText size="s">
                <Trans
                  i18nKey="datasetCard.created_at"
                  values={{
                    date: moment.parseZone(created_at).format(process.env.REACT_APP_DATE_FORMAT),
                  }}
                  components={[<b key={0} />]}
                />
              </EuiText>
            </>
          )}
          {category && (
            <>
              <EuiSpacer size="xs" />
              <EuiText size="s">
                <Trans
                  i18nKey="datasetCard.category"
                  values={{
                    category: category,
                  }}
                  components={[<b key={0} />]}
                />
              </EuiText>
            </>
          )}
          <EuiSpacer size="xs" />
          <EuiText size="s">
            <Trans
              i18nKey="datasetCard.lines"
              values={{
                lines: countLines,
              }}
              components={[<b key={0} />]}
            />
          </EuiText>
          {fields && fields.length && (
            <>
              <EuiSpacer size="xs" />
              <EuiText size="s">
                <Trans
                  i18nKey="datasetCard.fields"
                  values={{
                    fields: fields.join(' '),
                  }}
                  components={[<b key={0} />]}
                />
              </EuiText>
            </>
          )}

          <EuiSpacer size="m" />
          <EuiButton fullWidth onClick={handleLoad} isLoading={isDownload} fill color="primary">
            {t('button.download')}
          </EuiButton>
        </EuiSplitPanel.Inner>
      </EuiSplitPanel.Outer>
    </EuiModal>
  );
};
