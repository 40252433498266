import {
  EuiBadge,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface MemberFieldProps {
  index: number;
  data: Omit<Definitions.CustomerHTTPResponse, 'id'>;
  disabled: boolean;
  onRemove: (index: number) => void;
}

export const MemberField: FC<MemberFieldProps> = ({ index, data, disabled, onRemove }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  return (
    <div>
      <EuiHorizontalRule margin="none" />
      <EuiFlexGroup
        alignItems="center"
        style={{
          paddingBlock: euiTheme.size.xs,
        }}
      >
        <EuiFlexItem>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem>
              <EuiFlexGroup gutterSize="xs" alignItems="flexStart">
                <EuiText
                  style={{
                    wordBreak: 'break-all',
                  }}
                  size="s"
                >
                  <b>{data.nickname || data.login || 'N/A'}</b>
                </EuiText>

                {data.group_title && <EuiBadge color="default">{data.group_title}</EuiBadge>}
              </EuiFlexGroup>
              <EuiSpacer size="xs" />
              <EuiText size="xs" color="subdued">
                {t(`roles.${data.role_id}`)}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={0}>
          <EuiButtonEmpty
            size="s"
            color="text"
            disabled={disabled}
            iconType="trash"
            iconSide="left"
            onClick={() => onRemove(index)}
          >
            {t('button.remove')}
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule margin="none" />
    </div>
  );
};
