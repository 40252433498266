import { createAction, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { Apps, getSelectedApp } from '@/hooks';
import { leakApi } from '@/api';
import { toastActions } from '../toast';
import { ToastVariantWithText } from '@/components';
import i18n from '@/i18next';

export interface State {
  currentApp: Apps | null;
  databaseLoad: Definitions.DatabaseStats | null;
}

const changeApp = createAction<Apps | null>('app/changeApp');

type GetDatabaseLoadPayload =
  | {
      showToast: boolean;
    }
  | undefined;

export const getDatabaseLoad = createAsyncThunk(
  'app/getDatabaseLoad',
  async (
    payload: GetDatabaseLoadPayload = {
      showToast: true,
    },
    thunkApi
  ) => {
    try {
      const { showToast } = payload;
      const {
        data: { data },
      } = await leakApi.getDatabaseStatus();

      if (showToast) {
        if (data?.load_status && ['medium', 'high'].includes(data?.load_status))
          thunkApi.dispatch(
            toastActions.create({
              type: ToastVariantWithText.WARNING_ACTION,
              text: i18n.t(`toast.text.databaseLoad.base`, {
                speed: i18n.t(`toast.text.databaseLoad.speed.${data?.load_status}`),
              }),
            })
          );
      }
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'app',
  initialState: {
    currentApp: getSelectedApp(),
    databaseLoad: null,
  },
  reducers: {
    changeApp: (state, { payload }: PayloadAction<Apps | null>) => {
      state.currentApp = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDatabaseLoad.fulfilled, (state, action) => {
      if (action.payload) state.databaseLoad = action.payload;
    });
  },
});

export const actions = {
  changeApp,
  getDatabaseLoad,
};

export default slice.reducer;
