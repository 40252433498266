import { object, string, SchemaOf } from 'yup';

export const types = [
  'profiler',
  'password_finder',
  'system',
  'phonebook',
  'datasets',
  'recursive_search',
  'map_radar',
];

export const schema: SchemaOf<Paths.CreateUpdateLogs.Parameters.Body | Paths.EditUpdateLogs.Parameters.Body> =
  object().shape({
    datetime: string().required('form.error.required'),
    log: string().required('form.error.required'),
    type: string().required('form.error.required').oneOf(types),
  });
