import { EuiFlexGroup } from '@elastic/eui';
import { FC, Ref } from 'react';

export const PanelWrapper: FC<{
  panelRef?: Ref<HTMLDivElement | HTMLSpanElement> | undefined;
}> = ({ children, panelRef }) => (
  <EuiFlexGroup ref={panelRef} direction="column" justifyContent="flexStart" gutterSize="none">
    {children}
  </EuiFlexGroup>
);
