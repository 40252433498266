import { FC, createContext, useContext, useEffect, useState } from 'react';

import { useAppSelector } from '@/store';
import { mapSelectors } from '@/store/map';

import { DataMap } from './types';

const DataMapContext = createContext<DataMap>({
  profilers: new Map(),
  landmarks: new Map(),
  leaks: new Map(),
});

interface DataMapWrapperProps {
  isLoading: boolean;
}

export const useDataMap = () => {
  const context = useContext(DataMapContext);
  return context;
};

export const DataMapWrapper: FC<DataMapWrapperProps> = ({ isLoading, children }) => {
  const profilers = useAppSelector(mapSelectors.getProfilers);
  const landmarks = useAppSelector(mapSelectors.getLandmarks);
  const leaks = useAppSelector(mapSelectors.getLeaks);

  const [data, setData] = useState<DataMap>({
    profilers: new Map(),
    landmarks: new Map(),
    leaks: new Map(),
  });

  useEffect(() => {
    if (!isLoading) {
      setData({
        profilers,
        leaks,
        landmarks,
      });
    }
  }, [isLoading, landmarks, leaks, profilers]);

  return <DataMapContext.Provider value={data}>{children}</DataMapContext.Provider>;
};
