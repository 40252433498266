import { Control, ControlPosition, CustomContainer, CustomControlContainerOptions, DomUtil, Map } from 'leaflet';

export class CustomControlContainer extends Control {
  constructor(options: CustomControlContainerOptions) {
    super(options);
    this.options = options;
  }
  options: CustomControlContainerOptions;
  private _map!: Map & {
    _customContainerCorners: Record<ControlPosition, CustomContainer> | undefined;
    getCustomControlContainer(position: ControlPosition, name: string): HTMLElement | undefined;
  };

  onAdd(): HTMLElement {
    const container = DomUtil.create('div', 'leaflet-custom-control-container');
    const { position, containerName } = this.options;
    if (this._map) {
      if (!this._map._customContainerCorners) {
        this._map._customContainerCorners = {
          topleft: {},
          topright: {},
          bottomleft: {},
          bottomright: {},
        };
      }

      if (!this._map.getCustomControlContainer)
        this._map.getCustomControlContainer = this.getCustomControlContainer.bind(this);

      if (position && containerName && !this._map._customContainerCorners[position][containerName]) {
        this._map._customContainerCorners[position][containerName] = container;
      }
    }
    return container;
  }

  getCustomControlContainer(position: ControlPosition, name: string): HTMLElement | undefined {
    return this._map?._customContainerCorners?.[position]?.[name];
  }
}

export const customControlContainers = (options: CustomControlContainerOptions) => new CustomControlContainer(options);
