import { useEuiTheme } from '@elastic/eui';

import { ThemeExtensions } from '../ThemeProvider';

export const HeaderDelimiter = () => {
  const { euiTheme } = useEuiTheme<ThemeExtensions>();

  return (
    <div
      style={{
        height: '100%',
        padding: `${euiTheme.size.m} 0`,
      }}
    >
      <div
        style={{
          height: '100%',
          width: 1,
          background: euiTheme.colors.headerDelimiter,
        }}
      />
    </div>
  );
};
