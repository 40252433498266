import {
  EuiButton,
  EuiButtonIcon,
  EuiCopy,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SuccessCreateAccountPropsData = Paths.RegisterCustomer.Responses.$200 & {
  password: string;
};

interface SuccessCreateAccountProps {
  data: SuccessCreateAccountPropsData;
  onClose: () => void;
}
export const SuccessCreateAccount: FC<SuccessCreateAccountProps> = ({ data, onClose }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  const [isShowPassword, setIsShowPassword] = useState(true);
  const [isShowToken, setIsShowToken] = useState(true);

  const copyText = useMemo(
    () => `${t('form.label.login')}: ${data.login}
${t('form.label.nickname')}: ${data.nickname || ''}
${t('form.label.role_id')}: ${data.role_id ? t('roles.' + data.role_id) : ''}
${t('form.label.group_id')}: ${data.group_title || ''}
${t('form.label.expiration_date')}: ${
      data.subscription_expires_at ? moment(data.subscription_expires_at).format(process.env.REACT_APP_DATE_FORMAT) : ''
    }
${t('form.label.api_token')}: ${data.api_token || ''}
${t('form.label.password')}: ${data.password}
`,
    [data, t]
  );

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t('modals.successCreateAccount.title')}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {[
          {
            label: 'form.label.login',
            value: data.login,
          },
          {
            label: 'form.label.nickname',
            value: data.nickname,
          },
          {
            label: 'form.label.role_id',
            value: data.role_id ? t('roles.' + data.role_id) : '',
          },
          {
            label: 'form.label.group_id',
            value: data.group_title,
          },
          {
            label: 'form.label.expiration_date',
            value: data.subscription_expires_at
              ? moment(data.subscription_expires_at).format(process.env.REACT_APP_DATE_FORMAT)
              : '',
          },
        ].map(({ label, value }) => (
          <EuiText
            size="s"
            style={{
              marginBottom: 20,
            }}
            key={label}
          >
            <b
              style={{
                width: 120,

                display: 'inline-block',
              }}
            >
              {t(label)}:
            </b>
            {value}
          </EuiText>
        ))}
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiText
              size="s"
              style={{
                marginTop: 6,
                marginBottom: 20,
              }}
            >
              <b
                style={{
                  width: 120,

                  display: 'inline-block',
                }}
              >
                API:
              </b>
            </EuiText>
            <EuiFieldText
              type={isShowToken ? 'password' : 'text'}
              value={data.api_token}
              fullWidth
              readOnly
              append={
                <EuiFlexGroup
                  style={{
                    backgroundColor: euiTheme.colors.emptyShade,
                    flexGrow: 0,
                    paddingRight: euiTheme.size.m,
                  }}
                  gutterSize="none"
                >
                  <EuiButtonIcon
                    style={{
                      backgroundColor: euiTheme.colors.emptyShade,
                    }}
                    display="empty"
                    iconType={isShowToken ? 'eye' : 'eyeClosed'}
                    onClick={() => setIsShowToken((prevValue) => !prevValue)}
                    color="text"
                    aria-label="Show securety field"
                  />
                </EuiFlexGroup>
              }
            />
            <EuiFlexGroup
              style={{
                marginTop: euiTheme.size.m,
              }}
              gutterSize="xs"
            >
              <EuiIcon type="alert" color="warning" />
              <EuiText size="xs">
                {t('modals.alert.base', {
                  subject: t('modals.alert.api'),
                })}
              </EuiText>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText
              size="s"
              style={{
                marginTop: 6,
                marginBottom: 20,
              }}
            >
              <b
                style={{
                  width: 120,

                  display: 'inline-block',
                }}
              >
                {t('form.label.password')}:
              </b>
            </EuiText>
            <EuiFieldText
              type={isShowPassword ? 'password' : 'text'}
              value={data.password}
              fullWidth
              readOnly
              append={
                <EuiFlexGroup
                  style={{
                    backgroundColor: euiTheme.colors.emptyShade,
                    flexGrow: 0,
                    paddingRight: euiTheme.size.m,
                  }}
                  gutterSize="none"
                >
                  <EuiButtonIcon
                    style={{
                      backgroundColor: euiTheme.colors.emptyShade,
                    }}
                    display="empty"
                    iconType={isShowPassword ? 'eye' : 'eyeClosed'}
                    onClick={() => setIsShowPassword((prevValue) => !prevValue)}
                    color="text"
                    aria-label="Show securety field"
                  />
                </EuiFlexGroup>
              }
            />
            <EuiFlexGroup
              style={{
                marginTop: euiTheme.size.m,
              }}
              gutterSize="xs"
            >
              <EuiIcon type="alert" color="warning" />
              <EuiText size="xs">
                {t('modals.alert.base', {
                  subject: t('modals.alert.password'),
                })}
              </EuiText>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiCopy textToCopy={copyText}>
          {(copy) => (
            <EuiButton onClick={copy} fill color="success">
              {t('button.copy')}
            </EuiButton>
          )}
        </EuiCopy>
      </EuiModalFooter>
    </EuiModal>
  );
};
