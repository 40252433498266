import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import axios from 'axios';

import { releaseNotesApi } from '@/api';

export interface State {
  loading: boolean;
  items: Definitions.UpdateLog[];
  total: number;
  offset: number;
  detail: Definitions.UpdateLog | null;
  init: boolean;
}

const clearSearch = createAction('logs/clearSearch');
const clearDetail = createAction('logs/clearDetail');

const fetchData = createAsyncThunk(
  'logs/fetchData',
  async (payload: Paths.GetUpdateLogsList.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await releaseNotesApi.fetchList(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.GetUpdateLogsList.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

const fetchById = createAsyncThunk('logs/fetchById', async (payload: Paths.GetUpdateLog.PathParameters, thunkApi) => {
  try {
    const {
      data: { data },
    } = await releaseNotesApi.fetchById(payload);
    return data;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const responseError = e?.response as { data: Paths.GetUpdateLog.Responses.$400 };
      return thunkApi.rejectWithValue(responseError?.data?.error);
    }
  }
});

const create = createAsyncThunk('logs/create', async (payload: Paths.CreateUpdateLogs.Parameters.Body, thunkApi) => {
  try {
    const {
      data: { data },
    } = await releaseNotesApi.create(payload);
    thunkApi.dispatch(clearSearch());
    return data;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const responseError = e?.response as { data: Paths.CreateUpdateLogs.Responses.$400 };
      return thunkApi.rejectWithValue(responseError?.data?.error);
    }
  }
});

const update = createAsyncThunk(
  'logs/update',
  async (payload: Paths.EditUpdateLogs.Parameters.Body & Paths.EditUpdateLogs.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await releaseNotesApi.update(payload);

      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.EditUpdateLogs.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

const remove = createAsyncThunk('logs/delete', async (payload: Paths.DeleteUpdateLog.PathParameters, thunkApi) => {
  try {
    const {
      data: { data },
    } = await releaseNotesApi.delete(payload);

    return data;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const responseError = e?.response as { data: Paths.DeleteUpdateLog.Responses.$400 };
      return thunkApi.rejectWithValue(responseError?.data?.error);
    }
  }
});

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'logs',
  initialState: {
    loading: true,
    items: [],
    detail: null,
    total: 0,
    offset: 0,
    init: false,
  },
  reducers: {
    clearSearch: (state) => {
      state.loading = true;
      state.items = [];
      state.offset = 0;
      state.total = 0;
      state.init = false;
    },
    clearDetail: (state) => {
      state.detail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.init = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          if (action.payload.items) {
            if (action.payload.offset && action.payload.offset > 0) {
              state.items = [...state.items, ...action.payload.items];
            } else {
              state.items = action.payload.items;
            }
          }
          if (action.payload.total_items) {
            state.total = action.payload.total_items;
          }
          if (action.payload.offset) {
            state.offset = action.payload.offset;
          }
        }
      })
      .addCase(fetchData.rejected, (state) => {
        state.loading = false;
        state.items = [];
      });

    builder.addCase(fetchById.fulfilled, (state, action) => {
      if (action.payload) state.detail = action.payload;
    });

    builder.addCase(update.fulfilled, (state, action) => {
      if (action.payload) {
        state.detail = action.payload;
        state.items = state.items.map((item) =>
          item.id === (action.payload as Definitions.UpdateLog).id ? (action.payload as Definitions.UpdateLog) : item
        );
      }
    });

    builder.addCase(create.fulfilled, (state, action) => {
      if (action.payload) state.detail = action.payload;
    });

    builder.addCase(remove.fulfilled, (state, action) => {
      state.detail = null;
      state.items = state.items.filter((item) => item.id !== Number(action.meta.arg.id));
    });
  },
});

export const actions = {
  clearSearch,
  clearDetail,
  fetchData,
  fetchById,
  create,
  update,
  remove,
};

export default slice.reducer;
