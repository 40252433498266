import { CustomControlContainerOptions } from 'leaflet';
import { useLayoutEffect, useRef } from 'react';

import { useMap } from '../MapContainer';

import { customControlContainers } from './CustomControlContainer';
import './index.scss';

export enum CUSTOM_CONTAINERS {
  SEARCH = 'SEARCH',
  ZOOM = 'ZOOM',
  TOOLS = 'TOOLS',
  FILTERS = 'FILTERS',
  LAYERS = 'LAYERS',
}

const CustomControlContainerComponent = (props: CustomControlContainerOptions) => {
  const ctx = useRef(customControlContainers(props));
  const { map } = useMap();
  useLayoutEffect(() => {
    const control = ctx.current;
    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map]);

  return null;
};

export const CustomControlContainers = () => {
  return (
    <>
      <CustomControlContainerComponent position="topleft" containerName={CUSTOM_CONTAINERS.SEARCH} />
      <CustomControlContainerComponent position="topleft" containerName={CUSTOM_CONTAINERS.ZOOM} />
      <CustomControlContainerComponent position="topleft" containerName={CUSTOM_CONTAINERS.TOOLS} />
      <CustomControlContainerComponent position="topright" containerName={CUSTOM_CONTAINERS.LAYERS} />
      <CustomControlContainerComponent position="bottomright" containerName={CUSTOM_CONTAINERS.FILTERS} />
    </>
  );
};
