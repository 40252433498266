import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { EuiButtonIcon, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';

import { useMap } from '../MapContainer';
import { GeoBoundBox } from '@/api/geo/types';
import { useAppDispatch } from '@/store';
import { mapActions } from '@/store/map';

import { geocoderControl } from './GeocoderControl';
import { GeocoderForm } from './GeocoderForm';
import { ReactComponent as Icon } from './icon.svg';
import './index.scss';
interface GeocoderProps {
  location: string;
  isActive: boolean;
  toggleActive: () => void;
  onChangeLocation: (query: string) => void;
}
export const Geocoder: FunctionComponent<GeocoderProps> = ({ location, isActive, toggleActive, onChangeLocation }) => {
  const dispatch = useAppDispatch();
  const { map } = useMap();
  const { euiTheme } = useEuiTheme();
  const [container, setContainer] = useState<HTMLElement | null>();
  const ctr = useRef(geocoderControl({ setContainer }));

  useEffect(() => {
    const control = ctr.current;
    control.addTo(map);

    return () => {
      dispatch(mapActions.setGeoQuery(''));
      dispatch(mapActions.setGeoItems([]));
      control.remove();
    };
  }, [dispatch, map]);

  const handleSearch = (bounds: GeoBoundBox) => {
    if (bounds.length === 4)
      map.fitBounds([
        [Number(bounds[0]), Number(bounds[2])],
        [Number(bounds[1]), Number(bounds[3])],
      ]);
  };

  if (ctr.current.button) {
    return (
      <>
        {createPortal(
          !isActive ? (
            <EuiButtonIcon
              className={css(`
              background-color: ${euiTheme.colors.emptyShade};
            `)}
              display="base"
              color="text"
              size="s"
              iconType={Icon}
              onClick={toggleActive}
              onDoubleClickCapture={(e: any) => e.stopPropagation()}
              onDoubleClick={(e: any) => e.stopPropagation()}
            />
          ) : null,
          ctr.current.button
        )}
        {isActive &&
          container &&
          createPortal(
            <GeocoderForm location={location} onSearch={handleSearch} onChangeLocation={onChangeLocation} />,
            container
          )}
      </>
    );
  }

  return null;
};
