import { Control, ControlOptions, ControlPosition, DomUtil, Map } from 'leaflet';

import { CUSTOM_CONTAINERS } from '../CustomControlContainers';

interface ZoomControlOptions extends ControlOptions {
  container: string;
  position: ControlPosition;
}

const INITIAL_OPTIONS: ZoomControlOptions = {
  position: 'topleft',
  container: CUSTOM_CONTAINERS.ZOOM,
};

class ZoomControl extends Control {
  constructor(options?: ZoomControlOptions) {
    super({ ...INITIAL_OPTIONS, ...options });
    this.options = { ...INITIAL_OPTIONS, ...options };
  }
  options: ZoomControlOptions;
  button: HTMLElement | undefined;

  onAdd(map: Map): HTMLElement {
    let container = map.getCustomControlContainer(this.options.position, this.options.container);
    if (!container) container = DomUtil.create('div');
    this.button = container;
    return container;
  }
}

export const zoomControl = (options?: ZoomControlOptions) => new ZoomControl(options);
