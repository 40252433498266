import { useCallback, useMemo, useState } from 'react';
import { EuiButtonEmpty, EuiContextMenu, EuiContextMenuPanelDescriptor, EuiPopover, useEuiTheme } from '@elastic/eui';
import { useLinkClickHandler } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/store';
import { customerSelectors } from '@/store/customer';
import { Routes } from '@/routes';
import { Roles, useRole } from '@/hooks';

export const HeaderUser = () => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  const user = useAppSelector(customerSelectors.getInfo);
  const { validateAccessRole } = useRole([]);

  const [isPopoverOpen, setPopover] = useState(false);

  const handleClickMyAccountHref = useLinkClickHandler(Routes.ACCOUNT_GENERAL_SETTING);
  const handleClickOrganizationHref = useLinkClickHandler(Routes.ORGANIZATION);
  const handleClickLogoutHref = useLinkClickHandler(Routes.LOGOUT);

  const handleClickAvatar = useCallback(() => {
    setPopover((isOpen) => !isOpen);
  }, [setPopover]);

  const handleClosePopover = useCallback(() => {
    setPopover(false);
  }, [setPopover]);

  const panels = useMemo<
    (EuiContextMenuPanelDescriptor & {
      roles?: Roles[];
    })[]
  >(() => {
    const items = [
      {
        name: t('menu.account.items.current'),
        href: Routes.ACCOUNT_GENERAL_SETTING,
        onClick: (e: any) => {
          if (!e.defaultPrevented) {
            handleClickMyAccountHref(e);
            handleClosePopover();
          }
        },
      },
      {
        name: t('menu.account.items.organization'),
        roles: [Roles.ADMIN, Roles.SUPER_ADMIN, Roles.SYSTEM],
        href: Routes.ORGANIZATION,
        onClick: (e: any) => {
          if (!e.defaultPrevented) {
            handleClickOrganizationHref(e);
            handleClosePopover();
          }
        },
      },
      {
        name: t('menu.account.items.logout'),
        href: Routes.LOGOUT,
        onClick: (e: any) => {
          if (!e.defaultPrevented) {
            handleClickLogoutHref(e);
            handleClosePopover();
          }
        },
      },
    ];
    return [
      {
        id: 0,
        items: items.filter((item) => !(item.roles && !validateAccessRole(item.roles))),
      },
    ];
  }, [
    handleClickMyAccountHref,
    handleClickLogoutHref,
    handleClosePopover,
    handleClickOrganizationHref,
    t,
    validateAccessRole,
  ]);

  if (!user) return null;

  return (
    <EuiPopover
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
      }}
      isOpen={isPopoverOpen}
      closePopover={handleClosePopover}
      panelPaddingSize="none"
      anchorPosition="downRight"
      hasArrow={true}
      offset={4}
      button={
        <EuiButtonEmpty
          style={{
            padding: `${euiTheme.size.m} ${euiTheme.size.base}`,
            height: '100%',
          }}
          iconType="arrowDown"
          iconSide="right"
          size="s"
          color="text"
          onClick={handleClickAvatar}
        >
          {user.nickname || user.login}
        </EuiButtonEmpty>
      }
    >
      <EuiContextMenu initialPanelId={0} panels={panels} />
    </EuiPopover>
  );
};
