import { useCallback } from 'react';

import { useAppSelector } from '@/store';
import { customerSelectors } from '@/store/customer';

export enum Roles {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  USER = 3,
  SYSTEM = 100,
}

export const useRole = (roles?: Roles[]) => {
  const user = useAppSelector(customerSelectors.getInfo);

  const validateAccessRole = useCallback(
    (accessRoles: Roles[]) => !!user && !!user.role_id && accessRoles.includes(user.role_id),
    [user]
  );

  return {
    validateAccessRole,
    isValid: !!user && !!user.role_id && roles && roles.includes(user.role_id),
    role: (user?.role_id || 0) as Roles,
  };
};
