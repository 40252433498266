import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';

import { OrganizationRoutes, Routes } from '@/routes';
import { RequestHistory } from '../Modals';

import { AdministrationCard } from './AdministrationCard';
export const SuperAdministration = () => {
  const navigate = useNavigate();

  const [isOpenRHModal, setOpenRHModal] = useState(false);

  const handleStatistic = useCallback(() => {
    navigate(`${Routes.ORGANIZATION}/${OrganizationRoutes.STATISTIC}`);
  }, [navigate]);

  const handleHistory = useCallback(() => {
    setOpenRHModal(true);
  }, []);

  const handleCloseRHModal = useCallback(() => {
    setOpenRHModal(false);
  }, []);

  return (
    <>
      <AdministrationCard type="statistic" onClick={handleStatistic} />
      <AdministrationCard type="history" onClick={handleHistory} />
      {isOpenRHModal && <RequestHistory onClose={handleCloseRHModal} />}
    </>
  );
};
