/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Control, ControlOptions, ControlPosition, DomUtil, Map } from 'leaflet';

import { CUSTOM_CONTAINERS } from '../CustomControlContainers';

interface LayersControlOptions extends ControlOptions {
  title?: string;
  initialActive?: boolean;
}

const INITIAL_OPTIONS: LayersControlOptions = {
  position: 'topright',
  title: 'Layers',
};

export class LayersControl extends Control {
  constructor(options?: LayersControlOptions) {
    super({ ...INITIAL_OPTIONS, ...options });
    this.options = { ...INITIAL_OPTIONS, ...options };
  }

  isInit = false;
  options: LayersControlOptions = INITIAL_OPTIONS;
  button: HTMLElement | undefined;
  private _map!: Map;
  private isActive = false;

  onAdd(map: Map): HTMLElement {
    let button = map.getCustomControlContainer(this.options.position as ControlPosition, CUSTOM_CONTAINERS.LAYERS);

    if (!button) {
      button = DomUtil.create('div');
    }

    this.isInit = true;
    this.button = button;

    return button;
  }

  getMap() {
    return this._map as Map;
  }
}

export const layersControl = (options?: LayersControlOptions) => {
  return new LayersControl(options);
};
