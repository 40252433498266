export const iconTypeMap = [
  'accessibility',
  'addDataApp',
  'advancedSettingsApp',
  'agentApp',
  'aggregate',
  'alert',
  'analyzeEvent',
  'annotation',
  'apmApp',
  'apmTrace',
  'appSearchApp',
  'apps',
  'arrowDown',
  'arrowLeft',
  'arrowRight',
  'arrowUp',
  'arrowStart',
  'arrowEnd',
  'asterisk',
  'auditbeatApp',
  'beaker',
  'bell',
  'bellSlash',
  'beta',
  'bolt',
  'boxesHorizontal',
  'boxesVertical',
  'branch',
  'branchUser',
  'broom',
  'brush',
  'bug',
  'bullseye',
  'calendar',
  'canvasApp',
  'casesApp',
  'check',
  'checkInCircleFilled',
  'cheer',
  'classificationJob',
  'clock',
  'cloudDrizzle',
  'cloudStormy',
  'cloudSunny',
  'cluster',
  'codeApp',
  'color',
  'compute',
  'console',
  'consoleApp',
  'container',
  'continuityAbove',
  'continuityAboveBelow',
  'continuityBelow',
  'continuityWithin',
  'controlsHorizontal',
  'controlsVertical',
  'copy',
  'copyClipboard',
  'createAdvancedJob',
  'createMultiMetricJob',
  'createPopulationJob',
  'createSingleMetricJob',
  'cross',
  'crossClusterReplicationApp',
  'crossInACircleFilled',
  'crosshairs',
  'currency',
  'cut',
  'dashboardApp',
  'dataVisualizer',
  'database',
  'desktop',
  'devToolsApp',
  'discoverApp',
  'document',
  'documentEdit',
  'documentation',
  'documents',
  'dot',
  'doubleArrowLeft',
  'doubleArrowRight',
  'download',
  'editorAlignCenter',
  'editorAlignLeft',
  'editorAlignRight',
  'editorBold',
  'editorChecklist',
  'editorCodeBlock',
  'editorComment',
  'editorDistributeHorizontal',
  'editorDistributeVertical',
  'editorHeading',
  'editorItalic',
  'editorItemAlignBottom',
  'editorItemAlignCenter',
  'editorItemAlignLeft',
  'editorItemAlignMiddle',
  'editorItemAlignRight',
  'editorItemAlignTop',
  'editorLink',
  'editorOrderedList',
  'editorPositionBottomLeft',
  'editorPositionBottomRight',
  'editorPositionTopLeft',
  'editorPositionTopRight',
  'editorRedo',
  'editorStrike',
  'editorTable',
  'editorUnderline',
  'editorUndo',
  'editorUnorderedList',
  'email',
  'empty',
  'emsApp',
  'eql',
  'eraser',
  'exit',
  'expand',
  'expandMini',
  'exportAction',
  'eye',
  'eyeClosed',
  'faceHappy',
  'faceNeutral',
  'faceSad',
  'filebeatApp',
  'filter',
  'filterExclude',
  'filterIgnore',
  'filterInclude',
  'filterInCircle',
  'flag',
  'fleetApp',
  'fold',
  'folderCheck',
  'folderClosed',
  'folderExclamation',
  'folderOpen',
  'frameNext',
  'framePrevious',
  'fullScreen',
  'fullScreenExit',
  'function',
  'gear',
  'gisApp',
  'glasses',
  'globe',
  'grab',
  'grabHorizontal',
  'graphApp',
  'grid',
  'grokApp',
  'heart',
  'heartbeatApp',
  'heatmap',
  'help',
  'home',
  'iInCircle',
  'image',
  'importAction',
  'indexClose',
  'indexEdit',
  'indexFlush',
  'indexManagementApp',
  'indexMapping',
  'indexOpen',
  'indexPatternApp',
  'indexRollupApp',
  'indexRuntime',
  'indexSettings',
  'indexTemporary',
  'infinity',
  'inputOutput',
  'inspect',
  'invert',
  'ip',
  'keyboard',
  'kqlField',
  'kqlFunction',
  'kqlOperand',
  'kqlSelector',
  'kqlValue',
  'kubernetesNode',
  'kubernetesPod',
  'launch',
  'layers',
  'lensApp',
  'lettering',
  'lineDashed',
  'lineDotted',
  'lineSolid',
  'link',
  'list',
  'listAdd',
  'lock',
  'lockOpen',
  'logoAWS',
  'logoAWSMono',
  'logoAerospike',
  'logoApache',
  'logoAppSearch',
  'logoAzure',
  'logoAzureMono',
  'logoBeats',
  'logoBusinessAnalytics',
  'logoCeph',
  'logoCloud',
  'logoCloudEnterprise',
  'logoCode',
  'logoCodesandbox',
  'logoCouchbase',
  'logoDocker',
  'logoDropwizard',
  'logoElastic',
  'logoElasticStack',
  'logoElasticsearch',
  'logoEnterpriseSearch',
  'logoEtcd',
  'logoGCP',
  'logoGCPMono',
  'logoGithub',
  'logoGmail',
  'logoGolang',
  'logoGoogleG',
  'logoHAproxy',
  'logoIBM',
  'logoIBMMono',
  'logoKafka',
  'logoKibana',
  'logoKubernetes',
  'logoLogging',
  'logoLogstash',
  'logoMaps',
  'logoMemcached',
  'logoMetrics',
  'logoMongodb',
  'logoMySQL',
  'logoNginx',
  'logoObservability',
  'logoOsquery',
  'logoPhp',
  'logoPostgres',
  'logoPrometheus',
  'logoRabbitmq',
  'logoRedis',
  'logoSecurity',
  'logoSiteSearch',
  'logoSketch',
  'logoSlack',
  'logoUptime',
  'logoWebhook',
  'logoWindows',
  'logoWorkplaceSearch',
  'logsApp',
  'logstashFilter',
  'logstashIf',
  'logstashInput',
  'logstashOutput',
  'logstashQueue',
  'machineLearningApp',
  'magnet',
  'magnifyWithExclamation',
  'magnifyWithMinus',
  'magnifyWithPlus',
  'managementApp',
  'mapMarker',
  'memory',
  'menu',
  'menuDown',
  'menuLeft',
  'menuRight',
  'menuUp',
  'merge',
  'metricbeatApp',
  'metricsApp',
  'minimize',
  'minus',
  'minusInCircle',
  'minusInCircleFilled',
  'mobile',
  'monitoringApp',
  'moon',
  'namespace',
  'nested',
  'node',
  'notebookApp',
  'number',
  'offline',
  'online',
  'outlierDetectionJob',
  'package',
  'packetbeatApp',
  'pageSelect',
  'pagesSelect',
  'paperClip',
  'partial',
  'pause',
  'payment',
  'pencil',
  'percent',
  'pin',
  'pinFilled',
  'pipelineApp',
  'play',
  'playFilled',
  'plus',
  'plusInCircle',
  'plusInCircleFilled',
  'popout',
  'push',
  'questionInCircle',
  'quote',
  'recentlyViewedApp',
  'refresh',
  'regressionJob',
  'reporter',
  'reportingApp',
  'returnKey',
  'save',
  'savedObjectsApp',
  'scale',
  'search',
  'searchProfilerApp',
  'securityAnalyticsApp',
  'securityApp',
  'securitySignal',
  'securitySignalDetected',
  'securitySignalResolved',
  'sessionViewer',
  'shard',
  'share',
  'snowflake',
  'sortAscending',
  'sortDescending',
  'sortDown',
  'sortLeft',
  'sortRight',
  'sortUp',
  'sortable',
  'spaces',
  'spacesApp',
  'sqlApp',
  'starEmpty',
  'starEmptySpace',
  'starFilled',
  'starFilledSpace',
  'starMinusEmpty',
  'starMinusFilled',
  'starPlusEmpty',
  'starPlusFilled',
  'stats',
  'stop',
  'stopFilled',
  'stopSlash',
  'storage',
  'string',
  'submodule',
  'sun',
  'swatchInput',
  'symlink',
  'tableDensityCompact',
  'tableDensityExpanded',
  'tableDensityNormal',
  'tableOfContents',
  'tag',
  'tear',
  'temperature',
  'timeline',
  'timelionApp',
  'timeRefresh',
  'timeslider',
  'training',
  'trash',
  'unfold',
  'unlink',
  'upgradeAssistantApp',
  'uptimeApp',
  'user',
  'userAvatar',
  'users',
  'usersRolesApp',
  'vector',
  'videoPlayer',
  'visArea',
  'visAreaStacked',
  'visBarHorizontal',
  'visBarHorizontalStacked',
  'visBarVertical',
  'visBarVerticalStacked',
  'visGauge',
  'visGoal',
  'visLine',
  'visMapCoordinate',
  'visMapRegion',
  'visMetric',
  'visPie',
  'visTable',
  'visTagCloud',
  'visText',
  'visTimelion',
  'visVega',
  'visVisualBuilder',
  'visualizeApp',
  'watchesApp',
  'wordWrap',
  'wordWrapDisabled',
  'workplaceSearchApp',
  'wrench',
  'tokenAlias',
  'tokenAnnotation',
  'tokenArray',
  'tokenBinary',
  'tokenBoolean',
  'tokenClass',
  'tokenCompletionSuggester',
  'tokenConstant',
  'tokenDate',
  'tokenDenseVector',
  'tokenElement',
  'tokenEnum',
  'tokenEnumMember',
  'tokenEvent',
  'tokenException',
  'tokenField',
  'tokenFile',
  'tokenFlattened',
  'tokenFunction',
  'tokenGeo',
  'tokenHistogram',
  'tokenInterface',
  'tokenIP',
  'tokenJoin',
  'tokenKey',
  'tokenKeyword',
  'tokenMethod',
  'tokenMetricCounter',
  'tokenMetricGauge',
  'tokenModule',
  'tokenNamespace',
  'tokenNested',
  'tokenNull',
  'tokenNumber',
  'tokenObject',
  'tokenOperator',
  'tokenPackage',
  'tokenParameter',
  'tokenPercolator',
  'tokenProperty',
  'tokenRange',
  'tokenRankFeature',
  'tokenRankFeatures',
  'tokenRepo',
  'tokenSearchType',
  'tokenShape',
  'tokenString',
  'tokenStruct',
  'tokenSymbol',
  'tokenTag',
  'tokenText',
  'tokenTokenCount',
  'tokenVariable',
];
