import { createAction, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import axios from 'axios';
import { captureException } from '@sentry/react';

import { leakApi } from '@/api';
import { appActions } from '../app';
import { RootState } from '../store';

export interface State {
  loading: boolean;
  items: Definitions.RecursiveSearchTask[];
  watchedItems: string[];
  total_items: number;
  error: Definitions.Error | null;
  detail: Definitions.RecursiveSearchResponse | null;
  edited: Definitions.RecursiveSearchResponse | null;
}

type PayloadEditTask = string | null;

const clearSearch = createAction('recursive/clearSearch');
const clearDetail = createAction('recursive/clearDetail');
const editTask = createAction<PayloadEditTask>('recursive/editTask');

export const fetchData = createAsyncThunk(
  'recursive/fetchData',
  async (payload: Paths.GetRecursiveSearchTasks.QueryParameters, thunkApi) => {
    thunkApi.dispatch(appActions.getDatabaseLoad());

    try {
      const {
        data: { data },
      } = await leakApi.getRecursiveTasks(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchById = createAsyncThunk(
  'recursive/fetchById',
  async (payload: Paths.GetRecursiveSearchTaskResults.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.getRecursiveTask(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const remove = createAsyncThunk(
  'recursive/remove',
  async (payload: Paths.DeleteRecursiveSearchTask.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.deleteRecursiveTask(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.DeleteUpdateLog.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

export const create = createAsyncThunk(
  'recursive/create',
  async (payload: Paths.CreateRecursiveSearchTasks.Parameters.Body, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.createRecursiveTask(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.RegisterCustomer.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

export const edit = createAsyncThunk(
  'recursive/edit',
  async (payload: Paths.EditRecursiveSearchTask.Parameters.Body, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.editRecursiveTask(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.RegisterCustomer.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

type CheckStatusPayload = {
  id: string;
  signal: AbortSignal;
};

export const checkStatus = createAsyncThunk('recursive/checkStatus', async (payload: CheckStatusPayload, thunkApi) => {
  try {
    const {
      data: { data },
    } = await leakApi.getRecursiveTask({ id: `${payload.id}` }, payload.signal);
    return data;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const responseError = e?.response as { data: Paths.GetRecursiveSearchTaskResults.Responses.$400 };
      return thunkApi.rejectWithValue(responseError?.data?.error);
    }
  }
});

const STATUSES_FOR_CHECKING = ['failed', 'success'];

export const watch = createAsyncThunk('recursive/watch', async (_, thunkApi) => {
  let intervalId;

  try {
    intervalId = setInterval(() => {
      const watchedItems = (thunkApi.getState() as RootState).recursive.watchedItems;

      (thunkApi.getState() as RootState).recursive.items
        .filter((item) => !STATUSES_FOR_CHECKING.includes(item.status as string))
        .map((item) => item.id as string)
        .filter((id) => !watchedItems.includes(id))
        .forEach((id) => {
          thunkApi.dispatch(checkStatus({ id, signal: thunkApi.signal }));
        });
    }, 5000);
  } catch (e: any) {
    if (thunkApi.signal.aborted) {
      clearInterval(intervalId);
    }
    captureException(e);
  }
});

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'recursive',
  initialState: {
    loading: false,
    items: [],
    total_items: 0,
    error: null,
    watchedItems: [],
    detail: null,
    edited: null,
  },
  reducers: {
    clearSearch: (state) => {
      state.items = [];
      state.total_items = 0;
      state.error = null;
    },
    clearDetail: (state) => {
      state.detail = null;
    },
    editTask: (state, action: PayloadAction<PayloadEditTask>) => {
      if (action.payload) {
        const task = state.items.find((item) => item.id === action.payload);
        if (task) state.edited = task;
      } else {
        state.edited = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        if (action.payload) {
          const { items, total_items } = action.payload;

          state.items = items || [];
          state.total_items = total_items || 0;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        const { error } = action.payload as Paths.SearchCredentials.Responses.$400;
        state.loading = false;
        state.items = [];
        state.total_items = 0;
        state.error = error || {
          message: 'unknownError',
        };
      });

    builder.addCase(edit.fulfilled, (state, action) => {
      if (action.payload) {
        const editedItem = action.payload;
        state.items = state.items.map((item) => (item.id === editedItem.id ? editedItem : item));
      }
    });

    builder
      .addCase(checkStatus.pending, (state, action) => {
        state.watchedItems = [...state.watchedItems, action.meta.arg.id];
      })
      .addCase(checkStatus.fulfilled, (state, action) => {
        state.items = state.items.map((item) =>
          !!action.payload && item.id === action.payload?.id ? action.payload : item
        );
        state.watchedItems = state.watchedItems.filter((id) => action.meta.arg.id !== id);
      })
      .addCase(checkStatus.rejected, (state, action) => {
        state.watchedItems = state.watchedItems.filter((id) => action.meta.arg.id !== id);
      });

    builder.addCase(fetchById.fulfilled, (state, action) => {
      if (action.payload) state.detail = action.payload;
    });
  },
});

export const actions = {
  clearSearch,
  clearDetail,
  fetchData,
  fetchById,
  create,
  remove,
  watch,
  edit,
  editTask,
};

export default slice.reducer;
