import { Navigate, RouteObject } from 'react-router';

import { ReleaseNotes, UpdatesLog } from '@/pages';
import { RoleRequiredChildren } from '@/components';
import { Roles } from '@/hooks';

import { ReleaseNotesRoutes, Routes } from './types';

export const logs: RouteObject[] = [
  {
    path: '',
    element: <UpdatesLog />,
  },
  {
    path: ReleaseNotesRoutes.CREATE,
    element: (
      <RoleRequiredChildren roles={[Roles.SYSTEM]} fallback={<Navigate to={Routes.UPDATES} />}>
        <ReleaseNotes />
      </RoleRequiredChildren>
    ),
  },
  {
    path: ReleaseNotesRoutes.UPDATE,
    element: (
      <RoleRequiredChildren roles={[Roles.SYSTEM]} fallback={<Navigate to={Routes.UPDATES} />}>
        <ReleaseNotes />
      </RoleRequiredChildren>
    ),
  },
];
