import { useCallback, useEffect, useState } from 'react';

export const useApiData = (document: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState<string>('');

  const bootstrap = useCallback(async () => {
    setIsLoading(true);

    const response = await fetch(document);
    const text = await response.text();

    setContent(text);
    setIsLoading(false);
  }, [document]);

  useEffect(() => {
    bootstrap();
  }, [bootstrap]);

  return {
    content,
    isLoading,
  };
};
