import { memo } from 'react';

import { SelectedPoint } from '../types';
import { MarkerCluster, MarketClusterPoint } from '../MarkerCluster';

export interface ClusterPointsProps {
  profilers: Map<string, Definitions.AddressLocation[]>;
  landmarks: Map<string, Definitions.Landmarks>;
  leaks: Map<string, Definitions.LeakLocation>;
  onSelect: (points: SelectedPoint[]) => void;
}

export const ClusterPoints = memo<ClusterPointsProps>(
  function ClusterPoints({ profilers, landmarks, leaks, onSelect }) {
    const data: MarketClusterPoint[] = [];
    profilers.forEach((addresses, id) => {
      addresses.forEach((point) => {
        if (point && point.location)
          data.push({
            id,
            position: {
              lat: point.location.lat,
              lng: point.location.lon,
            },
            type: 'people',
          });
      });
    });
    landmarks.forEach((point, id) => {
      if (point.location && point.type) {
        data.push({
          id,
          position: {
            lat: point.location.lat,
            lng: point.location.lon,
          },
          type: point.type,
        });
      }
    });

    leaks.forEach((point, id) => {
      if (point.location) {
        const { location, ...rest } = point;
        data.push({
          id,
          position: {
            lat: location.lat,
            lng: location.lon,
          },
          type: 'compromised_machine',
          ...rest,
        });
      }
    });

    return <MarkerCluster points={data} onPointsSelect={onSelect} spiderfyOnMaxZoom={false} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.profilers === nextProps.profilers &&
      prevProps.landmarks === nextProps.landmarks &&
      prevProps.leaks === prevProps.leaks
    );
  }
);
