import { FunctionComponent, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { EuiButtonIcon, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';

import { useMap } from '../MapContainer';

import { fullscreenControl } from './FullscreenControl';
import './index.scss';

interface FullScreenProps {
  isActive: boolean;
  setActive: (state: boolean) => void;
}

export const FullScreen: FunctionComponent<FullScreenProps> = ({ isActive, setActive }) => {
  const { euiTheme } = useEuiTheme();
  const { map } = useMap();
  const ctx = useRef(fullscreenControl());

  useEffect(() => {
    const control = ctx.current;
    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map]);

  const handleChange = () => {
    setActive(!isActive);
    ctx.current.toggleFullscreen(!isActive);
  };

  if (ctx.current.button) {
    return createPortal(
      <EuiButtonIcon
        className={css(`
                background-color: ${euiTheme.colors.emptyShade};
              `)}
        size="s"
        display="base"
        color="text"
        iconType={isActive ? 'fullScreenExit' : 'fullScreen'}
        onClick={handleChange}
      />,
      ctx.current.button
    );
  }
  return null;
};
