export enum Columns {
  title = 'title',
  status = 'status',
  created_at = 'created_at',
  login = 'login',
  recursion_levels_number = 'recursion_levels_number',
  total_credentials = 'total_credentials',
  total_identities = 'total_identities',
  system_message = 'system_message',
  note = 'note',
}

export type ColumnItem = {
  [key in Columns]: string | number | undefined | null | Definitions.RecursiveSearchTask;
};
