import axios from 'axios';

const headers: Record<string, string> = {
  'Content-Type': 'application/json; charset=utf-8',
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_GEO_API_URL,
  headers,
});

export default instance;
