import { useCallback, useEffect, useMemo, useState } from 'react';
import fileDownload from 'js-file-download';
import { captureException } from '@sentry/react';

import { leakApi } from '@/api';

export const useDownloads = (id?: string) => {
  const [leak, setLeak] = useState<Definitions.LeakData | null>(null);
  const [image, setImage] = useState<string | null>();
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const isCanDownload = useMemo(() => !!leak, [leak]);

  const handleLoadImage = useCallback(async () => {
    try {
      if (leak && leak.screenshot) {
        const response = await leakApi.download(
          {
            target: 'screenshot',
            title: leak.screenshot,
          },
          'blob'
        );
        if (response.data) {
          if (response.data.size > 0) {
            const imageUrl = URL.createObjectURL(response.data);
            setImage(imageUrl);
          } else {
            captureException(new Error('Image cannot load! Has size < 0'));
          }
        }
      }
    } catch (e) {
      captureException(e);
    } finally {
      setIsLoadingImage(false);
    }
  }, [leak]);

  const handleLoadFile = useCallback(async () => {
    if (leak) {
      try {
        setIsLoadingFile(true);
        const response = await leakApi.download(
          {
            target: 'archive',
            title: `${leak.title}.zip`,
          },
          'blob'
        );

        fileDownload(response.data, `${leak.title}.zip`);
      } catch (e) {
        captureException(e);
      } finally {
        setIsLoadingFile(false);
        setIsOpenConfirmModal(false);
      }
    }
  }, [leak]);

  const handleLoadLeak = useCallback(async () => {
    if (id) {
      try {
        const response = await leakApi.getLeakById({ id });
        if (response && response.data && response.data.data) setLeak(response.data.data);
      } catch (e) {
        captureException(e);
      }
    }
  }, [id]);

  useEffect(() => {
    handleLoadImage();
  }, [handleLoadImage]);

  useEffect(() => {
    handleLoadLeak();
  }, [handleLoadLeak]);

  return {
    image,
    leak,
    isLoadingImage,
    isLoadingFile,
    isCanDownload,
    isOpenConfirmModal,
    handleLoadFile,
    setIsOpenConfirmModal,
  };
};
