import { EuiHealth, EuiText } from '@elastic/eui';
import { FunctionComponent } from 'react';

type DatabaseStatusStatusParam = 'medium' | 'high' | 'low' | string;

interface DatabaseStatusProps {
  status?: DatabaseStatusStatusParam;
  className?: string;
}

const getColor = (status?: DatabaseStatusStatusParam): string => {
  switch (status) {
    case 'medium':
      return 'warning';
    case 'low':
      return 'success';
    case 'high':
      return 'danger';
    default:
      return 'default';
  }
};
export const DatabaseStatus: FunctionComponent<DatabaseStatusProps> = ({ status, className }) => {
  const color = getColor(status);
  return (
    <div
      className={className}
      style={{
        display: 'inline-block',
      }}
    >
      <EuiHealth
        style={{
          textTransform: 'capitalize',
        }}
        color={color}
      >
        <EuiText color={color}>{status}</EuiText>
      </EuiHealth>
    </div>
  );
};
