import { object, string, array, SchemaOf, boolean } from 'yup';

import { FormFields } from './types';

export const schema: SchemaOf<Omit<FormFields, 'id'> & { id?: string }> = object().shape({
  title: string().required('form.error.required'),
  description: string().notRequired(),
  members: array().of(object()).notRequired(),
  is_active: boolean().notRequired(),
  id: string().notRequired(),
});
