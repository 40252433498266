import {
  EuiBadge,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLink,
  EuiSpacer,
  EuiSplitPanel,
  EuiText,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cn from 'classnames';
import { captureException } from '@sentry/react';
import { useHref, useLinkClickHandler } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';

import { LeakCountryBadge, useCountry } from '@/components/LeakCountryBadge';
import { PointMachine } from '../types';
import { useAppDispatch, useAppSelector } from '@/store';
import { mapActions, mapSelectors } from '@/store/map';
import { Loader } from '@/components/Loader';
import { leakApi } from '@/api';
import { Routes } from '@/routes';
import { getLines, CardData, filterData } from '@/components/CardData';

interface LeakMapPointCardProps {
  point: PointMachine;
}
export const LeakMapPointCard: FunctionComponent<LeakMapPointCardProps> = ({ point }) => {
  const { getCountry } = useCountry();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { euiTheme } = useEuiTheme();

  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [image, setImage] = useState<string | null>();

  const data = useAppSelector((state) => mapSelectors.getLeaksCardById(state, point.id as string));
  const credentialsCount = useAppSelector((state) =>
    mapSelectors.getLeaksCredentialCounterById(state, point.id as string)
  );

  const [isLoading, setLoading] = useState(!data);

  const [isOpen, setOpen] = useState(false);

  const params = useMemo(() => {
    const urlSearchParams = new URLSearchParams({
      query: `leaks.title.eq: "${point.title}"`,
    });

    return urlSearchParams.toString();
  }, [point]);

  const url = useHref({
    search: params,
    pathname: Routes.PASSWORD_FINDER,
  });

  const handleClick = useLinkClickHandler(url, {
    target: '_blank',
  });

  const handleToggleOpen = () => {
    setOpen((state) => !state);
  };

  const handleLoadImage = useCallback(async () => {
    if (data && data.screenshot) {
      try {
        const response = await leakApi.download(
          {
            target: 'screenshot',
            title: data.screenshot,
          },
          'blob'
        );
        if (response.data) {
          if (response.data.size > 0) {
            const imageUrl = URL.createObjectURL(response.data);
            setImage(imageUrl);
          } else {
            captureException(new Error('Image cannot load! Has size < 0'));
          }
        }
      } catch (e) {
        captureException(e);
      } finally {
        setIsLoadingImage(false);
      }
    }
  }, [data]);

  useEffect(() => {
    handleLoadImage();
  }, [handleLoadImage]);

  useEffect(() => {
    if (!data && isOpen) {
      try {
        Promise.all([
          dispatch(
            mapActions.fetchLeakCardById({
              id: point.id,
            })
          ).unwrap(),
          dispatch(
            mapActions.fetchLeakCredentialsCounter({
              id: point.id,
              query: `leaks.title.eq: "${point.title}"`,
            })
          ).unwrap(),
        ]);
      } finally {
        setLoading(false);
      }
    }
  }, [isOpen, data, dispatch, point]);

  const lines = useMemo(
    () => (data?.meta ? getLines.call({ profilerValidatedKeys: [] }, filterData(cloneDeep(data?.meta)), 0, '') : null),
    [data]
  );

  return (
    <EuiSplitPanel.Outer hasBorder={true} hasShadow={false} borderRadius={'none'}>
      <EuiSplitPanel.Inner color="subdued" grow={false}>
        <EuiFlexGroup gutterSize="xs" alignItems="center">
          {point?.country && (
            <EuiFlexItem grow={0}>
              <LeakCountryBadge code={point.country} getCountry={getCountry} />
            </EuiFlexItem>
          )}
          {point?.released_at && (
            <EuiFlexItem grow={0}>
              <EuiBadge
                className={cn(
                  'leakText',
                  css(`
      border-color: #D3DAE6!important;
      margin: 0!important;
    `)
                )}
                color="transparent"
                title={t(`tooltips.date`)}
              >
                {moment.parseZone(point.released_at).format(process.env.REACT_APP_DATE_FORMAT)}
              </EuiBadge>
            </EuiFlexItem>
          )}
          <EuiFlexItem
            grow={1}
            style={{
              minWidth: 0,
            }}
          >
            <EuiText size="s">
              <EuiLink
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingRight: 16,
                  maxWidth: '100%',
                }}
                color={'text'}
                onClick={handleToggleOpen}
              >
                <b className="leakText">{point?.title}</b>
              </EuiLink>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={0}>
            <EuiButtonIcon
              aria-label={isOpen ? 'hide' : 'show'}
              color="ghost"
              iconType={isOpen ? 'arrowUp' : 'arrowDown'}
              onClick={handleToggleOpen}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiSplitPanel.Inner>
      {isOpen && (
        <EuiSplitPanel.Inner>
          {data && !isLoading ? (
            <>
              <EuiTitle size="xxs">
                <h2
                  style={{
                    fontWeight: euiTheme.font.weight.regular,
                    wordBreak: 'break-all',
                    paddingRight: euiTheme.size.m,
                  }}
                >
                  <b>{t('modals.leak.title')}:</b> {data.title}
                </h2>
              </EuiTitle>
              <EuiSpacer size="m" />
              {!isLoadingImage && image && (
                <EuiFlexItem grow={0}>
                  <EuiImage size="l" allowFullScreen alt={data.title as string} src={image} />
                </EuiFlexItem>
              )}
              <EuiSpacer size="m" />
              <EuiFlexGroup alignItems="flexStart">
                <EuiFlexItem grow={1}>
                  {!!data.released_at && (
                    <EuiFlexGroup gutterSize="s" alignItems="center">
                      <EuiBadge>released_at</EuiBadge>
                      <EuiText size="xs">{data.released_at}</EuiText>
                    </EuiFlexGroup>
                  )}
                  <EuiSpacer size="xs" />
                  {!!data.type && i18n.exists(`modals.leak.type.${data.type}`) && (
                    <EuiText size="s" color="subdued">
                      {t(`modals.leak.type.${data.type}`)}
                    </EuiText>
                  )}
                  {!!credentialsCount && (
                    <>
                      <EuiSpacer size="m" />
                      <EuiFlexGroup gutterSize="s">
                        <EuiButtonEmpty
                          style={{ paddingInline: 0 }}
                          iconType="search"
                          contentProps={{
                            style: {
                              paddingInline: 0,
                            },
                          }}
                          href={url.toString()}
                          onClick={handleClick}
                          target="_blank"
                        >
                          {t('modals.leak.button.showAll')}
                        </EuiButtonEmpty>
                      </EuiFlexGroup>
                    </>
                  )}
                  <EuiSpacer size="l" />
                </EuiFlexItem>
              </EuiFlexGroup>
              {lines && <CardData id={data.id as string} data={lines} />}
            </>
          ) : (
            <EuiFlexGroup alignItems="center" justifyContent="center">
              <Loader />
            </EuiFlexGroup>
          )}
        </EuiSplitPanel.Inner>
      )}
    </EuiSplitPanel.Outer>
  );
};
