import {
  EuiButtonEmpty,
  EuiButtonGroup,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiPanel,
  EuiSpacer,
  EuiStat,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { captureException } from '@sentry/react';
import { useReactToPrint } from 'react-to-print';

import { EmptyPanel, Panel, PanelWrapper, StatisticChart, TreemapChart } from '@/components';
import { Roles, useRole, useTitle } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { organizationActions, organizationSelectors } from '@/store/organization';
import { customerSelectors } from '@/store/customer';
import { OrganizationSelector } from '@/components/OrganizationSelector';
import { ReactComponent as PrintIcon } from '@/assets/icons/Print.svg';
import { Period } from '@/components/StatisticChart/types';

export type DatePicker = 'week' | 'month' | 'year' | 'all' | 'custom';

export type FormFields = Paths.OrganizationsCounters.QueryParameters & Paths.OrganizationsCounters.PathParameters;

export const dates: DatePicker[] = ['week', 'month', 'year', 'all', 'custom'];
const stats: (keyof Omit<
  Definitions.HistoryCountersData,
  'requests_by_access_type' | 'requests_by_tools' | 'requests_by_users' | 'top_users'
>)[] = [
  'total_requests',
  'passwords_finder_requests',
  'profiler_requests',
  'profiler_2_requests',
  'profiler_leaks_requests',
  'api_requests',
  'telegram_bot_requests',
  'web_app_requests',
  'deep_search_requests',
  'downloaded_data_sets',
  'map_radar_requests',
  'total_notes',
  'total_markers',
  'total_users',
];
const dataAdapter = (data: FormFields) => {
  try {
    for (const key in data) {
      if (!data[key as keyof FormFields]) {
        delete data[key as keyof FormFields];
      }
    }

    for (const key in data) {
      if ((key as keyof FormFields) === 'from' || (key as keyof FormFields) === 'to') {
        data[key as keyof FormFields] = moment(data[key as keyof FormFields]).format('YYYY-MM-DD');
      }
    }
  } catch (e) {
    captureException(e);
  }
  return data;
};

const getPeriod = (date: DatePicker): Period => {
  switch (date) {
    case 'week':
      return 'week';
    case 'month':
      return 'month';
    case 'year':
      return 'year';
    default:
      return 'auto';
  }
};

export const getDate = (date: DatePicker): { from_date: string; to_date: string } => {
  let from_date = '';
  let to_date = '';
  switch (date) {
    case 'week': {
      from_date = moment().startOf('isoWeek').toISOString();
      to_date = moment().endOf('isoWeek').toISOString();
      break;
    }
    case 'month': {
      from_date = moment().startOf('month').toISOString();
      to_date = moment().endOf('month').toISOString();
      break;
    }

    case 'year': {
      from_date = moment().startOf('year').toISOString();
      to_date = moment().endOf('year').toISOString();
      break;
    }
    case 'custom': {
      from_date = moment().toISOString();
      to_date = moment().toISOString();
      break;
    }
  }

  return {
    from_date,
    to_date,
  };
};

const getTotal = (data: Definitions.RequestsMetric) =>
  Array.from(
    Object.values(data)
      .reduce((prev, current) => prev.concat(current || []), [])
      .reduce((map, current) => {
        const { day } = current;
        const grouped = map.get(day);
        if (!grouped) {
          map.set(day, { ...current });
        } else {
          map.set(day, { ...grouped, count: grouped.count + current.count });
        }
        return map;
      }, new Map())
      .values()
  );

export default function OrganizationStatistic() {
  const dispatch = useAppDispatch();

  const { isValid: isSystem } = useRole([Roles.SYSTEM]);

  const componentRef = useRef(null);

  const user = useAppSelector(customerSelectors.getInfo);
  const isLoading = useAppSelector(organizationSelectors.getLoadingCounters);
  const counters = useAppSelector(organizationSelectors.getCounters);

  const requests_by_access_type = useMemo<Definitions.RequestsMetric>(
    () =>
      counters && counters.requests_by_access_type
        ? {
            total: getTotal(counters.requests_by_access_type),
            ...counters.requests_by_access_type,
          }
        : {},
    [counters]
  );

  const requests_by_tools = useMemo<Definitions.RequestsMetric>(
    () =>
      counters && counters.requests_by_tools
        ? {
            total: getTotal(counters.requests_by_tools),
            ...counters.requests_by_tools,
          }
        : {},
    [counters]
  );

  const { t } = useTitle('page.organizationStatistic.title');

  const [date, setDate] = useState<DatePicker>('all');
  const [organizationId, setOrganizationId] = useState(user?.organization_id);

  const { control, watch, setValue, register, unregister, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      organization_id: `${organizationId}`,
      from: getDate(date).from_date,
      to: getDate(date).to_date,
    },
  });

  const { from, to } = watch();

  const isDisabledDatePicker = useMemo(() => date !== 'custom', [date]);

  const onSubmit = useCallback(
    (data: FormFields) => {
      dispatch(organizationActions.fetchCounters(dataAdapter(data)));
    },
    [dispatch]
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const { from_date, to_date } = getDate(date);

    setValue('from', from_date);
    setValue('to', to_date);
  }, [date, setValue]);

  useEffect(() => {
    setValue('organization_id', `${organizationId}`);
  }, [organizationId, setValue]);

  useEffect(() => {
    handleSubmit(onSubmit)();
  }, [from, to, organizationId, onSubmit, handleSubmit]);

  useEffect(() => {
    register('organization_id');
    return () => {
      unregister('organization_id');
    };
  }, [register, unregister]);

  useEffect(() => {
    if (date !== 'custom') {
      register('from');
      register('to');
    }

    return () => {
      if (date !== 'custom') {
        unregister('from');
        unregister('to');
      }
    };
  }, [date, register, unregister]);

  return (
    <PanelWrapper panelRef={componentRef}>
      <EmptyPanel>
        <EuiSpacer size="l" />
        <EuiFlexGroup alignItems="center">
          <EuiTitle size="s">
            <h1>{t('page.organizationStatistic.title')}</h1>
          </EuiTitle>
          {isSystem && <OrganizationSelector selectedId={organizationId} onChange={setOrganizationId} />}
        </EuiFlexGroup>
        <EuiSpacer size="l" />
        <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
          <EuiFlexItem grow={1}>
            <EuiFlexGroup alignItems="center">
              <EuiTitle size="xs">
                <h2>{t('page.organizationStatistic.section_1')}</h2>
              </EuiTitle>
              <EuiButtonEmpty aria-label="print" size="s" iconType={PrintIcon} onClick={handlePrint}>
                {t('button.print')}
              </EuiButtonEmpty>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={0} aria-label="print">
            <EuiFlexGroup>
              <EuiDatePickerRange
                isInvalid={!isDisabledDatePicker && !!from && !!to && Date.parse(from) > Date.parse(to)}
                disabled={isDisabledDatePicker}
                startDateControl={
                  <Controller
                    control={control}
                    name="from"
                    render={({ field: { value, onChange } }) => (
                      <EuiDatePicker
                        selected={value ? moment(value) : moment()}
                        onChange={(date) => date && onChange(date)}
                        startDate={value ? moment(value) : moment()}
                        endDate={to ? moment(to) : moment()}
                        aria-label="Start date"
                        disabled={isDisabledDatePicker}
                        dateFormat={process.env.REACT_APP_DATE_FORMAT}
                        minDate={moment('01-01-2022')}
                      />
                    )}
                  />
                }
                endDateControl={
                  <Controller
                    control={control}
                    name="to"
                    render={({ field: { value, onChange } }) => (
                      <EuiDatePicker
                        selected={value ? moment(value) : moment()}
                        onChange={(date) => date && onChange(date)}
                        startDate={from ? moment(from) : moment()}
                        endDate={value ? moment(value) : moment()}
                        aria-label="End date"
                        disabled={isDisabledDatePicker}
                        showIcon={false}
                        dateFormat={process.env.REACT_APP_DATE_FORMAT}
                        minDate={moment('01-01-2022')}
                      />
                    )}
                  />
                }
              />
              <EuiButtonGroup
                legend=""
                color="primary"
                options={dates.map((id) => ({
                  id,
                  label: t(`page.organizationStatistic.datePicker.${id}`),
                }))}
                idSelected={date}
                onChange={(id) => setDate(id as DatePicker)}
              />
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EmptyPanel>
      <EuiSpacer size="xl" />
      {isLoading ? (
        <Panel>
          <EuiSpacer size="xl" />
          <EuiLoadingContent lines={10} />
          <EuiSpacer size="xl" />
          <EuiLoadingContent lines={10} />
          <EuiSpacer size="xl" />
          <EuiLoadingContent lines={10} />
          <EuiSpacer size="xl" />
          <EuiLoadingContent lines={10} />
          <EuiSpacer size="xl" />
        </Panel>
      ) : counters ? (
        <Panel>
          <EuiFlexGroup wrap justifyContent="flexStart">
            {stats.map((key) => (
              <EuiFlexItem key={key} grow={0}>
                <EuiPanel
                  style={{
                    width: 220,
                    paddingBlock: 42,
                    paddingInline: 16,
                  }}
                >
                  <EuiStat
                    descriptionElement="div"
                    title={new Intl.NumberFormat('uk-UA').format(Number(counters[key]) || 0)}
                    description={
                      <>
                        <EuiText size="s" color="subdued">
                          {t(`page.organizationStatistic.data.${key}`)}
                        </EuiText>
                        <EuiSpacer size="xs" />
                      </>
                    }
                  />
                </EuiPanel>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
          <EuiSpacer size="xl" aria-label="print-break" />
          <EuiTitle size="xs">
            <h2>{t('page.organizationStatistic.section_2')}</h2>
          </EuiTitle>
          <EuiSpacer size="m" />
          <StatisticChart data={requests_by_access_type} period={getPeriod(date)} from={from || ''} to={to || ''} />
          <EuiSpacer size="xl" />
          <EuiTitle size="xs">
            <h2>{t('page.organizationStatistic.section_3')}</h2>
          </EuiTitle>
          <EuiSpacer size="m" />
          <StatisticChart data={requests_by_tools} period={getPeriod(date)} from={from || ''} to={to || ''} />

          {counters.requests_by_users && (
            <>
              <EuiSpacer size="xl" aria-label="print-break" />
              <EuiTitle size="xs">
                <h2>{t('page.organizationStatistic.section_4')}</h2>
              </EuiTitle>
              <EuiSpacer size="m" />
              <StatisticChart
                data={counters.requests_by_users}
                type="line"
                period={getPeriod(date)}
                from={from || ''}
                to={to || ''}
              />
            </>
          )}

          {counters.top_users && (
            <>
              <EuiSpacer size="xl" />
              <EuiTitle size="xs">
                <h2>{t('page.organizationStatistic.section_5')}</h2>
              </EuiTitle>
              <EuiSpacer size="m" />
              <TreemapChart data={counters.top_users} />
            </>
          )}
        </Panel>
      ) : null}
    </PanelWrapper>
  );
}
