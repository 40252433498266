import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { captureException } from '@sentry/react';
import fileDownload from 'js-file-download';
import { EuiButtonIcon } from '@elastic/eui';

import { Files } from '../CardData/types';
import { useAppDispatch } from '@/store';
import { leakApi } from '@/api';
import { toastActions } from '@/store/toast';
import { ToastVariantBasic } from '@/components/Toasts';
import { ReactComponent as ImportIcon } from '@/assets/icons/Import.svg';
import { ReactComponent as FullscreenIcon } from '@/assets/icons/FullScreen.svg';

import { FileLoader, FileTypes } from './types';
import { PreviewFile } from './PreviewFile';
import { Thumbnail } from './Thumbnail';
import './styles.scss';

type FileProps = Pick<Files, 'value'>['value'][number];
export const FileCard: FC<FileProps> = ({ name, s3_file_path: path, s3_thumbnail_path: thumbnail_file }) => {
  const dispatch = useAppDispatch();

  const abortController = useRef<AbortController>(new AbortController());

  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [isLoaded, setLoaded] = useState<FileLoader>('none');
  const [file, setFile] = useState<Blob | null>(null);
  const { extension, type } = useMemo<{ extension: string; type: FileTypes }>(() => {
    const extension = name?.split('.').pop()?.toLowerCase() || '';
    let type: FileTypes = 'unknown';
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'webp':
        type = 'image';
        break;
      case 'pdf':
      case 'doc':
      case 'docx':
      case 'txt':
        type = 'document';
        break;
      default:
        type = 'unknown';
    }

    return {
      extension,
      type,
    };
  }, [name]);

  const loadFile = useCallback(
    async (loader: FileLoader) => {
      setLoaded((prevLoader) => {
        if (prevLoader === 'none') return loader;
        return 'all';
      });
      if (isLoaded === 'none') {
        try {
          const { data } = await leakApi.download(
            {
              target: 'document',
              title: path as string,
            },
            'blob',
            abortController.current.signal
          );

          setFile(data);
        } catch (error: any) {
          if (error?.code !== "ERR_CANCELED'") {
            captureException(error);
            dispatch(
              toastActions.create({
                type: ToastVariantBasic.DOWNLOADED_ERROR_FILE,
              })
            );
          }
        } finally {
          setLoaded('none');
        }
      }
    },
    [dispatch, isLoaded, path]
  );

  const handleSaveFile = useCallback(
    ({ detail: file }: CustomEvent<Blob>) => {
      fileDownload(file, (name || path) as string);
      document.removeEventListener('loadFile', handleSaveFile);
    },
    [name, path]
  );

  const handleDownload = async () => {
    document.addEventListener('loadFile', handleSaveFile);
    if (file) {
      document.dispatchEvent(
        new CustomEvent<Blob>('loadFile', {
          detail: file,
        })
      );
    } else {
      await loadFile('download');
    }
  };

  const handleView = async () => {
    if (file) {
      setFullScreen(true);
    } else {
      await loadFile('view');
      setFullScreen(true);
    }
  };

  useEffect(() => {
    if (file) {
      document.dispatchEvent(
        new CustomEvent<Blob>('loadFile', {
          detail: file,
        })
      );
    }
  }, [file]);

  useEffect(() => {
    const controller = abortController.current;
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener('loadFile', handleSaveFile);
    };
  }, [handleSaveFile]);

  const canViewving = type === 'image' || (type === 'document' && extension === 'pdf');

  const isLoadDownloading = isLoaded === 'all' || isLoaded === 'download';
  const isLoadViewving = isLoaded === 'all' || isLoaded === 'view';

  return (
    <div className="fileCard">
      <Thumbnail name={name} ext={extension} type={type} file={thumbnail_file} />
      <div className="controls">
        <EuiButtonIcon
          isLoading={isLoadDownloading}
          onClick={handleDownload}
          display="base"
          color="text"
          iconType={ImportIcon}
        />
        {canViewving && (
          <EuiButtonIcon
            isLoading={isLoadViewving}
            onClick={handleView}
            display="base"
            color="text"
            iconType={FullscreenIcon}
          />
        )}
      </div>
      {fullScreen && file && canViewving && (
        <PreviewFile
          name={name || path}
          file={URL.createObjectURL(file)}
          ext={extension}
          type={type}
          onClose={() => setFullScreen(false)}
        />
      )}
    </div>
  );
};
