import { useTranslation } from 'react-i18next';
import { EuiFlexGroup, EuiLink, EuiText } from '@elastic/eui';

export const TelegramBotInfo = () => {
  const { t } = useTranslation();
  return (
    <EuiFlexGroup direction="column" gutterSize="s">
      <EuiText size="s" style={{ lineHeight: '1.7rem' }}>
        {t('page.account.settings.telegram.subtitle')}
      </EuiText>
      <EuiLink href={process.env.REACT_APP_TELEGRAM_BOT_LINK} target="_blank" external={false}>
        {process.env.REACT_APP_TELEGRAM_BOT_LINK}
      </EuiLink>
      <EuiText
        size="s"
        style={{
          maxWidth: 368,
          whiteSpace: 'pre-wrap',
          marginTop: '16px',
        }}
      >
        {t('page.account.settings.telegram.description')}
      </EuiText>
    </EuiFlexGroup>
  );
};
