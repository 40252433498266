import { configureStore } from '@reduxjs/toolkit';

import { credentialsReducer } from './credentials';
import { authenticationReducer } from './authentication';
import { customerReducer } from './customer';
import { toastReducer } from './toast';
import { organizationReducer } from './organization';
import { groupsReducer } from './groups';
import { profilerReducer } from './profiler';
import { appReducer } from './app';
import { leaksReducer } from './leaks';
import { logsReducer } from './logs';
import { phonebookReducer } from './phonebook';
import { mapReducer } from './map';
import { datasetReducer } from './dataset';
import { recursiveReducer } from './recursive';
import { profiler2Reducer } from './profiler2';

export const store = configureStore({
  reducer: {
    credentials: credentialsReducer,
    authentication: authenticationReducer,
    customer: customerReducer,
    toast: toastReducer,
    organization: organizationReducer,
    groups: groupsReducer,
    profiler: profilerReducer,
    app: appReducer,
    leaks: leaksReducer,
    logs: logsReducer,
    phonebook: phonebookReducer,
    map: mapReducer,
    dataset: datasetReducer,
    recursive: recursiveReducer,
    profiler2: profiler2Reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
