import moment from 'moment';

const peopleSexes = ['male', 'female'] as const;

type Sex = typeof peopleSexes[number];

export class ProfilerValidator {
  constructor(value: string) {
    this.value = value;
  }

  private RU_INN_COEFFICIENT_NATURAL = {
    10: [7, 2, 4, 10, 3, 5, 9, 4, 6, 8],
    12: [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8],
  };
  private RU_INN_COEFFICIENT_LEGAL = [2, 4, 10, 3, 5, 9, 4, 6, 8];
  private UA_INN_COEFFICIENT = [-1, 5, 7, 9, 4, 6, 10, 5, 7];

  private value: string;

  private validateINNRu() {
    const inn = this.value;
    if (inn.length === 10) {
      const checkSum =
        (Array.from(inn.slice(0, 9)).reduce(
          (acc, curr, index) => (acc += Number(curr) * this.RU_INN_COEFFICIENT_LEGAL[index]),
          0
        ) %
          11) %
        10;

      return checkSum === Number(inn[9]);
    } else if (inn.length === 12) {
      const checkSum10 =
        (Array.from(inn.slice(0, 10)).reduce(
          (acc, curr, index) => (acc += Number(curr) * this.RU_INN_COEFFICIENT_NATURAL[10][index]),
          0
        ) %
          11) %
        10;
      const checkSum12 =
        (Array.from(inn.slice(0, 11)).reduce(
          (acc, curr, index) => (acc += Number(curr) * this.RU_INN_COEFFICIENT_NATURAL[12][index]),
          0
        ) %
          11) %
        10;

      return checkSum10 === Number(inn[10]) && checkSum12 === Number(inn[11]);
    }
    return false;
  }

  private validateINNUa() {
    const inn = this.value;

    if (inn.length === 10) {
      const checkSum =
        (Array.from(inn.slice(0, 9)).reduce(
          (acc, curr, index) => (acc += Number(curr) * this.UA_INN_COEFFICIENT[index]),
          0
        ) %
          11) %
        10;

      return checkSum === Number(inn[9]);
    }
    return false;
  }

  static validateSnils(value: string) {
    const snils = value.replaceAll(/\D/g, '');
    if (snils.length <= 9) return false;

    const number = snils.slice(0, 9);
    const sum = snils.slice(9, snils.length);

    const checkSum = Array.from(number)
      .reverse()
      .reduce((acc, number, index) => (acc += Number(number) * (index + 1)), 0);

    const reminderFromDivision = checkSum % 101;

    return reminderFromDivision === Number(sum);
  }

  static validateINN(value: string) {
    if (value.match(/\D/)) return false;
    const validator = new ProfilerValidator(value);
    return validator.validateINNRu() || validator.validateINNUa();
  }

  static validateBirthday(value: string) {
    const dateNow = moment();
    let date = moment(value);
    if (!date.isValid()) {
      date = moment(value, ['DD.MM.YYYY']);
    }
    const year = date.year();
    return date.isValid() && date.valueOf() < dateNow.valueOf() && year >= 1900;
  }

  static validateBirthdayByInn(date: string, inn: string) {
    const validator = new ProfilerValidator(inn);
    const isValidUA = validator.validateINNUa();
    if (isValidUA) {
      if (!ProfilerValidator.validateBirthday(date)) return false;

      let birthday = moment(date);
      if (!birthday.isValid()) {
        birthday = moment(date, ['DD.MM.YYYY']);
      }
      birthday.set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0);
      const daysInInn = Number(inn.slice(0, 5));
      const innBirthday = moment(new Date(1900, 0, 0, 0, 0, 0, 0)).add(daysInInn, 'days');
      return (
        birthday.date() === innBirthday.date() &&
        birthday.month() === innBirthday.month() &&
        birthday.year() === innBirthday.year()
      );
    }
    return null;
  }

  static validateSexByInn(sex: string, inn: string) {
    if (peopleSexes.includes(sex as any)) {
      const validator = new ProfilerValidator(inn);
      const isValidUA = validator.validateINNUa();

      if (isValidUA) {
        const innSex: Sex = Number(inn[8]) % 2 === 0 ? 'female' : 'male';
        return innSex === sex;
      }
    }
    return null;
  }
}
