import { EuiDataGridColumn } from '@elastic/eui';

import { Columns } from './types';

export const columnsConfig: EuiDataGridColumn[] = [
  {
    id: Columns.title,
    displayAsText: 'Title',
    isExpandable: false,
    initialWidth: 300,
    actions: false,
  },
  {
    id: Columns.status,
    displayAsText: 'Status',
    isExpandable: false,
    initialWidth: 200,
    actions: false,
  },
  {
    id: Columns.created_at,
    displayAsText: 'Created at',
    isExpandable: false,
    actions: false,
    initialWidth: 200,
  },
  {
    id: Columns.login,
    displayAsText: 'User',
    isExpandable: false,
    initialWidth: 200,
    actions: false,
  },
  {
    id: Columns.records,
    displayAsText: 'Records',
    isExpandable: false,
    actions: false,
    initialWidth: 200,
  },
  {
    id: Columns.note,
    displayAsText: 'Note',
    isExpandable: false,
    actions: false,
  },
];

export const CONFIG_ITEMS_PER_PAGE = [25, 50, 100];
