export enum Columns {
  trust = 'trust',
  description = 'description',
  emails_count = 'emails_count',
  released_at = 'released_at',
  title = 'title',
  records = 'records',
  fields = 'fields',
  addresses_count = 'addresses_count',
  birthday_count = 'birthday_count',
  citizenships_count = 'citizenships_count',
  inn_count = 'inn_count',
  insurance_count = 'insurance_count',
  military_count = 'military_count',
  names_count = 'names_count',
  notes_count = 'notes_count',
  organization_count = 'organization_count',
  other_documents_count = 'other_documents_count',
  passports_count = 'passports_count',
  passwords_count = 'passwords_count',
  payments_count = 'payments_count',
  phones_count = 'phones_count',
  positions_count = 'positions_count',
  sessions_count = 'sessions_count',
  snils_count = 'snils_count',
  socials_count = 'socials_count',
  usernames_count = 'usernames_count',
  vehicles_count = 'vehicles_count',
  websites_count = 'websites_count',
  input_lines_count = 'input_lines_count',
}
type PickKey<T, K extends keyof T> = Extract<keyof T, K>;

export type ColumnItem = {
  [key in PickKey<
    typeof Columns,
    'trust' | 'title' | 'released_at' | 'description' | 'fields' | 'records' | 'input_lines_count'
  >]: string | string[] | number | undefined | null;
};

export type SortColumn = {
  id: string;
  direction: SortColumnDirection;
};

export type SortColumnDirection = 'asc' | 'desc';
