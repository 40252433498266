import { AxiosPromise } from 'axios';

import axios from '@/services/axios';

class OrganizationApi {
  private BASE_PATH = '/customer/organizations';

  getCurrent(): AxiosPromise<Paths.Organization.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}`,
      method: 'get',
    });
  }

  getGroups(params: Paths.GetOrganizationGroups.QueryParameters): AxiosPromise<{
    data: Paths.GetOrganizationGroups.Responses.$200;
  }> {
    return axios({
      url: `${this.BASE_PATH}/groups`,
      method: 'get',
      params,
    });
  }

  getMembers(
    params: Paths.GetOrganizationMembers.QueryParameters
  ): AxiosPromise<{ data: Paths.GetOrganizationMembers.Responses.$200 }> {
    return axios({
      url: `${this.BASE_PATH}/members`,
      method: 'get',
      params,
    });
  }

  getStatistic({
    organization_id,
    ...params
  }: Paths.OrganizationsCounters.PathParameters &
    Paths.OrganizationsCounters.QueryParameters): AxiosPromise<Paths.OrganizationsCounters.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${organization_id}/counters`,
      method: 'get',
      params,
    });
  }

  downloadStatistic({
    organization_id,
    ...data
  }: Paths.DownloadOrganizationRequestHistory.PathParameters &
    Paths.DownloadOrganizationRequestHistory.Parameters.Body): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/${organization_id}/request-history`,
      method: 'post',
      data,
    });
  }

  getList(): AxiosPromise<Paths.OrganizationsList.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/list`,
      method: 'get',
    });
  }

  getActionHistory({
    organization_id,
    ...params
  }: Paths.GetOrganizationActionHistory.QueryParameters &
    // eslint-disable-next-line max-len
    Paths.GetOrganizationActionHistory.PathParameters): AxiosPromise<Paths.GetOrganizationActionHistory.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${organization_id}/action-history`,
      method: 'get',
      params,
    });
  }
}

export const organizationApi = new OrganizationApi();
