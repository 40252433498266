import { LatLng, Map } from 'leaflet';
import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';

import { useMap } from './MapContainer';

export type HandleMoveParams = {
  nw: LatLng;
  se: LatLng;
  center: LatLng;
  zoom: number;
};

export type MoveHandler = (params: HandleMoveParams) => void;

interface MoveControlProps {
  onMove: MoveHandler;
}

const preparePoints = (map: Map): HandleMoveParams => {
  const bounds = map.getBounds();
  const center = map.wrapLatLng(map.getCenter());
  const zoomLevel = map.getZoom();
  const nw = map.wrapLatLng(bounds.getNorthWest());
  const se = map.wrapLatLng(bounds.getSouthEast());

  return { nw, se, center, zoom: zoomLevel };
};

export const MoveControl: FunctionComponent<MoveControlProps> = ({ onMove }) => {
  const { map } = useMap();
  const prevCenter = useRef<LatLng>(map.getCenter());
  const prevZoomLevel = useRef<number>(map.getZoom());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMove = useCallback(
    debounce(() => {
      const zoomLevel = map.getZoom();

      if (prevZoomLevel.current === zoomLevel) {
        const bounds = map.getBounds();
        const northEastPoint = bounds.getNorthEast();
        const southWestPoint = bounds.getSouthWest();

        const mapDiagonalInMeters = northEastPoint.distanceTo(southWestPoint);

        const center = map.getCenter();
        const distance = center.distanceTo(prevCenter.current);

        const MOVING_COEFFICIENT = zoomLevel > 8 ? 0.4 : 0.15;

        if (distance > mapDiagonalInMeters * MOVING_COEFFICIENT) {
          prevCenter.current = center;
          onMove(preparePoints(map));
        }
      } else {
        prevZoomLevel.current = map.getZoom();
        prevCenter.current = map.getCenter();
        onMove(preparePoints(map));
      }
    }, 1000),
    [map, onMove]
  );

  useEffect(() => {
    map.on('move', handleMove);

    return () => {
      map.off('move', handleMove);
    };
  }, [map, handleMove]);

  return null;
};
