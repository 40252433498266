import { AxiosPromise } from 'axios';

import axios from '@/services/axios';

class SubscriptionApi {
  private BASE_PATH = '/customer/subscriptions';

  fetchById(id: number): AxiosPromise<{
    data: Definitions.Subscription;
  }> {
    return axios({
      url: `${this.BASE_PATH}/${id}`,
      method: 'get',
    });
  }
}

export const subscriptionApi = new SubscriptionApi();
