import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { leakApi } from '@/api';
import { RootState } from '../store';
import { appActions } from '../app';

export interface State {
  searchParams: Definitions.SearchProfilers | null;
  loading: boolean;
  loadMoreLoading: boolean;
  items: Definitions.Identity[];
  total_items: Definitions.TotalItems;
  error: Definitions.Error | null;
  filters: Definitions.Filter[];
  leak: Definitions.Leak | null;
  counters: Definitions.ProfilerCounters | null;
}

const clearSearch = createAction('profiler/clearSearch');
const clearSearchParams = createAction('profiler/clearSearchParams');
const showLeak = createAction<null | Definitions.Leak>('profiler/showLeak');

export const fetchData = createAsyncThunk(
  'profiler/fetchData',
  async (payload: Definitions.SearchProfilers, thunkApi) => {
    thunkApi.dispatch(showLeak(null));
    thunkApi.dispatch(appActions.getDatabaseLoad());
    const state = thunkApi.getState() as RootState;

    if (
      !!state.profiler.searchParams &&
      !Object.entries(payload).some(
        ([key, value]) =>
          state.profiler.searchParams && value !== state.profiler.searchParams[key as keyof Definitions.SearchProfilers]
      )
    ) {
      return {
        items: state.profiler.items,
        total_items: state.profiler.total_items,
      };
    }
    try {
      const {
        data: { data },
      } = await leakApi.profilersSearch(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchFilters = createAsyncThunk(
  'profiler/fetchFilters',
  async (payload: Paths.ProfilerSearchFilters.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.getProfilersSearchFilter(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const fetchCounters = createAsyncThunk('profiler/fetchCounters', async (_, thunkApi) => {
  try {
    const {
      data: { data },
    } = await leakApi.getProfilerCounters();
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  }
});

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'profiler',
  initialState: {
    loading: false,
    loadMoreLoading: false,
    error: null,
    items: [],
    total_items: {},
    searchParams: null,
    filters: [],
    leak: null,
    counters: null,
  },
  reducers: {
    clearSearch: (state) => {
      state.items = [];
      state.total_items = {};
      state.error = null;
      state.searchParams = null;
    },
    clearSearchParams: (state) => {
      state.searchParams = null;
    },
    showLeak: (state, action) => {
      state.leak = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        if (action.meta.arg.offset === 0) {
          state.loading = true;
        } else {
          state.loadMoreLoading = true;
        }
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.searchParams = action.meta.arg;

        if (action.payload) {
          const { items, total_items } = action.payload;
          if (action.meta.arg.offset === 0) {
            state.items = items || [];
            state.total_items = total_items || {};
          } else {
            if (items) {
              (state.items = [...state.items, ...items]), (state.total_items = total_items || {});
            }
          }
        }

        state.loadMoreLoading = false;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.searchParams = action.meta.arg;
        const { error } = action.payload as Paths.SearchCredentials.Responses.$400;
        state.loading = false;
        state.loadMoreLoading = false;
        state.items = [];
        state.total_items = {};
        state.error = error || {
          message: 'unknownError',
        };
      });

    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      if (action.payload) {
        state.filters = action.payload.items || [];
      }
    });

    builder.addCase(fetchCounters.fulfilled, (state, action) => {
      if (action.payload) {
        state.counters = action.payload || [];
      }
    });
  },
});

export const actions = {
  clearSearch,
  fetchData,
  fetchFilters,
  clearSearchParams,
  showLeak,
  fetchCounters,
};

export default slice.reducer;
