import { EuiButtonIcon, EuiFieldSearch, EuiText } from '@elastic/eui';
import {
  ChangeEventHandler,
  Dispatch,
  KeyboardEventHandler,
  MutableRefObject,
  RefObject,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { List } from 'react-virtualized';
import { captureException } from '@sentry/react';

import { usePrevious } from '@/hooks';
import { formatDate, getType } from '../CardData';

interface AppSearchProfilerProps {
  value: string;
  items: Definitions.Identity[];
  isOpen: boolean;
  listRef: RefObject<List>;
  itemRefs: MutableRefObject<Record<number, HTMLDivElement | null>>;
  showedIndex: number;
  searchIndex: MutableRefObject<string[]>;
  jsonItems: string[];
  setShowedIndex: Dispatch<SetStateAction<number>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setValue: Dispatch<SetStateAction<string>>;
}

export interface AppSearchProfilerRef {
  research: () => void;
  scrollToElement: () => void;
}

export const SCREENING_SPECIAL_CHARS = /(\*|\?|\)|\(|\[|\]|\{|\}|\\|\^|\$|\.|\||\+)/g;

const findItem = (regExp: RegExp, value: number | string | bigint): string[] => {
  return `${value}`.match(regExp) || [];
};

const getSearchValues = (regExp: RegExp, data: Record<any, any>): string[] => {
  const findIds: string[] = [];
  if (typeof data === 'object' && Array.isArray(data)) {
    data.forEach((item) => {
      findIds.push(...getSearchValues(regExp, item));
    });
  } else if (typeof data === 'object' && data !== null) {
    Object.entries(data).forEach(([key, value]) => {
      findIds.push(...findItem(regExp, key));
      findIds.push(...getSearchValues(regExp, value));
    });
  } else if (['number', 'string', 'bigint', 'symbol'].includes(typeof data)) {
    findIds.push(...findItem(regExp, data));
  }
  return findIds;
};

const exclude = new Set(['leak', 'id', 'created_at']);

const itemAdapter = (data: any) => {
  try {
    const type = getType(data);
    switch (type) {
      case 'object':
        for (const key in data) {
          switch (key) {
            case 'date_of_expiry':
            case 'date_of_issue':
            case 'release_order':
            case 'birthday': {
              if (data.date || data.raw) {
                data[key] = formatDate(data.date || data.raw);
              }
              break;
            }
            case 'emails': {
              const keyType = getType(data[key]);
              if (keyType === 'array') {
                data[key] = data[key].map((value: any) => ({
                  email: value.email || value.raw,
                  type: value.type,
                }));
              }
              break;
            }
            default:
              data[key] = itemAdapter(data[key]);
              break;
          }
        }
        return data;
      case 'array':
        return data.map((itemData: any) => itemAdapter(itemData));
      default:
        return data;
    }
  } catch (error) {
    captureException(error);
    return data;
  }
};

export const AppSearchProfiler = forwardRef<AppSearchProfilerRef, AppSearchProfilerProps>(function AppSearchProfiler(
  { showedIndex, items, isOpen, itemRefs, listRef, value, searchIndex, jsonItems, setValue, setIsOpen, setShowedIndex },
  ref
) {
  const position = useRef<HTMLDivElement>(null);
  const [findIndex, setFindIndex] = useState<string[]>([]);
  const previesShowIndex = usePrevious(showedIndex);
  const needToScrollElement = useRef<boolean>(false);

  const adaptedItems = useMemo(
    () => items.map((item) => (jsonItems.includes(item.id) ? item : itemAdapter(JSON.parse(JSON.stringify(item))))),
    [items, jsonItems]
  );

  const searchElements = useCallback(
    (value: string) => {
      const findIds: string[] = [];
      if (value) {
        const searchedRegexp = new RegExp(value.replaceAll(SCREENING_SPECIAL_CHARS, '\\$1'), 'ig');
        adaptedItems.forEach((item, rowIndex) => {
          const ids = getSearchValues(
            searchedRegexp,
            Object.fromEntries(Object.entries(item).filter((e) => !exclude.has(e[0])))
          );
          if (ids.length > 0) findIds.push(...ids.map((_, index) => `${rowIndex + 1}-${index}`));
        });
      }

      setFindIndex(findIds);
    },
    [adaptedItems]
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setValue(event.target.value);
      setShowedIndex(-1);
      searchElements(event.target.value);
    },
    [searchElements, setShowedIndex, setValue]
  );

  const handleSearch = useCallback(
    (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && (event.code === 'KeyF' || String.fromCharCode(event.keyCode) === 'F')) {
        setIsOpen(true);
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [setIsOpen]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleCloseKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey && ['Escape', 'Esc'].includes(event.key)) {
        handleClose();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleClose]
  );

  const handlePrev = useCallback(() => {
    const newIndex = showedIndex - 1;
    if (newIndex > -1) {
      searchIndex.current = [];
      setShowedIndex(newIndex);
    }
  }, [showedIndex, searchIndex, setShowedIndex]);

  const handleNext = useCallback(() => {
    const newIndex = showedIndex + 1;
    if (newIndex < findIndex.length) {
      searchIndex.current = [];
      setShowedIndex(newIndex);
    }
  }, [showedIndex, searchIndex, findIndex, setShowedIndex]);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Enter') {
        if (!!findIndex.length && showedIndex < findIndex.length - 1) {
          handleNext();
        }
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [findIndex.length, showedIndex, handleNext]
  );

  useImperativeHandle(
    ref,
    () => ({
      research: () => {
        if (isOpen && !!value) searchElements(value);
      },
      scrollToElement: () => {
        if (listRef.current && needToScrollElement.current) {
          needToScrollElement.current = false;
          const [rowIndex, cellIndex] = findIndex[showedIndex].split('-');

          if (itemRefs.current && itemRefs.current[Number(rowIndex)]) {
            const currentOffset = listRef.current.getOffsetForRow({
              alignment: 'start',
              index: Number(rowIndex),
            });

            const elements = itemRefs.current[Number(rowIndex)]?.querySelectorAll('.highlight-content');

            if (elements) {
              const searchedRegexp = new RegExp(value.replaceAll(SCREENING_SPECIAL_CHARS, '\\$1'), 'ig');
              const findInNodes = Array.from(elements).filter((element) => element.textContent?.match(searchedRegexp));
              const clearActiveElements = document.querySelectorAll('.highlight-parent[active="true"]');
              clearActiveElements.forEach((element) => element.removeAttribute('active'));
              const findInElements: Element[] = [];
              findInNodes.forEach((node) => {
                const countIncomes = node.textContent?.match(searchedRegexp)?.length || 0;
                for (let i = 0; i < countIncomes; i++) {
                  findInElements.push(node);
                }
              });
              const elementToScroll = findInElements[Number(cellIndex)];
              if (elementToScroll) {
                const parentPosition = itemRefs.current[Number(rowIndex)]?.getBoundingClientRect().top || 0;
                const elementToScrollPosition = elementToScroll.getBoundingClientRect().top;
                const offsetToScroll = currentOffset + (elementToScrollPosition - parentPosition);

                listRef.current?.scrollToPosition(offsetToScroll);

                elementToScroll.parentElement?.setAttribute('active', 'true');
              }
            }
          }
        }
      },
    }),
    [value, isOpen, findIndex, itemRefs, listRef, showedIndex, searchElements]
  );

  useEffect(() => {
    if (showedIndex !== -1 && previesShowIndex !== showedIndex && listRef.current && findIndex[showedIndex]) {
      const [rowIndex] = findIndex[showedIndex].split('-');
      const offset = listRef.current.getOffsetForRow({
        index: Number(rowIndex),
      });
      listRef.current.scrollToPosition(offset + 1);
      listRef.current.scrollToRow(Number(rowIndex));
      needToScrollElement.current = true;
    }
  }, [listRef, showedIndex, previesShowIndex, findIndex]);

  useEffect(() => {
    if (!isOpen) needToScrollElement.current = false;
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (position.current) {
        const top = window.scrollY >= 60 ? 0 : window.scrollY > 0 ? 60 - window.scrollY : 60;
        position.current.style.top = `${top + 5}px`;
      }
    };
    window.addEventListener('keydown', handleSearch);
    window.addEventListener('keydown', handleCloseKeyboard);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('keydown', handleSearch);
      window.removeEventListener('keydown', handleCloseKeyboard);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleSearch, handleCloseKeyboard]);

  if (!isOpen) return null;

  return (
    <div
      ref={position}
      style={{
        position: 'fixed',
        right: 1,
        top: 60,
        minWidth: 350,
        zIndex: 1100,
      }}
    >
      <EuiFieldSearch
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        compressed
        autoFocus
        append={
          <>
            <EuiText>
              {showedIndex > -1 ? showedIndex + 1 : 0}/{findIndex.length}
            </EuiText>
            <EuiButtonIcon
              color="text"
              display="empty"
              size="xs"
              iconType="sortDown"
              onClick={handleNext}
              isDisabled={!(!!findIndex.length && showedIndex < findIndex.length - 1)}
            />
            <EuiButtonIcon
              color="text"
              display="empty"
              size="xs"
              iconType="sortUp"
              onClick={handlePrev}
              isDisabled={!(!!findIndex.length && showedIndex > -1)}
            />
            <EuiButtonIcon color="text" display="empty" size="xs" iconType="cross" onClick={handleClose} />
          </>
        }
      />
    </div>
  );
});
