export enum Columns {
  title = 'title',
  status = 'status',
  created_at = 'created_at',
  login = 'login',
  records = 'total_records',
  note = 'note',
}

export type ColumnItem = {
  [key in Columns]: string | number | undefined | null | Definitions.Leak[] | Definitions.HTTPInfo;
};
