export enum ToastVariantBasic {
  NETWORK_ERROR = 'NETWORK_ERROR',
  DAILY_LIMIT = 'DAILY_LIMIT',
  SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  DOWNLOADING_FILE = 'DOWNLOADING_FILE',
  DOWNLOADED_FILE = 'DOWNLOADED_FILE',
  DOWNLOADED_ERROR_FILE = 'DOWNLOADED_ERROR_FILE',
  TO_MANY_REQUESTS = 'TO_MANY_REQUESTS',
}

export enum ToastVariantWithText {
  SUCCESS_ACTION = 'SUCCESS_ACTION',
  ERROR_ACTION = 'ERROR_ACTION',
  WARNING_ACTION = 'WARNING_ACTION',
}

export type ToastVariant = ToastVariantBasic | ToastVariantWithText;

interface InitialToast {
  id: string;
}
export interface ToastBasic extends InitialToast {
  type: ToastVariantBasic;
}

export interface ToastWithText extends InitialToast {
  type: ToastVariantWithText;
  text: string;
}

export type Toast = ToastBasic | ToastWithText;
