import { createContext, useContext } from 'react';
import { Location } from 'react-router-dom';

import { Routes } from '@/routes';

export const useHistory = () => {
  const { get } = useContext(HistoryContext);
  return get;
};

export interface HistoryContextProps {
  get: (path: string | Location) => Location | Routes | string;
}

export const HistoryContext = createContext<HistoryContextProps>({
  get: () => Routes.HOME,
});
