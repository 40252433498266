import { EuiBadge, EuiCopy, EuiLink, EuiToolTip } from '@elastic/eui';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LeakCountryBadge, useCountry } from '../LeakCountryBadge';

interface LeakCellProps {
  leak: Definitions.CredentialsLeak;
  direction: 'row' | 'column';
  onLeakOpen: (data: Definitions.Leak) => void;
}

export const LeakCell: FC<LeakCellProps> = ({ leak, direction, onLeakOpen }) => {
  const isStealer = leak.type === 'stealer';
  const { t } = useTranslation();
  const { getCountry } = useCountry();
  return (
    <div
      style={{
        flexShrink: 0,
        display: direction === 'row' ? 'inline-block' : 'blok',
      }}
    >
      {leak.country ? (
        <>
          <EuiToolTip
            position="top"
            content={<p>{leak.country === 'WW' ? t(`country.WW`) : getCountry(leak.country)}</p>}
          >
            <LeakCountryBadge code={leak.country} getCountry={getCountry} />
          </EuiToolTip>
          &nbsp;
        </>
      ) : null}
      {leak.title ? (
        <>
          <EuiToolTip position="top" content={<p>{leak.title}</p>}>
            <EuiLink
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '180px',
              }}
              color={isStealer ? 'primary' : 'text'}
              onClick={() => {
                if (isStealer) onLeakOpen(leak);
              }}
            >
              {leak.title}
            </EuiLink>
          </EuiToolTip>
          &nbsp;
        </>
      ) : null}
      {leak.released_at ? (
        <>
          <EuiCopy textToCopy={moment.parseZone(leak.released_at).format(process.env.REACT_APP_DATE_FORMAT)}>
            {(copy) => (
              <EuiBadge onClickAriaLabel="copy leak released_at" onClick={copy}>
                {moment.parseZone(leak.released_at).format(process.env.REACT_APP_DATE_FORMAT)}
              </EuiBadge>
            )}
          </EuiCopy>
          &nbsp;
        </>
      ) : null}
      {leak.meta?.ip ? (
        <EuiCopy textToCopy={leak.meta?.ip}>
          {(copy) => (
            <EuiBadge onClickAriaLabel="copy leak ip" onClick={copy}>
              {leak.meta?.ip}
            </EuiBadge>
          )}
        </EuiCopy>
      ) : null}
      &nbsp;
    </div>
  );
};
