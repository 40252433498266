import { ColumnItem, Columns } from './types';

export const getRowData = (
  items: Definitions.GroupResponse[]
): (ColumnItem & {
  rest: Omit<Definitions.GroupResponse, 'id' | ' title' | 'description' | 'total_members' | 'is_active'>;
})[] =>
  items.map(({ id, title, description, total_members, is_active, ...rest }) => ({
    [Columns.id]: id,
    [Columns.title]: title,
    [Columns.description]: description,
    [Columns.total_members]: total_members,
    [Columns.status]: is_active,
    rest,
  }));
