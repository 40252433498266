import debounce from 'lodash.debounce';
import { Control, ControlOptions, ControlPosition, DomUtil, Map } from 'leaflet';
import { createPortal } from 'react-dom';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiHorizontalRule,
  EuiIcon,
  EuiPanel,
  EuiText,
  EuiToolTip,
  useEuiTheme,
} from '@elastic/eui';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { LandmarksTypes, landmarksTypes } from '@/pages/Map/types';
import { useMap } from '../MapContainer';
import { CUSTOM_CONTAINERS } from '../CustomControlContainers';

import { LandmarkControl } from './LandMarkControl';
import { ReactComponent as Icon } from './icon.svg';
import './styles.scss';

interface CustomControlOptions extends ControlOptions {
  container?: string;
}

const INITIAL_OPTIONS: CustomControlOptions = {
  position: 'bottomright',
  container: CUSTOM_CONTAINERS.FILTERS,
};

interface FilterProps {
  data: LandmarksTypes[];
  onChange: (types: LandmarksTypes[]) => void;
}

class CustomControl extends Control {
  constructor(options?: ControlOptions) {
    super({ ...INITIAL_OPTIONS, ...options });
    if (options) {
      this.options = { ...INITIAL_OPTIONS, ...options };
    }
  }

  options: CustomControlOptions = INITIAL_OPTIONS;
  button: HTMLElement | undefined;
  container: HTMLElement | undefined;

  private _map!: Map;

  onAdd(map: Map): HTMLElement {
    let container = map.getCustomControlContainer(
      this.options.position as ControlPosition,
      this.options.container as string
    );
    if (!container) {
      container = DomUtil.create('div');
    }
    this.container = (this._map as any)._controlCorners?.bottomleft as HTMLElement;
    this.button = container;
    return container;
  }
}

function customControl(options?: ControlOptions) {
  return new CustomControl(options);
}

export const Filter: FunctionComponent<FilterProps> = ({ data, onChange }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  const { map } = useMap();
  const [isOpen, setIsOpen] = useState(false);
  const isInit = useRef(false);
  const [types, setTypes] = useState(data);

  const ctrl = useRef(customControl());

  const handleChange = (type: LandmarksTypes) => {
    if (types.includes(type)) {
      setTypes(types.filter((t) => type !== t));
    } else {
      setTypes([...types, type]);
    }
  };

  const handleShowAll = () => {
    setTypes([...landmarksTypes]);
  };

  const handleHideAll = () => {
    setTypes([]);
  };

  const handleToggle = () => setIsOpen((prev) => !prev);

  const handleChangedTypes = debounce((types: LandmarksTypes[]) => {
    onChange(types);
  }, 1500);

  useEffect(() => {
    if (isInit.current) {
      handleChangedTypes(types);
    } else {
      isInit.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types]);

  useEffect(() => {
    const control = ctrl.current;
    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map]);

  if (ctrl.current.button) {
    return (
      <>
        {createPortal(
          <EuiToolTip position="left" content={t('map.filter.description')}>
            <EuiButtonIcon
              className={css(`
                position: relative;
                background: ${euiTheme.colors.emptyShade};

                ${
                  !types.length
                    ? ''
                    : `
                    &:before {
                      content:  '${types.length}';
                      position: absolute;
                      right: 0;
                      top: 0;
                      border-radius: 50%;
                      background: ${euiTheme.colors.primary};
                      color: ${euiTheme.colors.lightestShade};
                      transform: translate(35%, -35%);
                      width: ${euiTheme.size.base};
                      height: ${euiTheme.size.base};
                      font-size: ${euiTheme.font.scale.s}rem;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      line-height: 1;
                    }
                    `
                }
              `)}
              size="s"
              display="base"
              color="text"
              onClick={handleToggle}
              onDoubleClickCapture={(e: any) => e.stopPropagation()}
              onDoubleClick={(e: any) => e.stopPropagation()}
              iconType={Icon}
            />
          </EuiToolTip>,
          ctrl.current.button
        )}
        {ctrl.current.container &&
          isOpen &&
          createPortal(
            <EuiPanel
              className="leaflet-map-filter-panel leaflet-control"
              onDoubleClickCapture={(e: any) => e.stopPropagation()}
              onDoubleClick={(e: any) => e.stopPropagation()}
            >
              <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                <EuiFlexGroup alignItems="center" gutterSize="xs">
                  <EuiText>{t('map.filter.title')}</EuiText>
                  <EuiToolTip position="top" content={<p>{t('map.filter.description')}</p>}>
                    <EuiIcon type="iInCircle" />
                  </EuiToolTip>
                </EuiFlexGroup>
                <EuiButtonIcon color="ghost" iconType={'cross'} onClick={handleToggle} />
              </EuiFlexGroup>
              <EuiHorizontalRule margin="s" />
              <div
                className={cn(
                  css(`
                      max-height: 25vh;
                      overflow: auto;
                    `),
                  'eui-scrollBar'
                )}
                onScroll={(e) => e.stopPropagation()}
                onWheel={(e) => e.stopPropagation()}
                onWheelCapture={(e) => e.stopPropagation()}
              >
                {landmarksTypes.map((type) => (
                  <LandmarkControl key={type} type={type} selected={types.includes(type)} onChange={handleChange} />
                ))}
              </div>
              <EuiHorizontalRule margin="s" />
              <EuiFlexGroup justifyContent="spaceBetween">
                <EuiButtonEmpty size="xs" onClick={handleShowAll}>
                  {t('map.filter.button.showAll')}
                </EuiButtonEmpty>
                <EuiButtonEmpty size="xs" onClick={handleHideAll}>
                  {t('map.filter.button.hideAll')}
                </EuiButtonEmpty>
              </EuiFlexGroup>
            </EuiPanel>,
            ctrl.current.container
          )}
      </>
    );
  }

  return null;
};
