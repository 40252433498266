import { FC, useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { EuiLoadingSpinner } from '@elastic/eui';

import { leakApi } from '@/api';
import { ReactComponent as PdfFile } from '@/assets/icons/PdfFile.svg';
import { ReactComponent as UnkownFile } from '@/assets/icons/UnknownFile.svg';

import { FileTypes } from './types';

type ThumbnailProps = {
  name?: string;
  ext: string;
  type: FileTypes;
  file?: string;
};

export const Thumbnail: FC<ThumbnailProps> = ({ name, file, ext }) => {
  const [isLoading, setLoading] = useState<boolean>(!!file);
  const [thumbnailImage, setThumbnailImage] = useState<string | null>(null);

  const loadThumbnail = useCallback(async (path: string, abortSignal: AbortSignal) => {
    try {
      const { data } = await leakApi.download(
        {
          target: 'thumbnail',
          title: path,
        },
        'blob',
        abortSignal
      );

      const loadedImage = URL.createObjectURL(data);
      setThumbnailImage(loadedImage);
    } catch (error: any) {
      if (error?.code !== "ERR_CANCELED'") {
        captureException(error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (file) loadThumbnail(file, controller.signal);

    return () => controller.abort();
  }, [file, loadThumbnail]);

  const renderIcon = () => {
    if (file) {
      if (thumbnailImage) {
        return <img className="thumbnail" src={thumbnailImage} alt={`thumbnail_${name}`} />;
      } else if (isLoading) {
        return <EuiLoadingSpinner size="s" />;
      }
    }

    const Icon = ext === 'pdf' ? PdfFile : UnkownFile;

    return <Icon className="thumbnail" />;
  };

  return (
    <>
      <div className="title" title={name}>
        {name}
      </div>
      {renderIcon()}
    </>
  );
};
