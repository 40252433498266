import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { captureException } from '@sentry/react';

import { store } from '@/store';
import { authenticationActions } from '@/store/authentication';
import { toastActions } from '@/store/toast';
import { ToastVariantBasic } from '@/components';
import { Mutex } from '../mutex';

const MAX_RETRY_REQUEST_LIMIT = 3;

const headers: Record<string, string> = {
  'Content-Type': 'application/json; charset=utf-8',
};

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_CF_CLIENT_ID &&
  process.env.REACT_APP_CF_CLIENT_SECRET
) {
  headers['CF-Access-Client-Id'] = process.env.REACT_APP_CF_CLIENT_ID;
  headers['CF-Access-Client-Secret'] = process.env.REACT_APP_CF_CLIENT_SECRET;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});

const mutex = new Mutex();

instance.interceptors.request.use(async function (config) {
  if (process.env.NODE_ENV === 'development') {
    const token = localStorage.getItem('token') || localStorage.getItem('token_otp');
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      config.headers = { ...config.headers, ...headers };
    }
  }

  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError<{ error?: { code?: string } }>) {
    const axiosConfig = error?.config as AxiosRequestConfig & {
      retry?: number;
    };

    if (error.response) {
      switch (error.response.status) {
        case 401:
          try {
            const refreshPromise = mutex.isLocked()
              ? mutex.getValue()
              : mutex.acquire(store.dispatch(authenticationActions.refresh()).unwrap());

            await refreshPromise;

            if (axiosConfig) {
              axiosConfig.retry = axiosConfig.retry ? axiosConfig.retry + 1 : 1;
              if (axiosConfig.retry && axiosConfig.retry <= MAX_RETRY_REQUEST_LIMIT) {
                try {
                  const response = await instance(axiosConfig);
                  return response;
                } catch (errorRetry) {
                  return Promise.reject(errorRetry);
                }
              } else {
                captureException(new Error('Max attempts retry'));
                store.dispatch(authenticationActions.logout());
              }
            }
          } catch (errorRefresh) {
            captureException(errorRefresh);
          } finally {
            mutex.release();
          }
          return Promise.reject(error);
        case 402:
          if (error.response.data && error.response.data.error) {
            switch (error.response.data.error.code) {
              case 'requests_limit_reached':
                store.dispatch(
                  toastActions.create({
                    type: ToastVariantBasic.DAILY_LIMIT,
                  })
                );
                break;
              case 'expired_subscription':
                store.dispatch(
                  toastActions.create({
                    type: ToastVariantBasic.SUBSCRIPTION_EXPIRED,
                  })
                );
                if (store.getState().authentication.token) {
                  store.dispatch(authenticationActions.logout());
                }
                break;
              default:
                break;
            }
          }
          return Promise.reject(error);
        case 403:
          store.dispatch(authenticationActions.logout());
          return Promise.reject(error);
        case 429:
          if (
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.code === 'too_many_requests'
          ) {
            store.dispatch(
              toastActions.create({
                type: ToastVariantBasic.TO_MANY_REQUESTS,
              })
            );
          }
          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    } else if (error.message === 'Network Error') {
      store.dispatch(
        toastActions.create({
          type: ToastVariantBasic.NETWORK_ERROR,
        })
      );
      return Promise.reject({
        message: 'networkError',
      });
    }
    if (error.code === 'ERR_CANCELED') {
      return Promise.reject(error);
    } else {
      store.dispatch(
        toastActions.create({
          type: ToastVariantBasic.UNKNOWN_ERROR,
        })
      );
      return Promise.reject({
        message: 'unknownError',
      });
    }
  }
);

export default instance;
