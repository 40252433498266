import { AxiosPromise } from 'axios';

import axios from '@/services/axios';

class ReleaseNotesApi {
  private BASE_PATH = '/customer/update-logs';

  fetchList(params: Paths.GetUpdateLogsList.QueryParameters): AxiosPromise<Paths.GetUpdateLogsList.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/list`,
      method: 'get',
      params,
    });
  }

  fetchById({ id }: Paths.GetUpdateLog.PathParameters): AxiosPromise<Paths.GetUpdateLog.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${id}`,
      method: 'get',
    });
  }

  create(data: Paths.CreateUpdateLogs.Parameters.Body): AxiosPromise<Paths.CreateUpdateLogs.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}`,
      method: 'post',
      data,
    });
  }

  update({
    id,
    ...data
  }: Paths.EditUpdateLogs.Parameters.Body &
    Paths.EditUpdateLogs.PathParameters): AxiosPromise<Paths.EditUpdateLogs.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${id}`,
      method: 'put',
      data,
    });
  }

  delete({ id }: Paths.DeleteUpdateLog.PathParameters): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/${id}`,
      method: 'delete',
    });
  }
}

export const releaseNotesApi = new ReleaseNotesApi();
