import {
  EuiButton,
  EuiContextMenuItem,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiInputPopover,
  keys,
  useEuiTheme,
} from '@elastic/eui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Fragment,
  FunctionComponent,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';

import { GeoBoundBox, GeoItem, GeoSearchParams } from '@/api/geo/types';
import { geoApi } from '@/api';
import { useAppDispatch, useAppSelector } from '@/store';
import { mapActions, mapSelectors } from '@/store/map';

interface GeocoderFormProps {
  location: string;
  onSearch: (bounds: GeoBoundBox) => void;
  onChangeLocation: (query: string) => void;
}

export const GeocoderForm: FunctionComponent<GeocoderFormProps> = ({ location, onSearch, onChangeLocation }) => {
  const abortController = useRef<AbortController | null>(null);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  const savedGeoQuery = useAppSelector(mapSelectors.getGeoQuery);
  const geoItems = useAppSelector(mapSelectors.getGeoItems);

  const [items, setItems] = useState<(GeoItem & { onClick: () => void })[]>(
    geoItems.map((item) => ({
      ...item,
      onClick: () => {
        onSearch(item.boundingbox);
      },
    }))
  );
  const [isLoading, setLoading] = useState(false);
  const [isPopoverOpen, setIsOpenPopover] = useState(!!items.length);
  const [selectedItem, setSelectedItem] = useState<number>(-1);

  const { control, watch, handleSubmit } = useForm<GeoSearchParams>({
    defaultValues: {
      q: location,
      format: 'json',
      limit: 10,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchGeo = useCallback(
    debounce(async (params: GeoSearchParams) => {
      try {
        const controller = new AbortController();
        abortController.current = controller;

        const { data } = await geoApi.search(params, controller.signal);

        if (data && Array.isArray(data) && data.length > 0) {
          setItems(
            data.map((item) => ({
              ...item,
              onClick: () => {
                onSearch(item.boundingbox);
              },
            }))
          );
          setSelectedItem(0);
          dispatch(mapActions.setGeoItems(data));
        } else {
          throw 'Incorrect geo response';
        }
      } catch {
        setItems([]);
        setSelectedItem(-1);
      } finally {
        setIsOpenPopover(true);
        setLoading(false);
      }
    }, 150),
    []
  );

  const { q } = watch();

  useEffect(() => {
    if (q !== savedGeoQuery) {
      setLoading(true);
      setItems([]);
      if (abortController.current) {
        abortController.current.abort();
      }
      if (q.length > 0) {
        handleSubmit(handleSearchGeo)();
      } else {
        handleSearchGeo.cancel();
        if (abortController.current) {
          abortController.current.abort();
        }
        setLoading(false);
      }
      onChangeLocation(q);
      dispatch(mapActions.setGeoQuery(q));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedGeoQuery, q, handleSubmit]);

  useEffect(() => {
    const controller = abortController.current;
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, []);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      switch (e.key) {
        case keys.ARROW_DOWN:
          e.preventDefault();
          e.stopPropagation();
          setSelectedItem((state) => Math.min(state + 1, items.length - 1));
          break;
        case keys.ARROW_UP:
          e.preventDefault();
          e.stopPropagation();
          setSelectedItem((state) => Math.max(state - 1, -1));
          break;
        case keys.ENTER:
          if (selectedItem > -1) {
            e.preventDefault();
            e.stopPropagation();
            items[selectedItem].onClick();
          } else {
            setItems([]);
            setSelectedItem(-1);
            setIsOpenPopover(false);
          }
          break;
        default:
          break;
      }
    },
    [selectedItem, items]
  );

  const handleClickButton: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (items.length > 0) items[selectedItem].onClick();
  };

  const handleFocus = () => {
    if (items.length) setIsOpenPopover(true);
  };

  const handleClosePopover = () => {
    setIsOpenPopover(false);
  };

  return (
    <EuiFlexGroup onDoubleClickCapture={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
      <EuiFlexItem>
        <EuiInputPopover
          fullWidth
          input={
            <Controller
              control={control}
              name="q"
              render={({ field: { value, onChange } }) => (
                <EuiFieldText
                  value={value}
                  onChange={onChange}
                  onFocus={handleFocus}
                  onKeyDown={handleKeyDown}
                  placeholder={t('map.geocoder.searchPlaceholder')}
                  icon="mapMarker"
                  isLoading={isLoading}
                  compressed
                  fullWidth
                />
              )}
            />
          }
          isOpen={isPopoverOpen}
          closePopover={handleClosePopover}
          disableFocusTrap
          panelPaddingSize="none"
        >
          {items.map(({ place_id, display_name, onClick }, index) => (
            <Fragment key={place_id}>
              <EuiContextMenuItem
                tabIndex={-1}
                size="s"
                key={`item-${place_id}`}
                onClick={() => {
                  setSelectedItem(index);
                  onClick();
                }}
                style={{
                  background: selectedItem === index ? euiTheme.colors.lightestShade : undefined,
                }}
              >
                {display_name}
              </EuiContextMenuItem>
              {index !== items.length - 1 && <EuiHorizontalRule margin="none" />}
            </Fragment>
          ))}
          {items.length === 0 && !isLoading && (
            <EuiContextMenuItem icon="annotation" tabIndex={-1} size="s" key={`item-no-place`}>
              {t('geocoderForm.error.not_found')}
            </EuiContextMenuItem>
          )}
        </EuiInputPopover>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton size={'s'} fill type="button" onClick={handleClickButton}>
          {t('button.search')}
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
