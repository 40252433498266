import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import axios from 'axios';

import { authenticationApi, customerApi, subscriptionApi } from '@/api';
import { RootState } from '../store';
import { toastActions } from '../toast';
import { ToastVariantBasic } from '@/components';
import { authenticationActions } from '../authentication';
import { credentialsActions } from '../credentials';

import * as customerSelectors from './selectors';

export interface State {
  loading: boolean;
  isLoadingUpdatePassword: boolean;
  info: Definitions.Profile | null;
  subscription: Definitions.Subscription | null;
  twoAuthQrImage: Blob | null;
  recoveryCodes: Definitions.RecoveryCodes | null;
  isLoadingQr: boolean;
  isLoadingTwoAuth: boolean;
  isLoadingResetRecoveryCodes: boolean;
}

const clearInfo = createAction('customer/clearInfo');
const clearRecoveryCodes = createAction('customer/clearRecoveryCodes');

export const fetchCurrentAccount = createAsyncThunk('customer/fetchCurrentAccount', async (_, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    if (state.authentication.token) {
      const {
        data: { data },
      } = await customerApi.getCurrentAccount();
      return data;
    }
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const fetchCurrentAccountSync = createAsyncThunk('customer/fetchCurrentAccountSync', async (_, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const customer = customerSelectors.getInfo(state);
    if (state.authentication.token) {
      const {
        data: { data },
      } = await customerApi.getCurrentAccount();

      if (customer?.group_id !== data?.group_id) {
        thunkApi.dispatch(credentialsActions.clearSearch());
      }
      return data;
    }
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const fetchSubscription = createAsyncThunk('customer/fetchSubscription', async (payload: number, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    if (state.authentication.token) {
      const {
        data: { data },
      } = await subscriptionApi.fetchById(payload);
      return data || null;
    }
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const updatePasswordCurrentAccount = createAsyncThunk(
  'customer/password-change',
  async (
    payload: Definitions.ChangePasswordRequest & {
      onSuccess: () => void;
      onError: (error: Definitions.Error) => void;
    },
    thunkApi
  ) => {
    const { onSuccess, onError, ...data } = payload;
    try {
      await customerApi.updatePasswordCurrentAccount(data);
      onSuccess();
      thunkApi.dispatch(
        toastActions.create({
          type: ToastVariantBasic.PASSWORD_CHANGED,
        })
      );
      thunkApi.dispatch(authenticationActions.logout());
      return;
    } catch (error: any) {
      const serverError: Paths.CustomerLogin.Responses.$400 = error?.response?.data || { error };
      onError(
        serverError.error || {
          message: 'unknownError',
        }
      );
    }
  }
);

export const updatePasswordAccountById = createAsyncThunk(
  'customer/updatePasswordAccountById',
  async (
    payload: Paths.ExtendedCustomerPasswordChange.Parameters.Body & Paths.ExtendedCustomerPasswordChange.PathParameters,
    thunkApi
  ) => {
    try {
      const {
        data: { data },
      } = await customerApi.updatePasswordAccountById(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.ExtendedCustomerPasswordChange.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

export const updateCurrentAccount = createAsyncThunk(
  'customer/change',
  async (payload: Paths.CustomerUpdate.Parameters.Body, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerApi.updateCurrentAccount(payload);

      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.RegisterCustomer.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

export const updateAccountById = createAsyncThunk(
  'customer/updateAccountById',
  async (
    payload: Paths.ExtendedCustomerUpdate.PathParameters & Paths.ExtendedCustomerUpdate.Parameters.Body,
    thunkApi
  ) => {
    try {
      const state = thunkApi.getState() as RootState;
      const customer = customerSelectors.getInfo(state);
      const {
        data: { data },
      } = await customerApi.updateAccountById(payload);
      if (customer?.group_id !== data?.group_id) {
        thunkApi.dispatch(credentialsActions.clearSearch());
      }
      if (customer?.id === data?.id) {
        thunkApi.dispatch(fetchCurrentAccountSync());
      }
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.RegisterCustomer.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

const updateApiTokenCurrentAccount = createAsyncThunk('customer/updateApiTokenCurrentAccount', async (_, thunkApi) => {
  try {
    const {
      data: { data },
    } = await authenticationApi.updateApiTokenCurrentAccount();

    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  }
});

const updateApiTokenAccountById = createAsyncThunk(
  'customer/updateApiTokenAccountById',
  async (payload: Paths.ExtendedApiTokenReset.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerApi.updateApiTokenAccountById(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.RegisterCustomer.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

const updateTelegramTokenCurrentAccount = createAsyncThunk(
  'customer/updateTelegramTokenCurrentAccount',
  async (_, thunkApi) => {
    try {
      const {
        data: { data },
      } = await authenticationApi.updateTelegramTokenCurrentAccount();

      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

const updateTelegramTokenById = createAsyncThunk(
  'customer/updateTelegramTokenById',
  async (payload: Paths.ExtendedTelegramTokenReset.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerApi.updateTelegramTokenById(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.RegisterCustomer.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

const fetchQrImageTwoAuth = createAsyncThunk('customer/fetchQrImageTwoAuth', async (_, thunkApi) => {
  try {
    const { data } = await customerApi.getQrCodeTwoAuth();
    return data;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const responseError = e?.response as { data: unknown };
      return thunkApi.rejectWithValue(responseError?.data);
    }
  }
});

const enableTwoAuth = createAsyncThunk(
  'customer/enableTwoAuth',
  async (payload: Paths.EnableTwoFactorAuth.Parameters.Body, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerApi.enableTwoAuth(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.EnableTwoFactorAuth.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

const resetRecoveryCodes = createAsyncThunk(
  'customer/resetRecoveryCodes',
  async (payload: Paths.ResetRecoveryCodes.Parameters.Body, thunkApi) => {
    try {
      const {
        data: { data },
      } = await customerApi.regenerateCodesTwoAuth(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.ResetRecoveryCodes.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

const disableTwoAuth = createAsyncThunk(
  'customer/disableTwoAuth',
  async (payload: Paths.DisableTwoFactorAuth.Parameters.Body, thunkApi) => {
    try {
      await customerApi.disableTwoAuth(payload);
      return;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.DisableTwoFactorAuth.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'customer',
  initialState: {
    loading: true,
    isLoadingUpdatePassword: false,
    info: null,
    subscription: null,
    twoAuthQrImage: null,
    recoveryCodes: null,
    isLoadingQr: false,
    isLoadingTwoAuth: false,
    isLoadingResetRecoveryCodes: false,
  },
  reducers: {
    clearInfo: (state) => {
      state.loading = true;
      state.info = null;
      state.subscription = null;
      state.twoAuthQrImage = null;
      state.recoveryCodes = null;
      state.isLoadingQr = false;
    },
    clearRecoveryCodes: (state) => {
      state.recoveryCodes = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCurrentAccount.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.info = action.payload;
        }
      })
      .addCase(fetchCurrentAccount.rejected, (state) => {
        state.loading = false;
        state.info = null;
      });

    builder.addCase(updateCurrentAccount.fulfilled, (state, action) => {
      if (action.payload && state.info) {
        state.info.nickname = action.payload.nickname;
      }
    });

    builder.addCase(fetchCurrentAccountSync.fulfilled, (state, action) => {
      if (action.payload) {
        state.info = action.payload;
      }
    });

    builder
      .addCase(fetchSubscription.pending, (state) => {
        state.subscription = null;
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        if (action.payload) {
          state.subscription = action.payload;
        }
      })
      .addCase(fetchSubscription.rejected, (state) => {
        state.subscription = null;
      });

    builder
      .addCase(updatePasswordCurrentAccount.pending, (state) => {
        state.isLoadingUpdatePassword = true;
      })
      .addCase(updatePasswordCurrentAccount.fulfilled, (state) => {
        state.isLoadingUpdatePassword = false;
      })
      .addCase(updatePasswordCurrentAccount.rejected, (state) => {
        state.isLoadingUpdatePassword = false;
      });

    builder
      .addCase(enableTwoAuth.pending, (state) => {
        state.isLoadingTwoAuth = true;
      })
      .addCase(enableTwoAuth.fulfilled, (state, action) => {
        if (action.payload) {
          state.recoveryCodes = action.payload;
          if (state.info) {
            state.info.enabled_two_fa = true;
          }
        }
        state.isLoadingTwoAuth = false;
      })
      .addCase(enableTwoAuth.rejected, (state) => {
        state.isLoadingTwoAuth = false;
      });

    builder
      .addCase(resetRecoveryCodes.pending, (state) => {
        state.isLoadingResetRecoveryCodes = true;
      })
      .addCase(resetRecoveryCodes.fulfilled, (state, action) => {
        if (action.payload) {
          state.recoveryCodes = action.payload;
        }
        state.isLoadingResetRecoveryCodes = false;
      })
      .addCase(resetRecoveryCodes.rejected, (state) => {
        state.isLoadingResetRecoveryCodes = false;
      });

    builder
      .addCase(disableTwoAuth.pending, (state) => {
        state.isLoadingTwoAuth = true;
      })
      .addCase(disableTwoAuth.fulfilled, (state) => {
        state.isLoadingTwoAuth = false;
        if (state.info) {
          state.info.enabled_two_fa = false;
        }
      })
      .addCase(disableTwoAuth.rejected, (state) => {
        state.isLoadingTwoAuth = false;
      });

    builder
      .addCase(fetchQrImageTwoAuth.pending, (state) => {
        state.isLoadingQr = true;
      })
      .addCase(fetchQrImageTwoAuth.fulfilled, (state, action) => {
        if (action.payload) {
          state.twoAuthQrImage = action.payload;
        }
        state.isLoadingQr = false;
      })
      .addCase(fetchQrImageTwoAuth.rejected, (state) => {
        state.isLoadingQr = false;
      });
  },
});

export const actions = {
  clearInfo,
  fetchCurrentAccount,
  updatePasswordCurrentAccount,
  fetchSubscription,
  fetchCurrentAccountSync,
  updateApiTokenCurrentAccount,
  updateCurrentAccount,
  updateAccountById,
  updateApiTokenAccountById,
  updatePasswordAccountById,
  enableTwoAuth,
  disableTwoAuth,
  fetchQrImageTwoAuth,
  clearRecoveryCodes,
  resetRecoveryCodes,
  updateTelegramTokenCurrentAccount,
  updateTelegramTokenById,
};

export default slice.reducer;
