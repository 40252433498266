import {
  EuiAvatar,
  EuiAvatarProps,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCommentList,
  EuiCommentProps,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiMarkdownFormat,
  EuiSpacer,
  EuiThemeComputed,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { FC, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLinkClickHandler, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { css } from '@emotion/css';

import { ReleaseNotesRoutes, Routes } from '@/routes';
import { ReactComponent as ProfilerIcon } from '@/assets/icons/Subtract.svg';
import { ReactComponent as PasswordFinderIcon } from '@/assets/icons/Union.svg';
import { ReactComponent as PhonebookIcon } from '@/assets/icons/Phone.svg';
import { ReactComponent as DatasetsIcon } from '@/assets/icons/Pie.svg';
import { ReactComponent as RecursiveSearchIcon } from '@/assets/icons/Research.svg';
import { ThemeExtensions } from '../ThemeProvider';
import { useAppDispatch, useAppSelector } from '@/store';
import { logsActions, logsSelectors } from '@/store/logs';
import { Roles, useRole } from '@/hooks';
import { Loader } from '../Loader';

export const LOG_DATE_FORMAT = 'DD.MM.YYYY';

const STEP = 10;
interface UpdateLoggerProps {
  limit?: number;
}

//const getTranslate = (translates: Record<Languages, string>, current: Languages) => translates[current];

const CopyAction: FC<{ children: string }> = ({ children }) => (
  <EuiCopy textToCopy={children}>
    {(copy) => <EuiButtonIcon aria-label="Copy text" color="text" iconType="copy" onClick={copy} />}
  </EuiCopy>
);

const EditAction: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();
  return <EuiButtonIcon aria-label="Edit log" color="text" iconType="pencil" onClick={() => navigate(id)} />;
};

export const getActions = (
  text: string,
  type: string,
  id: string | undefined = undefined,
  isSystem = false
): ReactNode | ReactNode[] => {
  switch (type) {
    case 'password_finder':
    case 'profiler':
    case 'phonebook':
    case 'datasets':
    case 'recursive_search':
    case 'map_radar':
      return (
        <>
          <CopyAction>{text}</CopyAction>
          {isSystem && id && <EditAction id={id} />}
        </>
      );
    default:
      return isSystem && id ? <EditAction id={id} /> : null;
  }
};

export const getAvatar = (
  type: string,
  euiTheme: EuiThemeComputed<ThemeExtensions>
): ReactNode | EuiAvatarProps['iconType'] => {
  switch (type) {
    case 'password_finder':
      return (
        <EuiAvatar
          name={type}
          color={euiTheme.colors.lightestShade}
          iconColor={euiTheme.colors.tools.PASSWORD_FINDER}
          iconType={PasswordFinderIcon}
        />
      );
    case 'profiler':
      return (
        <EuiAvatar
          name={type}
          color={euiTheme.colors.lightestShade}
          iconColor={euiTheme.colors.tools.PROFILER}
          iconType={ProfilerIcon}
        />
      );
    case 'phonebook':
      return (
        <EuiAvatar
          name={type}
          color={euiTheme.colors.lightestShade}
          iconColor={euiTheme.colors.tools.PHONEBOOK_PARSER}
          iconType={PhonebookIcon}
        />
      );
    case 'datasets':
      return (
        <EuiAvatar
          name={type}
          color={euiTheme.colors.lightestShade}
          iconColor={euiTheme.colors.tools.DATASETS}
          iconType={DatasetsIcon}
        />
      );
    case 'recursive_search':
      return (
        <EuiAvatar
          name={type}
          color={euiTheme.colors.lightestShade}
          iconColor={euiTheme.colors.tools.RECURSIVE_SEARCH}
          iconType={RecursiveSearchIcon}
        />
      );
    case 'map_radar':
      return (
        <EuiAvatar
          name={type}
          color={euiTheme.colors.lightestShade}
          iconColor={euiTheme.colors.tools.MAP_RADAR}
          iconType="visMapRegion"
        />
      );
    case 'system':
      return 'dot';
  }
};

export const UpdateLogger: FC<UpdateLoggerProps> = ({ limit }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isSending = useRef(false);
  const { t } = useTranslation();
  const { euiTheme, colorMode } = useEuiTheme<ThemeExtensions>();
  const { isValid } = useRole([Roles.SYSTEM]);

  const data = useAppSelector(logsSelectors.getList);
  const isLoading = useAppSelector(logsSelectors.getLoading);
  const offset = useAppSelector(logsSelectors.getOffset);
  const total = useAppSelector(logsSelectors.getTotal);
  const init = useAppSelector(logsSelectors.getInit);

  const { handleSubmit, register, unregister, setValue } = useForm<Paths.GetUpdateLogsList.QueryParameters>({
    defaultValues: {
      limit: Math.min(STEP, limit || STEP),
      offset: offset,
    },
  });

  const onSubmit = useCallback(
    (data: Paths.GetUpdateLogsList.QueryParameters) => {
      dispatch(logsActions.fetchData(data));
    },
    [dispatch]
  );

  const handleScroll = useCallback(() => {
    const containerHeight = window.document.documentElement.clientHeight;
    const scrollHeight = window.document.documentElement.scrollHeight;
    const scrollTop = window.document.documentElement.scrollTop;

    const progress = ((scrollTop + containerHeight) / scrollHeight) * 100;
    if (progress > 50 && offset <= total && !isSending.current) {
      isSending.current = true;
      setValue('offset', offset + STEP);
      handleSubmit(onSubmit)();
    }
  }, [offset, total, setValue, handleSubmit, onSubmit]);

  useEffect(() => {
    isSending.current = false;
  }, [offset]);

  useEffect(() => {
    if (!init) handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit, init]);

  useEffect(() => {
    register('limit');
    register('offset');

    return () => {
      unregister('limit');
      unregister('offset');
    };
  }, [register, unregister]);

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll);
    if (!limit) window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, limit]);

  const logs = useMemo<EuiCommentProps[]>(() => {
    return data
      .map(({ log, datetime, type, id }) => ({
        username: type,
        event: 'оновлення',
        timelineAvatar: getAvatar(type, euiTheme),
        timestamp: `за ${moment(datetime).format(LOG_DATE_FORMAT)}`,
        children: <EuiMarkdownFormat textSize="xs">{log}</EuiMarkdownFormat>,
        actions: getActions(log, type, `${id}`, isValid && !limit),
      }))
      .slice(0, limit || data.length)
      .map(({ username, ...item }) => ({
        ...item,
        username: t(`updateLogger.type.${username}`),
        className: css(`
        & .euiCommentEvent__body {
          background-color: ${colorMode === 'LIGHT' ? euiTheme.colors.ghost : euiTheme.colors.shadow};
        }
      `),
      }));
  }, [limit, t, euiTheme, data, colorMode, isValid]);

  const handleClickHref = useLinkClickHandler(Routes.UPDATES, {});
  const handleClickFullView = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      window.scrollTo({
        top: 0,
      });
      handleClickHref(event);
    },
    [handleClickHref]
  );
  return (
    <>
      <div
        style={{
          width: '100%',
        }}
      >
        <EuiFlexGroup alignItems="flexStart" justifyContent="spaceBetween">
          <EuiFlexItem>
            <EuiTitle size="s">
              <h1
                style={{
                  margin: 0,
                  fontSize: '18px',
                }}
              >
                {t('updateLogger.title')}
              </h1>
            </EuiTitle>
            <EuiSpacer size="s" />
            <EuiTitle size="xxs">
              <h2
                style={{
                  fontWeight: 400,
                }}
              >
                {t('updateLogger.subTitle')}
                {limit ? (
                  <>
                    &nbsp;
                    {t('updateLogger.subTitle2')}
                    <EuiButtonEmpty href={Routes.UPDATES} onClick={handleClickFullView}>
                      {`
                    ${window.location.href.replace(/\/$/, '')}${Routes.UPDATES}
                `}
                    </EuiButtonEmpty>
                  </>
                ) : null}
              </h2>
            </EuiTitle>
          </EuiFlexItem>
          {isValid && !limit && (
            <EuiFlexItem grow={0}>
              <EuiButton onClick={() => navigate(ReleaseNotesRoutes.CREATE)}>{t('button.create')}</EuiButton>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </div>
      <EuiSpacer size="xl" />
      <EuiSpacer size="m" />
      <EuiFlexItem
        style={{
          width: '100%',
        }}
      >
        {data.length > 0 && <EuiCommentList comments={logs} aria-label="Comment list example" />}
        <EuiSpacer size="m" />
        {isLoading && (
          <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
            <EuiSpacer size="xl" />
            <Loader />
          </EuiFlexGroup>
        )}
        <EuiSpacer size="l" />
      </EuiFlexItem>
    </>
  );
};
