/* eslint-disable @typescript-eslint/ban-ts-comment */
import { LoaderFunctionArgs, RouteObject } from 'react-router-dom';

import { Api } from '@/pages';
import { GuidesDocument } from '@/components';
//@ts-ignore
import document1 from '@/i18next/documentation/v1.0/LeaksRegistry/data-structure.md';
//@ts-ignore
import document2 from '@/i18next/documentation/v1.0/LeaksRegistry/how-it-work.md';
//@ts-ignore
import apiDoc from '@/i18next/documentation/api/leak_registry_06_04_2023.yaml';
import { SearchResult } from '@/pages/LeakRegistry/SearchResult';

import { LeakRegistryRoutes } from './types';

export async function loader({ params }: LoaderFunctionArgs) {
  switch (params.slug as LeakRegistryRoutes) {
    case LeakRegistryRoutes.DATA_STRUCTURE:
      return document1;
    case LeakRegistryRoutes.SEARCH_CONCEPT:
      return document2;
    default:
      return null;
  }
}

export const leakRegistry: RouteObject[] = [
  {
    path: '',
    element: <SearchResult />,
  },
  {
    path: LeakRegistryRoutes.API,
    loader: async () => apiDoc,
    element: <Api />,
  },
  {
    path: ':slug',
    loader,
    element: <GuidesDocument />,
  },
];
