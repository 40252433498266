import { AxiosPromise } from 'axios';

import axios from '@/services/axios';

class TasksApi {
  private BASE_PATH = '/phonebookparser';

  search(params: Paths.GetTasks.QueryParameters): AxiosPromise<Paths.GetTasks.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/tasks`,
      method: 'get',
      params,
    });
  }

  create({ file, ...data }: Paths.CreateTasks.FormDataParameters): AxiosPromise<Paths.GetTasks.Responses.$200> {
    const fd = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      fd.append(key, value);
    });

    const uploadedFile = (file as FileList).item(0);

    if (uploadedFile) fd.append('file', uploadedFile);

    return axios({
      url: `${this.BASE_PATH}/tasks`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: fd,
    });
  }

  remove({ id }: Paths.DeleteTasks.PathParameters): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/tasks/${id}`,
      method: 'delete',
    });
  }

  fetchById({ id }: Paths.GetTask.PathParameters, signal?: AbortSignal): AxiosPromise<Paths.GetTask.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/tasks/${id}`,
      method: 'get',
      signal,
    });
  }
}

export const tasksApi = new TasksApi();
