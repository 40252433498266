import { FC } from 'react';
import { EuiFlexGroup, EuiIcon, EuiPage, EuiPageSection, EuiPageTemplate, EuiSpacer, EuiTitle } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useAppSelector } from '@/store';
import { getInfo } from '@/store/customer/selectors';

interface PermissionRequiredProps {
  permission: keyof Definitions.ProductsPermission | (keyof Definitions.ProductsPermission)[];
}

export const useToolsPermission = (
  permission: keyof Definitions.ProductsPermission | (keyof Definitions.ProductsPermission)[]
) => {
  const user = useAppSelector(getInfo);

  return Boolean(
    user &&
      user.products_permission &&
      ((Array.isArray(permission) &&
        permission.some((value) =>
          Object.keys(user.products_permission as Definitions.ProductsPermission).includes(value)
        )) ||
        (typeof permission === 'string' && user.products_permission[permission]))
  );
};

export const PermissionRequired: FC<PermissionRequiredProps> = ({ permission, children }) => {
  const hasAccess = useToolsPermission(permission);
  const { t } = useTranslation();

  if (hasAccess) {
    return children ? <>{children}</> : <Outlet />;
  }

  return (
    <EuiPageTemplate grow>
      <EuiPage direction="column" grow>
        <EuiPageSection
          paddingSize="none"
          grow
          alignment="center"
          contentProps={{
            css: {
              width: '100%',
            },
          }}
        >
          <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
            <EuiIcon type="faceSad" size="xl" color="#69707D" />
            <EuiSpacer size="l" />
            <EuiTitle size="s">
              <h1 style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>{t('page.page403.header')}</h1>
            </EuiTitle>
          </EuiFlexGroup>
        </EuiPageSection>
      </EuiPage>
    </EuiPageTemplate>
  );
};
