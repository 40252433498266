import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EuiButtonIcon, EuiIcon, EuiLink, EuiPanel, EuiText } from '@elastic/eui';
import cn from 'classnames';
import { css } from '@emotion/css';

import { ReactComponent as InfoCircle } from '@/assets/icons/infoCircle.svg';
import { Routes } from '@/routes';

export const TgBotNotification: FC<{ close: () => void }> = ({ close }) => {
  const { t } = useTranslation();
  const accountLink = `${window.location.origin}${Routes.ACCOUNT_GENERAL_SETTING}`;

  return (
    <EuiPanel
      className={css`
        box-shadow: 0px 0px 10px 0px var(--color-accent-shadow);
        position: relative;
        padding: 16px 20px 24px;
      `}
    >
      <EuiText
        size="m"
        className={cn(
          css`
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 5px;
            svg {
              width: 20px;
              height: 20px;
            }
          `,
          'accentText'
        )}
      >
        <EuiIcon type={InfoCircle} size="m" />
        <span>{t('tgBotNotification.title')}</span>
      </EuiText>
      <EuiText
        size="s"
        className={css`
          margin-top: 10px;
          line-height: 1.5;
        `}
      >
        <Trans
          i18nKey="tgBotNotification.message"
          values={{
            accountLink: accountLink,
            botLink: process.env.REACT_APP_TELEGRAM_BOT_LINK,
          }}
          components={[
            <span
              key={0}
              className={css`
                font-weight: 600;
              `}
            />,
            <EuiLink key={1} href={accountLink} target="_blank" external={false} className="euiText accentText" />,
            <EuiLink
              key={2}
              href={process.env.REACT_APP_TELEGRAM_BOT_LINK}
              target="_blank"
              external={false}
              className="euiText accentText"
            />,
          ]}
          tOptions={{
            interpolation: { escapeValue: false },
          }}
        />
      </EuiText>
      <EuiButtonIcon
        color={'text'}
        onClick={close}
        iconType="cross"
        aria-label="close notification"
        className={css`
          position: absolute;
          top: 10px;
          right: 10px;
        `}
      />
    </EuiPanel>
  );
};
