import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCheckbox,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormErrorText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
} from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { useAppDispatch } from '@/store';
import { toastActions } from '@/store/toast';
import { ToastVariantBasic, ToastVariantWithText } from '@/components/Toasts';
import { recursiveActions } from '@/store/recursive';

import { FormFields, MAX_LIMIT_RECURSION, MIN_LIMIT_RECURSION, schema } from './schema';
import { FieldInfo } from './FieldInfo';
interface CreateRecursiveSearchTaskProps {
  defaultData?: FormFields;
  onClose: () => void;
  onSuccess: () => void;
}

const DEFAULT_RECURSION_LEVEL = 3;

export const CreateRecursiveSearchTask: FC<CreateRecursiveSearchTaskProps> = ({ defaultData, onClose, onSuccess }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState<string[]>([]);
  const { control, handleSubmit, setError } = useForm<FormFields>({
    defaultValues: {
      title: defaultData?.title || '',
      recursion_levels_number: DEFAULT_RECURSION_LEVEL,
      search_fields: {
        address: defaultData?.search_fields?.address || '',
        birthday: defaultData?.search_fields?.birthday || '',
        email: defaultData?.search_fields?.email || '',
        inn: defaultData?.search_fields?.inn || '',
        passport: defaultData?.search_fields?.passport || '',
        phone: defaultData?.search_fields?.phone || '',
        raw_name: defaultData?.search_fields?.raw_name || '',
        snils: defaultData?.search_fields?.snils || '',
      },
      different_name_forms: defaultData?.different_name_forms || false,
    },
    resolver: yupResolver(schema),
  });

  const handleUpload = useCallback(
    async (data: FormFields) => {
      try {
        setLoading(true);
        await dispatch(recursiveActions.create(data)).unwrap();
        setLoading(false);
        onClose();
        onSuccess();
        dispatch(
          toastActions.create({
            type: ToastVariantWithText.SUCCESS_ACTION,
            text: 'Success! Your request has been created',
          })
        );
      } catch (e: any) {
        const errorResponse = e as Definitions.Error;
        if (errorResponse?.errors) {
          const globalErrors: string[] = [];
          let errorsCount = 0;
          errorResponse.errors.forEach((error) => {
            if (error.location && Object.keys(control._fields.search_fields as object).includes(error.location)) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              setError(`search_fields.${error.location}`, error);
              errorsCount++;
            } else {
              if (error.code && i18n.exists(`createRecursiveTaskForm.error.${error.message}`)) {
                globalErrors.push(t(`createRecursiveTaskForm.error.${error.message}`));
              } else if (error.message) {
                globalErrors.push(error.message);
              }
            }
          });
          setGlobalError(globalErrors);
          if (errorsCount === 0 && globalErrors.length === 0) {
            dispatch(
              toastActions.create({
                type: ToastVariantBasic.UNKNOWN_ERROR,
              })
            );
          }
        } else if (errorResponse?.message) {
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.ERROR_ACTION,
              text: errorResponse.message,
            })
          );
        } else {
          dispatch(
            toastActions.create({
              type: ToastVariantBasic.UNKNOWN_ERROR,
            })
          );
        }
        setLoading(false);
      }
    },
    [dispatch, onClose, onSuccess, control._fields.search_fields, setError, i18n, t]
  );

  return (
    <EuiModal onClose={onClose} maxWidth="l">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t('modals.createRecursiveSearchTask.title')}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm
          className={css`
            max-width: 530px;
            margin: 0 auto;
          `}
          >
          <Controller
            control={control}
            name="title"
            render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                fullWidth
                label={
                  <FieldInfo
                    label={t(`createRecursiveTaskForm.label.${name}`)}
                    info={
                      i18n.exists(`createRecursiveTaskForm.info.${name}`)
                        ? t(`createRecursiveTaskForm.info.${name}`)
                        : ''
                    }
                  />
                }
                isInvalid={!!error}
                error={t(error?.message as string)}
              >
                <EuiFieldText
                  placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
          <Controller
            control={control}
            name="recursion_levels_number"
            render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                fullWidth
                label={
                  <FieldInfo
                    label={t(`createRecursiveTaskForm.label.${name}`)}
                    info={
                      i18n.exists(`createRecursiveTaskForm.info.${name}`)
                        ? t(`createRecursiveTaskForm.info.${name}`)
                        : ''
                    }
                  />
                }
                isInvalid={!!error}
                error={t(error?.message as string)}
              >
                <EuiFieldNumber
                  placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                  min={MIN_LIMIT_RECURSION}
                  max={MAX_LIMIT_RECURSION}
                />
              </EuiFormRow>
            )}
          />
          <EuiFormRow fullWidth>
            <EuiFlexGroup alignItems="flexStart">
              <EuiFlexItem>
                <Controller
                  control={control}
                  name="search_fields.raw_name"
                  render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
                    <EuiFormRow
                      fullWidth
                      label={
                        <FieldInfo
                          label={t(`createRecursiveTaskForm.label.${name}`)}
                          info={
                            i18n.exists(`createRecursiveTaskForm.info.${name}`)
                              ? t(`createRecursiveTaskForm.info.${name}`)
                              : ''
                          }
                        />
                      }
                      isInvalid={!!error}
                      error={t(error?.message as string)}
                    >
                      <EuiFieldText
                        placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        isInvalid={!!error}
                        fullWidth
                      />
                    </EuiFormRow>
                  )}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <Controller
                  control={control}
                  name="search_fields.birthday"
                  render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
                    <EuiFormRow
                      fullWidth
                      label={
                        <FieldInfo
                          label={t(`createRecursiveTaskForm.label.${name}`)}
                          info={
                            i18n.exists(`createRecursiveTaskForm.info.${name}`)
                              ? t(`createRecursiveTaskForm.info.${name}`)
                              : ''
                          }
                        />
                      }
                      isInvalid={!!error}
                      error={t(error?.message as string)}
                    >
                      <EuiFieldText
                        placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        isInvalid={!!error}
                        fullWidth
                      />
                    </EuiFormRow>
                  )}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
          <Controller
            control={control}
            name="different_name_forms"
            render={({ field: { value, name, onChange }, fieldState: { error } }) => (
              <EuiFormRow fullWidth isInvalid={!!error} error={t(error?.message as string)}>
                <>
                  <EuiCheckbox
                    id={name}
                    checked={value}
                    onChange={onChange}
                    label={t(`createRecursiveTaskForm.label.${name}`)}
                  />
                  {value && (
                    <>
                      <EuiSpacer size="xs" />
                      <EuiCallOut
                        title={t(`createRecursiveTaskForm.alert.${name}`)}
                        color="warning"
                        iconType="alert"
                        size="s"
                        className={css`
                          border-radius: 4px;
                          word-break: break-word;
                        `}
                      />
                    </>
                  )}
                </>
              </EuiFormRow>
            )}
          />
          <Controller
            control={control}
            name="search_fields.phone"
            render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                fullWidth
                label={
                  <FieldInfo
                    label={t(`createRecursiveTaskForm.label.${name}`)}
                    info={
                      i18n.exists(`createRecursiveTaskForm.info.${name}`)
                        ? t(`createRecursiveTaskForm.info.${name}`)
                        : ''
                    }
                  />
                }
                isInvalid={!!error}
                error={t(error?.message as string)}
              >
                <EuiFieldText
                  placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
          <Controller
            control={control}
            name="search_fields.email"
            render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                fullWidth
                label={
                  <FieldInfo
                    label={t(`createRecursiveTaskForm.label.${name}`)}
                    info={
                      i18n.exists(`createRecursiveTaskForm.info.${name}`)
                        ? t(`createRecursiveTaskForm.info.${name}`)
                        : ''
                    }
                  />
                }
                isInvalid={!!error}
                error={t(error?.message as string)}
              >
                <EuiFieldText
                  placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
          <Controller
            control={control}
            name="search_fields.inn"
            render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                fullWidth
                label={
                  <FieldInfo
                    label={t(`createRecursiveTaskForm.label.${name}`)}
                    info={
                      i18n.exists(`createRecursiveTaskForm.info.${name}`)
                        ? t(`createRecursiveTaskForm.info.${name}`)
                        : ''
                    }
                  />
                }
                isInvalid={!!error}
                error={t(error?.message as string)}
              >
                <EuiFieldText
                  placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
          <Controller
            control={control}
            name="search_fields.address"
            render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                fullWidth
                label={
                  <FieldInfo
                    label={t(`createRecursiveTaskForm.label.${name}`)}
                    info={
                      i18n.exists(`createRecursiveTaskForm.info.${name}`)
                        ? t(`createRecursiveTaskForm.info.${name}`)
                        : ''
                    }
                  />
                }
                isInvalid={!!error}
                error={t(error?.message as string)}
              >
                <EuiFieldText
                  placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
          <Controller
            control={control}
            name="search_fields.passport"
            render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                fullWidth
                label={
                  <FieldInfo
                    label={t(`createRecursiveTaskForm.label.${name}`)}
                    info={
                      i18n.exists(`createRecursiveTaskForm.info.${name}`)
                        ? t(`createRecursiveTaskForm.info.${name}`)
                        : ''
                    }
                  />
                }
                isInvalid={!!error}
                error={t(error?.message as string)}
              >
                <EuiFieldText
                  placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
          <Controller
            control={control}
            name="search_fields.snils"
            render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow
                fullWidth
                label={
                  <FieldInfo
                    label={t(`createRecursiveTaskForm.label.${name}`)}
                    info={
                      i18n.exists(`createRecursiveTaskForm.info.${name}`)
                        ? t(`createRecursiveTaskForm.info.${name}`)
                        : ''
                    }
                  />
                }
                isInvalid={!!error}
                error={t(error?.message as string)}
              >
                <EuiFieldText
                  placeholder={t(`createRecursiveTaskForm.placeholder.${name}`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                />
              </EuiFormRow>
            )}
          />
          {globalError.map((error, index) => (
            <EuiFormRow key={index}>
              <EuiFormErrorText>{error}</EuiFormErrorText>
            </EuiFormRow>
          ))}
          <EuiSpacer size="l" />
          <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
            <EuiButtonEmpty color="text" onClick={onClose}>
              {t('button.cancel')}
            </EuiButtonEmpty>
            <EuiButton isLoading={isLoading} onClick={handleSubmit(handleUpload)} fill>
              {t('button.create')}
            </EuiButton>
          </EuiFlexGroup>
        </EuiForm>
      </EuiModalBody>
    </EuiModal>
  );
};
