import { AxiosPromise } from 'axios';

import axios from '@/services/axios';

type WithDataResponse<T = unknown> = {
  data: T;
};

class CustomerApi {
  private BASE_PATH = '/customer/customers';

  getCurrentAccount(): AxiosPromise<Paths.GetCustomer.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}`,
      method: 'get',
    });
  }

  updateCurrentAccount(data: Paths.CustomerUpdate.Parameters.Body): AxiosPromise<Paths.CustomerUpdate.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}`,
      method: 'put',
      data,
    });
  }

  updateAccountById({
    id,
    ...data
  }: Paths.ExtendedCustomerUpdate.PathParameters &
    Paths.ExtendedCustomerUpdate.Parameters.Body): AxiosPromise<Paths.ExtendedCustomerUpdate.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${id}`,
      method: 'put',
      data,
    });
  }

  updatePasswordCurrentAccount(data: Paths.CustomerPasswordChange.Parameters.Body): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/password-change`,
      method: 'post',
      data,
    });
  }

  updatePasswordAccountById({
    id,
    ...data
  }: Paths.ExtendedCustomerPasswordChange.Parameters.Body &
    Paths.ExtendedCustomerPasswordChange.PathParameters): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/${id}/password-change`,
      method: 'post',
      data,
    });
  }

  createAccount(
    data: Paths.RegisterCustomer.Parameters.Body
  ): AxiosPromise<{ data: Paths.RegisterCustomer.Responses.$200 }> {
    return axios({
      url: `${this.BASE_PATH}`,
      method: 'post',
      data,
    });
  }

  updateApiTokenAccountById({
    id,
  }: Paths.ExtendedApiTokenReset.PathParameters): AxiosPromise<Paths.ExtendedApiTokenReset.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${id}/api-tokens`,
      method: 'post',
    });
  }

  updateTelegramTokenById({
    id,
  }: Paths.ExtendedTelegramTokenReset.PathParameters): AxiosPromise<Paths.ExtendedTelegramTokenReset.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/${id}/telegram-tokens`,
      method: 'post',
    });
  }

  getQrCodeTwoAuth(): AxiosPromise<Blob> {
    return axios({
      url: `${this.BASE_PATH}/two-factor-auth`,
      method: 'get',
      responseType: 'blob',
    });
  }

  enableTwoAuth(
    data: Paths.EnableTwoFactorAuth.Parameters.Body
  ): AxiosPromise<WithDataResponse<Paths.EnableTwoFactorAuth.Responses.$200>> {
    return axios({
      url: `${this.BASE_PATH}/two-factor-auth`,
      method: 'post',
      data,
    });
  }

  disableTwoAuth(data: Paths.DisableTwoFactorAuth.Parameters.Body): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/two-factor-auth`,
      method: 'delete',
      data,
    });
  }

  regenerateCodesTwoAuth(
    data: Paths.ResetRecoveryCodes.Parameters.Body
  ): AxiosPromise<WithDataResponse<Paths.ResetRecoveryCodes.Responses.$200>> {
    return axios({
      url: `${this.BASE_PATH}/two-factor-auth/codes`,
      method: 'post',
      data,
    });
  }
}

export const customerApi = new CustomerApi();
