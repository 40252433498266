import {
  EuiButton,
  EuiButtonIcon,
  EuiCopy,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SuccessUpdatePasswordPropsData = {
  password: string;
  id: string;
};

interface SuccessUpdatePasswordProps {
  data: SuccessUpdatePasswordPropsData;
  onClose: () => void;
}
export const SuccessUpdatePassword: FC<SuccessUpdatePasswordProps> = ({ data, onClose }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();

  const [isShowPassword, setIsShowPassword] = useState(true);

  const copyText = useMemo(() => `${data.password}`, [data]);

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t('modals.titles.success')}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFlexItem>
          <EuiText
            size="s"
            style={{
              marginTop: 6,
              marginBottom: 20,
            }}
          >
            {t('modals.successUpdatePassword.title')}
          </EuiText>
          <EuiFieldText
            type={isShowPassword ? 'password' : 'text'}
            value={data.password}
            fullWidth
            readOnly
            append={
              <EuiFlexGroup
                style={{
                  backgroundColor: euiTheme.colors.emptyShade,
                  flexGrow: 0,
                  paddingRight: euiTheme.size.m,
                }}
                gutterSize="none"
              >
                <EuiButtonIcon
                  style={{
                    backgroundColor: euiTheme.colors.emptyShade,
                  }}
                  display="empty"
                  iconType={isShowPassword ? 'eye' : 'eyeClosed'}
                  onClick={() => setIsShowPassword((prevValue) => !prevValue)}
                  color="text"
                  aria-label="Show securety field"
                />
              </EuiFlexGroup>
            }
          />
          <EuiFlexGroup
            style={{
              marginTop: euiTheme.size.m,
            }}
            gutterSize="xs"
          >
            <EuiIcon type="alert" color="warning" />
            <EuiText
              size="xs"
              color="subdued"
              style={{
                maxWidth: 280,
              }}
            >
              {t('modals.alert.base', {
                subject: t('modals.alert.password'),
              })}
            </EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiCopy textToCopy={copyText}>
          {(copy) => (
            <EuiButton onClick={copy} fill color="success">
              {t('button.copy')}
            </EuiButton>
          )}
        </EuiCopy>
      </EuiModalFooter>
    </EuiModal>
  );
};
