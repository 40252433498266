import { useCallback, useState } from 'react';
import { EuiButton, EuiFlexGroup } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '@/store';
import { customerActions } from '@/store/customer';
import { ConfirmGenerateToken, SuccessGenerateToken } from '../Modals';
import { useIsOpenControl } from '@/hooks';

export const TelegramTokenForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { isOpen: isOpenConfirm, open: handleOpenConfirm, close: handleCloseConfirm } = useIsOpenControl();
  const [successData, setSuccessData] = useState<Definitions.TelegramToken | null>(null);

  const handleGenerate = useCallback(async () => {
    handleCloseConfirm();
    const data = await dispatch(customerActions.updateTelegramTokenCurrentAccount()).unwrap();
    if (data) {
      setSuccessData(data);
    }
  }, [dispatch, handleCloseConfirm]);

  const handleSuccess = useCallback(() => {
    setSuccessData(null);
  }, [setSuccessData]);

  return (
    <>
      <EuiFlexGroup>
        <EuiButton color="danger" onClick={handleOpenConfirm}>
          {t('page.account.settings.telegram.items.buttonGenerateToken')}
        </EuiButton>
      </EuiFlexGroup>
      {isOpenConfirm && (
        <ConfirmGenerateToken onClose={handleCloseConfirm} onConfirm={handleGenerate} tokenType="telegram" />
      )}
      {!!successData && <SuccessGenerateToken token={successData.token} onClose={handleSuccess} tokenType="telegram" />}
    </>
  );
};
