import { EuiSpacer, EuiText } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { memo } from 'react';

import { useAppSelector } from '@/store';
import { Roles, useRole } from '@/hooks';
import { organizationSelectors } from '@/store/organization';

export const OrganizationInfo = memo(function OrganizationInfo() {
  const { t } = useTranslation();

  const organization = useAppSelector(organizationSelectors.getInfo);
  const { isValid } = useRole([Roles.SUPER_ADMIN, Roles.SYSTEM]);

  return (
    <>
      <EuiSpacer size="s" />
      {isValid && !!organization && organization.subscription_expires_at && (
        <EuiText color="subdued" size="s">
          {t('page.account.settings.organizationUntil', {
            date: moment(organization.subscription_expires_at).format(process.env.REACT_APP_DATE_FORMAT),
          })}
        </EuiText>
      )}
    </>
  );
});
