import {
  Control,
  ControlOptions,
  ControlPosition,
  DivIcon,
  DomUtil,
  LatLng,
  LatLngBounds,
  LeafletMouseEvent,
  Map,
  Marker,
  Rectangle,
} from 'leaflet';
import throttle from 'lodash/throttle';

import { CUSTOM_CONTAINERS } from '../CustomControlContainers';

interface AreaSelectorControlOptions extends ControlOptions {
  title?: string;
  onEndSelect?: (bounds: LatLngBounds) => void;
  onControlCreate?: () => void;
  onControlRemove?: () => void;
}

const INITIAL_OPTIONS: AreaSelectorControlOptions = {
  position: 'topleft',
  title: 'Select area',
};

export class AreaSelectorControl extends Control {
  constructor(options?: AreaSelectorControlOptions) {
    super({ ...INITIAL_OPTIONS, ...options });
    this.options = { ...INITIAL_OPTIONS, ...options };
  }
  private _map!: Map;
  private isActive = false;
  private isSelecting = false;
  private startPoint: LatLng | null = null;
  private endPoint: LatLng | null = null;
  private rectangle: Rectangle | null = null;
  private controlContainer: Marker | null = null;
  isInit = false;
  options: AreaSelectorControlOptions = INITIAL_OPTIONS;
  button: HTMLElement | undefined;
  controlContainerElement: HTMLElement | undefined | null = null;

  onAdd(map: Map): HTMLElement {
    let container = map.getCustomControlContainer(this.options.position as ControlPosition, CUSTOM_CONTAINERS.TOOLS);
    if (!container) container = DomUtil.create('div');

    this.isInit = true;
    this.button = container;

    return container;
  }

  toggleActivation() {
    this.isActive = !this.isActive;

    const map = this.getMap();
    if (this.isActive) {
      this.clearArea();
      map.dragging.disable();
      map.scrollWheelZoom.disable();

      map.on('mousedown', this.startSelect, this);
      map.on('mouseup', this.endSelect, this);
      map.on('mousemove', this.moveSelect, this);

      DomUtil.addClass(map.getContainer(), 'leaflet-area-selecting');
    } else {
      map.dragging.enable();
      map.scrollWheelZoom.enable();

      map.off('mousedown', this.startSelect, this);
      map.off('mouseup', this.endSelect, this);
      map.off('mousemove', this.moveSelect, this);

      DomUtil.removeClass(map.getContainer(), 'leaflet-area-selecting');
    }
  }

  getMap() {
    return this._map as Map;
  }

  startSelect(event: LeafletMouseEvent) {
    this.startPoint = event.latlng;
    this.isSelecting = true;
    if (this.controlContainer) {
      this.controlContainer.remove();
    }
  }

  moveSelect(event: LeafletMouseEvent) {
    if (this.isSelecting) {
      this.renderSelectAreaThrottle(event.latlng);
    }
  }

  endSelect(event: LeafletMouseEvent) {
    this.endPoint = event.latlng;
    if (this.rectangle) {
      if (this.startPoint && this.endPoint)
        this.rectangle.setBounds([
          [this.startPoint.lat, this.startPoint.lng],
          [this.endPoint.lat, this.endPoint.lng],
        ]);

      const ne = this.rectangle.getBounds().getNorthEast();
      if (!this.controlContainer) {
        const map = this.getMap();
        this.controlContainer = new Marker(ne, {
          icon: new DivIcon({
            iconSize: [0, 0],
            className: 'leaflet-marker-control-container',
          }),
        });
        this.controlContainer.on('add', this.onControlContainerAdd, this);
        this.controlContainer.on('remove', this.onControlContainerRemove, this);
        this.controlContainer.addTo(map);
      } else {
        this.controlContainer.setLatLng(ne);
      }
      if (this.options.onEndSelect && this.rectangle) {
        const bounds = this.rectangle.getBounds();
        this.options.onEndSelect(bounds);
      }
      this.toggleActivation();
    }

    this.isSelecting = false;
  }

  onControlContainerRemove() {
    this.controlContainer = null;
    this.controlContainerElement = null;
    if (this.options.onControlRemove) this.options.onControlRemove();
  }

  onControlContainerAdd() {
    if (this.controlContainer) {
      this.controlContainerElement = this.controlContainer.getElement();

      if (this.options.onControlCreate) this.options.onControlCreate();
    }
  }

  clearArea() {
    if (this.controlContainer) {
      this.controlContainer.remove();
      this.controlContainer = null;
    }
    if (this.rectangle) {
      this.rectangle.remove();
      this.rectangle = null;
    }
  }

  //TODO initial create area

  renderSelectArea(point: LatLng) {
    if (!this.startPoint) {
      this.startPoint = point;
    }

    if (this.startPoint) {
      const map = this.getMap();
      if (!this.rectangle) {
        this.rectangle = new Rectangle(
          [
            [this.startPoint.lat, this.startPoint.lng],
            [point.lat, point.lng],
          ],
          {
            color: '#0077cc',
            weight: 3,
            fill: false,
            dashArray: '20, 20',
          }
        );
        this.rectangle.addTo(map);
      } else {
        this.rectangle.setBounds([
          [this.startPoint.lat, this.startPoint.lng],
          [point.lat, point.lng],
        ]);
      }
    }
  }

  renderSelectAreaThrottle = throttle(this.renderSelectArea, 16);
}

export const areaSelectorControl = (options?: AreaSelectorControlOptions) => {
  return new AreaSelectorControl(options);
};
