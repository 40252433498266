import { createAction, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Mutex } from 'async-mutex';
import fileDownload from 'js-file-download';

import { leakApi } from '@/api';
import { RootState } from '../store';
import { getUiLimitRow } from '../customer/selectors';
import { toastActions, toastSelectors } from '../toast';
import { ToastVariantBasic } from '@/components';
import { appActions } from '../app';

import { getTotalData } from './selectors';

export interface State {
  searchParams: Definitions.Search | null;
  loading: boolean;
  items: Definitions.LeakStats[];
  total_items: Definitions.TotalItems;
  error: Definitions.Error | null;
  isExportPennding: boolean;
  filters: Definitions.Filter[];
  detail: Definitions.LeakStats | null;
}

const EXPORT_FILENAME = 'data.json';

const mutexExportData = new Mutex();

const clearSearch = createAction('leaks/clearSearch');
const clearSearchParams = createAction('leaks/clearSearchParams');
const setDetail = createAction<string | null>('leaks/setDetail');

export const fetchData = createAsyncThunk('leaks/fetchData', async (payload: Definitions.Search, thunkApi) => {
  thunkApi.dispatch(appActions.getDatabaseLoad());
  const state = thunkApi.getState() as RootState;

  if (
    !!state.leaks.searchParams &&
    !Object.entries(payload).some(
      ([key, value]) => state.leaks.searchParams && value !== state.leaks.searchParams[key as keyof Definitions.Search]
    )
  ) {
    return {
      items: state.leaks.items,
      total_items: state.leaks.total_items,
    };
  }
  try {
    const {
      data: { data },
    } = await leakApi.leakProfilerSearch(payload);

    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  }
});

export const exportData = createAsyncThunk('leaks/exportData', async (payload: Definitions.Search, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const totalItems = getTotalData(state);
  const uiLimitRow = getUiLimitRow(state);

  if (uiLimitRow && totalItems && totalItems.value) {
    thunkApi.dispatch(
      toastActions.create({
        type: ToastVariantBasic.DOWNLOADING_FILE,
      })
    );
    let items: Definitions.Credential[] = [];
    const total = Math.min(totalItems.value, uiLimitRow);
    let offset = 0;
    const limit = 100;
    try {
      while (offset < total) {
        const release = await mutexExportData.acquire();
        const {
          data: { data },
        } = await leakApi.leakProfilerSearch({ ...payload, limit, offset });
        if (data && data.items) {
          items = [...items, ...data.items];
        }

        offset += limit;
        release();
      }
      fileDownload(JSON.stringify(items, null, 2), EXPORT_FILENAME);
      thunkApi.dispatch(
        toastActions.create({
          type: ToastVariantBasic.DOWNLOADED_FILE,
        })
      );
    } catch (error) {
      thunkApi.dispatch(
        toastActions.create({
          type: ToastVariantBasic.DOWNLOADED_ERROR_FILE,
        })
      );
      throw error;
    } finally {
      const toasts = toastSelectors.getItems(thunkApi.getState() as RootState);
      const toast = toasts.find((item) => item.type === ToastVariantBasic.DOWNLOADING_FILE);
      if (toast) thunkApi.dispatch(toastActions.remove(toast.id));
    }
  }
});

export const fetchFilters = createAsyncThunk(
  'leaks/fetchFilters',
  async (payload: Paths.ProfilerLeaksSearchFilters.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.getLeakProfilerFilter(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const updateLeakById = createAsyncThunk(
  'leaks/updateLeakById',
  async (payload: Paths.EditProfilerLeaks.PathParameters & Paths.EditProfilerLeaks.Parameters.Body, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.updateProfilerLeakById(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'leaks',
  initialState: {
    loading: false,
    error: null,
    items: [],
    total_items: {},
    isExportPennding: false,
    searchParams: null,
    filters: [],
    detail: null,
  },
  reducers: {
    clearSearch: (state) => {
      state.items = [];
      state.total_items = {};
      state.error = null;
      state.searchParams = null;
      state.detail = null;
    },
    clearSearchParams: (state) => {
      state.searchParams = null;
    },
    setDetail: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        const item = state.items.find(({ id }) => id === action.payload);
        if (item) state.detail = item;
      } else {
        state.detail = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.searchParams = action.meta.arg;
        if (action.payload) {
          const { items, total_items } = action.payload;
          state.items = items || [];
          state.total_items = total_items || {};
        }

        state.loading = false;
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.searchParams = action.meta.arg;
        const { error } = action.payload as Paths.SearchProfilerLeaks.Responses.$400;
        state.loading = false;
        state.items = [];
        state.total_items = {};
        state.error = error || {
          message: 'unknownError',
        };
      });

    builder.addCase(updateLeakById.fulfilled, (state, action) => {
      if (action.payload) {
        state.detail = action.payload;
        state.items = state.items.map((item) =>
          action.payload && item.id === action.payload.id ? action.payload : item
        );
      }
    });

    builder
      .addCase(exportData.pending, (state) => {
        state.isExportPennding = true;
      })
      .addCase(exportData.fulfilled, (state) => {
        state.isExportPennding = false;
      })
      .addCase(exportData.rejected, (state) => {
        state.isExportPennding = false;
      });

    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      if (action.payload) {
        state.filters = action.payload.items || [];
      }
    });
  },
});

export const actions = {
  clearSearch,
  fetchData,
  exportData,
  clearSearchParams,
  fetchFilters,
  setDetail,
  updateLeakById,
};

export default slice.reducer;
