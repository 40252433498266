import { EuiFlexGroup } from '@elastic/eui';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Routes } from '@/routes';
import { RoleRequiredChildren } from '../layouts';
import { Roles } from '@/hooks';

import { AdministrationCard } from './AdministrationCard';
import { SuperAdministration } from './SuperAdministration';

export const Administration = () => {
  const navigate = useNavigate();
  const handleUserGroups = useCallback(() => {
    navigate(Routes.ORGANIZATION);
  }, [navigate]);

  const handleAccount = useCallback(() => {
    navigate(Routes.ACCOUNT_GENERAL_SETTING);
  }, [navigate]);

  return (
    <EuiFlexGroup wrap justifyContent="spaceBetween" gutterSize="l">
      <AdministrationCard type="account" onClick={handleAccount} />
      <AdministrationCard type="users_groups" onClick={handleUserGroups} />
      <RoleRequiredChildren fallback={null} roles={[Roles.SUPER_ADMIN, Roles.SYSTEM]}>
        <SuperAdministration />
      </RoleRequiredChildren>
    </EuiFlexGroup>
  );
};
