import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { authenticationApi } from '@/api';
import { fingerprint } from '@/services/fingerprint';
import { customerActions } from '../customer';
import { credentialsActions } from '../credentials';
import { organizationActions } from '../organization';
import { groupsActions } from '../groups';
import { profilerActions } from '../profiler';
import { logsActions } from '../logs';
import { phonebookActions } from '../phonebook';
import { mapActions } from '../map';
import { datasetActions } from '../dataset';
import { recursiveActions } from '../recursive';
import { leaksActions } from '../leaks';

export interface State {
  loading: boolean;
  token: string | null;
  error: Definitions.Error | null;
}

const clearError = createAction('authentication/clearError');

const login = createAsyncThunk(
  'authentication/login',
  async (payload: Omit<Definitions.AuthRequest, 'fingerprint'>, thunkApi) => {
    try {
      const visitorId = await fingerprint;
      const {
        data: { data },
      } = await authenticationApi.login({
        ...payload,
        fingerprint: visitorId,
      });
      if (data) {
        if (data.access_token && data.pending_two_fa) {
          localStorage.setItem('token_otp', data.access_token);
          data.access_token = undefined;
        } else if (data.access_token) {
          localStorage.setItem('token', data.access_token);
        }
      }
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

const logout = createAsyncThunk('authentication/logout', async (_, thunkApi) => {
  try {
    await authenticationApi.logout();
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  } finally {
    localStorage.removeItem('token');
    localStorage.removeItem('token_otp');
    thunkApi.dispatch(credentialsActions.clearSearch());
    thunkApi.dispatch(profilerActions.clearSearch());
    thunkApi.dispatch(organizationActions.clearInfo());
    thunkApi.dispatch(groupsActions.clearInfo());
    thunkApi.dispatch(logsActions.clearSearch());
    thunkApi.dispatch(phonebookActions.clearSearch());
    thunkApi.dispatch(mapActions.clearSearch());
    thunkApi.dispatch(datasetActions.clearSearch());
    thunkApi.dispatch(recursiveActions.clearSearch());
    thunkApi.dispatch(leaksActions.clearSearch());
  }

  return;
});

const refresh = createAsyncThunk('authentication/refresh', async (_, thunkApi) => {
  try {
    await authenticationApi.refresh();
    thunkApi.dispatch(customerActions.fetchCurrentAccountSync());
    return;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  }
});

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'authentication',
  initialState: {
    token: localStorage.getItem('token') ?? null,
    loading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload) {
          const { access_token } = action.payload;
          state.token = access_token ?? null;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        const { error } = action.payload as Paths.CustomerLogin.Responses.$400;
        state.loading = false;
        state.token = null;
        state.error = error || {
          message: 'unknownError',
        };
      });

    builder
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
      })
      .addCase(logout.rejected, (state) => {
        state.loading = false;
        state.token = null;
      });
  },
});

export const actions = {
  login,
  logout,
  refresh,
  clearError,
};

export default slice.reducer;
