import { AxiosPromise } from 'axios';

import axios from '@/services/axios';

class AuthenticationApi {
  private BASE_PATH = '/customer';

  login(data: Paths.CustomerLogin.Parameters.Body): AxiosPromise<Paths.CustomerLogin.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/login`,
      method: 'post',
      data,
    });
  }

  logout(): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/logout`,
      method: 'get',
    });
  }

  refresh(): AxiosPromise {
    return axios({
      url: `${this.BASE_PATH}/token-refresh-anonymously`,
      method: 'post',
    });
  }

  updateApiTokenCurrentAccount(): AxiosPromise<Paths.ResetApiTokens.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/api-tokens`,
      method: 'post',
    });
  }

  updateTelegramTokenCurrentAccount(): AxiosPromise<Paths.ResetTelegramTokens.Responses.$200> {
    return axios({
      url: `${this.BASE_PATH}/telegram-tokens`,
      method: 'post',
    });
  }
}

export const authenticationApi = new AuthenticationApi();
