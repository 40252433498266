import { RootState } from '../store';

export const getData = (state: RootState) => state.leaks.items;
export const getTotalData = (state: RootState) => state.leaks.total_items;
export const getLoading = (state: RootState) => state.leaks.loading;
export const getError = (state: RootState) => state.leaks.error;
export const getExportPennding = (state: RootState) => state.leaks.isExportPennding;

export const getSearchParams = (state: RootState) => state.leaks.searchParams;
export const getFilters = (state: RootState) => state.leaks.filters;

export const getDetail = (state: RootState) => state.leaks.detail;
