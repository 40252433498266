import { FunctionComponent } from 'react';

import { FileCard } from '../FileCard/FileCard';

import { FilesLineProps } from './types';

export const FilesLine: FunctionComponent<FilesLineProps> = ({ data }) => {
  const { key, level, value } = data;

  const formattedKey: string | JSX.Element = key && key + ':';

  return (
    <span className="line" data-level={level}>
      <span
        className="key"
        style={{
          paddingLeft: level * 10,
        }}
      >
        {formattedKey}
      </span>
      <span className="files">
        {value.map((file) => (
          <FileCard key={file.name} {...file} />
        ))}
      </span>
    </span>
  );
};
