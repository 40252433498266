import {
  EuiBadge,
  EuiButtonEmpty,
  EuiCodeBlock,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiImage,
  EuiSpacer,
  EuiTab,
  EuiTabProps,
  EuiTabs,
  EuiText,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/core';

import { ConfirmDownloadFile } from '../Modals';
import { LeakMetaCard } from '../LeakMetaCard';
import { Matcher } from '../AutoComplete/matcher';

import { useDownloads } from './useDownloads';

interface LeakFlyoutProps {
  leak: Definitions.CredentialsLeak;
  hrefAllLeak: string | undefined;
  onShowAllLeak: MouseEventHandler<HTMLAnchorElement>;
  onClose: () => void;
}

export enum DataTabs {
  META = 'META',
  JSON = 'JSON',
}

type TabProps = EuiTabProps & { id: DataTabs; name: string };

export const LeakFlyout: FC<LeakFlyoutProps> = ({ leak, hrefAllLeak, onShowAllLeak, onClose }) => {
  const { t, i18n } = useTranslation();
  const {
    image,
    leak: detailLeak,
    isCanDownload,
    isLoadingFile,
    isLoadingImage,
    isOpenConfirmModal,
    setIsOpenConfirmModal,
    handleLoadFile,
  } = useDownloads(leak?.id);
  const { euiTheme } = useEuiTheme();

  const tabs = useMemo<TabProps[]>(
    () =>
      [
        {
          id: DataTabs.META,
        },
        {
          id: DataTabs.JSON,
        },
      ].map((item) => ({
        ...item,
        name: t(`modals.leak.tabs.${item.id}`),
      })),
    [t]
  );

  const [selectedTabId, setSelectedTabId] = useState<DataTabs>(DataTabs.META);

  const renderTabs = useCallback(() => {
    return tabs.map((tab) => {
      const handleClick: MouseEventHandler<HTMLAnchorElement> = () => {
        setSelectedTabId(tab.id);
      };
      return (
        <EuiTab
          key={tab.id}
          onClick={handleClick}
          isSelected={tab.id === selectedTabId}
          disabled={tab.disabled}
          prepend={tab.prepend}
          append={tab.append}
        >
          {tab.name}
        </EuiTab>
      );
    });
  }, [selectedTabId, tabs]);

  const renderTabContent = useMemo(() => {
    const dataMeta = leak.meta || {};
    switch (selectedTabId) {
      case DataTabs.META: {
        let replaceRgx: RegExp | string = '';
        try {
          replaceRgx = new RegExp(`${Matcher.screenSpecialChars(leak.title as string)}`, 'i');
        } catch (error) {
          captureException(error);
        }

        return leak && leak.id ? (
          <LeakMetaCard
            data={
              detailLeak?.folder_tree?.length
                ? {
                    ...dataMeta,
                    folder_tree: detailLeak.folder_tree.map((v) => {
                      const chunks = v.split('/');
                      if (chunks.length > 1 && chunks[0] && chunks[0].match(replaceRgx)) {
                        chunks.shift();
                      }
                      return chunks.join('/');
                    }),
                  }
                : dataMeta
            }
            id={leak.id}
          />
        ) : null;
      }
      case DataTabs.JSON:
        return leak ? (
          <div
            style={{
              position: 'relative',
            }}
          >
            <EuiCodeBlock lineNumbers language="json" transparentBackground paddingSize="none">
              {JSON.stringify(
                detailLeak?.folder_tree?.length ? { ...dataMeta, folder_tree: detailLeak.folder_tree } : dataMeta,
                null,
                2
              )}
            </EuiCodeBlock>
            <EuiCopy key="copy" textToCopy={JSON.stringify(leak.meta, null, 2)} display="block">
              {(copy) => (
                <EuiButtonEmpty
                  style={{
                    position: 'absolute',
                    top: euiTheme.size.base,
                    right: euiTheme.size.xxs,
                  }}
                  onClick={() => {
                    copy();
                  }}
                  size="s"
                  iconType="copyClipboard"
                >
                  {t('button.copy_to_clipboard')}
                </EuiButtonEmpty>
              )}
            </EuiCopy>
          </div>
        ) : null;
      default:
        return null;
    }
  }, [selectedTabId, euiTheme, t, leak, detailLeak]);

  return (
    <EuiFlyout
      aria-label={`flyout-${leak.title}`}
      onClose={onClose}
      ownFocus
      maxWidth={700}
      maskProps={{
        headerZindexLocation: 'above',
      }}
    >
      <EuiFlyoutBody>
        <EuiTitle size="xxs">
          <h2
            style={{
              fontWeight: euiTheme.font.weight.regular,
              wordBreak: 'break-all',
              paddingRight: euiTheme.size.m,
            }}
          >
            <b>{t('modals.leak.title')}:</b> {leak.title}
          </h2>
        </EuiTitle>
        <EuiSpacer size="m" />
        <EuiFlexGroup alignItems="flexStart">
          {!isLoadingImage && image && (
            <EuiFlexItem grow={0}>
              <EuiImage size="l" allowFullScreen alt={leak.title as string} src={image} />
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={1}>
            {!!leak.released_at && (
              <EuiFlexGroup gutterSize="s" alignItems="center">
                <EuiBadge>released_at</EuiBadge>
                <EuiText size="xs">{leak.released_at}</EuiText>
              </EuiFlexGroup>
            )}
            <EuiSpacer size="xs" />
            {!!leak.type && i18n.exists(`modals.leak.type.${leak.type}`) && (
              <EuiText size="s" color="subdued">
                {t(`modals.leak.type.${leak.type}`)}
              </EuiText>
            )}
            <EuiSpacer size="m" />
            <EuiFlexGroup gutterSize="s">
              <EuiButtonEmpty
                style={{ paddingInline: 0 }}
                iconType="search"
                contentProps={{
                  style: {
                    paddingInline: 0,
                  },
                }}
                onClick={onShowAllLeak}
                href={hrefAllLeak}
              >
                {t('modals.leak.button.showAll')}
              </EuiButtonEmpty>
              {isCanDownload && (
                <EuiButtonEmpty
                  style={{ paddingInline: 0 }}
                  iconType="exportAction"
                  onClick={() => setIsOpenConfirmModal(true)}
                >
                  {t('modals.leak.button.export')}
                </EuiButtonEmpty>
              )}
            </EuiFlexGroup>
            <EuiSpacer size="s" />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiTabs bottomBorder>{renderTabs()}</EuiTabs>
        <EuiSpacer size="m" />
        {renderTabContent}
        {isOpenConfirmModal && (
          <ConfirmDownloadFile
            isLoading={isLoadingFile}
            onClose={() => setIsOpenConfirmModal(false)}
            onConfirm={handleLoadFile}
          />
        )}
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};
