import { EuiButtonEmpty, EuiContextMenuItem, EuiContextMenuPanel, EuiPopover } from '@elastic/eui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@/store';
import { organizationActions, organizationSelectors } from '@/store/organization';

interface OrganizationSelectorProps {
  selectedId: number | undefined;
  onChange: (id: number) => void;
}

export const OrganizationSelector = ({ selectedId, onChange }: OrganizationSelectorProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const organizations = useAppSelector(organizationSelectors.getList);

  const [isPopoverOpen, setPopover] = useState(false);
  const selectedOrganization = useMemo(
    () => organizations.find(({ id }) => id === selectedId),
    [selectedId, organizations]
  );

  useEffect(() => {
    dispatch(organizationActions.fetchList());
  }, [dispatch]);

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const items = useMemo(
    () =>
      organizations.map(({ id, name }) => (
        <EuiContextMenuItem
          key={id}
          onClick={() => {
            closePopover();
            onChange(id as number);
          }}
        >
          {name}
        </EuiContextMenuItem>
      )),
    [organizations, onChange]
  );

  return (
    <EuiPopover
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      button={
        <EuiButtonEmpty aria-label="print" size="s" iconType="arrowDown" iconSide="right" onClick={onButtonClick}>
          {t('organizationSelector.button', {
            name: selectedOrganization?.name,
          })}
        </EuiButtonEmpty>
      }
    >
      <EuiContextMenuPanel size="s" items={items} />
    </EuiPopover>
  );
};
