import { EuiButtonIcon, EuiFlexGroup, EuiPopover, EuiText } from '@elastic/eui';
import { FunctionComponent, MouseEventHandler, useState } from 'react';

interface FieldInfoProps {
  label: string;
  info: string;
}

export const FieldInfo: FunctionComponent<FieldInfoProps> = ({ label, info }) => {
  const [isOpen, setOpen] = useState(false);
  const togglePopover: MouseEventHandler<HTMLButtonElement> = () => {
    setOpen((state) => !state);
  };
  const closePopover = () => setOpen(false);

  return (
    <EuiFlexGroup alignItems="flexEnd" gutterSize="xs">
      <span>{label}</span>
      {!!info && (
        <div
          style={{
            height: 16,
            position: 'relative',
          }}
        >
          <EuiPopover
            isOpen={isOpen}
            button={
              <EuiButtonIcon
                size="xs"
                iconType="questionInCircle"
                onClick={togglePopover}
                aria-label="What is field?"
              />
            }
            closePopover={closePopover}
            style={{
              top: -5,
            }}
          >
            <EuiText style={{ maxWidth: 300 }}>
              <p>{info}</p>
            </EuiText>
          </EuiPopover>
        </div>
      )}
    </EuiFlexGroup>
  );
};
