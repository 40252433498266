import { Outlet } from 'react-router-dom';
import { ReactElement } from 'react';

import { useRole, Roles } from '@/hooks';

interface RoleRequiredProps {
  roles: Roles[];
  fallback: ReactElement | null;
}

export const RoleRequired = ({ roles, fallback }: RoleRequiredProps) => {
  const { isValid } = useRole(roles);
  return isValid ? <Outlet /> : fallback;
};
