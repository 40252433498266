export class Mutex {
  private request: Promise<unknown> | null = null;

  async acquire(request: Promise<unknown>): Promise<unknown> {
    if (!this.request) {
      this.request = request;
    }

    return this.request;
  }

  isLocked(): boolean {
    return !!this.request;
  }

  getValue(): Promise<unknown> {
    return this.request as Promise<unknown>;
  }

  release(): void {
    this.request = null;
  }
}
