import { LatLng, LatLngBounds } from 'leaflet';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { EuiButtonIcon, EuiFlexGroup, EuiToolTip, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { useMap } from '../MapContainer';
import { AreaBounds } from '../types';

import { AreaSelectorControl, areaSelectorControl } from './AreaSelectorControl';

import './index.scss';

interface AreaSelectorProps {
  area: AreaBounds;
  onSelect: (params: { nw: LatLng; se: LatLng }) => void;
  onClear: () => void;
}

//TODO: area init value
export const AreaSelector: FunctionComponent<AreaSelectorProps> = ({ onSelect, onClear }) => {
  const { map } = useMap();
  const { t } = useTranslation();

  const handleEndSelect = useCallback(
    (bounds: LatLngBounds) => {
      const nw = map.wrapLatLng(bounds.getNorthWest());
      const se = map.wrapLatLng(bounds.getSouthEast());
      onSelect({ nw, se });
    },
    [map, onSelect]
  );

  const handleClearSelect = useCallback(() => {
    if (onClear) onClear();
  }, [onClear]);

  const { euiTheme } = useEuiTheme();

  const ctr = useRef(
    areaSelectorControl({
      position: 'topleft',
      onEndSelect: handleEndSelect,
    })
  );

  useEffect(() => {
    const control = ctr.current;
    if (!control.isInit) control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map]);

  const handleClick = () => {
    if (ctr.current.toggleActivation) {
      ctr.current.toggleActivation();
    }
  };

  if (ctr.current.button) {
    return (
      <>
        {createPortal(
          <EuiToolTip position="right" content={t('map.areaSelector.title')}>
            <EuiButtonIcon
              className={css(`
              background-color: ${euiTheme.colors.emptyShade};
            `)}
              size="s"
              display="base"
              color="text"
              iconType="vector"
              onClick={handleClick}
            />
          </EuiToolTip>,
          ctr.current.button
        )}
        <AreaControl control={ctr.current} onClear={handleClearSelect} />
      </>
    );
  }

  return null;
};
interface AreaControlProps {
  control: AreaSelectorControl;
  onClear: () => void;
}

const AreaControl: FunctionComponent<AreaControlProps> = ({ control, onClear }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    control.options.onControlRemove = () => setShouldRender(false);
    control.options.onControlCreate = () => setShouldRender(true);
  }, [control]);

  const handleClear = () => {
    control.clearArea();
    onClear();
  };
  const handleReset = () => {
    control.toggleActivation();
  };

  if (shouldRender && control.controlContainerElement) {
    const element = control.controlContainerElement;

    if (element)
      return createPortal(
        <EuiFlexGroup className="leaflet-marker-control-container__reactContainer" gutterSize="xs">
          <EuiButtonIcon iconType="refresh" display="base" color="text" onClick={handleReset} />
          <EuiButtonIcon iconType="eraser" display="base" color="danger" onClick={handleClear} />
        </EuiFlexGroup>,
        element
      );
  }
  return null;
};
