import { EuiFlexItem, useEuiTheme } from '@elastic/eui';
import { FC } from 'react';

export const EmptyPanel: FC = ({ children }) => {
  const { euiTheme } = useEuiTheme();

  return (
    <EuiFlexItem
      style={{
        paddingInline: euiTheme.size.xxxxl,
      }}
      grow={0}
    >
      {children}
    </EuiFlexItem>
  );
};
