import {
  EuiButton,
  EuiButtonGroup,
  EuiButtonIcon,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFieldText,
  EuiFlexGroup,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { OrganizationSelector } from '@/components/OrganizationSelector';
import { Roles, useRole } from '@/hooks';
import { DatePicker, dates, getDate } from '@/pages/Account/OrganizationStatistic';
import { useAppDispatch, useAppSelector } from '@/store';
import { customerSelectors } from '@/store/customer';
import { organizationActions } from '@/store/organization';
import { toastActions } from '@/store/toast';
import { ToastVariantBasic, ToastVariantWithText } from '@/components/Toasts';

import { FormFields, schema } from './schema';

interface RequestHistoryProps {
  onClose: () => void;
}

export const dataAdapter = (data: FormFields) => {
  const newData: Paths.DownloadOrganizationRequestHistory.PathParameters &
    Paths.DownloadOrganizationRequestHistory.Parameters.Body = {
    organization_id: data.organization_id,
    password: data.password || '',
  };
  try {
    if (data.from && data.to) {
      newData['period'] = {
        from: moment(data.from).format('YYYY-MM-DD'),
        to: moment(data.to).format('YYYY-MM-DD'),
      };
    }
  } catch (e) {
    captureException(e);
  }
  return newData;
};

export const RequestHistory: FC<RequestHistoryProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { euiTheme } = useEuiTheme();

  const user = useAppSelector(customerSelectors.getInfo);

  const [date, setDate] = useState<DatePicker>('all');
  const [organizationId, setOrganizationId] = useState(user?.organization_id);
  const [isShowPassword, setIsShowPassword] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const { isValid: isSystem } = useRole([Roles.SYSTEM]);

  const { control, watch, setValue, register, unregister, handleSubmit, setError } = useForm<FormFields>({
    defaultValues: {
      organization_id: `${organizationId}`,
      from: getDate(date).from_date,
      to: getDate(date).to_date,
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const handleDownload = useCallback(
    async (data: FormFields) => {
      try {
        setLoading(true);
        await dispatch(organizationActions.downloadCounters(dataAdapter(data))).unwrap();
        onClose();
      } catch (e: any) {
        const errorResponse = e as Definitions.Error;
        if (errorResponse?.errors) {
          errorResponse.errors.forEach((error) => {
            setError(error.location as keyof FormFields, error);
          });
        } else if (errorResponse?.message) {
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.ERROR_ACTION,
              text: errorResponse.message,
            })
          );
        } else {
          dispatch(
            toastActions.create({
              type: ToastVariantBasic.DOWNLOADED_ERROR_FILE,
            })
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, onClose, setError]
  );

  const { from, to } = watch();

  const isDisabledDatePicker = useMemo(() => date !== 'custom', [date]);

  useEffect(() => {
    const { from_date, to_date } = getDate(date);

    setValue('from', from_date);
    setValue('to', to_date);
  }, [date, setValue]);

  useEffect(() => {
    setValue('organization_id', `${organizationId}`);
  }, [organizationId, setValue]);

  useEffect(() => {
    register('organization_id');
    return () => {
      unregister('organization_id');
    };
  }, [register, unregister]);

  useEffect(() => {
    if (date !== 'custom') {
      register('from');
      register('to');
    }

    return () => {
      if (date !== 'custom') {
        unregister('from');
        unregister('to');
      }
    };
  }, [date, register, unregister]);

  return (
    <EuiModal onClose={onClose} maxWidth="l">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t('administration.items.history.title')}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
          <EuiText>{t('administration.items.history.note')}</EuiText>
          {isSystem && <OrganizationSelector selectedId={organizationId} onChange={setOrganizationId} />}
        </EuiFlexGroup>
        <EuiSpacer size="l" />
        <EuiFlexGroup>
          <EuiDatePickerRange
            isInvalid={!isDisabledDatePicker && !!from && !!to && Date.parse(from) > Date.parse(to)}
            disabled={isDisabledDatePicker}
            startDateControl={
              <Controller
                control={control}
                name="from"
                render={({ field: { value, onChange } }) => (
                  <EuiDatePicker
                    selected={value ? moment(value) : moment()}
                    onChange={(date) => date && onChange(date)}
                    startDate={value ? moment(value) : moment()}
                    endDate={to ? moment(to) : moment()}
                    aria-label="Start date"
                    disabled={isDisabledDatePicker}
                    dateFormat={process.env.REACT_APP_DATE_FORMAT}
                    minDate={moment('01-01-2022')}
                  />
                )}
              />
            }
            endDateControl={
              <Controller
                control={control}
                name="to"
                render={({ field: { value, onChange } }) => (
                  <EuiDatePicker
                    selected={value ? moment(value) : moment()}
                    onChange={(date) => date && onChange(date)}
                    startDate={from ? moment(from) : moment()}
                    endDate={value ? moment(value) : moment()}
                    aria-label="End date"
                    disabled={isDisabledDatePicker}
                    showIcon={false}
                    dateFormat={process.env.REACT_APP_DATE_FORMAT}
                    minDate={moment('01-01-2022')}
                  />
                )}
              />
            }
          />
          <EuiButtonGroup
            legend=""
            color="primary"
            options={dates.map((id) => ({
              id,
              label: t(`page.organizationStatistic.datePicker.${id}`),
            }))}
            idSelected={date}
            onChange={(id) => setDate(id as DatePicker)}
          />
        </EuiFlexGroup>
        <EuiSpacer size="l" />
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
            <EuiFormRow fullWidth isInvalid={!!error} error={t(error?.message as string)}>
              <EuiFieldText
                isInvalid={!!error}
                autoComplete="new-password"
                type={isShowPassword ? 'password' : 'text'}
                placeholder={t('form.label.current_password')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                append={
                  <EuiFlexGroup
                    style={{
                      backgroundColor: euiTheme.colors.emptyShade,
                      flexGrow: 0,
                      paddingRight: euiTheme.size.m,
                    }}
                    gutterSize="none"
                  >
                    <EuiButtonIcon
                      style={{
                        backgroundColor: euiTheme.colors.emptyShade,
                      }}
                      display="empty"
                      iconType={isShowPassword ? 'eye' : 'eyeClosed'}
                      onClick={() => setIsShowPassword((prevValue) => !prevValue)}
                      color="text"
                      aria-label="Show securety field"
                    />
                  </EuiFlexGroup>
                }
              />
            </EuiFormRow>
          )}
        />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton isLoading={isLoading} onClick={handleSubmit(handleDownload)} fill>
          {t('modals.requestHistory.export_button')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
