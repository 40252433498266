import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import axios from 'axios';
import { captureException } from '@sentry/react';

import { tasksApi } from '@/api';
import { appActions } from '../app';
import { RootState } from '../store';

export interface State {
  loading: boolean;
  items: Definitions.TaskData[];
  watchedItems: number[];
  total_items: number;
  error: Definitions.Error | null;
}

const clearSearch = createAction('phonebook/clearSearch');

export const fetchData = createAsyncThunk(
  'phonebook/fetchData',
  async (payload: Paths.GetTasks.QueryParameters, thunkApi) => {
    thunkApi.dispatch(appActions.getDatabaseLoad());

    try {
      const {
        data: { data },
      } = await tasksApi.search(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

export const remove = createAsyncThunk(
  'phonebook/remove',
  async (payload: Paths.DeleteTasks.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await tasksApi.remove(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.DeleteUpdateLog.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

export const create = createAsyncThunk(
  'phonebook/create',
  async (payload: Paths.CreateTasks.FormDataParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await tasksApi.create(payload);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.RegisterCustomer.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

type CheckStatusPayload = {
  id: number;
  signal: AbortSignal;
};

export const checkStatus = createAsyncThunk('phonebook/checkStatus', async (payload: CheckStatusPayload, thunkApi) => {
  try {
    const {
      data: { data },
    } = await tasksApi.fetchById({ id: `${payload.id}` }, payload.signal);
    return data;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const responseError = e?.response as { data: Paths.RegisterCustomer.Responses.$400 };
      return thunkApi.rejectWithValue(responseError?.data?.error);
    }
  }
});

const STATUSES_FOR_CHECKING = ['failed', 'success'];

export const watch = createAsyncThunk('phonebook/watch', async (_, thunkApi) => {
  let intervalId;

  try {
    intervalId = setInterval(() => {
      const watchedItems = (thunkApi.getState() as RootState).phonebook.watchedItems;
      (thunkApi.getState() as RootState).phonebook.items
        .filter((item) => !STATUSES_FOR_CHECKING.includes(item.status as string))
        .map((item) => item.id as number)
        .filter((id) => !watchedItems.includes(id))
        .forEach((id) => {
          thunkApi.dispatch(checkStatus({ id, signal: thunkApi.signal }));
        });
    }, 5000);
  } catch (e: any) {
    if (thunkApi.signal.aborted) {
      clearInterval(intervalId);
    }
    captureException(e);
  }
});

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'phonebook',
  initialState: {
    loading: false,
    items: [],
    total_items: 0,
    error: null,
    watchedItems: [],
  },
  reducers: {
    clearSearch: (state) => {
      state.items = [];
      state.total_items = 0;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        if (action.payload) {
          const { items, total_items } = action.payload;

          state.items = items || [];
          state.total_items = total_items || 0;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        const { error } = action.payload as Paths.SearchCredentials.Responses.$400;
        state.loading = false;
        state.items = [];
        state.total_items = 0;
        state.error = error || {
          message: 'unknownError',
        };
      });

    builder
      .addCase(checkStatus.pending, (state, action) => {
        state.watchedItems = [...state.watchedItems, action.meta.arg.id];
      })
      .addCase(checkStatus.fulfilled, (state, action) => {
        state.items = state.items.map((item) =>
          !!action.payload && item.id === action.payload?.id ? action.payload : item
        );
        state.watchedItems = state.watchedItems.filter((id) => action.meta.arg.id !== id);
      })
      .addCase(checkStatus.rejected, (state, action) => {
        state.watchedItems = state.watchedItems.filter((id) => action.meta.arg.id !== id);
      });
  },
});

export const actions = {
  clearSearch,
  fetchData,
  create,
  remove,
  watch,
};

export default slice.reducer;
