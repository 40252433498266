/* eslint-disable @typescript-eslint/ban-ts-comment */
import { LoaderFunctionArgs, RouteObject } from 'react-router-dom';

import { Api } from '@/pages';
import { GuidesDocument, ProfilerContainer } from '@/components';
//@ts-ignore
import document1 from '@/i18next/documentation/v1.0/Profiler/data-structure.md';
//@ts-ignore
import document2 from '@/i18next/documentation/v1.0/Profiler/how-it-work.md';
//@ts-ignore
import document3 from '@/i18next/documentation/v1.0/Profiler/example.md';
//@ts-ignore
import apiDoc from '@/i18next/documentation/api/profiler_api.yaml';
import { SearchResult } from '@/pages/Profiler/SearchResult';

import { ProfilerRoutes } from './types';

export async function loader({ params }: LoaderFunctionArgs) {
  switch (params.slug as ProfilerRoutes) {
    case ProfilerRoutes.DATA_STRUCTURE:
      return document1;
    case ProfilerRoutes.SEARCH_CONCEPT:
      return document2;
    case ProfilerRoutes.EXAMPLES:
      return document3;
    default:
      return null;
  }
}

export const profiler: RouteObject[] = [
  {
    path: '',
    element: <SearchResult />,
  },
  {
    path: ProfilerRoutes.API,
    loader: async () => apiDoc,
    element: (
      <ProfilerContainer>
        <Api />
      </ProfilerContainer>
    ),
  },
  {
    path: ':slug',
    loader,
    element: (
      <ProfilerContainer>
        <GuidesDocument />
      </ProfilerContainer>
    ),
  },
];
