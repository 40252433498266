import { AxiosPromise } from 'axios';

import axios from './instanse';
import { GeoSearchParams, GeoSearchResponse } from './types';

class GeoApi {
  search(params: GeoSearchParams, signal?: AbortSignal): AxiosPromise<GeoSearchResponse> {
    return axios({
      url: '/search',
      params,
      signal,
    });
  }
}

export const geoApi = new GeoApi();
