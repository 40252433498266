import { EuiButtonEmpty } from '@elastic/eui';
import { FunctionComponent, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrevious } from '@/hooks';

interface CollapsedItemProps {
  size: number;
  data: any[];
  id: string;
  margin?: number;
  forceUpdate?: () => void;
  render: (button: ReactNode, data: any[]) => ReactNode;
}

interface CollapsedContextParams {
  items: string[];
  setItem: (id: string) => void;
}

export const CollapsedContext = createContext<CollapsedContextParams>({
  items: [],
  setItem: (id: string) => {
    console.warn('Not remember collapsed item ', id);
  },
} as CollapsedContextParams);

export const CollapsedItem: FunctionComponent<CollapsedItemProps> = ({
  data,
  size,
  id,
  margin = 0,
  render,
  forceUpdate,
}) => {
  const { t } = useTranslation();
  const { items, setItem } = useContext(CollapsedContext);

  const [isShowed, setShowed] = useState<boolean>(!(size < data.length) || (items && items.includes(id)));

  const showedData = useMemo(() => {
    if (!isShowed) {
      return [...data].slice(0, size);
    } else {
      return data;
    }
  }, [data, size, isShowed]);

  const previesShowed = usePrevious(isShowed);

  useEffect(() => {
    if (previesShowed !== isShowed && forceUpdate) forceUpdate();
  }, [isShowed, previesShowed, forceUpdate]);

  const handleCLick = () => {
    setShowed(true);
    setItem(id);
  };

  return (
    <>
      {render(
        !isShowed ? (
          <div
            style={{
              display: 'flex',
            }}
          >
            <EuiButtonEmpty
              size="xs"
              color="primary"
              onClick={handleCLick}
              style={{
                height: 12,
                marginLeft: margin,
              }}
              contentProps={{
                style: {
                  padding: 0,
                },
              }}
            >
              {t('button.showAll', {
                count: data.length - size,
              })}
            </EuiButtonEmpty>
          </div>
        ) : null,
        showedData
      )}
    </>
  );
};
