import {
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiIcon,
  EuiPopover,
  EuiSwitch,
  useEuiTheme,
} from '@elastic/eui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';
import cn from 'classnames';

import uk from '@/assets/languages/uk.png';
import en from '@/assets/languages/en.png';
import { useChangeTheme } from '@/hooks';
import { languages } from '@/i18next/config';

const icons = {
  uk,
  en,
};

export const HeaderUIMenu = () => {
  const { colorMode, euiTheme } = useEuiTheme();
  const { i18n } = useTranslation();
  const isLightTheme = useMemo<boolean>(() => colorMode === 'LIGHT', [colorMode]);
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false);
  const changeTheme = useChangeTheme();

  const handleChangeTheme = useCallback(() => {
    changeTheme(isLightTheme ? 'DARK' : 'LIGHT');
  }, [isLightTheme, changeTheme]);

  const handleCloseLanguageMenu = useCallback(() => {
    setIsOpenLanguageMenu(false);
  }, []);

  const handleOpenLanguageMenu = useCallback(() => {
    setIsOpenLanguageMenu((state) => !state);
  }, []);

  const handleChangeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      setIsOpenLanguageMenu(false);
    },
    [i18n]
  );

  return (
    <EuiFlexGroup
      style={{
        height: '100%',
      }}
    >
      <EuiPopover
        isOpen={isOpenLanguageMenu}
        closePopover={handleCloseLanguageMenu}
        panelPaddingSize="none"
        anchorPosition="downRight"
        hasArrow={true}
        offset={4}
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'center',
          marginLeft: euiTheme.size.base,
        }}
        button={
          <EuiFlexGroup
            style={{
              height: '100%',
            }}
            alignItems="center"
          >
            <EuiButtonIcon
              iconSize="original"
              onClick={handleOpenLanguageMenu}
              iconType={icons[i18n.language as keyof typeof icons]}
              aria-label="languages menu"
            />
          </EuiFlexGroup>
        }
      >
        <EuiContextMenuPanel
          items={Object.entries(languages).map(([language, { name }]) => (
            <EuiContextMenuItem key={language} onClick={() => handleChangeLanguage(language)}>
              <EuiIcon type={icons[language as keyof typeof icons]} size="original" />
              <span
                style={{
                  marginLeft: euiTheme.size.s,
                }}
              >
                {name}
              </span>
            </EuiContextMenuItem>
          ))}
        />
      </EuiPopover>
      <div
        style={{
          position: 'relative',
          alignSelf: 'center',
          display: 'inline-block',
          overflow: 'hidden',
          padding: 2,
        }}
      >
        <EuiSwitch className="themeSwitch" label="" checked={isLightTheme} onChange={handleChangeTheme} />
        <EuiIcon
          onClick={handleChangeTheme}
          className={cn(
            css(`
            position: absolute;
            right: ${isLightTheme ? '28px' : '-34px'};
            top: 6px;
            bottom: 0;
            width: 12px;
            height: 12px;
            transition: left .25s cubic-bezier(.34,1.61,.7,1),right .25s cubic-bezier(.34,1.61,.7,1);
            fill: #dfe5ef;
            cursor: pointer;
          }`)
          )}
          type="sun"
        />
        <EuiIcon
          onClick={handleChangeTheme}
          className={cn(
            css(`
            position: absolute;
            left: ${isLightTheme ? '-34px' : '28px'};
            top: 6px;
            bottom: 0;
            width: 12px;
            height: 12px;
            transition: left .25s cubic-bezier(.34,1.61,.7,1),right .25s cubic-bezier(.34,1.61,.7,1);
            fill: #dfe5ef;
            cursor: pointer;
          }`)
          )}
          type="moon"
        />
      </div>
    </EuiFlexGroup>
  );
};
