import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { leakApi } from '@/api';

export interface State {
  loading: boolean;
  items: Definitions.IdentityProfile[];
  error: Definitions.Error | null;
  counters: Definitions.ProfilerCounters | null;
  enriched: boolean;
}

const clearSearch = createAction('profiler2/clearSearch');

export const fetchData = createAsyncThunk(
  'profiler2/fetchData',
  async (payload: Definitions.ProfilerAssessmentsRequest, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.profilersAssessments(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);
export const fetchCounters = createAsyncThunk('profiler2/fetchCounters', async (_, thunkApi) => {
  try {
    const {
      data: { data },
    } = await leakApi.getProfilerCounters();
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  }
});

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'profiler2',
  initialState: {
    loading: false,
    error: null,
    items: [],
    counters: null,
    enriched: false,
  },
  reducers: {
    clearSearch: (state) => {
      state.items = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.items = action.payload?.items || [];
        state.enriched = !!action.payload?.enriched;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        const { error } = action.payload as Paths.ProfilerAssessments.Responses.$401;
        state.loading = false;
        state.items = [];
        state.error = error || {
          message: 'unknownError',
        };
      });
    builder.addCase(fetchCounters.fulfilled, (state, action) => {
      if (action.payload) {
        state.counters = action.payload || [];
      }
    });
  },
});

export const actions = {
  clearSearch,
  fetchData,
  fetchCounters,
};

export default slice.reducer;
