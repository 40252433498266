import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface ConfirmAddMemberProps {
  groupTitle: string;
  members: Omit<Definitions.CustomerHTTPResponse, 'id'>[];
  onClose: () => void;
  onConfirm: () => void;
}
export const ConfirmAddMember: FC<ConfirmAddMemberProps> = ({ groupTitle, members, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t('modals.confirmAddMember.title')}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <p>
          <Trans
            i18nKey="modals.confirmAddMember.note"
            values={{
              group: groupTitle,
              users: members.map((member) => member.nickname || member.login).join(', '),
            }}
            components={[
              <strong
                style={{
                  wordBreak: 'break-all',
                }}
                key={0}
              />,
            ]}
          />
        </p>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty color="text" onClick={onClose}>
          {t('button.cancel')}
        </EuiButtonEmpty>
        <EuiButton onClick={onConfirm} fill color="warning">
          {t('button.confirm')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
