import { FC } from 'react';
import { EuiButton, EuiButtonProps, EuiFlexGroup, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui';
// eslint-disable-next-line import/no-unresolved
import { EuiButtonPropsForButton } from '@elastic/eui/src/components/button/button';

import { ReactComponent as EmptyDataIcon } from '@/assets/empty_data.svg';

interface EmptyDataProps {
  title?: string;
  note?: string;
  button?: EuiButtonProps & EuiButtonPropsForButton;
}

export const EmptyData: FC<EmptyDataProps> = ({ title, note, button }) => {
  return (
    <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
      <EmptyDataIcon width={250} />
      {!!title && (
        <EuiTitle size="s">
          <h1 style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>{title}</h1>
        </EuiTitle>
      )}
      {!!note && (
        <EuiText color="subdued" size="s">
          {note}
        </EuiText>
      )}
      <EuiSpacer size="m" />
      {!!button && <EuiButton {...button} />}
    </EuiFlexGroup>
  );
};
