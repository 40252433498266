import {
  EuiBadge,
  EuiButtonIcon,
  EuiDataGrid,
  EuiDataGridCellValueElementProps,
  EuiFlexGroup,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiTablePagination,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { organizationActions } from '@/store/organization';
import { useAppDispatch } from '@/store';
import { OrganizationRoutes } from '@/routes';
import { useWindowSize } from '@/hooks';
import { Loader } from '../Loader';

import { useData } from './useData';
import { CONFIG_ITEMS_PER_PAGE, DEFAULT_ITEMS_PER_PAGE } from './constants';
import { Columns } from './types';

type FormsFields = Paths.GetOrganizationMembers.QueryParameters;

export const GroupsTable = memo(function GroupsTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues = useMemo<FormsFields>(() => {
    let limit = Number(searchParams.get('limit') ?? DEFAULT_ITEMS_PER_PAGE);
    let offset = Number(searchParams.get('offset') ?? 0);
    if (!CONFIG_ITEMS_PER_PAGE.includes(limit)) {
      limit = DEFAULT_ITEMS_PER_PAGE;
    }
    offset = offset - (offset % limit);
    return {
      limit,
      offset,
    };
  }, [searchParams]);

  const { euiTheme } = useEuiTheme();
  const { height } = useWindowSize();

  const { columns, rawData, totalItems, isLoading, visibleColumns, setVisibleColumns, handleColumnResize } = useData();

  const gridRef = useRef(null);

  const { handleSubmit, setValue, watch, reset } = useForm<FormsFields & { limit: number }>({
    defaultValues,
  });

  const { limit, offset } = watch();

  const activePage = useMemo(() => (offset && limit ? offset / limit : 0), [limit, offset]);

  const handleSetSearchParams = useCallback(
    (data: FormsFields) => {
      const params = new URLSearchParams(Object.entries(data).filter((item) => item[1]));

      setSearchParams(params);
    },
    [setSearchParams]
  );

  const handleRequest = useCallback(
    (data: FormsFields) => {
      dispatch(organizationActions.fetchGroups(data));
    },
    [dispatch]
  );

  const handleChangeItemsPerPage = useCallback(
    (limit: number) => {
      setValue('limit', limit);
      setValue('offset', 0);
      handleSubmit(handleSetSearchParams)();
    },
    [setValue, handleSubmit, handleSetSearchParams]
  );

  const handleChangePage = useCallback(
    (indexPage: number) => {
      setValue('offset', indexPage * (limit ?? 0));
      handleSubmit(handleSetSearchParams)();
    },
    [limit, setValue, handleSubmit, handleSetSearchParams]
  );

  useEffect(() => {
    handleRequest(defaultValues);
    reset(defaultValues);
  }, [defaultValues, handleRequest, reset]);

  const RenderCellValue = useCallback(
    ({ rowIndex, columnId }: EuiDataGridCellValueElementProps) => {
      const disabledColor = rawData[rowIndex].status ? undefined : euiTheme.colors.disabledText;
      switch (columnId) {
        case Columns.title:
          return (
            <EuiBadge
              style={{
                paddingBlock: 2,
              }}
              isDisabled={!!disabledColor}
              color="default"
            >
              {rawData[rowIndex].title}
            </EuiBadge>
          );
        case Columns.status:
          return (
            <EuiFlexGroup gutterSize="s">
              {rawData[rowIndex].status ? <EuiIcon type="lockOpen" /> : <EuiIcon type="lock" color={disabledColor} />}
              <EuiText color={disabledColor} size="s">
                {rawData[rowIndex].status ? 'Active' : 'Disabled'}
              </EuiText>
            </EuiFlexGroup>
          );
        case Columns.total_members:
          return (
            <EuiBadge
              style={{
                paddingBlock: 2,
              }}
              isDisabled={!!disabledColor}
              color="default"
            >
              <EuiIcon color={disabledColor} type="users" /> {rawData[rowIndex].total_members}
            </EuiBadge>
          );
        default:
          return (
            <EuiText color={disabledColor} size="s">
              {rawData[rowIndex][columnId as Columns]}
            </EuiText>
          );
      }
    },
    [rawData, euiTheme]
  );

  if (isLoading)
    return (
      <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
        <EuiSpacer size="xxl" />
        <Loader />
      </EuiFlexGroup>
    );

  return (
    <EuiPanel style={{ overflow: 'hidden', width: '100%', marginTop: 24 }} hasBorder paddingSize="none">
      <EuiDataGrid
        className="EuiDataEmptyTopBorder"
        aria-labelledby=""
        style={{
          maxHeight: Math.max(height - 320, 400),
          height: Math.max(height - 320, 400),
        }}
        columns={columns}
        columnVisibility={{ visibleColumns, setVisibleColumns }}
        rowCount={rawData.length}
        renderCellValue={RenderCellValue}
        rowHeightsOptions={{ defaultHeight: 'auto' }}
        toolbarVisibility={false}
        onColumnResize={handleColumnResize}
        ref={gridRef}
        trailingControlColumns={[
          {
            id: 'actions',
            width: 50,
            headerCellRender: () => null,
            rowCellRender: ({ rowIndex }) => (
              <>
                <EuiButtonIcon
                  color={'text'}
                  iconType="pencil"
                  onClick={() => {
                    navigate(`../${OrganizationRoutes.EDIT_GROUP}/${rawData[rowIndex].id}`, {});
                  }}
                  aria-label="Edit"
                />
              </>
            ),
          },
        ]}
        gridStyle={{
          border: 'horizontal',
          header: 'shade',
          fontSize: 'm',
          cellPadding: 'l',
        }}
      />
      <EuiTablePagination
        aria-label="Members pagination"
        pageCount={Math.ceil(totalItems / limit)}
        activePage={activePage}
        showPerPageOptions={true}
        onChangePage={handleChangePage}
        itemsPerPage={limit}
        onChangeItemsPerPage={handleChangeItemsPerPage}
        itemsPerPageOptions={CONFIG_ITEMS_PER_PAGE}
      />
    </EuiPanel>
  );
});
