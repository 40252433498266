import { EuiBadge } from '@elastic/eui';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Columns } from './types';

export const renderValueDocument = (value: string | number, id: Columns) => {
  switch (id) {
    case Columns.created_at:
    case Columns.updated_at:
      return value ? moment.parseZone(value).format(process.env.REACT_APP_DATE_FORMAT) : '';
    default:
      return value;
  }
};

export const DocumentItem: FC<{
  id: Columns;
  value: string | number;
}> = ({ id, value }) => {
  const { t } = useTranslation();

  return (
    <>
      <EuiBadge>{t(`document.badge.${id}`)}:</EuiBadge>
      &nbsp;
      <span style={{ marginRight: '10px' }}>{renderValueDocument(value, id)}</span>
      &nbsp;
    </>
  );
};
