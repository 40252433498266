import { EuiIcon, EuiToolTip, useEuiTheme } from '@elastic/eui';
import { css } from '@emotion/css';
import { FC } from 'react';

interface ValidatedProfilerValue {
  isValid: boolean;
  title: string;
}

export const ValidatedProfilerValue: FC<ValidatedProfilerValue> = ({ children, title, isValid }) => {
  const { euiTheme } = useEuiTheme();
  const color = isValid ? euiTheme.colors.successText : euiTheme.colors.dangerText;
  return (
    <EuiToolTip position="top" content={title}>
      <>
        <span
          className={css`
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            border: 1px solid ${color};
            margin-right: ${euiTheme.size.xs};
            user-selectable: none;
            svg {
              width: 10px;
              height: 10px;
            }
          `}
        >
          <EuiIcon color={color} type={isValid ? 'check' : 'cross'} size="s" />
        </span>
        <span>{children}</span>
      </>
    </EuiToolTip>
  );
};
