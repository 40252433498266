import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiTextArea,
} from '@elastic/eui';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/react';

import { toastActions } from '@/store/toast';
import { credentialsActions } from '@/store/credentials';
import { useAppDispatch } from '@/store';
import { ToastVariantBasic, ToastVariantWithText } from '../Toasts';

import { FormFields } from './types';
import { schema } from './shema';

interface NotesFormProps {
  onClose: () => void;
  data: FormFields;
}

export const NotesForm: FC<NotesFormProps> = ({ onClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { control, setError, setValue, handleSubmit, register, unregister } = useForm<FormFields>({
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    register('id');
    register('is_valid');
    register('work_in_progress');

    return () => {
      unregister('id');
      unregister('is_valid');
      unregister('work_in_progress');
    };
  }, [register, unregister]);

  const isEdit = useMemo(() => !!data.note_text, [data]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const handleRequest = useCallback(
    async (data: FormFields) => {
      try {
        await dispatch(credentialsActions.updateNoteCredentialById(data)).unwrap();

        dispatch(
          toastActions.create({
            type: ToastVariantWithText.SUCCESS_ACTION,
            text: `Success! Note has been ${isEdit ? 'updated' : 'created'}!`,
          })
        );
        onClose();
      } catch (e: any) {
        const errorResponse = e as Definitions.Error;
        if (errorResponse?.errors) {
          errorResponse.errors.forEach((error) => {
            if (control._names.mount.has(error.location as keyof FormFields)) {
              setError(error.location as keyof FormFields, error);
            } else if (error.message) {
              dispatch(
                toastActions.create({
                  type: ToastVariantWithText.ERROR_ACTION,
                  text: error.message,
                })
              );
            } else {
              dispatch(
                toastActions.create({
                  type: ToastVariantBasic.UNKNOWN_ERROR,
                })
              );
              captureException(errorResponse);
            }
          });
        } else if (errorResponse?.message) {
          dispatch(
            toastActions.create({
              type: ToastVariantWithText.ERROR_ACTION,
              text: errorResponse.message,
            })
          );
        }
      } finally {
        setIsLoading(false);
        setIsLoading2(false);
      }
    },
    [dispatch, setError, onClose, isEdit, control]
  );

  const handleSave = useCallback(() => {
    handleSubmit((data) => {
      setIsLoading(true);
      handleRequest(data);
    })();
  }, [handleSubmit, handleRequest]);

  const handleRemove = useCallback(() => {
    setValue('note_text', '');
    handleSubmit((data) => {
      setIsLoading2(true);
      handleRequest(data);
    })();
  }, [handleSubmit, setValue, handleRequest]);

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{t(`notesForm.${isEdit ? 'editTitle' : 'createTitle'}`)}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm
          style={{
            width: 412,
          }}
          component="form"
          onSubmit={handleSubmit(handleRequest)}
        >
          <Controller
            control={control}
            name="note_text"
            render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
              <EuiFormRow fullWidth isInvalid={!!error} error={t(error?.message as string)}>
                <EuiTextArea
                  placeholder={t(`notesForm.placeholder.${name}`)}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={!!error}
                  fullWidth
                  rows={10}
                />
              </EuiFormRow>
            )}
          />
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>{t('button.cancel')}</EuiButtonEmpty>
        {!!isEdit && (
          <EuiButton isLoading={isLoading2} onClick={handleRemove}>
            {t('button.remove')}
          </EuiButton>
        )}
        <EuiButton fill isLoading={isLoading} onClick={handleSave}>
          {t('button.save')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
