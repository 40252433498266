import { Chart, DARK_THEME, Datum, LIGHT_THEME, Partition, PartitionLayout, Settings } from '@elastic/charts';
import { useEuiTheme } from '@elastic/eui';
import { EUI_CHARTS_THEME_DARK, EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme';

import { indexInterpolatedFillColor, interpolatorCET2s } from './utils';

interface TreemapChartProps {
  data: Definitions.UserRequestsCounter[];
}

export const TreemapChart = ({ data }: TreemapChartProps) => {
  const { colorMode } = useEuiTheme();

  const theme = colorMode === 'DARK' ? DARK_THEME : LIGHT_THEME;
  const chartTheme = colorMode === 'DARK' ? EUI_CHARTS_THEME_DARK.theme : EUI_CHARTS_THEME_LIGHT.theme;

  return (
    <Chart size={{ height: 360 }}>
      <Settings
        theme={{
          ...chartTheme,
          partition: {
            ...chartTheme.partition,
            maxFontSize: 72,
          },
        }}
        baseTheme={theme}
      />
      <Partition
        id="top-users"
        data={data}
        layout={PartitionLayout.treemap}
        valueAccessor={(d: Definitions.UserRequestsCounter) => d.count as number}
        layers={[
          {
            groupByRollup: (d: Definitions.UserRequestsCounter) => d.username,
            nodeLabel: (d: Datum) => `${d} - `,
            shape: {
              fillColor: (key, sortIndex, node, tree) =>
                indexInterpolatedFillColor(interpolatorCET2s())(null, sortIndex, tree),
            },
          },
        ]}
      />
    </Chart>
  );
};
