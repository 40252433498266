import { RouterProvider } from 'react-router-dom';
import { EuiText } from '@elastic/eui';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import { router } from '@/routes';

import '@/i18next';

import { store } from './store';
import { CacheChecker, ErrorFallback, StorageCleaner, ThemeProvider, ToastProvider } from './components';

function App() {
  return (
    <ThemeProvider>
      <Sentry.ErrorBoundary fallback={() => <ErrorFallback />}>
        <CacheChecker>
          <StorageCleaner>
            <Provider store={store}>
              <RouterProvider router={router} />
              <ToastProvider />
            </Provider>
            <div
              style={{
                position: 'fixed',
                bottom: 2,
                right: 2,
              }}
            >
              <EuiText size="xs" color="subdued">
                v:{process.env.REACT_APP_VERSION_NUMBER}
              </EuiText>
            </div>
          </StorageCleaner>
        </CacheChecker>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
