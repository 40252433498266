import { createAction, createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Mutex } from 'async-mutex';
import fileDownload from 'js-file-download';
import axios from 'axios';

import { leakApi } from '@/api';
import { RootState } from '../store';
import { getUiLimitRow } from '../customer/selectors';
import { toastActions, toastSelectors } from '../toast';
import { ToastVariantBasic } from '@/components';
import { appActions } from '../app';

import { getTotalData } from './selectors';

export interface State {
  searchParams: Definitions.Search | null;
  loading: boolean;
  items: Definitions.Credential[];
  total_items: Definitions.TotalItems;
  error: Definitions.Error | null;
  isExportPennding: boolean;
  isLoadingCredentials: string[];
  editNote: null | (Paths.UpdateCredentialNotes.Parameters.Body & Paths.UpdateCredentialNotes.PathParameters);
  filters: Definitions.Filter[];
  leak: Definitions.Leak | null;
}

const EXPORT_FILENAME = 'data.json';

const mutexExportData = new Mutex();

const clearSearch = createAction('credentials/clearSearch');
const clearSearchParams = createAction('credentials/clearSearchParams');

const updateNote = createAction<
  null | (Paths.UpdateCredentialNotes.Parameters.Body & Paths.UpdateCredentialNotes.PathParameters)
>('credentials/updateNote');

const showLeak = createAction<null | Definitions.Leak>('credentials/showLeak');

export const fetchData = createAsyncThunk('credentials/fetchData', async (payload: Definitions.Search, thunkApi) => {
  thunkApi.dispatch(showLeak(null));
  thunkApi.dispatch(appActions.getDatabaseLoad());
  const state = thunkApi.getState() as RootState;

  if (
    !!state.credentials.searchParams &&
    !Object.entries(payload).some(
      ([key, value]) =>
        state.credentials.searchParams && value !== state.credentials.searchParams[key as keyof Definitions.Search]
    )
  ) {
    return {
      items: state.credentials.items,
      total_items: state.credentials.total_items,
    };
  }
  try {
    const {
      data: { data },
    } = await leakApi.search(payload);

    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data || { error });
  }
});

export const exportData = createAsyncThunk('credentials/exportData', async (payload: Definitions.Search, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const totalItems = getTotalData(state);
  const uiLimitRow = getUiLimitRow(state);

  if (uiLimitRow && totalItems && totalItems.value) {
    thunkApi.dispatch(
      toastActions.create({
        type: ToastVariantBasic.DOWNLOADING_FILE,
      })
    );
    let items: Definitions.Credential[] = [];
    const total = Math.min(totalItems.value, uiLimitRow);
    let offset = 0;
    const limit = 100;
    try {
      while (offset < total) {
        const release = await mutexExportData.acquire();
        const {
          data: { data },
        } = await leakApi.search({ ...payload, limit, offset });
        if (data && data.items) {
          items = [...items, ...data.items];
        }

        offset += limit;
        release();
      }
      fileDownload(JSON.stringify(items, null, 2), EXPORT_FILENAME);
      thunkApi.dispatch(
        toastActions.create({
          type: ToastVariantBasic.DOWNLOADED_FILE,
        })
      );
    } catch (error) {
      thunkApi.dispatch(
        toastActions.create({
          type: ToastVariantBasic.DOWNLOADED_ERROR_FILE,
        })
      );
      throw error;
    } finally {
      const toasts = toastSelectors.getItems(thunkApi.getState() as RootState);
      const toast = toasts.find((item) => item.type === ToastVariantBasic.DOWNLOADING_FILE);
      if (toast) thunkApi.dispatch(toastActions.remove(toast.id));
    }
  }
});

const updateNoteCredentialById = createAsyncThunk(
  'credentials/updateNoteCredentialById',
  async (
    payload: Paths.UpdateCredentialNotes.Parameters.Body & Paths.UpdateCredentialNotes.PathParameters,
    thunkApi
  ) => {
    try {
      const fd = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(payload).filter(([_, v]) => v !== undefined && v !== '')
      ) as Paths.UpdateCredentialNotes.Parameters.Body & Paths.UpdateCredentialNotes.PathParameters;

      const {
        data: { data },
      } = await leakApi.updateNote(fd);
      return data;
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const responseError = e?.response as { data: Paths.CreateGroup.Responses.$400 };
        return thunkApi.rejectWithValue(responseError?.data?.error);
      }
    }
  }
);

export const fetchFilters = createAsyncThunk(
  'credentials/fetchFilters',
  async (payload: Paths.CredentialSearchFilters.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await leakApi.getCredentialSearchFilter(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.response?.data || { error });
    }
  }
);

const slice = createSlice<State, SliceCaseReducers<State>>({
  name: 'credentials',
  initialState: {
    loading: false,
    error: null,
    items: [],
    total_items: {},
    isExportPennding: false,
    isLoadingCredentials: [],
    editNote: null,
    searchParams: null,
    filters: [],
    leak: null,
  },
  reducers: {
    clearSearch: (state) => {
      state.items = [];
      state.total_items = {};
      state.error = null;
      state.searchParams = null;
      state.leak = null;
    },
    clearSearchParams: (state) => {
      state.searchParams = null;
    },
    updateNote: (state, action) => {
      state.editNote = action.payload;
    },
    showLeak: (state, action) => {
      state.leak = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.searchParams = action.meta.arg;
        if (action.payload) {
          const { items, total_items } = action.payload;
          state.items = items || [];
          state.total_items = total_items || {};
        }

        state.loading = false;
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.searchParams = action.meta.arg;
        const { error } = action.payload as Paths.SearchCredentials.Responses.$400;
        state.loading = false;
        state.items = [];
        state.total_items = {};
        state.error = error || {
          message: 'unknownError',
        };
      });

    builder
      .addCase(updateNoteCredentialById.pending, (state, action) => {
        state.isLoadingCredentials = [...state.isLoadingCredentials, action.meta.arg.id];
      })
      .addCase(updateNoteCredentialById.fulfilled, (state, action) => {
        state.isLoadingCredentials = state.isLoadingCredentials.filter((id) => action.meta.arg.id !== id);
        state.items = state.items.map((item) =>
          item.id === action.payload?.id ? (action.payload as Definitions.CredentialResponse) : item
        );
      })
      .addCase(updateNoteCredentialById.rejected, (state, action) => {
        state.isLoadingCredentials = state.isLoadingCredentials.filter((id) => action.meta.arg.id !== id);
      });

    builder
      .addCase(exportData.pending, (state) => {
        state.isExportPennding = true;
      })
      .addCase(exportData.fulfilled, (state) => {
        state.isExportPennding = false;
      })
      .addCase(exportData.rejected, (state) => {
        state.isExportPennding = false;
      });

    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      if (action.payload) {
        state.filters = action.payload.items || [];
      }
    });
  },
});

export const actions = {
  clearSearch,
  fetchData,
  exportData,
  updateNoteCredentialById,
  updateNote,
  clearSearchParams,
  fetchFilters,
  showLeak,
};

export default slice.reducer;
