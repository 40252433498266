import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiIcon,
  EuiPopover,
  IconType,
  useEuiTheme,
} from '@elastic/eui';
import { css } from '@emotion/css';
import { TileLayer as LeafletTileLayer, TileLayerOptions, tileLayer } from 'leaflet';

import { useMap } from '../MapContainer';
import { LayerTheme } from '../types';

import { layersControl } from './LayersControl';

interface TileLayer {
  name: string;
  url: string;
  options: TileLayerOptions;
  icon: IconType;
  theme: LayerTheme;
}

interface LayersProps {
  layers: TileLayer[];
}

export const Layers = memo<LayersProps>(
  function Layers({ layers }) {
    const ctr = useRef(layersControl());
    const prevLayer = useRef<LeafletTileLayer | null>(null);

    const { map, setLayerTheme } = useMap();
    const { euiTheme } = useEuiTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState<number | null>(null);

    const loadedLayers = useMemo<[string, LeafletTileLayer][]>(
      () =>
        layers.map(({ url, options, name }) => {
          const layersObject: [string, LeafletTileLayer] = [name, tileLayer(url, options)];
          return layersObject;
        }),
      [layers]
    );

    useEffect(() => {
      if (selected !== null && loadedLayers[selected]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, layer] = loadedLayers[selected];
        layer.addTo(map);
        if (prevLayer.current) {
          prevLayer.current.removeFrom(map);
        }
        prevLayer.current = layer;
      }
    }, [selected, loadedLayers, map]);

    useEffect(() => {
      if (selected === null && layers.length > 0) {
        setSelected(0);
      }
    }, [selected, layers]);

    useEffect(() => {
      const control = ctr.current;
      if (!control.isInit) control.addTo(map);

      return () => {
        control.remove();
      };
    }, [map]);

    const items = useMemo(
      () =>
        layers.map((layer, index) => (
          <EuiContextMenuItem
            key={`layer-${index}`}
            icon={<EuiIcon size="xl" type={layer.icon} />}
            onClick={() => {
              setLayerTheme(layer.theme);
              setSelected(index);
            }}
            size="s"
          >
            {layer.name}
          </EuiContextMenuItem>
        )),
      [layers, setLayerTheme]
    );

    const handleToggle = () => {
      setIsOpen((prev) => !prev);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    if (ctr.current.button) {
      return createPortal(
        <EuiPopover
          id="layers"
          button={
            <EuiButtonIcon
              className={css(`
                background-color: ${euiTheme.colors.emptyShade};
              `)}
              display="base"
              color="text"
              size="s"
              iconType="layers"
              onClick={handleToggle}
              onDoubleClickCapture={(e: any) => e.stopPropagation()}
              onDoubleClick={(e: any) => e.stopPropagation()}
            />
          }
          isOpen={isOpen}
          closePopover={handleClose}
          panelPaddingSize="none"
          anchorPosition="leftUp"
          offset={5}
        >
          <EuiContextMenuPanel size="s" items={items} />
        </EuiPopover>,
        ctr.current.button
      );
    }

    return null;
  },
  () => true
);
