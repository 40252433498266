import { EuiCallOut, EuiButtonEmpty, EuiText, useEuiTheme, EuiIcon, EuiFlexGroup } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { useLinkClickHandler } from 'react-router-dom';
import { FC } from 'react';

import { Routes } from '@/routes';

export const AccountTwoAuthCallOut: FC = () => {
  const url = `${Routes.ACCOUNT_GENERAL_SETTING}#two-auth`;
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  const handleShowAllData = useLinkClickHandler(url);

  return (
    <EuiCallOut
      style={{
        borderRadius: euiTheme.border.radius.medium,
      }}
      size="s"
      color="warning"
    >
      <EuiFlexGroup gutterSize="s">
        <EuiIcon type="alert" color="warning" />
        <EuiText size="s" color="warning">
          {t('accountTwoAuthCallout.message')}
          &nbsp;
          <EuiButtonEmpty
            style={{ padding: 0, height: 'auto' }}
            contentProps={{
              style: {
                padding: 0,
              },
            }}
            href={url}
            onClick={handleShowAllData}
            color="warning"
          >
            {t('accountTwoAuthCallout.anchor')}
          </EuiButtonEmpty>
        </EuiText>
      </EuiFlexGroup>
    </EuiCallOut>
  );
};
