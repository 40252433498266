import { EuiButton, EuiContextMenuPanel, EuiFlexGroup, EuiPopover } from '@elastic/eui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Routes } from '@/routes';
import { useSelectedApp } from '@/hooks';
import { useAppSelector } from '@/store';
import { customerSelectors } from '@/store/customer';

import { HeaderMenuLink } from './HeaderMenuLink';

const HOME_KEY = 'HOME';

const checkPermission = (permission: Definitions.ProductsPermission | undefined, route: Routes) => {
  switch (route) {
    case Routes.LEAK_REGISTRY:
    case Routes.PROFILER:
    case Routes.PROFILER_2:
      return !!permission?.profiler;
    case Routes.PASSWORD_FINDER:
      return !!permission?.password_finder;
    case Routes.PHONEBOOK:
      return !!permission?.phonebook;
    case Routes.DATASETS:
      return Boolean(permission?.password_finder || permission?.profiler);
    case Routes.RECURSIVE_SEARCH:
      return !!permission?.recursive_search;
    case Routes.MAP_RADAR:
      return !!permission?.map_radar;
    default:
      return true;
  }
};

export const HeaderMenu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { selectedApp } = useSelectedApp();
  const user = useAppSelector(customerSelectors.getInfo);

  const items = useMemo(
    () =>
      [
        { route: Routes.HOME, name: HOME_KEY },
        { route: Routes.PASSWORD_FINDER, name: 'PASSWORD_FINDER' },
        { route: Routes.PROFILER, name: 'PROFILER' },
        { route: Routes.PROFILER_2, name: 'PROFILER_2' },
        { route: Routes.RECURSIVE_SEARCH, name: 'RECURSIVE_SEARCH' },
        { route: Routes.LEAK_REGISTRY, name: 'LEAK_REGISTRY' },
        { route: Routes.DATASETS, name: 'DATASETS' },
        { route: Routes.PHONEBOOK, name: 'PHONEBOOK_PARSER' },
        { route: Routes.MAP_RADAR, name: 'MAP_RADAR' },
      ].filter(({ route }) => checkPermission(user?.products_permission, route)),
    [user]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={handleClose}
      panelPaddingSize="none"
      anchorPosition="downLeft"
      hasArrow={true}
      offset={4}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
      }}
      button={
        <EuiFlexGroup
          style={{
            height: '100%',
          }}
          alignItems="center"
        >
          <EuiButton iconType="arrowDown" iconSide="right" size="s" onClick={handleOpen}>
            {t(`menu.apps.${selectedApp ?? HOME_KEY}`)}
          </EuiButton>
        </EuiFlexGroup>
      }
    >
      <EuiContextMenuPanel
        style={{
          width: 200,
        }}
        items={items.map(({ name, route }) => (
          <HeaderMenuLink key={route} name={t(`menu.apps.${name}`)} to={route} onClick={handleClose} />
        ))}
      />
    </EuiPopover>
  );
};
