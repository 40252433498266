import { EuiPageSection } from '@elastic/eui';
import { useContext } from 'react';

import { LeakProfilerData } from '@/components';
import { SortColumnDirection } from '@/components/SearchData/types';
import { sortingUiAdapter } from '@/components/SearchData/helper';

import { SearchResultContext } from './context';

export const SearchResult = () => {
  const {
    activePage,
    limit,
    isSearched,
    defaultValues,
    handleChangeItemsPerPage,
    handleChangePage,
    handleChangeSort,
    handleExportToJson,
  } = useContext(SearchResultContext);

  return (
    <EuiPageSection
      paddingSize="none"
      grow
      alignment="center"
      contentProps={{
        css: {
          width: '100%',
        },
      }}
    >
      <LeakProfilerData
        activePage={activePage}
        limitPagination={limit}
        onChangeItemsPerPage={handleChangeItemsPerPage}
        onChangePage={handleChangePage}
        onChangeSort={handleChangeSort}
        onExport={handleExportToJson}
        isSearched={isSearched}
        initialSort={
          defaultValues.order_by && defaultValues.sort_by
            ? sortingUiAdapter({
                id: defaultValues.order_by,
                direction: defaultValues.sort_by as SortColumnDirection,
              })
            : null
        }
      />
    </EuiPageSection>
  );
};
