import { FunctionComponent, RefObject, memo, useEffect, useMemo, useState } from 'react';
import { EuiButton } from '@elastic/eui';

import { PointMachine } from '../types';

import { LeakMapPointCard } from './LeakMapPointCard';

interface LeakPaginationProps {
  data: PointMachine[];
  containerRef: RefObject<HTMLDivElement>;
}
const LIMIT = 100;
export const LeakPagination: FunctionComponent<LeakPaginationProps> = memo(function LeakPagination({
  data,
  containerRef,
}) {
  const [page, setPage] = useState<number>(1);
  const totalPages = useMemo(() => Math.ceil(data.length / LIMIT), [data]);
  const showedData = useMemo(() => [...data].slice((page - 1) * LIMIT, page * LIMIT), [data, page]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
      });
    }
  }, [page, containerRef]);
  return (
    <>
      {page > 1 && (
        <EuiButton
          aria-label="Previews"
          onClick={() => setPage((state) => state - 1)}
          fullWidth
          fill
          iconSide="right"
          iconType="arrowUp"
        >
          Previews
        </EuiButton>
      )}
      {showedData.map((machine) => (
        <LeakMapPointCard point={machine} key={machine.id} />
      ))}
      {page < totalPages && (
        <EuiButton
          aria-label="Next"
          onClick={() => setPage((state) => state + 1)}
          fullWidth
          fill
          iconSide="right"
          iconType="arrowDown"
        >
          Next
        </EuiButton>
      )}
    </>
  );
});
