import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiSpacer, EuiText, EuiToolTip } from '@elastic/eui';
import { FC, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { Languages } from '@/i18next/types';

import { iconTypeMap } from './constants';
import { DatasetModal } from './DatasetModal';

interface DatasetCardProps {
  data: Definitions.Dataset;
}

export const DatasetCard: FC<DatasetCardProps> = ({ data }) => {
  const { lines_count, icon } = data;

  const [isOpenModal, setOpenModal] = useState(false);

  const { i18n } = useTranslation();

  const showedIcon = useMemo(() => {
    if (icon) {
      if (iconTypeMap.includes(icon)) {
        return (
          <EuiIcon
            className={css(`
              block-size: 56px;
              inline-size: 56px;

              & .euiIcon__fillSecondary {
                fill: inherit;
              }
            `)}
            type={icon}
            size="xxl"
          />
        );
      } else {
        return (
          <EuiIcon
            className={css(`
                block-size: 56px;
                inline-size: 56px;
              `)}
            size="xxl"
            type={(props: any) => {
              return <SVG src={icon} {...props} />;
            }}
          />
        );
      }
    }
    return (
      <EuiIcon
        className={css(`
          block-size: 56px;
          inline-size: 56px;
        `)}
        type={'reporter'}
        size="xxl"
      />
    );
  }, [icon]);

  const description = useMemo(() => {
    if (i18n.language !== Languages.EN && data[`description_${i18n.language}` as keyof Definitions.Dataset]) {
      return data[`description_${i18n.language}` as keyof Definitions.Dataset] as string;
    } else {
      return data.description;
    }
  }, [data, i18n.language]);

  const title = useMemo(() => {
    if (i18n.language !== Languages.EN && data[`title_${i18n.language}` as keyof Definitions.Dataset]) {
      return data[`title_${i18n.language}` as keyof Definitions.Dataset] as string;
    } else {
      return data.title;
    }
  }, [data, i18n.language]);

  const countLines = lines_count
    ? new Intl.NumberFormat('en', { notation: 'compact' }).format(lines_count).toString()
    : '0';

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <>
      <EuiPanel
        hasBorder
        hasShadow={false}
        borderRadius={'m'}
        onClick={handleOpen}
        className={css(`
        width: calc( (100% - (24px * 2) ) / 3 )!important;
        flex: 0 1 auto;
      `)}
      >
        <EuiFlexGroup
          gutterSize="s"
          alignItems="flexStart"
          style={{
            height: '100%',
          }}
        >
          {!!showedIcon && <EuiFlexItem grow={false}>{showedIcon}</EuiFlexItem>}
          <EuiFlexItem
            grow={1}
            style={{
              minWidth: 0,
            }}
          >
            <EuiFlexGroup gutterSize="xs" alignItems="center">
              <EuiFlexItem>
                <EuiToolTip position="top" content={title}>
                  <EuiText
                    size="xs"
                    className={css(`
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        font-weight: bold;
                        word-break: break-all;
                      `)}
                  >
                    {title}
                  </EuiText>
                </EuiToolTip>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiBadge>
                  <EuiText size="xs">
                    <b>{countLines}</b>
                  </EuiText>
                </EuiBadge>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="xs" />
            <EuiText
              size="xs"
              color="subdued"
              className={css(`
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-all;
              `)}
            >
              {description}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
      {isOpenModal && (
        <DatasetModal title={title} description={description} icon={showedIcon} data={data} onClose={handleClose} />
      )}
    </>
  );
};
