import { DisambiguateSet, EuiFlexItem, EuiPanel, useEuiTheme } from '@elastic/eui';
// eslint-disable-next-line import/no-unresolved
import { _EuiPanelButtonlike, _EuiPanelDivlike } from '@elastic/eui/src/components/panel/panel';
import { FunctionComponent } from 'react';

export const Panel: FunctionComponent<
  | (DisambiguateSet<_EuiPanelButtonlike, _EuiPanelDivlike> & _EuiPanelDivlike)
  | (DisambiguateSet<_EuiPanelDivlike, _EuiPanelButtonlike> & _EuiPanelButtonlike)
> = ({ style, ...props }) => {
  const { euiTheme } = useEuiTheme();
  return (
    <EuiFlexItem grow={1}>
      <EuiPanel
        {...props}
        style={{
          ...style,
          minHeight: '100%',
          paddingInline: euiTheme.size.xxxxl,
        }}
        hasBorder={false}
        hasShadow={false}
        borderRadius="none"
        paddingSize="xl"
      />
    </EuiFlexItem>
  );
};
